import React, {useEffect, useState, useCallback} from "react";
import styled from "styled-components";
import {Component, Container, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../style/comp/comm';
import {DetailCard} from "./DetailCard";
import {DetailList} from "./DetailList";

import PagePrevIcon from "../../../media/images/CultureMap/pagePrevIcon.webp";
import PageNextIcon from "../../../media/images/CultureMap/pageNextIcon.webp";
import NoDataIcon from "../../../media/images/Comm/no-data.svg";
import {useQuery} from "react-query";
import { Link, useSearchParams } from 'react-router-dom';

import Search from './search/Search';
import useSearch from './search/useSearch';
import qs from 'qs';


export default function BBS ({ id, info, infoData, queries, tabType, porcessType, viewType, pageSize }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const categoryParam = searchParams.get('category');
  const searchColumnParam = searchParams.get('search_column');
  const searchValueParam = searchParams.get('search_value');
  /* const keywordParam = searchParams.get('keyword');
  const categoryParam = searchParams.get('category');
  const pageParam = searchParams.get('page'); */

  // const userAgent = window.navigator.userAgent.toLowerCase();
  console.log(pageParam)
  const { key, url, images, filter_1, filter_2, name }= infoData;
  const selectedCategory= name;
  const [selectedProcess, setSelectedProcess] = useState(categoryParam || filter_1?.[0] || '');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showCategory, setShowCategory] = useState(false);
  const [categoryValue, setCategoryValue] = useState(filter_2[0]);
  const [filteredData, setfilteredData] = useState([]);

  


  const query= queries?.[id]?.query;

  const [ searchs, setSearchs ]= useState();
  const setSubmit= (searchValue)=> {
    console.log(searchValue)
    if( searchValue.length < 2 ) return alert('2자이상 입력해 주세요')
    const param= {
      search_column: categoryValue.value,
      search_value: searchValue,
    }
    
    setSearchs(qs.stringify(param))
    setSearchParams({
      category: selectedProcess,
      page: currentPage,
      search_column: categoryValue.value,
      search_value: searchValue,
    })
  }
  const { searchValue, setSearchValue, handlerSearchEnter, handlerSearchSubmit, handlerSearchValue } = useSearch({
    query, callback: setSubmit
  });

  const reqParam= {
    currentPage: `current_page=${currentPage}`, 
    search: searchs,
    process:  qs.stringify({
      category: selectedProcess,
    }), 
  }
  console.log(reqParam)
  const { data, error, isLoading, refetch } = useQuery([key, 
    reqParam
  ], 
  query,);
  
  useEffect(()=> {
    console.log(infoData, pageParam)
    setCurrentPage(pageParam ? Number(pageParam) : 1)
    setSelectedProcess(categoryParam || filter_1?.[0] || '');
    if( searchColumnParam && searchValueParam ) {
      setSearchValue(searchValueParam)
      setSearchs(qs.stringify({
        search_column: searchColumnParam,
        search_value: searchValueParam,
      }))
    } else {
      setSearchValue('')
      setSearchs('')
    }
    
  }, [infoData]);


  const handlePageChange = (page) => {
    console.log(categoryValue)
    setCurrentPage(page);
    setSearchParams({
      category: selectedProcess,
      search_column: categoryValue,
      search_value: searchValue,
      page
    })
  };

  /* const handleCategoryChange = (category) => {
    // setSelectedCategory(category);
    setSelectedProcess(filter_1[0]);
    setCurrentPage(1);
    
  } */

  const handleProcessChange = (process) => {
    setSelectedProcess(process);
    setCurrentPage(1);
    setSearchParams({
      category: process,
      search_column: categoryValue,
      search_value: searchValue,
      page: 1,
    })
  }

  const handleSelection = (value) => {
    setCategoryValue(value)
    setShowCategory(false);
  };
  
  const processFilters= (
    filter_1 && 
    <div className={`flx-cl m-order-1 ${porcessType == 'tab-side' ? '' : 'm-mg-t10'} m-w100 m-flx-cr`}>
      {filter_1.map((process, index) => {
        return <React.Fragment key={index}>
          <BodyText1
            className="ft-b cursor-point"
            onClick={() => handleProcessChange(process)}
            style={{color: selectedProcess === process ? '#5b9dc9' : 'initial'}}
          >
            {process}
          </BodyText1>
          {index < filter_1.length-1 && <div className="m-x5 ft-b">|</div>}
        </React.Fragment>
      })}
    </div>
  )


  console.log(data)
  if( isLoading ) return <div className="h100v flx-cc">...Loading</div>
  return (
    <div className="p-x10">
    <Component>
      {
        images && <>{images[0]}</>
      }


      {/* 키워드 작성란 */}
      <div className="flx-cr m-flx-col zIdx3">
        {
          porcessType == 'side' && processFilters
        }
        {/* 진행 상황에 따른 카테고리 버튼 */}
        <div className="m-w100 flx-0 m-l10 m-order-0 m-mg-s0">
          <div className="flx-0 inst-box">
            <DropdownInput 
              className=""
              onClick={() => setShowCategory(!showCategory)}
              readOnly value={categoryValue.key} 
              // Medge={userAgent.includes('windows')}
            />
            {showCategory && (
              <>
              {
                filter_2 && 
                <DropdownListSection className="p-y2">
                  {
                    filter_2.map(f=> {
                      return (
                        <DropdownListText key={f.value} onClick={() => handleSelection(f)}>{f.key}</DropdownListText>
                      )
                    })
                  }
                </DropdownListSection>
              }
              </>
              
            )}
          </div>
          
          <Search 
            searchValue={searchValue} setSearchValue={setSearchValue} 
            handlerSearchEnter={handlerSearchEnter} 
            handlerSearchSubmit={handlerSearchSubmit} 
            handlerSearchValue={handlerSearchValue}
          />
        </div>
      </div>



      {/* 각 카테고리 버튼 헤더 */}
      
      <div className="w100 flx-sbc bd-tp-bk m-t20 inst-box zIdx2" style={{ backgroundColor: '#f5f5f1'}}>

      {
        (tabType != 'none' || porcessType == 'tab-side') && 
        <div className="w100 p-y10 flx-sbc bd-bt-bk">
          {
            tabType != 'none' && 
            <div className="flx-cl txt-nowrap">
              {info.map((val, index) => {
                const { name: category, url, id }= val;
                return <React.Fragment key={id}>
                  <div
                    className=""
                    // onClick={() => handleCategoryChange(category)}
                    style={{color: selectedCategory == category ? '#5b9dc9' : 'initial'}}
                  >
                    <Link to={`${url}`}>
                      <BodyText1 className="ft-b">{category}</BodyText1>
                    </Link>
                  </div>
                  {index < info.length-1 && <div className="ft-b m-x5">|</div>}
                </React.Fragment>
              })}
            </div>
          }
          { porcessType == 'tab-side' && processFilters }
        </div>
      }
      </div>
      

      {
        ( viewType == 'list' ) && 
        <DetailList 
          data={data}
          selectedCategory={selectedCategory} 
          url={`${url}`} 
          id={id} info={info} 
          infoData={infoData}
          reqParam={reqParam}
          query={query}
        />
      }
      



      {
        ( !viewType || viewType == 'grid' )  && 
        <div className="flx-w grid-r10 m-t10">
          {
            data?.articles.length == 0 && 
            <div className="w100">
              <div className="flx-cc w100 p-y40">
                <img src={NoDataIcon} alt="" className="icon-img-m" />
                <BodyText1>검색결과가 없습니다.</BodyText1>
              </div>
              
            </div>
          }
          {
            data?.articles.map((item, key) => {
              const param={};
              Object.keys(infoData).map(key=> {
                if( key != 'images' ) param[key]= infoData[key]
              })

              
              return (
                <div key={item.id} className="w25-r10 m-w50-r10 m-x10 mg-h20 flx-0">
                  <DetailCard 
                    data={item} allData={filteredData} 
                    selectedCategory={selectedCategory} 
                    url={`${url}/detail/`} 
                    id={id} info={info} infoData={param}
                    reqParam={reqParam}
                    query={query}
                  />
                </div>
              )
            })
          }
        </div>
      }
      


      {/* 페이지네이션 */}
      <div className="flx-cc m-y20">
        <PrevButton
          src={PagePrevIcon}
          alt="Previous"
          onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
        />
        {Array.from({length: Math.ceil(data?.total_count/8)}, (_, index) => index + 1).map(page => (
          <TinyText
            className="ft-b cursor-point m-x5"
            key={page}
            onClick={() => handlePageChange(page)}
            style={{color: currentPage === page ? '#5b9dc9' : 'initial'}}
          >
            {page}
          </TinyText>
        ))}
        <PrevButton
          src={PageNextIcon}
          alt="Next"
          onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
        />
      </div>


    </Component>
    </div>
  )
}


const PrevButton = styled.img`
  width: 10px;
  cursor: pointer;
  margin: 0 20px;
  @media (max-width: 800px) {
    width: 8px;
  }
`;

const DropdownInput = styled.input`
  border: none;
  width: 85px;
  background-color: black;
  color: white;
  text-align: center;
  cursor: pointer;
`

const DropdownListSection = styled.div`
  position: absolute;
  bottom: -1px;
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  transform: translateY(100%);
`

const DropdownListText = styled.div`
  cursor: pointer;
  margin: 4px 0;
  color: white;
`