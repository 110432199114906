import {useState} from 'react';
import {BodyText1, BodyText2, TinyText1, Divider, Divider1_1,}from '../../../../style/comp/comm';
import styled from "styled-components";
import {Title} from '../../../../style/comp/comm';
import Img1 from "../../../../media/images/Culture/img-1.png";
import Img2 from "../../../../media/images/Culture/img-2.png";
import Img3 from "../../../../media/images/Culture/img-3.png";
import Img4 from "../../../../media/images/Culture/img-4.png";
import Arrow3 from "../../../../media/images/Comm/arrow-3.png";



const info= [
  {
    id: '문화도시추진위원회',
    title: '문화도시추진위원회',
    subject: (
      <>
      <p className="">
        <BodyText1>문화도시추진위원회는 문화도시를 함께 만들어나가는 주체로서 </BodyText1>
      </p>
      <p className="">
        <BodyText1 className='ft-c1'>'문화도시 충주'의 핵심 동력을 모아 진정성 있는 추진 방향으로 나아가고자 하는 사람들로 구성되어 있습니다</BodyText1>
      </p>
      </>
    ),
    image: Img1,
    list: {
      name: '문화도시추진위원회',
      data: [
        { name: '이정남', org: '문화체육관광국장'},
        { name: '최내현', org: '예총충주지회장'},
        { name: '이영희', org: '민예총충주지부장'},
        { name: '이영광', org: '충주예술인협회장'},
        { name: '신선희', org: '서양화가(前민미협회장)'},
        { name: '박경환', org: '성악가(前음악협회장)'},
        { name: '김진미', org: '청주예술단 예술감독'},
        { name: '김순화', org: '충주오페라단 단장'},
        { name: '김경인', org: '충주 문향회 회장'},
        { name: '임창식', org: '충주사회단체연합회장'},
        { name: '박해림', org: '건국대글로컬캠퍼스교수'},
        { name: '채희락', org: '충주시의회 의원'},
        { name: '김선영', org: '홍익대 교수'},
      ]
    }
  },
  {
    id: '문화다양성위원회',
    title: '문화다양성위원회',
    subject: (
      <>
      <p className="">
        <BodyText1>문화다양성위원회는 남녀노소, 각양각색의 직업을 가진  충주시민들이 모여 다양한 충주사람의 이야기를 담습니다. </BodyText1>
      </p>
      </>
    ),
    image: Img2,
    list: {
      name: '문화다양성위원회',
      data: [
        { name: '최성자', org: '문화예술산업연구소'},
        { name: '차종혁', org: '충주자연생태체험관'},
        { name: '이선희', org: '충주모모학교'},
        { name: '류효숙', org: '충주작은영화제'},
        { name: '이연호', org: '책이있는글터'},
        { name: '오세정', org: '노은면노인복지센터'},
        { name: '우혜빈', org: '마을회관'},
        { name: '양승희', org: '파파이팜'},
        { name: '손병용', org: '내포긴들 체험마을'},
        { name: '민자영', org: '(사)충주시 장애인분모연대 회장'},
        { name: '엄수정', org: '스페이스선(사회적 기업)'},
        { name: '신동진', org: '연원마을 신문 개국'},
        { name: '도미니크', org: '레돔(양조장)'},
        { name: '최효정', org: '업싸이클창작기술체험터'},
        { name: '장효민', org: '한국교통대 디자인학부 교수'},
        { name: '이종민', org: '한국교통대 경영학 학생'},
        { name: '신동주', org: '칠금중3학년(동아리연합회장)'},
        { name: '남다은', org: '동덕여대 1학년'},
        { name: '예장희', org: '14그램'},
      ]
    }
  },

  {
    id: '인문사회위원회 ',
    title: '인문사회위원회 ',
    subject: (
      <>
      <p className="">
        <BodyText1>인문사회위원회는 충주의 인문, 역사, 예술의 거장들이 함께 모여 다음 세대를 위한 문화도시 유산을 생각합니다.  </BodyText1>
      </p>
      </>
    ),
    image: Img3,
    list: {
      name: '인문사회위원회',
      data: [
        { name: '홍석화', org: '(사)충주시여성단체협의회장'},
        { name: '엄창수', org: '충주시 지체장애인총연합회장'},
        { name: '김경구', org: '동화작가'},
        { name: '채희천', org: '건축가(건축사협회장)'},
        { name: '전찬덕', org: '행단연서회 회장'},
        { name: '백경임', org: '충북예총부회장'},
        { name: '김병구', org: '충주학연구소장'},
        { name: '엄정옥', org: '충주 YWCA'},
        { name: '이상기', org: '중심고을연구원(문학박사)'},
        { name: '이상은', org: '건국대 명예교수'},
        { name: '정연자', org: '건국대 뷰티디자인학 교수'},
        { name: '이상희', org: '(사)대한노인회 충주시지회장'},
        { name: '구자승', org: '서양화가'},
        { name: '장준식', org: '국원문화재연구원장'},
        { name: '백종오', org: '한국교통대학교 교수'},
        { name: '권재은', org: '소리마을 고문'},
        { name: '오영미', org: '한국교통대학교 교수'},
        { name: '전한숙', org: '임계'},
        { name: '문재범', org: '국립중원문화재연구소장'},
        { name: '김인동', org: '충주 전통문화회장'},
        { name: '최원석', org: '건국대학교 교수'},
      ]
    }
  },

  
  {
    id: '청년문화기획단 ',
    title: '청년문화기획단 ',
    subject: (
      <>
      <p className="">
        <BodyText1>청년문화기획단은 <span className="ft-c1 ft-md">도시의 새로운 성장동력</span>인 로컬 크리에이터와 청년 예술가가 함께 모여 진정석 있는 도시의 성장을 고민합니다. </BodyText1>
      </p>
      </>
    ),
    image: Img4,
    list: {
      name: '청년문화기획단',
      data: [
        { name: '김세영', org: '살로메'},
        { name: '이상창', org: '세상상회'},
        { name: '선한빛', org: '선한빛라이프팩토리'},
        { name: '양재형', org: '천연염색바른'},
        { name: '백준하', org: '꽃마피엔씨'},
        { name: '박진영', org: '유월상점'},
        { name: '유순상', org: '순아트스튜디오'},
        { name: '심규민', org: '㈜튠즈'},
        { name: '이슬기', org: '극단 보물/해슬'},
        { name: '이성경', org: '포스네'},
      ]
    }
  }
];

export default function CityBottom () {
  const [drop, setDrop]= useState('');
  const handlerDrop= (area)=> (e)=> {
    if( drop ==  area ) {
      setDrop('');
      return;
    }
    setDrop(area);
  }
  return (
    <div className='m-t60'>

      <Divider className="inst-box m-t10 "/>

      <div className="m-t60 p-x20 m-pd-s10">
        <Title>함께하는 사람들 </Title>
        <p className="txt-center m-t5">
          <BodyText2 className='m-t10'>다양한 사람들이 함께 문화도시 충주를 이야기하고 만듭니다. </BodyText2>
        </p>


        {
          info.map(val=> {
            const { id, title, subject, image, list }= val;
            const { name, data }= list;
            const value= drop == id;
            
            return (
              <div className="">
              <div key={id} className="flx-0 m-flx-col m-t40">
                <div className="w50 m-w100 inst-box bd-rd-30 oflow-h">
                  <BorderGradient className='bg-cover-area'></BorderGradient>
                  <img src={image} alt="" className="block-box w100" />
                </div>
                <div className="w50 m-w100 p-l20 m-pd-s0 p-t10 flx-col-sb">
                  <BodyText2 className='ft-b '>{title}</BodyText2>
                  <div className="m-t20 lh-15">{subject}</div>
                  <Divider1_1 className='m-y25 m-mg-h10' />
                  <div onClick={handlerDrop(id)} className="flx-cl cursor-point">
                    <BodyText1 className='ft-c1 ft-b'>함께하는 사람들 </BodyText1>
                    <SmallArrow src={Arrow3} alt="" className='m-l8' drop={value.toString()} />
                  </div>
                </div>
              </div>

              {
                value && 
                <div className="flx-0 bd-tp-dg2 bg-clr-wh m-y20">
                  <div className="w10">
                  {
                    data.map((item, i)=> {
                      return (
                        <div className="bd-bt-gr2 bd-rt-gr2 txt-center p-y2">
                          <TinyText1>{i+1}</TinyText1>
                        </div>
                      )
                    })
                  }
                  </div>

                  <div className="w20 bd-bt-gr2 bd-rt-gr2 flx-cc">
                    <TinyText1 className='ft-eb txt-center m-x4'>{name}</TinyText1>
                  </div>
                  <div className="w20">
                  {
                    data.map((item)=> {
                      const { name }= item
                      return (
                        <div className="bd-bt-gr2 bd-rt-gr2 txt-center p-y2">
                          <TinyText1>{name}</TinyText1>
                        </div>
                      )
                    })
                  }
                  </div>
                  <div className="w50">
                  {
                    data.map((item)=> {
                      const { org }= item
                      return (
                        <div className="bd-bt-gr2 txt-center p-y2">
                          <TinyText1>{org}</TinyText1>
                        </div>
                      )
                    })
                  }
                  </div>

                </div>
              }
              
              </div>
            )
          })
        }
        



      </div>
    </div>
  )
}


const SmallArrow= styled.img`
  width: 16px; 
  transform: ${(props) => props.drop =='true' ? `rotate(180deg)` : 'none'};
  @media (max-width: 800px) {
    width: 12px;
  }
`
const BorderGradient= styled.div`
  border-radius: 30px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(90deg,#e9e223,#92caf0) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /*4*/
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
`
/* const BorderGradient1= styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  margin: 10px;
  border: 3px solid transparent;
  border-radius: 10%;
  background-image: linear-gradient(#fff, #fff), 
  linear-gradient(to right, red 0%,  orange 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
` */


