import React, {useEffect, useState, useCallback} from "react";
import styled from "styled-components";
import SearchIconImage from "../../../../media/images/DreamCityDetail/DreamCityDetailSearchIcon.webp";

export default function Search ({ searchValue, setSearchValue, handlerSearchEnter, handlerSearchSubmit, handlerSearchValue }) {
  

  return (
    <div className="w100 flx-0 inst-box">
      <InputItem 
        value={searchValue}
        onChange={handlerSearchValue}
        onKeyUp={handlerSearchEnter}
        placeholder={'키워드를 입력하세요'}
      />
      <InputIcon 
        onClick={handlerSearchSubmit}
        src={SearchIconImage}
      />
    </div>
  )
}


const InputItem = styled.input`
  width: 240px;
  /* height: 48px; */
  /* padding-left: 21px; */
  padding: .6em;
  /* font-size: 18.5px; */
  color: #a7a9ac;
  border: 3px solid black;
  border-left: none;
  @media (max-width: 800px) {
    width: 100%;
  }
  /* @media (max-width: 1920px) {
    width: 19.47917vw;
    height: 2.5vw;
    padding-left: 1.09375vw;
    font-size: 0.96354vw;
  } */
`

const InputIcon = styled.img`
  position: absolute;
  width: 20px;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  /* @media (max-width: 1920px) {
    width: 1.40625vw;
    height: 1.51042vw;
    right: 0.46875vw;
    top: 0.52vw;
  } */
`