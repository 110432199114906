import React from "react";
import styled from "styled-components";
import ProcessingIcon from '../../../media/images/DreamCityDetail/processingIcon.webp'
import EndProcessIcon from '../../../media/images/DreamCityDetail/endProcessIcon.webp'
import {useNavigate, Link} from "react-router-dom";
import {PUBLISH_URL} from "../../../api/global";
import {Component, Container, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, BodyText3, TinyText, TinyText2, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../style/comp/comm';

export const DetailCard = ({data, allData, selectedCategory, url, infoData, type}) => {
  const navigate = useNavigate();
  const handleContentClick = (data) => {
    // console.log(data, infoData)
    navigate(`${url}${data.id}?type=${type}`, { state: { data: data, allData: allData, selectedCategory: selectedCategory, infoData } });
  };
  // console.log(url, reqParam)
  return (
    <Components 
      onClick={() => handleContentClick(data)}
      className="flx-col cursor-point"
    >
      {/* 우리가 꿈꾸는 도시 카드 */}

      <CardSection>
        <div className="img100 inst-box">
          {/* <div className="bg-cover-area flx-cc h100"> */}
            <CardImg src={`${PUBLISH_URL}${data.thumbnail}`} lazy={'true'} />
            { !infoData?.disableBadge && <CardProcessIcon src={data.event_text === '진행중' ? ProcessingIcon : EndProcessIcon}/> }
          {/* </div> */}
        </div>
      </CardSection>

      {/* <div className="flx-0"> */}
      <div className="m-t10 p-x5 txt-keep flx-itm-r flx-col">
        <div className="flx-itm-r">
          <p className="">
            <BodyText1 className="ft-md">{data.subject}</BodyText1>
          </p>
          <p className="m-t10">
            <BodyText1 className="ft-rg">{data.summary}</BodyText1>
          </p>
        </div>

        <div className="bd-tp-dg2 m-t5">
          <p className="m-t5">
            <BodyText1 className="ft-rg">{data.target}&nbsp;</BodyText1>
          </p>
          {
            data?.tags && data?.tags.length > 0 &&
            <div className="">
              <ul className="">
                {data.tags.map((tag) => 
                  <Link 
                    onClick={e=> e.stopPropagation()}
                    to={`/totalSearch?hashtag=${tag}`} className="m-r5 ft45 ft-c1 ft-gr1-hover"
                  >{`#${tag}`}</Link>
                )}
              </ul>
            </div>
          }
          { !infoData?.disableBadge && 
            <p className="">
              <TinyText2 className="ft-c1 ft-md">{`${data.start_date.slice(0, 10)} ~ ${data.end_date.slice(0, 10)}`}</TinyText2>
            </p>
          }
        </div>
          
      </div>
      {/* </div> */}
    </Components>
  )
}

const Components = styled.div`
  width: 100%;
  /* width: 342px;
  margin-right: 11px;
  margin-left: 11px;
  margin-bottom: 83px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 17.8125vw;
    margin-right: 0.57292vw;
    margin-left: 0.57292vw;
    margin-bottom: 4.32292vw;
  } */
`

const CardImg = styled.img`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: 342px;
  height: 342px;
  margin-bottom: 19px;
  background-color: #282c34;

  @media (max-width: 1920px) {
    width: 17.8125vw;
    height: 17.8125vw;
    margin-bottom: 0.98958vw;
  } */
`

const CardSection = styled.div`
  position: relative;
`

const CardProcessIcon = styled.img`
  position: absolute;
  right: 17px;
  top: 17px;
  width: 90px;
  height: 30px;

  @media (max-width: 1920px) {
    right: 0.88542vw;
    top: 0.88542vw;
    width: 4.6875vw;
    height: 1.5625vw;

  }
`

const CardTitle = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-bottom: 16px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin-bottom: 0.83333vw;
  }
`

const CardDetail = styled.div`
  margin-bottom: 15px;

  @media (max-width: 1920px) {
    margin-bottom: 0.78125vw;
  }
`

const CardLine = styled.div`
  margin-bottom: 14px;
  width: 318px;
  border-bottom: 3px solid #464646;

  @media (max-width: 1920px) {
    margin-bottom: 0.72917vw;
    width: 16.5625vw;
    border-bottom: 0.15625vw solid #464646;
  }
`

const CardTarget = styled.div`
  font-size: 14.5px;
  font-family: 'SCDream5', serif;
  color: #5b9dc9;

  @media (max-width: 1920px) {
    font-size: 0.75521vw;
  }
`

const CardPeriod = styled.div`
  font-size: 14.5px;
  font-family: 'SCDream5', serif;
  color: #5b9dc9;

  @media (max-width: 1920px) {
    font-size: 0.75521vw;
  }
`

const TextSection = styled.div`
  margin-left: 10px;

  @media (max-width: 1920px) {
    margin-left: 0.52083vw;
  }
`