// import {ConsultationRequest} from  './ConsultationRequest';
import styled from "styled-components";
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, BodyText3, TinyText1, Divider, SubTitle3, Divider1} from '../../../style/comp/comm';
import Bg1 from "../../../media/images/Comm/bg-2.png";
import {TitleSection} from '../_Include/Title';

import {ApplicationForm} from "../../blocks/ConsultationRequest/ApplicationForm";
import HillImage from "../../../media/images/ConsultationRequest/consultationRequestHillImage.webp";
import MenIconImage from '../../../media/images/ConsultationRequest/top-img-1.svg';
import PatternImage from '../../../media/images/Comm/top-icon-3.svg';
// export default ConsultationRequest
import Icon1 from '../../../media/images/ConsultationRequest/icon-1.svg';
import Icon2 from '../../../media/images/ConsultationRequest/icon-2.svg';
import Icon3 from '../../../media/images/ConsultationRequest/icon-3.svg';
import Icon4 from '../../../media/images/ConsultationRequest/icon-4.svg';
import Icon5 from '../../../media/images/ConsultationRequest/icon-5.svg';
import Icon6 from '../../../media/images/ConsultationRequest/icon-6.svg';
import Icon7 from '../../../media/images/ConsultationRequest/icon-7.svg';
import Img1 from '../../../media/images/ConsultationRequest/img-1.png';

const title= '상담신청';

const IconStyle= styled.img`
  width: 100%;
  max-width: 48px;
`

const info= [
  {
    id: 1,
    title: '귀농/귀촌 정보 수집하기',
    subject: '귀농/귀촌 관련 기관 방문 또는 전화·온라인 상담을 통해 필요한 정보를 수집하여 정보를 얻습니다.',
    icon: <IconStyle src={Icon1} alt="" />,
    arrow: 'right',
    order: 1,
  },
  {
    id: 2,
    title: '가족들과 의논하기',
    subject: '귀농/귀촌 결심에 관하여 가족들과 충분히 의논한 후  동의를 얻습니다.',
    icon: <IconStyle src={Icon2} alt="" />,
    arrow: 'bottom',
    order: 2,
  },
  {
    id: 3,
    title: '농가 방문 및 영농 체험',
    subject: '귀농/귀촌 시 안정적인 경제 활동을 위해 체험 프로그램,  교육 수강 등에 참여하여 필요한 정보를 얻습니다. ',
    icon: <IconStyle src={Icon4} alt="" />,
    arrow: 'left',
    order: 4,
  },
  {
    id: 4,
    title: '작목 선택 및 영농기술 습득',
    subject: '영농 교육 참여, 우수 농가 견학, 선배 귀농인 방문 등을 통해 영농기술을 배우고 익힙니다.',
    icon: <IconStyle src={Icon3} alt="" />,
    arrow: 'bottom',
    order: 3,
  },
  {
    id: 5,
    title: '정착지 물색',
    subject: '교육, 주거, 자연환경 등을 고려하여 정착지를 물색하고 결정합니다',
    icon: <IconStyle src={Icon5} alt="" />,
    arrow: 'right',
    order: 5,
  },
  {
    id: 6,
    title: '주택 및 농지구입',
    subject: '주택의 규모와 형태, 농지 임차·매입 여부를 결정한 뒤  최소 3~4곳을 비교 후 선택합니다. ',
    icon: <IconStyle src={Icon6} alt="" />,
    arrow: 'bottom',
    order: 6,
  },

  {
    id: 8,
    icon: <img src={Img1} alt="" className="w100 h100" style={{ objectFit: 'cover' }}/>,
    order: 7,
  },
  {
    id: 7,
    title: '영농계획 수립',
    subject: '농산물을 생산하여 수익이 생길 때까지 최소 4개월에서 길게 4~5년 정도 걸리므로 초보 귀농인은 가격 변동이 적고, 영농기술과 자본이 적게 드는 작목 중심으로 영농계획을 수립합니다',
    icon: <IconStyle src={Icon7} alt="" />,
    arrow: 'left',
    order: 8,
  },
]
export default function ConsultationRequest () {
  return (
    <div className="w100">
      
      <Component className="zIdx2">
        <div className="w100 p-x10 zIdx2">
          <div className="inst-box">
            <TitleSection 
              path={`홈 >  ${title}`} 
              title={`${title}`} 
              subtitle={'충주 귀농/귀촌에 대해 무엇이든 물어보세요!'} 
            />
          </div>

          
          <div className="w100 mx1000 mg-ct">
            
            <div className="flx-sbe">
              <p className="m-l40 m-b10 m-mg-10">
                <BodyText2 className="ft-eb">귀농/귀촌 프로세스</BodyText2>
              </p>
              <TopIconArea>
                <TopIconWrap>
                  <PatternImg src={PatternImage}/>
                  <MenIconImg src={MenIconImage}/>
                </TopIconWrap>
              </TopIconArea>
            </div>
            
            <div className="zIdx2">
              
              <DiagramWrap className="flx-w grid-20">
                {
                  info.map( val=> {
                    const { id, title, subject, icon, arrow, order }= val;
                    if( id == 8 ) return (
                      <DiagramItem key={id} className="w50-g20 m-w100 mg-s20 mg-h10 inst-box flx-0" $order={order} $id={id}>
                        <div className="bd-rd-30 oflow-h flx-0 w100">
                          <Diagram1 className="w100"/>
                          <div className="flx-cc w100">
                            {icon}
                          </div>
                        </div>
                      </DiagramItem>
                    )
                    return (
                      <DiagramItem key={id} className="w50-g20 m-w100 mg-s20 mg-h10 inst-box flx-0" $order={order} $id={id}>
                        <Diagram className="w100 p-y10 p-x20" $arrow={arrow}>
                          <div className="flx-cc" style={{minHeight: '110px'}}>
                            {icon}
                          </div>
                          <div className="bd-tp-bk">
                            <div className="flx-0 p-t15 p-b8">
                              <NumId className="">{id}</NumId>
                              <div className="m-l10 flx-col-sb">
                                <p className="">
                                  <BodyText3 className="ft-b">{title}</BodyText3>
                                </p>
                                <p className="m-t7">
                                  <BodyText1 className="ft-md">{subject}</BodyText1>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Diagram>
                      </DiagramItem>
                    )
                  })
                }
              </DiagramWrap>
            </div>
          </div>
        </div>

        

        <div className="bd-tp-bk m-t30 w100">
          <ApplicationForm/>
        </div>
        {/* <HillImg src={HillImage}/> */}
        
      </Component>
      <div className="inst-box p-b80">
        <BgImg1 className=" inst-box">
          {/* <BttomImg src={BgIcon1} alt="" /> */}
        </BgImg1>
        
      </div>
    </div>
  )
}
const NumId= styled.p`
  font-size: 5.8em;
  font-weight: 800;
  line-height: .9;
  @media (max-width: 800px) {
    font-size: 3.8em;
  }
`
const DiagramWrap= styled.div`
  margin-top: -10px;
`
const DiagramItem= styled.div`
  order: ${({$order})=> $order};
  @media (max-width: 800px) {
    order: ${({$id})=> $id};
  }
`

const arrWidth=8;
const Diagram= styled.div`
  border: 7px solid #d1d3d4;
  border-radius: 30px;
  background-color: #fff;
  position: relative;

  ${
    ({$arrow})=> {
      if( $arrow =='right' ){
        return `
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -${arrWidth}px;
            right: -30px;
            border-bottom: solid ${arrWidth}px transparent;
            border-left: solid ${arrWidth*2}px #d1d3d4;
            border-right: solid ${arrWidth}px transparent;
            border-top: solid ${arrWidth}px transparent;
          }
        `
      }
      if( $arrow =='left' ){
        return `
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -30px;
            margin-top: -${arrWidth}px;
            border-bottom: solid ${arrWidth}px transparent;
            border-right: solid ${arrWidth*2}px #d1d3d4;
            border-left: solid ${arrWidth}px transparent;
            border-top: solid ${arrWidth}px transparent;
          }
        `
      }
      if( $arrow =='bottom' ){
        return `
          &::before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -30px;
            margin-left: -${arrWidth}px;
            border-bottom: solid ${arrWidth}px transparent;
            border-top: solid ${arrWidth*2}px #d1d3d4;
            border-left: solid ${arrWidth}px transparent;
            border-right: solid ${arrWidth}px transparent;
          }
        `
      }
    }
  }
  @media (max-width: 800px) {
    &::before {
      content: none;
    }
  }
`
const Diagram1= styled.div`
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  border-radius: 30px; /*1*/
  border: 7px solid transparent; /*2*/
  background: linear-gradient(90deg,#e9e223,#92caf0) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /*4*/
  -webkit-mask-composite: xor; /*5'*/
`

const TopIconArea= styled.div`
  margin-top: -60px;
  position: relative;
  width: 100%;
  max-width: 170px;
  display: flex;
  justify-content: flex-end;
  @media  (max-width: 800px) {
    max-width: 90px;
  }
`
const TopIconWrap= styled.div`
  width: 100%;
  padding-top: 130%;
  position: relative;
  left: -27px;
  overflow: hidden;
`
const PatternImg = styled.img`
  z-index: 0;
  position: absolute;
  right: 0px;
  top: 14%;
  width: 100%;
`

const MenIconImg = styled.img`
  z-index: 1;
  position: absolute;
  bottom: -2px;
  display: block;
  width: 100%;
`

const BgImg1= styled.div`
  /* padding-top: 36%; */
  /* margin-top: -10em; */
  position: absolute;
  bottom : 0;
  width: 100%;
  height: 645px;
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(125%) 0%;
  /* background-size: 230%; */
  @media (max-width: 800px) {
    background-position: calc(100%) 0%;
  }
`
