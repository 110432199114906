import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import Visual1 from '../../../media/images/MainHome/TopSlide/visual-01.webp'
import Visual2 from '../../../media/images/MainHome/TopSlide/visual-02.webp'
import Visual3 from '../../../media/images/MainHome/TopSlide/visual-03.webp'
import GradientButton from '../../../media/images/MainHome/TopSlide/gradientButton.webp'
import WhiteArrow from '../../../media/images/MainHome/TopSlide/whiteArrow.webp'
import PrevImage from '../../../media/images/MainHome/TopSlide/prev.webp'
import NextImage from '../../../media/images/MainHome/TopSlide/next.webp'
import PauseImage from '../../../media/images/MainHome/TopSlide/pause.webp'
import PatternImage from '../../../media/images/MainHome/TopSlide/pattern.webp'
import {API_KEY, API_URL, PUBLISH_URL} from "../../../api/global";
import {Link, useParams} from "react-router-dom";

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation'; // Navigation CSS
import 'swiper/css/scrollbar';

// import required modules
import {Autoplay, Scrollbar, Navigation} from 'swiper/modules';
import {useQuery} from "react-query";
import {main_banner_list} from "../../../api/MainHome";

export const TopSlide = ({ data }) => {
  const swiperRef = useRef(null);
  const [pauseState, setPauseState] = useState(true);
  // const { data, error, isLoading } = useQuery('main_banner_list', main_banner_list);
  console.log(data)


  const pauseAutoplay = () => {
    const swiper = swiperRef.current;
    swiper.swiper.autoplay.pause()
    setPauseState(false)
  };

  const resumeAutoplay = () => {
    const swiper = swiperRef.current;
    swiper.swiper.autoplay.resume()
    setPauseState(true)
  };


  return (
    <>
      <PatternSection>
        <PatternImg src={PatternImage}/>
      </PatternSection>

      <Container>

        {/* 메인 화면 Carosual */}
        <Swiper
          ref={swiperRef}
          modules={[Autoplay, Navigation, Scrollbar]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          scrollbar={{
            draggable: true,
            el: '.myScroll',
            enabled: true
          }}
          navigation={{
            nextEl: '.next',
            prevEl: '.prev',
          }}
          loop={true} // 무한 반복
          style={{width: "1920px"}} // 너비 설정
        >
          {
            data && data.map( d=> {
              
              return (
                <SwiperSlide>
                  <Link to={d.link} target={d.link_new_win ? '_blank' : '_self'}>
                    <MainImageSection>
                      <MainImage src={`${PUBLISH_URL}${d.image_pc}`}/>
                      {/* <ThirdButtonSection /> */}
                    </MainImageSection>
                  </Link>
                </SwiperSlide>
              )
            })
          }
          {/* 첫번째 사진 */}
          {/* <SwiperSlide>
            <MainImageSection>
              <MainImage src={Visual1}/>
              <ButtonSection>
                <ImageButton src={GradientButton}/>
                <ButtonText>위치 확인하기</ButtonText>
                <ButtonArrow src={WhiteArrow}/>
              </ButtonSection>
            </MainImageSection>
          </SwiperSlide> */}

          {/* 두번째 사진 */}
          
          {/* <SwiperSlide>
            <MainImageSection>
              <MainImage src={Visual2}/>
              <ButtonSection>
                <ImageButton src={GradientButton}/>
                <ButtonText>정보 확인하기</ButtonText>
                <ButtonArrow src={WhiteArrow}/>
              </ButtonSection>
            </MainImageSection>
          </SwiperSlide> */}

          {/* 세번째 사진 */}
          {/* <SwiperSlide>
            <MainImageSection>
              <MainImage src={Visual3}/>
              <ThirdButtonSection />
            </MainImageSection>
          </SwiperSlide> */}

        </Swiper>


        <ControllerBox>
          {/* 사진 위치 정보 */}
          <ScrollBarBox>
            <ScrollBarLine className={'myScroll'}>
              <ScrollDrag className={'swiper-scrollbar-drag'}/>
            </ScrollBarLine>
          </ScrollBarBox>

          {/* 사진 컨트롤러 */}
          <NavigationBox>
            <PrevArrowButton className="prev" src={PrevImage}/>
            {pauseState ? <PauseButton onClick={pauseAutoplay} src={PauseImage}/>
              :
              <PlayButton src={NextImage} onClick={resumeAutoplay}/>
            }
            <NextArrowButton className="next" src={NextImage}/>
          </NavigationBox>

        </ControllerBox>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 102px;
  z-index: 1;

  @media (max-width: 1920px) {
    margin-bottom: 5.3125vw;
  }
`
const PatternSection = styled.div`
  height: 80px;
  overflow: hidden;
  position: relative;
  margin-top: 28px;
  display: flex;
  justify-content: end;
  width: 1920px;

  @media (max-width: 1920px) {
    height: 4.1667vw;
    margin-top: 1.4583vw;
    width: 100vw;
  }
`

const PatternImg = styled.img`
  width: 413px;
  height: 196px;
  margin-right: 197px;

  @media (max-width: 1920px) {
    width: 21.5104vw;
    height: 10.2083vw;
    margin-right: 10.2604vw;
  }
`


const MainImageSection = styled.div`
  position: relative;
`

const ButtonSection = styled.div`
  width: 292px;
  height: 44px;
  position: absolute;
  bottom: 80px;
  left: 212px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 15.2083vw;
    height: 2.2917vw;
    bottom: 4.1667vw;
    left: 11.0417vw;
  }
`

const ThirdButtonSection = styled.div`
  width: 352px;
  height: 54px;
  position: absolute;
  bottom: 100px;
  left: 212px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 18.33vw;
    height: 2.81vw;
    position: absolute;
    bottom: 5.21vw;
    left: 11.04vw;
  }
`

const ImageButton = styled.img`
  width: 100%;
  height: 100%;
`

const ButtonText = styled.div`
  position: absolute;
  top: 12px;
  left: 64px;
  color: white;
  font-size: 21px;
  font-family: 'SCDream4', serif;

  @media (max-width: 1920px) {
    top: 0.625vw;
    left: 3.3333vw;
    font-size: 1.0938vw;
  }
`

const ButtonArrow = styled.img`
  width: 21px;
  height: 8px;
  position: absolute;
  right: 66px;
  bottom: 20px;

  @media (max-width: 1920px) {
    width: 1.0938vw;
    height: 0.4167vw;
    right: 3.4375vw;
    bottom: 1.0417vw;
  }
`

const MainImage = styled.img`
  width: 1920px;

  @media (max-width: 1920px) {
    width: 100vw;
  }
`

const ControllerBox = styled.div`
  display: flex;
  flex-direction: column;

`

const NavigationBox = styled.div`
  width: 1434px;
  display: flex;
  justify-content: end;
  margin-top: 12px;

  @media (max-width: 1920px) {
    width: 74.6875vw;
    margin-top: 0.625vw;
  }
`

const PrevArrowButton = styled.img`
  width: 17px;
  height: 20px;
  margin-right: 39px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 0.8854vw;
    height: 1.0417vw;
    margin-right: 2.0312vw;
  }
`

const NextArrowButton = styled.img`
  width: 17px;
  height: 20px;
  margin-left: 39px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 0.8854vw;
    height: 1.0417vw;
    margin-left: 2.0312vw;
  }
`

const PlayButton = styled.img`
  width: 17px;
  height: 20px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 0.8854vw;
    height: 1.0417vw;
  }
`

const PauseButton = styled.img`
  cursor: pointer;
  width: 17px;
  height: 20px;

  @media (max-width: 1920px) {
    width: 0.8854vw;
    height: 1.0417vw;
  }
`

const ScrollBarBox = styled.div`
  width: 1434px;
  margin-top: 15px;
  position: relative;

  @media (max-width: 1920px) {
    width: 74.6875vw;
    margin-top: 0.7813vw;
  }
`

const ScrollBarLine = styled.div`
  border-top: 3px solid #464646;

  @media (max-width: 1920px) {
    border-top: 0.1563vw solid #464646;
  }
`

const ScrollDrag = styled.div`
  background-color: black;
  width: 33.3%;
  border-radius: unset;
  height: 6px;
  position: absolute;
  top: -1px;

  @media (max-width: 1920px) {
    height: 0.3125vw;
    top: -0.0521vw;
  }
`