import styled from "styled-components";
import {TitleSection} from '../../_Include/Title';
import {Component, BodyText1, BodyText2, TinyText, }from '../../../../style/comp/comm';

import Bg1 from "../../../../media/images/Local/bg-1.png";
import MainImg from "../../../../media/images/Culture/main.png";
import TopIcon from "../../../../media/images/Comm/top-icon-1.svg";
import QuotIcon from "../../../../media/images/Comm/quot.png";
import TopImg from "../../../../media/images/Culture/top-img-1.png";
import Icon1 from "../../../../media/images/Culture/icon-1.svg";
import Icon2 from "../../../../media/images/Culture/icon-2.svg";
import Icon3 from "../../../../media/images/Culture/icon-3.svg";
import Diag1 from "../../../../media/images/Culture/diagram-1.png";

import Arrow1 from "../../../../media/images/Comm/arrow-1.png";
// import Arrow2 from "../../../../media/images/Comm/arrow-2.svg";
import BgIcon1 from "../../../../media/images/Culture/bg-icon.svg";



import CityBottom from './CityBottom';

const title= '문화도시 충주';



const Diagram= ({txt1, txt2, disUnderline})=> (
  <div className="inst-box">
    <div className="">
      <img src={Diag1} alt="" className='w100'/>
    </div>
    <div className="bg-cover-area flx-cc txt-keep">
      <p className="ft-eb flx-col-cc p-x8 txt-center">
        <BodyText1>{txt1}</BodyText1>
        <BodyText1 className={`${!disUnderline && 'txt-underline'} m-t5`}>{txt2}</BodyText1>
      </p>
    </div>
  </div>
)
export default function City () {

  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 충주(Culture) > ${title}`} 
              title={title} 
              subtitle={'우리가 그리는 문화도시 충주'} 
            />
            
            <div className="inst-box">
              
              <p className="p-l40 p-y10 m-pd-10 inst-box zIdx2">
                <BodyText2 className="ft-eb">문화도시 충주는 </BodyText2>
              </p>
              <IconImg src={TopIcon} alt="" className="" />
              <TopImgArea src={TopImg} alt="" className="" />
              <img src={MainImg} alt="" className="w100 inst-box" />



              <div className="m-t60">
                <div className="flx-0 m-flx-col">
                  <div className="w50 m-w100 flx-0">
                    <div className="w100 bg-clr-wh p-y30 p-r40 bd-rd-20 flx-0 m-y10 m-r10 m-mg-s0">
                      <div className="w40 flx-cc">
                        <Icon1Area src={Icon1} alt="" className="" />
                      </div>
                      <div className="w60">

                        <div className="bd-bt-gr2 p-b20 m-pd-b10">
                          <QuotIconArea src={QuotIcon} alt="" />
                          <p className="ft-eb">
                            <BodyText2>문화도시 충주의 </BodyText2>
                            <BodyText2 className='ft-c1'>미래상</BodyText2>
                          </p>
                        </div>

                        <div className="flx-cl m-t25 m-mg-t10">
                            <TinyText className='ft-eb lh-18'>충청의 중심,<br></br>세계를 이어주는 문화광역시 충주! </TinyText>
                        </div>

                      </div>
                    </div>
                  </div>
                  

                  <div className="w50 m-w100 flx-0">
                    <div className="w100 bg-clr-wh p-y30 p-r40 bd-rd-20 flx-0 m-y10 m-l10 m-mg-s0">
                      <div className="w40 flx-cc">
                        <Icon1Area src={Icon2} alt="" className="" />
                      </div>
                      <div className="w60">

                        <div className="bd-bt-gr2 p-b20 m-pd-b10">
                          <QuotIconArea src={QuotIcon} alt="" />
                          <p className="ft-eb">
                            <BodyText2>문화도시 충주의 </BodyText2>
                            <BodyText2 className='ft-c1'>비전</BodyText2>
                          </p>
                        </div>

                        <div className="flx-cl m-t25 m-mg-t10">
                            <TinyText className='ft-eb lh-18'>글로컬 문화콘텐츠 중심 도시, 충주 </TinyText>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

                <div className="w100 bg-clr-wh p-y40 bd-rd-20 m-t10">
                  <div className="flx-cc w100">
                    <Icon3Area src={Icon3}/>
                    <p className="ft-eb m-x10">
                      <BodyText2>문화도시 충주의 </BodyText2>
                      <BodyText2 className='ft-c1'>4대 목표 및 추진 전략</BodyText2>
                    </p>
                    <Icon3Area src={Icon3} rev={'true'}/>
                  </div>
                  
                  <div className="flx-0 m-flx-col w100 mx900 mg-ct m-t20">

                    {/* 다이어 그램 왼쪽 */}
                    <div className="w50 m-w100 flx-0 p-y10">
                      <div className="w50 ">
                        <div className="w100 mx150 mg-ct ">
                          <Diagram txt1={'충청권 문화공간'} txt2={'1,000곳'} />
                          <div className="txt-center p-x5 m-t10">
                            <div className="bd-bt-gr2 p-b10">
                              <BodyText2 className='ft-c1 ft-b '>문화누림</BodyText2>
                            </div>

                            <div className="m-t10">
                              <p className="">
                                <BodyText1>지역과 사람 연대로 </BodyText1>
                              </p>
                              <p className="ft-b">
                                <BodyText1>문화 접근력 UP </BodyText1>
                              </p>
                            </div>
                          </div>
                        </div>
                        
                      </div>


                      <div className="w50 ">
                        <div className="w100 mx150 mg-ct ">
                          <Diagram txt1={'경제적 파급효과'} txt2={'1,000억원'} />
                          <div className="txt-center p-x5 m-t10">
                            <div className="bd-bt-gr2 p-b10">
                              <BodyText2 className='ft-c1 ft-b '>문화혁신</BodyText2>
                            </div>

                            <div className="m-t10">
                              <p className="">
                                <BodyText1>사람과 정책 연대로 </BodyText1>
                              </p>
                              <p className="ft-b">
                                <BodyText1>문화 경쟁력 UP </BodyText1>
                              </p>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      
                    </div>


                    {/* 다이어 그램 오른쪽 */}
                    <div className="w50 m-w100 flx-w p-y10">
                      <div className="w50 ">
                        <div className="w100 mx150 mg-ct ">
                          <Diagram txt1={'충청권 문화  향유참여'} txt2={'100만명'} />
                        </div>
                        
                      </div>


                      <div className="w50 ">
                        <div className="w100 mx150 mg-ct ">
                          <Diagram txt1={'충북 여가활동 만족도'} 
                            txt2={<>17%
                            <SmallArrow src={Arrow1} className='m-x5'/>
                            20%</>} 
                            disUnderline={true}
                          />
                        </div>
                      </div>
                      
                      <div className="w100">
                        <div className="txt-center p-x5 m-t10">
                          <div className="bd-bt-gr2 p-b10">
                            <BodyText2 className='ft-c1 ft-b '>문화창조</BodyText2>
                          </div>

                          <div className="m-t10 flx-0">
                            <div className="w50">
                              <p className="">
                                <BodyText1>사람과 정책 연대로 </BodyText1>
                              </p>
                              <p className="ft-b">
                                <BodyText1>문화 창조력 UP </BodyText1>
                              </p>
                            </div>

                            <div className="w50">
                              <p className="">
                                <BodyText1>지역, 사람, 정책 연대로</BodyText1>
                              </p>
                              <p className="ft-b">
                                <BodyText1>문화 전승력 UP</BodyText1>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>


                  </div>

                </div>
              </div>


              <CityBottom />

            </div>
            

            
            
          </div>
        </div>
      </Component>

      <div className="inst-box">
        <BgImg1 className="p-y80 m-pd-h40 inst-box" />
        <BttomImg src={BgIcon1} alt="" />
      </div>
    </div>
  )
}

const SmallArrow= styled.img`
  width: 12px; 
  transform: rotate(90deg);
`

const Icon3Area= styled.img`
  width: 34px;
  transform: ${(props) => props?.rev != 'true' || `rotate(180deg)`};
  @media (max-width: 800px) {
    width: 18px;
  }
`

const QuotIconArea= styled.img`
  width: 32px;
  @media (max-width: 800px) {
    width: 24px;
  }
`
const Icon1Area= styled.img`
  width: 68px;
  @media (max-width: 800px) {
    width: 48px;
  }
`

const IconImg = styled.img`
  position: absolute;
  top: -30px; right: 0;
  width: 330px;
  @media (max-width: 800px) {
    width: 172px;
    top: 0px;
  }
`
const TopImgArea= styled.img`
  position: absolute;
  top: -70px; right: 100px;
  width: 150px;
  @media (max-width: 800px) {
    width: 92px;
    top: -30px; right: 15px;
  }
`
const BttomImg= styled.img`
  position: absolute;
  right: calc(50% - 500px); 
  bottom: 0px;
  z-index: 2;
  width: 180px;
  @media (max-width: 800px) {
    width: 80px;
    right: calc(50% - 200px);
    display: none;
  }
`

const BgImg1= styled.div`
  /* margin-top: -10em; */
  position: relative;
  width: 100%;
  /* height: 100%; */
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(50% ) -5%;
  background-size: 300%;
  @media (max-width: 800px) {

    /* background-position: calc(50% + 280px) 3%; */
  }
`