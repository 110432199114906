import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/css/index.css';
import './style/css/style.css';
import './style/css/jhc.css';
import './style/css/jhc-m.css';
import App from './components/App';
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';


const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
        <App/>
        </QueryClientProvider>
      </React.StrictMode>
    </BrowserRouter>
  </RecoilRoot>
);

