import styled from "styled-components";
import MenIconImage from '../../../../media/images/CultureMap/cultureMapMenIcon.webp'
import PatterunImage from '../../../../media/images/CultureMap/culureMapPattern.webp'
import {MobileMapItem} from "../../atoms/CultureMap/MobileMapItem";

export const MobileCultureMapContents = () => {


  return (
    <Component>

      <Title>문화지도</Title>
      <SubTitle>{`충주의 다양한 로컬 크리에이터들의 공간을 
      확인해보세요!`}</SubTitle>

      <CultureMapMenIcon src={MenIconImage}/>
      <CultureMapPattern src={PatterunImage}/>

      <GradientLine/>

      <MobileMapItem/>

    </Component>
  )
}

const Component = styled.div`
  width: 100vw;
  position: relative;
`

const Title = styled.div`
  font-size: 5.71vw;
  font-family: 'SCDream7', serif;
  text-align: center;
  margin-bottom: 2.61vw;
  margin-top: 7.50vw;
`

const SubTitle = styled.div`
  font-size: 2.36vw;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-bottom: 5.22vw;
  white-space: pre-line;
`

const GradientLine = styled.div`
  border-bottom: 0.57vw solid #c8d971;
  width: 100vw;
  z-index: 3;
  position: relative;
`

const CultureMapMenIcon = styled.img`
  position: absolute;
  width: 20.15vw;
  height: 24.72vw;
  top: 5.5vw;
  right: 5.22vw;
  z-index: 1;
`

const CultureMapPattern = styled.img`
  position: absolute;
  width: 42.01vw;
  height: 35.49vw;
  top: 8.16vw;
  right: -9.39vw;
`
