import {API_KEY, API_URL} from "./global";


export const main_banner_list = async() => {
  const response = await fetch(API_URL + 'api/main/banner', {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('main_banner_list Error')
  }

  return response.json();
}

export const main_communities_list = async() => {
  const response = await fetch(API_URL + 'api/main/communities', {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('main_communities_list Error')
  }

  return response.json();
}

export const main_live_choongju_list = async() => {
  const response = await fetch(API_URL + 'api/main/livechungjus', {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('main_live_choongju_list Error')
  }

  return response.json();
}

export const main_notice_list = async() => {
  const response = await fetch(API_URL + 'api/boards/notice', {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('main_notice_list Error')
  }

  return response.json();
}

export const main_popup_list = async ()=> {
  const response = await fetch(API_URL + 'api/main/popup', {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('main_popup_list Error')
  }

  return response.json();
}