import React from "react";
import styled from "styled-components";
import MenPicture from '../../../../media/images/MainHome/LiveChoongJu/menPicture.webp'
import {PUBLISH_URL} from "../../../../api/global";

export const MenCard = ({data, handleContentClick}) => {

  return (
    <Container 
      onClick={handleContentClick(data)}
    >
      {/* 충주의 살다 인물 카드 */}

      <MenImage src={data.thumbnail === "" ? MenPicture : PUBLISH_URL + data.thumbnail}/>

      <TextBox>
        <MenTitle>{data.subject}</MenTitle>
        <CardLine/>
        <HashTagText>
          {data.tags.map((data) => (
            data + ' '
          ))}
        </HashTagText>
      </TextBox>

    </Container>
  )
}

const Container = styled.div`
  width: 460px;
  height: 184px;
  border-radius: 30px;
  margin-left: 27px;
  margin-bottom: 19px;
  display: flex;
  background-color: white;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 23.9583vw;
    height: 9.5833vw;
    border-radius: 1.5625vw;
    margin-left: 1.4063vw;
    margin-bottom: 0.9896vw;
  }

`

const MenImage = styled.img`
  width: 157px;
  height: 183px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
 

  @media (max-width: 1920px) {
    width: 8.1771vw;
    height: 9.5313vw;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 23px;

  @media (max-width: 1920px) {
    margin-left: 1.1979vw;
  }
`

const MenTitle = styled.div`
  width: 220px;
  font-family: 'SCDream5', serif;
  font-size: 18px;

  @media (max-width: 1920px) {
    width: 11.4583vw;
    font-size: 0.9375vw;
  }
`

const CardLine = styled.div`
  border-bottom: solid 1px black;
  width: 259px;
  margin-top: 16px;
  margin-bottom: 15px;

  @media (max-width: 1920px) {
    width: 13.4896vw;
    margin-top: 0.8333vw;
    margin-bottom: 0.7813vw;
  }
`

const HashTagText = styled.text`
  width: 256px;
  font-size: 14.5px;
  font-family: 'SCDream2', serif;

  @media (max-width: 1920px) {
    width: 13.3333vw;
    font-size: 0.7552vw;
  }
`