import React from "react";
// import styled from "styled-components";
// import {ShareHeader} from "../../blocks/layouts/ShareHeader";
// import {ShareFooter} from "../../blocks/layouts/ShareFooter";
import {QuestionSection} from "../../blocks/MainHome/QuestionSection";
import {Notice} from "../../blocks/MainHome/Notice";
import {LiveChoongJu} from "../../blocks/MainHome/LiveChoongJu";
import {TopSlide} from "../../blocks/MainHome/TopSlide";
import {CultureChoongJu} from "../../blocks/MainHome/CultureChoongJu";
import {CommunityBiz} from "../../blocks/MainHome/CommunityBiz";
// import {ShareCategory} from "../../blocks/layouts/ShareCategory";
import {useRecoilValue} from "recoil";
// import {MobileShowCategory, MobileShowSearchResult, ShowCategory, ShowSearchResult} from "../../../recoil/LayoutRecoil";
import { ShowCategory} from "../../../recoil/LayoutRecoil";
// import {ShareSearch} from "../../blocks/layouts/ShareSearch";
import {useMediaQuery} from "react-responsive";
// import {MobileShareHeader} from "../../mobile/blocks/layouts/MobileShareHeader";
// import {MobileShareFooter} from "../../mobile/blocks/layouts/MobileShareFooter";
import {MobileNotice} from "../../mobile/blocks/MainHome/MobileNotice";
import {MobileQuestionSection} from "../../mobile/blocks/MainHome/MobileQuestionSection";
import {MobileTopSlide} from "../../mobile/blocks/MainHome/MobileTopSlide";
import {MobileCultureChoongJu} from "../../mobile/blocks/MainHome/MobileCultureChoongJu";
import {MobileCommunityBiz} from "../../mobile/blocks/MainHome/MobileCommunityBiz";
import {MobileLiveChoongJu} from "../../mobile/blocks/MainHome/MobileLiveChoongJu";
// import {MobileShareCategory} from "../../mobile/blocks/layouts/MobileShareCategory";
// import {MobileShareSearch} from "../../mobile/blocks/layouts/MobileShareSearch";

import {useQuery} from "react-query";
import {main_banner_list} from "../../../api/MainHome";

export const MainHome = () => {
  const ShowCategoryState = useRecoilValue(ShowCategory)
  // const MobileShowCategoryState = useRecoilValue(MobileShowCategory)
  // const ShowSearchResultState = useRecoilValue(ShowSearchResult)
  // const MobileShowSearchResultState = useRecoilValue(MobileShowSearchResult)
  // const isMobile = useMediaQuery({query: '(max-width: 1226px)'});
  const isMobile = useMediaQuery({query: '(max-width: 800px)'});

  const { data, error, isLoading } = useQuery('main_banner_list', main_banner_list);
  console.log(data)
  return (
    <>
    {isMobile ? <MobileTopSlide data={data} /> : <TopSlide data={data} />}

    {isMobile ? <MobileCultureChoongJu/> : <CultureChoongJu/>}

    {isMobile ? <MobileQuestionSection/> : <QuestionSection/>}
    <div className="m-b20"></div>

    {isMobile ? <MobileCommunityBiz/> : <CommunityBiz/>}

    {isMobile ? <MobileLiveChoongJu/> : <LiveChoongJu/>}

    {isMobile ? <MobileNotice/> : <Notice/>}

    {/* {isMobile ? <MobileTopSlide/> : <TopSlide/>}

    {isMobile ? <MobileCultureChoongJu/> : <CultureChoongJu/>}

    {isMobile ? <MobileCommunityBiz/> : <CommunityBiz/>}

    {isMobile ? <MobileLiveChoongJu/> : <LiveChoongJu/>}

    {isMobile ? <MobileNotice/> : <Notice/>}

    {isMobile ? <MobileQuestionSection/> : <QuestionSection/>} */}
    {/* <Container>

      {isMobile ? <MobileShareHeader/> : <ShareHeader/>}

      <HeaderLine/>

      {ShowCategoryState ? <ShareCategory/> : <></>}

      {ShowSearchResultState ? <ShareSearch/> : <></>}

      {MobileShowSearchResultState ? <MobileShareSearch/> : <></>}

      {MobileShowCategoryState ? <MobileShareCategory/> : <></>}

      {isMobile ? <MobileTopSlide/> : <TopSlide/>}

      {isMobile ? <MobileCultureChoongJu/> : <CultureChoongJu/>}

      {isMobile ? <MobileCommunityBiz/> : <CommunityBiz/>}

      {isMobile ? <MobileLiveChoongJu/> : <LiveChoongJu/>}

      {isMobile ? <MobileNotice/> : <Notice/>}

      {isMobile ? <MobileQuestionSection/> : <QuestionSection/>}

      {isMobile ? <MobileShareFooter/> : <ShareFooter/>}

    </Container> */}
    </>
  )
}

/* const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #f5f5f1;
`

const HeaderLine = styled.div`
  border-bottom: 1px solid #000000;
  width: 100%;
` */