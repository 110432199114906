
import styled from "styled-components";
// import { BoardTopImg } from '../_Include/Title';
import { events_list } from "../../../../api/Community";

import TopIcon1 from "../../../../media/images/Life/events/icon-img-1.svg";
import TopIcon2 from "../../../../media/images/Comm/top-icon-4.svg";


const eventFilter= ['전체', '접수중', '접수마감']
const searchFilter= [
  { key: '전체', value: 'all' }, 
  { key: '공연', value: '공연' }, 
  { key: '체험', value: '체험' }, 
  { key: '전시', value: '전시' }, 
  { key: '축제', value: '축제' }, 
]

const TopImgSectStyle= styled.div`
  width: 100%;
  max-width: 160px;
  position: relative;
  margin-bottom: -80px;
  margin-left: auto;
  margin-right: 46px;
  overflow: hidden;
  @media (max-width: 800px) {
    max-width: 70px;
    margin-right: 0px;
    margin-bottom: -50px;
    /* max-width: 80px; */
    /* bottom: -20px; */
  }
`

const TopImgWrapStyle= styled.div`
  width: 100%;
  position: relative;
  padding-top: 112%;
  @media (max-width: 800px) {
    padding-top: 120%;
  }
`

const TopIcon1Style= styled.img`
  position: absolute;
  max-width: 100px;
  bottom: -94px; left: 30px;
  width: 100%;
  @media (max-width: 800px) {
    bottom: -56px; left: 13px;
    max-width: 50px;
  }
`
const TopIcon2Style= styled.img`
  width: 100%;
  /* max-width: 150px; */
  position: absolute;
  bottom: -16px;
  @media (max-width: 800px) {
    bottom: -11px;
  }
`

const TopImg= ()=> <TopImgSectStyle className="">
  <TopImgWrapStyle className="">
    <TopIcon2Style src={TopIcon2} alt="" />
    <TopIcon1Style src={TopIcon1} alt="" />
  </TopImgWrapStyle>
</TopImgSectStyle>
export const info = [
  {
    id: 'events',
    key: 'events',
    name: '이벤트',
    // query: community_business,
    title: '이벤트',
    path: '살면(Life)',
    subject: '문화도시 충주의 새로운 소식과 정보를 확인해 보세요!',
    url: '/life/events',
    filter_1: eventFilter,
    filter_2: searchFilter,
    // disableBadge: true,
    images: [<TopImg />]
  },
]

export const queries= {
  ['events']: {
    key: 'events_list',
    query: events_list
  },
}