import React from "react";
import styled from "styled-components";
import QuoteIconImage from "../../../../media/images/DreamCity/quoteIcon.webp";
import SecondImage from "../../../../media/mobileImage/DreamCity/mobileDreamCitySecondImage.webp";
import GradientTextImage from "../../../../media/images/DreamCity/secondCardText.webp";
import {useSetRecoilState} from "recoil";
import {DreamCityDetailCategory} from "../../../../recoil/LayoutRecoil";
import {Link} from "react-router-dom";

export const MobileDreamCitySecondCard = () => {
  const setDreamCityDetailCategory = useSetRecoilState(DreamCityDetailCategory)
  const userAgent = window.navigator.userAgent.toLowerCase();

  return (
    <Components>
      <QuoteIcon src={QuoteIconImage}/>

      <Contents>
        <TextSection>
          <TopSection Medge={userAgent.includes('windows')}>
            <Title>너답게</Title>
            <SubTitle>{`개인의 라이프스타일과 취향을 인정하고
누구나 공감하는 문화활동의 기반을 만들고자
문화도시를 브랜딩하고 로컬문화산업을 창조합니다.`}</SubTitle>
            <GradientText src={GradientTextImage}/>
          </TopSection>

          <TextLine/>

          <BottomSection>
            <BizText>{`<주요사업>
[문화도시 브랜딩] 충주로컬자원아카이브 "WE LOVE 충주시티"
[문화도시 브랜딩]취향공유 커뮤니티 지원 "집 밖 문지방"
[로컬문화산업 창조]문화정기구독플랫폼"이 달의 충주문화"
[로컬문화산업 창조]충주문화자산 활성화"충주주민(ZOOM-IN)센터“`}</BizText>
            <Link to="/dreamCityDetail" style={{textDecoration: 'none'}}
                  onClick={() => {
                    setDreamCityDetailCategory('너답게')
                  }}>
              <DetailText Medge={userAgent.includes('windows')}>자세히 보기</DetailText>
            </Link>
          </BottomSection>
        </TextSection>

        <SecondImg src={SecondImage}/>
      </Contents>

    </Components>
  )
}

const Components = styled.div`
  width: 100vw;
  height: 56.75vw;
  position: relative;
  margin-top: 7.01vw;
`

const Contents = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  z-index: 1;
  position: relative;
`

const SecondImg = styled.img`
  width: 33.44vw;
  height: 56.75vw;
`

const QuoteIcon = styled.img`
  width: 8.07vw;
  height: 6.36vw;
  position: absolute;
  top: -4.07vw;
  left: 1.71vw;
  z-index: 2;
`

const TextSection = styled.div`
  margin-left: 4.16vw;
`

const TopSection = styled.div`
  margin-top: 5.55vw;
`

const BottomSection = styled.div`
`

const Title = styled.div`
  font-size: 4.77vw;
  font-family: 'SCDream8', serif;
  margin-bottom: 2.69vw;
`

const SubTitle = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 2.36vw;
  white-space: pre-line;
  margin-bottom: 2.77vw;
`

const GradientText = styled.img`
  width: 25.04vw;
  height: 2.69vw;
`

const TextLine = styled.div`
  border-bottom: 2px solid black;
  width: 50.41vw;
  margin: 2.94vw 0;
`

const BizText = styled.div`
  font-family: 'SCDream4', serif;
  font-size: 2.08vw;
  color: #808080;
  white-space: pre-line;
  line-height: 3.26vw;
`

const DetailText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 2.36vw;
  text-decoration: underline;
  display: flex;
  align-items: end;
  cursor: pointer;
  margin-top: 1.55vw;
`
