import React from "react";
import styled from "styled-components";
import {TitleSection} from '../_Include/Title';
import {Component, SectTitle, SectTitleBox, SubTitle0, SubTitle1, BodyText1, BodyText2, TinyText1, Divider, SubTitle3, Divider1} from '../../../style/comp/comm';

import About5 from "../../../media/images/About/about-5.png";
import BgIcon1 from "../../../media/images/Policy/icon-img-2.svg";
import Bg1 from "../../../media/images/Comm/bg-2.png";


import ManImage from '../../../media/images/Policy/icon-img-1.svg'
// import PatternImage from '../../../media/images/TotalSearch/totalSearchPattern.webp'
import PatternImage from '../../../media/images/Comm/top-icon-4.svg'

const title= '개인정보보호정책';

const IconSectStyle = styled.div`
  position: absolute;
  top: 0; right: 40px;
  width: 100%;
  max-width: 150px;
  transform: translateY(-100%);
  @media (max-width: 800px) {
    max-width: 80px;
    right: 4px;
    /* display: none; */
  }
`

const IconWrapStyle = styled.div`
  position: relative;
  margin-bottom: -1em;
  overflow: hidden;
  padding-top: 140%;
`
const Icon1Style = styled.img`
  width: 100%;
  position: absolute;
  top: 40%; left: 0%;
`
const Icon2Style = styled.img`
  width: 76%;
  position: absolute;
  bottom: 0px; left: 12%;
`
export default function Private () {

  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10">
          <div className="inst-box">
            <TitleSection 
              path={`홈 >  ${title}`} 
              title={title} 
              subtitle={''} 
            />

            <div className="w100 inst-box m-t60">
              {/* <IconImg src={TopIcon} alt="" className="" /> */}
              <IconSectStyle className="m-x10">
                <IconWrapStyle>
                  <Icon1Style src={PatternImage} alt="" />
                  <Icon2Style src={ManImage} alt="" />
                </IconWrapStyle>
              </IconSectStyle>

              <div className="inst-box bg-clr-wh w100 mx1000 mg-ct">
                <ul className="">
                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제1조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">총칙</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            본 사이트는 귀하의 개인정보보호를 매우 중요시하며, 『정보통신망이용촉진등에관한법률』상의 개인정보보호 규정 및 정보통신부가 제정한 『개인정보보호지침』을 준수하고 있습니다.<br></br>
                            본 사이트는 개인정보보호방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br></br>
                            본 사이트는 개인정보보호방침을 홈페이지 첫 화면 하단에 공개함으로써 귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.<br></br>
                            본 사이트는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

                            </BodyText1>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제2조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">개인정보 수집에 대한 동의</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            귀하께서 본 사이트의 개인정보보호방침 또는 이용약관의 내용에 대해 「동의한다」버튼 또는 「동의하지 않는다」버튼을 클릭할 수 있는 절차를 마련하여, 「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.
                            </BodyText1>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>



                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제3조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">개인정보의 수집 및 이용목적</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            본 사이트는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.
                            </BodyText1>
                          </p>
                          <p className="lh-18 txt-keep m-y10">
                            <BodyText1 className="ft-dg1 ft-b">
                            - 서비스제공을 위한 계약의 성립 : 본인식별 및 본인의사 확인 등 <br></br>
                            - 서비스의 이행 : 상담신청 
                            </BodyText1>
                          </p>
                          
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.
                            </BodyText1>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>


                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제4조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">수집하는 개인정보 항목</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            본 사이트는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.
                            </BodyText1>
                          </p>
                          <p className="lh-18 txt-keep m-y10">
                            <BodyText1 className="ft-dg1 ft-b">
                            - 수집항목 : 이름, 휴대전화번호, 이메일, 접속 로그, 접속 IP 정보<br></br>
                            - 개인정보 수집방법 : 홈페이지(상담신청)
                            </BodyText1>
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </li>


                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제5조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                              본 사이트는 귀하에 대한 정보를 저장하고 수시로 찾아내는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저 (넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 본 쇼핑몰의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.<br></br><br></br>
                              쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. <br></br> 
                              웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
                            </BodyText1>
                          </p>
                          
                          <p className="lh-18 txt-keep m-y10">
                            <BodyText1 className="ft-dg1 ft-b">
                            - 쿠키 등 사용 목적 : 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공<br></br> 
                            - 쿠키 설정 거부 방법 : 쿠키 설정을 거부하는 방법으로는 귀하가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br></br> 
                            - 설정방법 예시 : 인터넷 익스플로어의 경우 → 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보

                            </BodyText1>
                          </p>

                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                              단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                            </BodyText1>
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </li>



                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제6조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">목적 외 사용 및 제3자에 대한 제공</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                              본 사이트는 귀하의 개인정보를 "개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업·기관에 제공하지 않습니다. 그러나 보다 나은 서비스 제공을 위하여 귀하의 개인정보를 제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가 누구인지, 제공 또는 공유되는 개인정보 항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보호·관리되는지에 대해 개별적으로 전자우편 및 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다. 또한 이용자의 개인정보를 원칙적으로 외부에 제공하지 않으나, 아래의 경우에는 예외로 합니다.
                            </BodyText1>
                          </p>
                          
                          <p className="lh-18 txt-keep m-y10">
                            <BodyText1 className="ft-dg1 ft-b">
                            - 이용자들이 사전에 동의한 경우 <br></br>
                            - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                            </BodyText1>
                          </p>

                        </div>
                      </div>
                    </div>
                  </li>



                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제7조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">개인정보의 보유 및 이용기간</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                              원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 
                            </BodyText1>
                          </p>

                        </div>
                      </div>
                    </div>
                  </li>



                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제8조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">개인정보의 파기절차 및 방법</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            본 사이트는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.<br></br> 
                            파기절차 및 방법은 다음과 같습니다.
                            </BodyText1>
                          </p>
                          
                          <p className="lh-18 txt-keep m-y10">
                            <BodyText1 className="ft-dg1 ft-b">
                            - 파기절차 : 귀하가 상담신청 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다. <br></br> 
                            - 파기방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

                            </BodyText1>
                          </p>

                        </div>
                      </div>
                    </div>
                  </li>



                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제9조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">개인정보의 위탁처리</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            본 사이트는 서비스 향상을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다.<br></br>
                            개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 귀하에게 고지하겠습니다. <br></br>
                            개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.
                            </BodyText1>
                          </p>

                        </div>
                      </div>
                    </div>
                  </li>



                  <li className="p-20 bd-bt-gr2">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">제10조</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">의견수렴 및 불만처리</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            본 사이트는 개인정보보호와 관련하여 귀하가 의견과 불만을 제기할 수 있는 창구를 개설하고 있습니다. 개인정보와 관련한 불만이 있으신 분은 본 쇼핑몰의 개인정보 관리책임자에게 의견을 주시면 접수 즉시 조치하여 처리결과를 통보해 드립니다.
                            </BodyText1>
                          </p>
                          
                          <p className="lh-18 txt-keep m-y10">
                            <BodyText1 className="ft-dg1 ft-b">
                            - 개인정보 보호책임자 성명 : 신재민 <br></br>
                            - 전화번호 : <a href="tel:043-864-7380" className="txt-underline">043-864-7380</a> <br></br>
                            - 이메일 : <a href="mailto:hue1540@cjcf.or.kr" className="txt-underline">hue1540@cjcf.or.kr</a>
                            </BodyText1>
                          </p>

                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            또는 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                            </BodyText1>
                          </p>


                          <p className="lh-18 txt-keep m-y10">
                            <BodyText1 className="ft-dg1 ft-b">
                            - 개인정보 침해신고센터(한국인터넷진흥원 운영) : (국번없이) 
                            <a href="tel:118" className="m-l5 txt-underline ft-c1-hover">118</a> 
                            <span className="m-l2"> (<a href="https://privacy.kisa.or.kr" target="_blank" className="txt-underline ft-c1-hover">privacy.kisa.or.kr)</a></span><br></br>
                            
                            - 개인정보 분쟁조정위원회(국번없이) : 
                            <a href="tel:1833-6972" className="m-l5 txt-underline ft-c1-hover">1833-6972</a> 
                            <span className="m-l2"> (<a href="https://www.kopico.go.kr" target="_blank" className="txt-underline ft-c1-hover">www.kopico.go.kr)</a></span><br></br>

                            - 대검찰청 사이버범죄수사단 : 
                            <a href="tel:02-3480-3573" className="m-l5 txt-underline ft-c1-hover">02-3480-3573</a> 
                            <span className="m-l2"> (<a href="https://www.spo.go.kr" target="_blank" className="txt-underline ft-c1-hover">www.spo.go.kr)</a></span><br></br>
                            - 경찰청 사이버안전국 : (국번없이) 
                            <a href="tel:182" className="m-l5 txt-underline ft-c1-hover">182</a> 
                            <span className="m-l2"> (<a href="https://cyberbureau.police.go.kr" target="_blank" className="txt-underline ft-c1-hover">cyberbureau.police.go.kr)</a></span><br></br>

                            
                            </BodyText1>
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </li>



                  <li className="p-20 ">
                    <div className="flx-0 m-flx-col">
                      <p className="txt-nowrap w80p m-b15 txt-center m-txt-left">
                        <SubTitle1 className="ft-gr0 ft-b">부  칙</SubTitle1>
                      </p>
                      <div className="p-x10">
                        <p className="">
                          <SubTitle1 className="ft-hv">시행일 등</SubTitle1>
                        </p>
                        <div className="m-t10">
                          <p className="lh-18 txt-keep">
                            <BodyText1 className="ft-dg">
                            본 방침은 2023년 10월 31일부터 시행합니다.
                            </BodyText1>
                          </p>

                        </div>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
              
            </div>

          </div>
        </div> 
      </Component>

      <div className="inst-box h200p p-t80 m-t40">
        <BgImg1 className=" inst-box">
          <BttomImg src={BgIcon1} alt="" />
        </BgImg1>
        
      </div>

    </div>
  )
}


const BttomImg= styled.img`
  position: absolute;
  right: calc(50% - 470px); 
  bottom: 90px;
  z-index: 3;
  width: 115px;
  @media (max-width: 800px) {
    width: 80px;
    right: calc(50% - 140px);
  }
`

const BgImg1= styled.div`
  /* padding-top: 36%; */
  /* margin-top: -10em; */
  position: absolute;
  bottom : 0;
  width: 100%;
  height: 270px;
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(50%) -6%;
  /* background-size: 230%; */
  @media (max-width: 800px) {
    height: 180px;

    /* background-position: calc(50% + 280px) 3%; */
  }
`