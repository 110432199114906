import { format } from 'date-fns';
import {
  DayPicker,
  DayContent,
  DateFormatter
} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ko from 'date-fns/locale/ko';

import {TitleSection} from '../../_Include/Title';
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../../style/comp/comm';
import styled from "styled-components";
import Bg1 from "../../../../media/images/Local/bg-1.png";
import BgIcon1 from "../../../../media/images/Life/events/icon-img-2.svg";

import Calendar from './Calendar';
// import {useParams} from "react-router-dom";

const formatCaption = (month, options) => {
  // const season = getSeason(month);
  return (
    <>
      {/* <span role="img" aria-label={season}>
        {seasonEmoji[season]}
      </span>{' '} */}
      <span className="m-r10">{format(month, 'y', { locale: options?.locale })}년</span>
      <span className=''>{format(month, 'LLLL', { locale: options?.locale })}</span>
    </>
  );
};


function DateTime(props) {
  const dateTime = format(props.date, 'yyyy-MM-dd');
  return (
    <div dateTime={dateTime}>
      <DayContent {...props} />
    </div>
  );
}

// const title='이벤트';
const id= 'events'
export default function Evnet ({ info, queries }) {

  // const params = useParams();
  // const { id }= params;
  const data= info.find(o=> o.id == id);
  // const data= info[id];
  console.log(info)

  console.log(data)
  const { title }= data;
 
  return (
    <>
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10 zIdx2">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 살면(Life) > ${title}`} 
              title={`${title}`} 
              subtitle={'문화도시 충주의 이벤트정보를 확인해 보세요!'} 
            />
          </div>
        </div>

        <div className="p-x10">
          <Calendar id={id} info={info} infoData={data} queries={queries}/>
        </div>
      </Component>
      
    </div>



    <div className="w100">
      <Component>
        {/* <Calendar /> */}

        {/* <div className="w100 p-x10 zIdx2">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 살면(Life) > ${title}`} 
              title={`${title}`} 
              subtitle={'문화도시 충주의 이벤트정보를 확인해 보세요!'} 
            />
          </div>
        </div>
        <div className="m-t20">
          <DayPickerStyle>  
            <DayPicker
              components={{ DayContent: DateTime }}
              className={''}
              defaultMonth={new Date(2024, 1)}
              // month={'2024-01'} 
              // onMonthChange={(d)=>console.log(d)}
              mode="single"
              // selected={selectedRange}
              // onSelect={handleRangeSelect}
              locale={ko}
              // numberOfMonths={2}
              modifiersClassNames={{
                selected: 'my-selected',
                today: 'my-today'
              }}
              formatters={{ 
                formatCaption,
                // formatDay: (date, options)=> {
                //   return <Box sx={{ width: '200px '}}>{`${new Date(date).format('dd')}몰라1`}</Box>
                // }
              }}
            />
          </DayPickerStyle>
        </div> */}
        {/* <div className="flx-cc" style={{ height: '80vh'}}>
          <p className="">
            <span className="ft0 m-ft1">준비중입니다.</span>
          </p>
        </div> */}
      </Component>


      <div className="inst-box ">
        <BgImg1 className="p-y80 m-pd-h40 inst-box" />
        <BttomImg src={BgIcon1} alt="" />
      </div>
    </div>
    </>
  )
}
const BttomImg= styled.img`
  position: absolute;
  left: calc(50% - 500px); 
  bottom: 40px;
  z-index: 2;
  width: 80px;
  @media (max-width: 800px) {
    width: 40px;
    bottom: 20px;
    left: calc(50% - 140px);
    /* display: none; */
  }
`

const BgImg1= styled.div`
  /* margin-top: -10em; */
  position: relative;
  width: 100%;
  /* height: 100%; */
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(50% - 460px) -15%;
  @media (max-width: 800px) {
    background-position: calc(50% - 180px) -2%;
  }
`