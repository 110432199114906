import {API_KEY, API_URL} from "./global";
import { qryToString } from './_qryToString';

export const community_culture = async( params ) => {
  console.log(params)
  const qryStr= qryToString(params);
  const response = await fetch(API_URL + `api/boards/communityculture${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('community_culture Error')
  }

  return response.json();
}

export const community_business = async(params) => {
  const qryStr= qryToString(params);
  const response = await fetch(API_URL + `api/boards/communitybusiness${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('community_business Error')
  }

  return response.json();
}


export const events_list = async(params) => {
  const qryStr= qryToString(params);
  const response = await fetch(API_URL + `api/events${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('events Error')
  }

  return response.json();
}