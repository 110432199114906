import {TitleSection} from '../_Include/Title';
import {Component,}from '../../../style/comp/comm';
import BBS from "../../blocks/_bbs";
import {useParams, Outlet} from "react-router-dom";
import BBSDetail from "../../blocks/_bbs/Detail";


// import { info, queries } from "./info";
// console.log(info)

export default function Boards ({ info, queries, detail }) {

  const params = useParams();
  const { id }= params;
  const data= info.find(o=> o.id == params.id);
  // const data= info[id];
  console.log(data)
  const { title, path, subject }= data;
 
  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10 zIdx2">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > ${path} > ${title}`} 
              title={`${title}`} 
              subtitle={subject} 
            />
          </div>
        </div>

        {
          !detail && 
          <BBS id={id} info={info} infoData={data} queries={queries} tabType={'none'} porcessType={'side'} />
        }
        {
          detail && 
          <BBSDetail info={info} queries={queries} />
        }
      </Component>
      
    </div>
  )

}