import React from "react";
import styled from "styled-components";
import LogoImage from '../../../../media/mobileImage/layouts/mobileLogo.webp'
import SearchImage from '../../../../media/mobileImage/layouts/mobileSearch.webp'
import HambergerImage from '../../../../media/mobileImage/layouts/hamburgerIcon.webp'
import {MobileShowCategory, MobileShowSearchResult, ShowSearchResult} from "../../../../recoil/LayoutRecoil";
import {useSetRecoilState} from "recoil";
import {Link} from "react-router-dom";

export const MobileShareHeader = () => {
  const SetMobileShowCategory = useSetRecoilState(MobileShowCategory)
  // const SetMobileShowSearch = useSetRecoilState(MobileShowSearchResult)

  const setShowSearchResult = useSetRecoilState(ShowSearchResult)

  return (
    <Component>

      <Link to="/">
        <LogoImg src={LogoImage}/>
      </Link>

      <SearchImg src={SearchImage} onClick={() => setShowSearchResult(true)}/>
      <HamburgerImg src={HambergerImage} onClick={() => SetMobileShowCategory(true)}/>

    </Component>
  )
}

const Component = styled.div`
  width: 100vw;
  height: 12.98vw;
  display: flex;
  align-items: center;

`

const LogoImg = styled.img`
  width: 20.56vw;
  height: 8.41vw;
  margin-right: 20.97vw;
  margin-left: 40.37vw;
`

const SearchImg = styled.img`
  width: 4.89vw;
  height: 5.38vw;
  margin-right: 3.75vw;
  cursor: pointer;
`

const HamburgerImg = styled.img`
  width: 6.2vw;
  height: 4.81vw;
  cursor: pointer;
`