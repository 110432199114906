import React from "react";
import styled from "styled-components";
import DownloadImage from '../../../media/images/Notice/noticeDownloadIcon.webp'
import PreviewImage from '../../../media/images/Notice/noticePreviewIcon.webp'
import ViewListButtonImage from '../../../media/images/NoticeDetail/noticeDetailButton.webp'
import PatternImage from '../../../media/images/DreamCityCardDetail/dreamCityCardDetailPattern.webp'
import PatternNoticeImage from '../../../media/images/NoticeDetail/noticeDetailPattern.webp'
import PagePrevIcon from "../../../media/images/CultureMap/pagePrevIcon.webp";
import PageNextIcon from "../../../media/images/CultureMap/pageNextIcon.webp";
import {Link, useParams} from "react-router-dom";
import {API_KEY, API_URL, PUBLISH_URL} from "../../../api/global";
import {useQuery} from "react-query";
import {BodyText1, BodyText2, BodyText3, TinyText, TinyText1, Divider, Divider1_1,}from '../../../style/comp/comm';

export const DetailPage = ({data, selectedCategory, infoData, type}) => {
  const currentData= data;
  const dream_card_detail_data= currentData;
  // const { data:dream_card_detail_data, error, isLoading } = useQuery('dream_card_detail', dream_card_detail);




  console.log(infoData)
  const {id, title, subject, url, path, disableBadge}= infoData;
  const { prev_row_id, next_row_id }= currentData;

  console.log(url, url.indexOf('/notice') !== -1);
  const isNotice= url.indexOf('/notice') !== -1;
  return(
    <Component className="txt-keep">
      {/* <PathSection>홈 &gt; {path} &gt; {selectedCategory}</PathSection> */}

      <div className="p-x10">
        {/* <Title className="txt-center">{title}</Title> */}
        {/* <SubTitle className="txt-center">{subject}</SubTitle> */}

        <div className="inst-box">
          {
            isNotice &&
            <PatternImg src={PatternNoticeImage} className="mg-la" />
          }
          {
            !isNotice &&
            <PatternImg src={PatternImage} />
          }

          <TitleSection className="flx-sbc m-flx-col p-y6">
            <BodyText3 className="ft-b">{dream_card_detail_data && dream_card_detail_data.subject}</BodyText3>
            <TinyText1 classNa1e="ft-b">{dream_card_detail_data && dream_card_detail_data.createdate.slice(0, 10)}</TinyText1>
          </TitleSection>
          {
            dream_card_detail_data.tags && dream_card_detail_data.tags.length > 0 &&
            <div className="bd-bt-bk p-y7">
              <ul className="">
                {dream_card_detail_data.tags.map((tag) => 
                  <Link 
                    onClick={e=> e.stopPropagation()}
                    to={`/totalSearch?hashtag=${tag}`} className="m-r5 ft-c1 ft-gr1-hover"
                  >{`#${tag}`}</Link>
                )}
              </ul>
            </div>
          }
          
        </div>


        {dream_card_detail_data && dream_card_detail_data?.summary &&
          <DescriptionText className="p-y6">
            <BodyText1>{dream_card_detail_data.summary}</BodyText1>
          </DescriptionText>
        }
        {
          id == 'events' &&
          <>
          <div className="bd-bt-dg p-y6">
            <BodyText1 className="ft-md">
              기간 : {dream_card_detail_data && `${dream_card_detail_data.start_date.slice(0, 10)} ~ ${dream_card_detail_data.end_date.slice(0, 10)}`}
            </BodyText1>
          </div>
          {
            dream_card_detail_data.time_text &&
            <PeriodText className=" p-y6">
              <BodyText1 className="ft-md">
                시간 : {dream_card_detail_data && `${dream_card_detail_data.time_text}`}
              </BodyText1>
            </PeriodText>
          }
          
          </>
        }
        {
          (id != 'events' && selectedCategory !== '자료실' && !disableBadge) && 
          <PeriodText className=" p-y6">
            <BodyText1 className="ft-md">
              기간 : {dream_card_detail_data && `${dream_card_detail_data.start_date.slice(0, 10)} ~ ${dream_card_detail_data.end_date.slice(0, 10)}`}
            </BodyText1>
          </PeriodText>
        }
        {
          // disableBadge && 
        }

        <div className="p-y20">
        <div className="" selectedCategory={selectedCategory} dangerouslySetInnerHTML={{ __html: dream_card_detail_data && dream_card_detail_data.content.replace(/\/data\/images/g, `${PUBLISH_URL}/data/images`)}} />
        </div>

        {/*<MainImg />*/}

        {/*<MainText>{currentData.maidText}</MainText>*/}

        <PreviewSection className="flx-cl p-y6 inst-box w100">
          <div className="flx-cl">
            <PreviewIconImg src={PreviewImage}/>
            <TinyText className="m-x15 ft-md">미리보기</TinyText>
          </div>
          <div className="flx-w m-flx-col inst-box w70">
            {dream_card_detail_data && dream_card_detail_data.pdf_files.map((data) => (
            // {[{ url: '/test', name: 'test name test name test name test name test name test name '}, { url: '/test1', name: 'test name1'}].map((data) => (
              <DownloadContent className="m-l10" target="_blank" title={data.name} rel="noopener noreferrer" href={data.url}>
                <TinyText className="txt-underline">{data.name}</TinyText>
              </DownloadContent>
            ))}
          </div>
        </PreviewSection>

        <DownloadSection className="flx-cl p-y6">
          <div className="flx-cl">
            <PreviewIconImg src={DownloadImage}/>
            <TinyText className="m-x15 ft-md">첨부파일</TinyText>
          </div>
          <div className="flx-w m-flx-col inst-box w70">
            {dream_card_detail_data && dream_card_detail_data.files.map((data) => (
            // {[{ url: '/test', name: 'test name test name test name test name test name test name '}, { url: '/test1', name: 'test name1'}].map((data) => (
              <DownloadContent className="m-l10" target="_blank" title={data.name} rel="noopener noreferrer" href={`${PUBLISH_URL}${data.url}`}>
                <TinyText className="txt-underline">{data.name}</TinyText>
              </DownloadContent>
            ))}
          </div>

          
        </DownloadSection>
        
        <div className="w100 p-y40">
          {
            type != 'total-search' &&
            <div className="w100 flx-sbc">
              {
                prev_row_id && 
                <Link to={`${url}/detail/${prev_row_id}`}>
                  <PreListSection 
                    $dir={'prev'}
                    // $preData={prevData}  onClick={handlePrevClick}
                  >
                    <PreIconImg src={PageNextIcon} $dir={'prev'}  className="m-r10"/>
                    <span className="ft5 ft-md txt-nowrap">이전 글</span>
                  </PreListSection>
                </Link>
              }
              

              <div className="w100 m-x15">
                <Link to={url} className="block-box w100 mx300 mg-ct inst-box bd-rd-30 oflow-h">
                  <BorderGradient1 className='bg-cover-area'></BorderGradient1>
                  <div className="p-y7 p-x5 txt-center flx-sbc p-x20">
                    <BodyText1 className='ft-b flx-itm-r '>목록보기</BodyText1>
                  </div>
                </Link>
              </div>
              

              {
                next_row_id && 
                <Link to={`${url}/detail/${next_row_id}`}>
                  <PreListSection 
                    $dir={'next'} 
                  // $preData={nextData} onClick={handleNextClick}
                  >
                    <span className="ft5 ft-md txt-nowrap">다음 글</span>
                    <PreIconImg src={PageNextIcon} $dir={'next'} className="m-l10"/>
                  </PreListSection>
                </Link>
              }
              
            </div>
          }
        </div>

      </div>

      {/* <BottomSection>
        <PrevListSection prevData={prevData} onClick={handlePrevClick}>
          <PrevIconImg src={PagePrevIcon}/>
          <PrevText>이전 글</PrevText>
        </PrevListSection>

        <Link to={url}>
          <div className="w100 mx200  inst-box bd-rd-30 oflow-h m-t10">
            <BorderGradient1 className='bg-cover-area'></BorderGradient1>
            <div className="p-y7 p-x5 txt-center flx-sbc p-x20">
              <BodyText1 className='ft-b flx-itm-r '>목록보기</BodyText1>
            </div>
            
          </div>
          <ViewListSection>
            <ViewListButton src={ViewListButtonImage}/>
            <ViewListText>목록 보기</ViewListText>
          </ViewListSection>
        </Link>

          <NextListSection $preData={nextData} $dir={'next'} onClick={handleNextClick}>
            <NextText>다음 글</NextText>
            <NextIconImg src={PageNextIcon}/>
          </NextListSection>
      </BottomSection> */}

      


    </Component>
  )
}

const BorderGradient1= styled.div`
  border-radius: 30px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(90deg,#e9e223,#92caf0) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /*4*/
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
`

const Component = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  position: relative;
  
  @media (max-width: 1920px) {
    /* width: 74.79vw; */
  }
`

const PathSection = styled.div`
  width: 100%;
  margin-top: 33px;
  margin-bottom: 42px;
  font-size: 18px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    margin-top: 1.72vw;
    margin-bottom: 2.19vw;
    font-size: 0.94vw;
  }
`

const Title = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  margin-bottom: 21px;

  @media (max-width: 1920px) {
    font-size: 2.08vw;
    font-family: 'SCDream7', serif;
    margin-bottom: 1.09vw;
  }
`

const SubTitle = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-bottom: 121px;

  @media (max-width: 1920px) {
    font-size: 1.15vw;
    margin-bottom: 6.3vw;
  }
`

const PatternImg = styled.img`
  display: block;
  position: relative;
  left: 0;
  top: 0  ;
  z-index: 0;
  width: 240px;
  /* transform: translateY(-100%); */
  margin-top: 80px;
  @media (max-width: 800px) {
    width: 180px;
    margin-top: 40px;
  }
`

const TitleSection = styled.div`
  width: 100%;
  border-top: 3px solid black;
  border-bottom: 1px solid black;

  @media (max-width: 1920px) {
  }
`

const TitleText = styled.div`
  font-size: 22px;
  font-family: 'SCDream6', serif;

  @media (max-width: 1920px) {
    font-size: 1.15vw;
  }
`

const CreatedText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
  }
`

const DescriptionText = styled.div`
  width: 100%;
  border-bottom: 2px solid #959595;
  @media (max-width: 800px) {
    border-bottom: 1px solid #959595;
  }
`

const PeriodText = styled.div`
  width: 100%;
  border-bottom: 2px solid #000;
  @media (max-width: 800px) {
    border-bottom: 1px solid #000;
  }
`

const MainText = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;
  width: 1436px;
  padding: 0 5px;
  margin-top: ${({selectedCategory}) => selectedCategory === '자료실' ? '41px' : ''};

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream4', serif;
    width: 74.79vw;
    padding: 0 0.26vw;
    margin-top: ${({selectedCategory}) => selectedCategory === '자료실' ? '2.14vw' : ''};
  }
`

const MainImg = styled.div`
  width: 1434px;
  height: 668px;
  background-color: #959595;
  margin-top: 25px;
  margin-bottom: 24px;

  @media (max-width: 1920px) {
    width: 74.76vw;
    height: 34.79vw;
    background-color: #959595;
    margin-top: 1.3vw;
    margin-bottom: 1.25vw;
  }
`

const Youtube = styled.div`
  width: 1434px;
  height: 668px;
  margin-top: 23px;

  @media (max-width: 1920px) {
    width: 74.76vw;
    height: 34.79vw;
    margin-top: 1.2vw;
  }
`

const PreviewSection = styled.div`
  border-top: 3px solid black;
  border-bottom: 3px solid #959595;

  @media (max-width: 1920px) {
    border-top: 1px solid black;
    border-bottom: 1px solid #959595;
  }
`

const PreviewIconImg = styled.img`
  width: 20px;
  @media (max-width: 800px) {
    width: 16px;
  }
`

const PrevviewText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-left: 31px;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream5', serif;
    margin-left: 1.62vw;
  }
`

const DownloadSection = styled.div`
  border-bottom: 3px solid black;
  @media (max-width: 800px) {
    border-bottom: 1px solid black;
  }
`

const DownloadImg = styled.img`
  width: 27px;
  height: 27px;

  @media (max-width: 1920px) {
    width: 1.41vw;
    height: 1.41vw;
  }
`

const DownloadText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-left: 32px;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream5', serif;
    margin-left: 1.67vw;
  }
`

const DownloadContent = styled.a`
  color: #58595b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;

  @media (max-width: 800px) {
    max-width: 100%;
  }
`

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1436px;
  margin-top: 80px;
  margin-bottom: 84px;

  @media (max-width: 1920px) {
    width: 74.79vw;
    margin-top: 4.17vw;
    margin-bottom: 4.38vw;
  }
`

const PrevListSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.prevData ? 1 : 0};
  pointer-events: ${props => props.prevData ? 'auto' : 'none'};
`

const PrevIconImg = styled.img`
  width: 14px;
  height: 16px;
  margin-right: 25px;
  margin-bottom: 3px;

  @media (max-width: 1920px) {
    width: 0.73vw;
    height: 0.83vw;
    margin-right: 1.3vw;
    margin-bottom: 0.16vw;
  }
`

const PrevText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
  }
`

const ViewListSection = styled.div`
  position: relative;
  cursor: pointer;
`


const ViewListButton = styled.img`
  width: 295px;
  height: 46px;

  @media (max-width: 1920px) {
    width: 15.36vw;
    height: 2.4vw;
  }
`

const ViewListText = styled.div`
  font-size: 21.5px;
  font-family: 'SCDream5', serif;
  position: absolute;
  top: 13px;
  left: 105px;

  @media (max-width: 1920px) {
    font-size: 1.12vw;
    font-family: 'SCDream5', serif;
    position: absolute;
    top: 0.68vw;
    left: 5.47vw;
  }
`

const PreListSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  /* opacity: ${props => props.$preData ? 1 : 0}; */
  /* pointer-events: ${props => props.$preData ? 'auto' : 'none'}; */
`
const PreIconImg = styled.img`
  width: 10px;
  transform: rotate(${props => props.$dir == 'next' ? '0deg' : '180deg'});
`