import OpneTypeImg1 from '../../../media/images/Policy/img_opentype02-bottom.png'
import OpneTypeImg2 from '../../../media/images/Policy/img_opentype04-bottom.png'
import {Component, SectTitle, SectTitleBox, SubTitle0, SubTitle1, BodyText1, BodyText2, TinyText1, Divider, SubTitle3, Divider1} from '../../../style/comp/comm';
import WomenImage from '../../../media/images/Policy/icon-img-5.svg'

export default function PolicyBottom ({type}) {
  return (
    <div className="w100 bd-tp-gr">
      <Component className="p-y20 p-x10">
        <div className="flx-cc m-flx-col-cc">
          <div className="flx-cl m-r10">
            <img src={type == 'type1' ? OpneTypeImg1 : OpneTypeImg2} alt="" className='w100 mx100 m-r15 m-w70p'/>
            <p className="">
              <TinyText1 className='ft-md'>
                본 공공저작물은 공공누리 “출처표시 + {type == 'type1' ? '변형가능' : '변형불가능'} + 상업적이용금지” 조건에 따라 이용할 수 있습니다.
              </TinyText1>
            </p>
          </div>
          <div className="flx-cl m-mg-t10">
            <img src={WomenImage} alt="" className='w30p m-r7'/>
            <p className="">
            <TinyText1 className='ft-md'>
              <span className="ft-b">콘텐츠 제공 담당부서</span><span className='m-r5'>│문화도시기획팀</span>
              <span className="ft-b">연락처</span>│<a href="tel:043-846-7983" className="txt-underline ft-c1-hover">043-846-7983</a>
            </TinyText1>
            </p>
          </div>
        </div>
      </Component>
    </div>
  )
}