	
function setCookie ( cookieName, value, exdays ) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + exdays);
	var cookieValue = escape(value) + ((exdays==null) ? "" : "; expires=" + exdate.toGMTString());
	document.cookie = cookieName + "=" + cookieValue;
}

function getCookie ( cookieName ) {
	var cookieName = cookieName + '='
				,	cookieData = document.cookie
			,	start = cookieData.indexOf(cookieName)
			, cookieValue = '';
	if( start != -1 ){
		start += cookieName.length;
		var end = cookieData.indexOf(';', start);
		if(end == -1)end = cookieData.length;
		cookieValue = cookieData.substring(start, end);
	}
	return unescape(cookieValue);
}

function deleteCookie ( cookieName ){
	var expireDate = new Date();
	expireDate.setDate(expireDate.getDate() - 1);
	document.cookie = cookieName + "= " + "; expires=" + expireDate.toGMTString();
}


export { setCookie, getCookie, deleteCookie };