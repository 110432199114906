import {useState} from 'react';
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {TitleSection} from '../../_Include/Title';
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../../style/comp/comm';
import {Link} from "react-router-dom";

import Bg1 from "../../../../media/images/Comm/bg-2.png";
import MainImg from "../../../../media/images/Life/main.png";
import TopIcon from "../../../../media/images/Comm/top-icon-1.svg";
import QuotIcon from "../../../../media/images/Comm/quot.png";
import TopImg from "../../../../media/images/Life/top-icon-img.svg";
import Icon1 from "../../../../media/images/Culture/icon-1.svg";
import Icon2 from "../../../../media/images/Culture/icon-2.svg";
import Icon3 from "../../../../media/images/Culture/icon-3.svg";
import Diag1 from "../../../../media/images/Life/diagram-1.png";
import Diag1_1 from "../../../../media/images/Life/diagram-1-1.png";

import Arrow1 from "../../../../media/images/Comm/arrow-1.png";
import Arrow2 from "../../../../media/images/Comm/arrow-2.svg";
import IntroRegionImg1 from "../../../../media/images/Local/intro-region-1.png";
import BgIcon1 from "../../../../media/images/Life/bg-img.svg";



import ChoongjuroBottom from './ChoongjuroBottom';

const title= '충주로';

const Diagram= ({txt1, txt2, last})=> (
  <div className="inst-box w100">
    <div className="" style={{paddingTop: '90%'}}>
      {/* <img src={last ? Diag1_1 : Diag1} alt="" className='w100'/> */}
      <DiagItem last={last?.toString()}/>
    </div>
    <div className="bg-cover-area flx-cc txt-keep">
      <div className="ft-eb flx-col p-x20 txt-center" >
        <span className='ftxl m-ftl ft-gr1 ft-hv bd-bt-gr2 lh-10 p-b2'>{txt1}</span>
        <p className="m-t10 p-x8" style={{minHeight: 40 }}>
          <BodyText1 className={``}>{txt2}</BodyText1>
        </p>
      </div>
    </div>
  </div>
)
const DiagItem= styled.div`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props?.last ? Diag1_1 : Diag1});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 800px) {
    background-image: url(${Diag1_1});
  }
  
`
export default function Choongjuro () {

  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10 zIdx2">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 살면(Life) > ${title}`} 
              title={`How To ${title}`} 
              subtitle={'충주로 환승이주 하고 싶다면'} 
            />
            
            <div className="inst-box">
              
              <p className="p-l40 p-y10 m-pd-10 inst-box zIdx2">
                <BodyText2 className="ft-eb">충주로 환승이주 </BodyText2>
              </p>
              <IconImg src={TopIcon} alt="" className="" />
              <TopImgArea src={TopImg} alt="" className="" />
              <img src={MainImg} alt="" className="w100 inst-box" />



              <div className="bd-bt-bk p-y50 m-pd-h20">

                <p className="p-l40 m-pd-10">
                  <BodyText2 className="ft-eb">충주로의 환승이주를 실현하기 위한 단계</BodyText2>
                </p>
                <div className="flx-0 m-flx-col w100 mx900 mg-ct m-t30 ">

                  {/* 다이어 그램 왼쪽 */}
                  <div className="w50 m-w100 flx-0 ">
                    <div className="w50 ">
                      <div className="w100 mx200 mg-ct ">
                        <Diagram txt1={'01'} txt2={'귀농/귀촌 고민하기 '} />
                      </div>
                      
                    </div>


                    <div className="w50 ">
                      <div className="w100 mx200 mg-ct ">
                        <Diagram txt1={'02'} txt2={'귀농/귀촌 정보 수집하기'} />
                      </div>
                    </div>
                    
                  </div>


                  {/* 다이어 그램 오른쪽 */}
                  <div className="w50 m-w100 flx-w ">
                    <div className="w50 ">
                      <div className="w100 mx200 mg-ct ">
                        <Diagram txt1={'03'} txt2={'지역 방문 및 영농체험하기 '} />
                      </div>
                      
                    </div>


                    <div className="w50 ">
                      <div className="w100 mx200 mg-ct ">
                        <Diagram txt1={'04'} 
                          txt2={'정착지 물색하기 '} 
                          last={true}
                        />
                      </div>
                    </div>
                    
                    
                    
                  </div>


                </div>

              </div>



              <ChoongjuroBottom />

            </div>
            

            



            
          </div>
        </div>
      </Component>

      <div className="inst-box p-b80">
        <BgImg1 className=" inst-box">
          <BttomImg src={BgIcon1} alt="" />
        </BgImg1>
        
      </div>
    </div>
  )
}


const IconImg = styled.img`
  position: absolute;
  top: -30px; right: 0;
  width: 330px;
  @media (max-width: 800px) {
    width: 170px;
    top: 0px;
  }
`
const TopImgArea= styled.img`
  position: absolute;
  top: -70px; right: 100px;
  width: 110px;
  @media (max-width: 800px) {
    width: 58px;
    top: -30px; right: 15px;
  }
`
const BttomImg= styled.img`
  position: absolute;
  right: calc(50% - 570px); 
  bottom: 390px;
  z-index: 2;
  width: 115px;
  @media (max-width: 800px) {
    width: 80px;
    right: calc(50% - 200px);
    display: none;
  }
`

const BgImg1= styled.div`
  /* padding-top: 36%; */
  /* margin-top: -10em; */
  position: absolute;
  bottom : 0;
  width: 100%;
  height: 500px;
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(50% + 500px) -6%;
  /* background-size: 230%; */
  @media (max-width: 800px) {

    /* background-position: calc(50% + 280px) 3%; */
  }
`