import React from "react";
import styled from "styled-components";
import facebook from "../../../../media/images/layouts/fb.webp"
import youtube from "../../../../media/images/layouts/youtube.webp"
import instagram from "../../../../media/images/layouts/insgta.webp"
import Logo from "../../../../media/images/layouts/logo.webp"
import CI from "../../../../media/images/layouts/ci.webp"
import CI2 from "../../../../media/images/layouts/logo2.svg"
import {Link} from "react-router-dom";

export const MobileShareFooter = ({ snsLinks }) => {

  return (
    <Component>

      <Contents>

        <FirstLine>
          <FirstLeftText>(재)충주문화관광재단</FirstLeftText>

          <div className="flx-cl">
            <FirstLeftText className="m-r10">
              <Link to={"/private-policy"}>개인정보보호정책</Link>
              <span className="m-x5">|</span>
              <Link to={"/copyright-policy"}>저작권정책</Link>
            </FirstLeftText>

            <SNSbox>
              <Link to={snsLinks['youtube']} target="_blank">
                <SNSImg src={youtube}/>
              </Link>
              <Link to={snsLinks['facebook']} target="_blank">
                <SNSImg src={facebook}/>
              </Link>
              <Link to={snsLinks['instagram']} target="_blank">
                <SNSImg src={instagram}/>
              </Link>
            </SNSbox>
          </div>
        </FirstLine>

        <SecondLine>
          <SecondAddress>{`(27388) 충북 충주시 중앙로 128 2층
            사업자등록번호 303-82-07489   Fax 043-851-7983   대표전화 043-723-1356 / 043-723-1346`}
          </SecondAddress>
        </SecondLine>

        <FooterLine/>

        <ThirdLine>
          <ThirdLeftText>Copyright © 재단법인 충주문화관광재단. All Rights Reserved.</ThirdLeftText>
          <ThirdImageBox>
            <ThirdLogoImg src={Logo}/>
            <ThirdCultureImg src={CI2}/>
          </ThirdImageBox>
        </ThirdLine>

      </Contents>

    </Component>
  )
}

const Component = styled.footer`
  background-color: #333132;
  height: 31.36vw; /* 360px */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 100; */
`;

const Contents = styled.div`
  width: 93.48vw; /* 1120px */
`;

const FirstLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const FirstLeftText = styled.text`
  color: #bcbec0;
  font-family: 'SCDream6', serif;
  font-size: 2.77vw; /* 34px */
`;

const SNSbox = styled.div`
  width: 19.59vw; /* 240px */
  display: flex;
  justify-content: space-between;
`;

const SNSImg = styled.img`
  display: block;
  width: 4.08vw; /* 50px */
  height: 4.08vw; /* 50px */
  cursor: pointer;
`;

const SecondLine = styled.div`
  margin-top: 2.12vw; /* 26px */
  margin-bottom: 3.18vw; /* 39px */
`;

const SecondAddress = styled.text`
  color: #bcbec0;
  white-space: pre-line;
  font-family: 'SCDream2', serif;
  font-size: 2.12vw; /* 26px */
`;

const FooterLine = styled.div`
  border-bottom: 0.16vw solid #959595; /* 2px */
  margin-bottom: 1.63vw; /* 20px */
`;

const ThirdLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ThirdLeftText = styled.text`
  color: #bcbec0;
  font-size: 1.96vw; /* 24px */
  font-family: 'SCDream2', serif;
`;

const ThirdImageBox = styled.div``;

const ThirdLogoImg = styled.img`
  width: 3.10vw; /* 38px */
  height: 3.51vw; /* 43px */
`;

const ThirdCultureImg = styled.img`
  width: 15.42vw; /* 189px */
  height: 3.51vw; /* 43px */
  margin-left: 1.96vw; /* 24px */
`;
