import React, {useState} from "react";
import styled from "styled-components";
import SearchIcon from '../../../../media/mobileImage/layouts/mobileSearchIcon.webp'
import CloseIcon from '../../../../media/mobileImage/layouts/mobileSearchCloseIcon.webp'
import {SearchData} from "../../../../configs/MainHome/SearchData";
import {useSetRecoilState} from "recoil";
import {MobileShowSearchResult, TotalSearchResultAtom} from "../../../../recoil/LayoutRecoil";
import {Link, useNavigate} from "react-router-dom";
import {API_KEY, API_URL} from "../../../../api/global";


export const MobileShareSearch = () => {
  const setShowSearchResult = useSetRecoilState(MobileShowSearchResult)
  const [inputText, setInputText] = React.useState('');
  const userAgent = window.navigator.userAgent.toLowerCase();
  const navigate = useNavigate();
  const SetTotalSearchResult = useSetRecoilState(TotalSearchResultAtom);
  const [searchData, setSearchData] = useState([])


  const handleInputChange = async (event) => {
    setInputText(event.target.value);

    const encodedData = `?search_text=${event.target.value}`;

    if (event.target.value.length > 0) {
      const response = await fetch(API_URL + `api/totalsearch/keyword${encodedData}`, {
        headers: {
          'X-CCFCJ-API-KEY': `${API_KEY}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        }
      })

      if (!response.ok) {
        throw new Error('main_communities_list Error')
      }

      const data = await response.json();

      setSearchData(data.search_text)
    }
  }

  const handleSearch = () => {
    // const encodedData = `?search_text=${encodeURIComponent(inputText)}`;
    SetTotalSearchResult(inputText);

    // TODO 추후 삭제
    navigate('/totalSearch');
    setShowSearchResult(false)
  }

  return (
    <Componenat>

      <SearchInput placeholder={'검색어를 자세히 입력할 수록 정확한 정보를 찾으실 수 있습니다.'} onChange={handleInputChange}/>

      {/*<Link to="/totalSearch" onClick={() => setShowSearchResult(false)}>*/}
      {/*  <SearchImg src={SearchIcon}/>*/}
      {/*</Link>*/}

      <SearchImg src={SearchIcon} onClick={() => handleSearch()}/>

      <CloseImg src={CloseIcon} onClick={() => setShowSearchResult(false)}/>


      {/* 겁색에 따른 결과 데이터 리스트 */}
      {searchData.length > 0 && (
        <ResultSection>
          <ResultContents>
            {searchData.slice(0, 10).map((data) => (
              <ResultText>{data}</ResultText>
            ))}
          </ResultContents>
        </ResultSection>
      )}


    </Componenat>
  )
}

const Componenat = styled.div`
  width: 100vw;
  height: 18.36vw; /* 225px */
  background-color: white;
  z-index: 50;
  position: absolute;
  top: 13.12vw; /* 161px */
  display: flex;
  align-items: center;
`

const SearchInput = styled.input`
  width: 82.39vw; /* 1010px */
  height: 7.91vw; /* 97px */
  margin-right: 2.45vw; /* 30px */
  margin-left: 4.41vw; /* 54px */
  font-family: 'SCDream4', serif;
  font-size: 2.24vw; /* 27.5px */
  padding-left: 2.85vw; /* 35px */
  border: 0.16vw solid black; /* 2px */
`

const SearchImg = styled.img`
  width: 4.24vw; /* 52px */
  height: 4.65vw; /* 57px */
  cursor: pointer;
  position: absolute;
  top: 6.77vw; /* 83px */
  right: 15.48vw; /* 153px */
`

const CloseImg = styled.img`
  width: 4.48vw; /* 55px */
  height: 4.41vw; /* 54px */
  cursor: pointer;
`

const ResultSection = styled.div`
  width: 85.73vw; /* 1051px */
  height: 36.97vw; /* 490px */
  position: absolute;
  left: 4.41vw; /* 54px */
  top: 13.38vw; /* 164px */
  z-index: 100;
  background-color: white;
`

const ResultContents = styled.div`
  margin-top: 2.77vw; /* 34px */
  margin-left: 3.26vw; /* 40px */
  width: 60.19vw; /* 738px */
  height: 34.42vw; /* 422px */
`

const ResultText = styled.div`
  font-size: 1.79vw; /* 22px */
  font-family: 'SCDream4', serif;
  color: #959595;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 1.14vw; /* 14px */
`
