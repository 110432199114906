import styled from "styled-components";
import {useQuery} from "react-query";
import { main_popup_list } from "../../../api/MainHome";

import { useState } from 'react';
import { setCookie, getCookie, deleteCookie } from '../../../support/jhc-cookie';
import { PUBLISH_URL } from '../../../api/global'
import { Link } from 'react-router-dom';
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../style/comp/comm';

export default function PopUp () {
  const { data, error, isLoading, refetch } = useQuery(['main_popup_list' ], main_popup_list);
  
  return (
    data && data.map(d=> <PopUpItem key={d.id} data={d} /> )
  )
}

const PopUpItem= ({ data })=> {
  const { id, title, popup_type, top, left, width, height, link, link_new_win, image, close_type }= data;
  const idName= `popup-${id}`;
  // console.log(id, idName, getCookie(idName), document.cookie)
  // deleteCookie(idName)
  const [open, setOpen]= useState(getCookie(idName) ? false : true)
  const handleClose= (e)=> {
    if( checked ) setCookie(idName, true, 1)
    else deleteCookie(idName)
    setOpen(false)
  }

  const [checked, setChecked]= useState(false);
  const handleChecked= (id)=> (e)=> {
    setChecked(!checked)
  }
  return (
    
    open &&
    <PopUpStyle $top={top} $left={left} $width={width} $height={height}>
      <Link onClick={handleClose} to={`${link}`} target={Number(link_new_win) ? "_blank" : "_self"}>
        <PopUpImageStyle src={`${PUBLISH_URL}${image}`} alt="" className="w100 h100" />
      </Link>
        <ContentSectStyle className="p-y10 p-x10">
          <div className="flx-cr">
            <div className="m-r10">
            {
              close_type == "nottoday" && 
              <div className="flx-cl">
              
                <input type="checkbox" id={idName} checked={checked} onChange={handleChecked(idName)} className="cursor-point m-r3" />
                <label htmlFor={idName} className="cursor-point lh-10">
                  <TinyText>오늘 하루 열지 않기</TinyText>
                </label>
              </div>
            }
            </div>
            
            <div onClick={handleClose} className="cursor-point ft-c1 ft-b">
              {/* 닫기 */}
              <div onClick={handleClose} className="cursor-point flx-0">
                <svg fill="none" width="26" height="26" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg" id="fi_4347434"><g fill="rgb(111, 111, 111)"><path d="m255.575 476.292c-28.978.054-57.68-5.62-84.458-16.694s-51.103-27.331-71.5785-47.836c-86.0513-86.051-86.0513-226.057 0-312.108 20.4445-20.5595 44.7645-36.8599 71.5515-47.9576 26.786-11.0978 55.508-16.7725 84.503-16.6956 58.95 0 114.37 22.9517 156.036 64.6532 41.684 41.684 64.653 97.103 64.653 156.054s-22.952 114.37-64.653 156.054c-20.479 20.505-44.808 36.762-71.588 47.836-26.781 11.074-55.486 16.747-84.466 16.694zm.018-405.9809c-24.357-.0691-48.485 4.6953-70.987 14.0174s-42.931 23.0165-60.103 40.2895c-35.0103 35.011-54.2898 81.567-54.2898 131.09s19.2795 96.062 54.2898 131.09c72.28 72.28 189.899 72.298 262.162 0 35.01-35.01 54.307-81.567 54.307-131.09s-19.28-96.062-54.307-131.09c-17.173-17.268-37.599-30.9588-60.097-40.2806-22.499-9.3218-46.622-14.0892-70.975-14.0263z"></path><path d="m180.677 348.25c-3.495.008-6.914-1.023-9.822-2.961-2.908-1.939-5.175-4.698-6.512-7.927-1.338-3.229-1.685-6.783-1-10.21.686-3.427 2.375-6.573 4.852-9.039l149.804-149.804c1.639-1.639 3.585-2.939 5.727-3.827 2.141-.887 4.437-1.343 6.755-1.343s4.614.456 6.755 1.343c2.142.888 4.088 2.188 5.727 3.827s2.94 3.585 3.827 5.727 1.344 4.437 1.344 6.755-.457 4.614-1.344 6.756c-.887 2.141-2.188 4.087-3.827 5.726l-149.804 149.805c-1.635 1.645-3.58 2.949-5.723 3.837-2.142.888-4.44 1.342-6.759 1.335z"></path><path d="m330.491 348.25c-2.319.003-4.615-.453-6.757-1.341-2.143-.887-4.088-2.19-5.725-3.831l-149.805-149.805c-1.639-1.639-2.939-3.585-3.826-5.726-.887-2.142-1.344-4.438-1.344-6.756s.457-4.613 1.344-6.755 2.187-4.088 3.826-5.727c1.64-1.639 3.586-2.939 5.727-3.827 2.142-.887 4.438-1.343 6.756-1.343s4.613.456 6.755 1.343c2.142.888 4.088 2.188 5.727 3.827l149.804 149.804c2.477 2.466 4.166 5.612 4.851 9.039.686 3.427.338 6.981-.999 10.21-1.338 3.229-3.604 5.988-6.512 7.927-2.909 1.938-6.327 2.969-9.822 2.961z"></path></g></svg>
              </div>
            </div>
          </div>
        </ContentSectStyle>
      
    </PopUpStyle>
  )
}
const ContentSectStyle= styled.div`
  background-color: #f5f5f1;
`

const PopUpStyle= styled.div`
  position: fixed;
  z-index: 999;
  max-width: ${p=> `calc(90% - ${p.$left}px)`};
  max-height: ${p=> `calc(90% - ${p.$top}px)`};
  top: ${p=> `${p.$top}px`}; 
  left: ${p=> `${p.$left}px`}; 
  width: ${p=> `${p.$width}px`}; 
  height: ${p=> `${p.$height}px`}; 
`
const PopUpImageStyle= styled.img`
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
`