import React, {useRef, useEffect, useState} from 'react';
import styled from "styled-components";
import ApplyButtonImage from '../../../media/images/ConsultationRequest/ConsultationButton.webp'
import {useMutation} from "react-query";
import {consult_send_form} from "../../../api/Consultationrequest";
import { useNavigate } from "react-router-dom";


function phoneCheck (string) {
  let regex = new RegExp(/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/);
  const status= regex.test(string)
  return status;
}

function onlyNum (num) {
  let regex = /([^0-9,])|([~!@\#$%^&*\()\-=+_'])/g;
  const status= num.search(regex) == -1;
  return status;
}

export const ApplicationForm = () => {
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [contactMethod, setContactMethod] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailDomain, setEmailDomain] = useState('');
  const [emailDomainDrop, setEmailDomainDrop] = useState('직접입력');
  const [showEmailDomains, setShowEmailDomains] = useState(false);
  const [description, setDescription] = useState('');
  const [agree, setAgree] = useState(false);
  const [isCustomInput, setIsCustomInput] = useState(true);
  const userAgent = window.navigator.userAgent.toLowerCase();

  let navigate = useNavigate();
  const handleSelection = (value) => {
    if (value === "직접입력") {
      setIsCustomInput(true);
      setEmailDomain("");
      setEmailDomainDrop("직접입력");
    } else {
      setEmailDomain(value);
      setEmailDomainDrop(value);
      setIsCustomInput(false);
    }
    setShowEmailDomains(false);
  };

  const consult_send_form_mutation = useMutation(consult_send_form, {
    onSuccess: () => {
      console.log('Success Sending consult_send_form_mutation');
      alert('신청을 완료하였습니다.');
      navigate("/");

    },
    onError: (error) => {
      console.log(`오류 발생 consult_send_form_mutation: ${error.message}`);
    }
  });

  const handleSubmit = () => {
    if (!agree) {
      alert('개인정보 수집 및 이용에 동의해주세요.');
      return;
    }

    if( !category ) {
      alert('구분을 입력해 주세요.');
      return;
    }
    if( !name ) {
      alert('이름을 입력해 주세요.');
      return;
    }

    if( !contactMethod ) {
      alert('회신을 받을 수단을 선택해 주세요.');
      return;
    }
    if( !phone && (!email || !emailDomain) ) {
      alert('회신을 받을 수단을 입력해 주세요.');
      return;
    }
    if( !description ) {
      alert('상세내용을 입력해 주세요.');
      return;
    } 

    console.log(category, name, phone, email, description)

    const formData = {
      consultant_type : category === '귀농' ? 'nong' : 'chon',
      name : name,
      is_phone_or_email : contactMethod === '휴대폰' ? 'phone' : 'email',
      phone_or_email : contactMethod === '휴대폰' ? phone : `${email}@${emailDomain}`,
      content: description,
      is_agree : agree
    };

    const encodedText = encodeURIComponent(JSON.stringify(formData))
    const encodedData = `jsonRequestData=${encodedText}`;
    // return;
    consult_send_form_mutation.mutate(encodedData);
  };

  const domainInput= useRef();
  useEffect(()=> {
    domainInput?.current?.focus()
  }, [emailDomain])
  

  const handlerDetail= (e) => {
    const { value }= e.target;
    if( value.length == 500 ){
      alert('500까지 입력이 가능합니다.')
      return;
    }
    setDescription(e.target.value)
  }
  const handlerPhone= (e)=> {
    const { value }= e.target;
    const validity= onlyNum(value);
    console.log( validity )
    if( !validity ) {
      alert('숫자만 입력해주세요.')
      return;
    }
    setPhone(e.target.value)
  }
  return (
    <Component className='txt-keep'>

      {/* 구분 */}
      <DivisionSection>
        <Title>• 구분</Title>
        <DivisionInput type="radio" value="귀농" checked={category === '귀농'} onChange={() => setCategory('귀농')}/>
        <RadioText>귀농</RadioText>
        <DivisionInput type="radio" value="귀촌" checked={category === '귀촌'} onChange={() => setCategory('귀촌')}/>
        <RadioText>귀촌</RadioText>
      </DivisionSection>


      {/* 성명 */}
      <NameSection>
        <Title>• 성명</Title>
        <NameInput type="text" value={name} onChange={e => setName(e.target.value)}/>
      </NameSection>


      {/* 신청한 상담에 대한... */}
      <ReplySection className='m-y20'>
        <Title>• 신청한 상담에 대한 회신을 받을 수단을 선택해주세요.</Title>
        <div className="m-t10 m-x10">

          <div className="flx-cl w100">
            <div className="flx-cl">
              <EmailRadio type="radio" value="휴대폰" checked={contactMethod === '휴대폰'}
                              onChange={() => setContactMethod('휴대폰')}/>
              <RadioText>휴대폰</RadioText>
            </div>

            <div className="flx-cl">
              <EmailRadio type="radio" value="이메일" checked={contactMethod === '이메일'}
                          onChange={() => setContactMethod('이메일')}/>
              <RadioText>이메일</RadioText>
            </div>
          </div>

          <div className="flx-col m-t10">
            { 
              contactMethod=='휴대폰' &&
              <NameInput type="text" maxLength={11} value={phone} onChange={handlerPhone}/>
            }

            {/* <PhoneSection> </PhoneSection> */}


            {
              contactMethod == '이메일' &&
              <EmailSection className='flx-0 m-flx-col-cl'>
                <div className="flx-cl">
                  <EmailInput type="text" value={email} onChange={e => setEmail(e.target.value)}/>
                  <AtSign>@</AtSign>
                  <DomainInput 
                    ref={domainInput}
                    type="text" value={emailDomain} 
                    readOnly={!isCustomInput}
                    onChange={(e) => setEmailDomain(e.target.value)}
                  />
                </div>
                <DropdownSection className='m-w100 m-mg-t5'>
                  <DropdownInput 
                    onClick={() => setShowEmailDomains(!showEmailDomains)}
                    readOnly
                    value={emailDomainDrop}
                  />
                  {showEmailDomains && (
                    <DropdownListSection>
                      <DropdownListText onClick={() => handleSelection("직접입력")}>직접입력</DropdownListText>
                      <DropdownListText onClick={() => handleSelection("naver.com")}>naver.com</DropdownListText>
                      <DropdownListText onClick={() => handleSelection("gmail.com")}>gmail.com</DropdownListText>
                      <DropdownListText onClick={() => handleSelection("daum.net")}>daum.net</DropdownListText>
                    </DropdownListSection>
                  )}
                </DropdownSection>
              </EmailSection>
            }
            
          </div>

        </div>

        
        
      </ReplySection>


      {/* 상세내용 */}
      <DetailSection>
        <Title>• 상세내용</Title>
        
        <DetailTextArea placeholder='500자이내 입력가능' maxLength={500} value={description} onChange={
          handlerDetail}/>
      </DetailSection>


      {/* 수집 및 이용 동의 */}
      <AgreeSection className='m-t20'>
        <Title>• 환승이주(충주이주플랫폼) 개인정보 수집·이용 동의</Title>
        <AgreeText>
          {`환승이주(충주이주플랫폼)은 개인정보보호법을 준수하여 귀하의 소중한 개인정보를 안전하게 관리하고 있으며 개인정보보호법 제15조 및 제22조에
근거하여 다음과 같이 개인정보를 수집·이용하는데 동의를 받고자 합니다.

<개인정보 수집 및 이용목적>
* 이주 상담에 필요한 개인정보수집
* 개인정보 수집항목 및 수집방법
- 필수항목: 성명, 연락처, 상세내용
- 수집방법: 상담신청`}
        </AgreeText>
      </AgreeSection>


      {/* 개인정보 동의 */}
      <AgreeSelectSection className='flx-cl'>
        <AgreeSelectRadio type="radio" value={agree} checked={agree} onChange={() => setAgree(!agree)}/>
        <AgreeSelectText>개인정보 수집 및 이용에 동의합니다.</AgreeSelectText>
      </AgreeSelectSection>


      {/* 신청 버튼 */}
      <div className="flx-end">
        <ApplyButtonSection onClick={() => handleSubmit()}>
          {/* <ApplyImg src={ApplyButtonImage}/> */}
          <GradientBorder/>
          <ApplyText 
            // Medge={userAgent.includes('windows')}
          >신청</ApplyText>
        </ApplyButtonSection>
      </div>


    </Component>
  )
}

const GradientBorder= styled.div`
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  border-radius: 30px; /*1*/
  border: 3px solid transparent; /*2*/
  background: linear-gradient(90deg,#e9e223,#92caf0) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /*4*/
  -webkit-mask-composite: xor; /*5'*/
`

const Component = styled.div`
  width: 100%;
  background-color: white;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 6em;
  padding-right: 6em;
  @media (max-width: 800px) {
    padding-left: 1em;
    padding-right: 1em;
  }
`

const DivisionSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 54px;

  @media (max-width: 800px) {
    align-items: center;
    margin-top: 2.81vw;
  }
`

const NameSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 41px;

  @media (max-width: 800px) {
    align-items: center;
    margin-top: 20px;
  }
`

const ReplySection = styled.div`
  margin-top: 41px;

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`

const DetailSection = styled.div`

`

const AgreeSection = styled.div`
  
`

const Title = styled.div`
  font-size: 16px;
  font-family: 'SCDream6', serif;
  margin-right: 12px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

const DivisionInput = styled.input`
  width: 16px;
  height: 16px;
  cursor: pointer;

  @media (max-width: 800px) {
  }
`

const NameInput = styled.input`
  width: 100%;
  max-width: 251px;
  background-color: #e6e7e8;
  border: transparent;
  font-size: 16px;
  padding: 5px 10px;

  @media (max-width: 800px) {
    font-size: 12px;

  }
`

const EmailSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    align-items: center;
  }
`

const EmailRadio = styled.input`
  width: 16px;
  height: 16px;
  cursor: pointer;
  @media (max-width: 800px) {
  }
`

const EmailInput = styled.input`
  width: 264px;
  background-color: #e6e7e8;
  border: transparent;
  padding: 5px 10px;
  font-size: 16px;

  @media (max-width: 800px) {
    font-size: 12px;
    width: 100%;
  }
`

const DomainInput = styled.input`
  width: 150px;
  background-color: #e6e7e8;
  border: transparent;
  font-size: 16px;
  padding: 5px 10px;

  @media (max-width: 800px) {
    font-size: 12px;
    width: 100%;
  }
`

const DetailTextArea = styled.textarea`
  width: 100%;
  height: 293px;
  background-color: #e6e7e8;
  border: transparent;
  padding: 15px 20px;
  margin-top: 10px;
  resize: none;

  @media (max-width: 800px) {
    font-size: 12px;
    height: 153px;
  }
`

const AgreeText = styled.div`
  width: 100%;
  height: 293px;
  background-color: #e6e7e8;
  border: transparent;
  padding: 15px 20px;
  margin-top: 10px;
  /* resize: none; */

  @media (max-width: 800px) {
    font-size: 12px;
    height: 153px;
  }
`

const RadioText = styled.div`
  font-size: 16px;
  font-family: 'SCDream5', serif;
  margin-right: 35px;
  margin-left: 9px;

  @media (max-width: 800px) {
    font-size: 12px;
    /* font-size: 1.15vw; */
    font-family: 'SCDream5', serif;
    margin-right: 5px;
    margin-left: 5px;
  }
`

const AtSign = styled.div`
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

const DropdownSection = styled.div`
  position: relative;
  margin-left: 11px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -4px;;
    right: 8px;
    z-index: 22;
    border-top: 10px solid #000; 
    border-bottom: 6px solid transparent; /* 40px height (20+20) */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    pointer-events: none;
  }
  @media (max-width: 800px) {
    margin-left: 0;
  }
`

const DropdownInput = styled.input`
  cursor: pointer;
  width: 150px;
  border: 1px solid #959595;
  font-size: 16px;
  padding: 5px 10px;

  @media (max-width: 800px) {
    font-size: 12px;
    width: 100%;
  }
`

const DropdownListSection = styled.div`
  border: 1px solid #959595;
  position: absolute;
  /* width: 231px; */
  top: 30px;
  background-color: white;
  width: 150px;

  @media (max-width: 800px) {
    /* border: 0.05vw solid #959595; */
    font-size: 12px;
    position: absolute;
    /* width: 12.03vw; */
    /* top: 2.50vw; */
  }
`

const DropdownListText = styled.div`
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 16px;
  font-family: 'SCDream4', serif;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 12px;
    /* margin-left: 0.52vw;
    margin-top: 0.52vw;
    margin-bottom: 0.52vw;
    font-size: 0.94vw; */
  }
`

const AgreeSelectSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`

const AgreeSelectRadio = styled.input`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

const AgreeSelectText = styled.div`
  font-size: 16px;
  margin-left: 4px;

  @media (max-width: 800px) {
  font-size: 12px;
  }
`

const ApplyButtonSection = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  /* margin: 0 auto; */
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  @media (max-width: 800px) {
    max-width: none;

  }
`

const ApplyText = styled.p`
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-weight: 700;

  @media (max-width: 800px) {
    font-size: 14px;
    
  }
`
