import PopUp from './PopUp';

export default function Wrapper ({ component, routeInfo }) {
  const { policy }= routeInfo;
  return (
    <>
    {component}
    { policy && policy }
    <PopUp/>
    </>
  )
}