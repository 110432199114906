import React from "react";
import styled from "styled-components";
import QImage from '../../../../media/mobileImage/MainHome/QuestionSection/mobileQuestionCard.webp'
import QPatternImage from '../../../../media/mobileImage/MainHome/QuestionSection/mobilePatternQ.webp'
import { Link } from 'react-router-dom';

export const MobileQuestionSection = () => {
  return (
    <Component>
      <Link to={'consultaionRequest'}>

      <ImageButton/>
      </Link>
      <QImg src={QImage}/>
      
      <QPattern src={QPatternImage}/>
    </Component>
  )
}

const Component = styled.div`
  position: relative;
  height: 28.4vw; /* 348px */
  width: 100vw;
`;

const ImageButton = styled.div`
  width: 28.56vw; /* 350px */
  height: 4.08vw; /* 50px */
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  bottom: 7.84vw; /* 96px */
  left: 12.89vw; /* 158px */
  border-radius: 2.04vw; /* 25px */
`;

const QImg = styled.img`
  width: 100vw;
  height: 24.07vw; /* 295px */
  z-index: 1;
  position: relative;
`;

const QPattern = styled.img`
  z-index: 0;
  position: relative;
  top: -7.35vw; /* -90px */
  width: 28.56vw; /* 350px */
  height: 26.18vw; /* 321px */
  display: none;
`;
