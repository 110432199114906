import React from "react";
import styled from "styled-components";
import {MobileDreamCityFirstCard} from "../../atoms/DreamCity/MobileDreamCityFirstCard";
import {MobileDreamCitySecondCard} from "../../atoms/DreamCity/MobileDreamCitySecondCard";
import {MobileDreamCityThirdCard} from "../../atoms/DreamCity/MobileDreamCityThirdCard";

export const MobileDreamCityContents = () => {

  return (
    <Component>

      <Title>우리가 꿈꾸는 도시</Title>
      <SubTitle>모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!</SubTitle>

      <CardContents>
        <MobileDreamCityFirstCard/>

        <MobileDreamCitySecondCard/>

        <MobileDreamCityThirdCard/>
      </CardContents>


    </Component>
  )
}

const Component = styled.div`
  width: 100vw;
`

const Title = styled.div`
  font-size: 5.712vw;
  font-family: 'SCDream7', serif;
  text-align: center;
  margin-bottom: 2.528vw;
  margin-top: 7.662vw;
`

const SubTitle = styled.div`
  font-size: 2.364vw;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-bottom: 11.899vw;
`

const CardContents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
