import React from "react";
import styled from "styled-components";
import Background from "../../../media/images/MainHome/QuestionSection/back.webp"
import Question from "../../../media/images/MainHome/QuestionSection/q.webp"
import Arrow from "../../../media/images/MainHome/QuestionSection/arrow.webp"
import HandBanner from "../../../media/images/MainHome/QuestionSection/hand_banner.png"
import Hand from "../../../media/images/MainHome/QuestionSection/hand.webp"
import ResponsiveContainer from "../../../hooks/ResponsiveContainer";
import { Link } from 'react-router-dom';

export const QuestionSection = () => {

  return (
    <Container>

      {/* <BackgroundBox>
        <BackgroundImg src={Background}/>

        <LeftBox>
          <Link to={'consultaionRequest'}>
            <LeftButton>
              <ButtonText>지금 알아보기</ButtonText>
              <ButtonArrow src={Arrow}/>
            </LeftButton>
          </Link> 
        </LeftBox>
      </BackgroundBox> */}

      <div className="w100">
        <Link to={'consultaionRequest'}>
          <img src={HandBanner} alt="" className="block-box w100"/>
        </Link>
      </div>

      {/* <HandImg src={Hand}/> */}

    </Container>
  )
}

const Container = styled.div`
  width: 1434px;
  height: 296px;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 1920px) {
    width: 74.6875vw;
    height: 15.4167vw;
  }
`

const BackgroundBox = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  height: 246px;

  @media (max-width: 1920px) {
    height: 12.8125vw;
  }
`

const BackgroundImg = styled.img`
  width: 100%;
  height: 246px;

  @media (max-width: 1920px) {
    height: 12.8125vw;
  }
`

const LeftBox = styled.div`
  left: 160px;
  position: absolute;
  bottom: 35px;

  @media (max-width: 1920px) {
    left: 8.3333vw;
    bottom: 1.8229vw;
  }
`

const LeftButton = styled.div`
  border: 1px solid black;
  border-radius: 21.6px;
  height: 44px;
  width: 292px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1920px) {
    border: 0.0521vw solid black;
    border-radius: 1.125vw;
    height: 2.2917vw;
    width: 15.2083vw;
  }
`

const ButtonText = styled.text`
  font-family: 'SCDream4', serif;
  font-size: 21px;
  margin-right: 13px;


  @media (max-width: 1920px) {
    font-size: 1.0938vw;
    margin-right: 0.6771vw;
  }
`

const ButtonArrow = styled.img`
  width: 21px;
  padding-bottom: 5px;


  @media (max-width: 1920px) {
    width: 1.0938vw;
    padding-bottom: 0.2604vw;
  }
`

const HandImg = styled.img`
  position: absolute;
  right: 71px;
  bottom: 0;
  width: 426px;
  height: 262px;


  @media (max-width: 1920px) {
    right: 3.6979vw;
    bottom: 0;
    width: 22.1875vw;
    height: 13.6458vw;
  }
`