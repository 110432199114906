import React, {useState} from "react";
import styled from "styled-components";
import {MenData} from "../../../../configs/MainHome/MenData";
import {MobileMenCard} from "../../atoms/MainMenu/MobileMenCard";
import {useQuery} from "react-query";
import {main_live_choongju_list} from "../../../../api/MainHome";

import { Link, useNavigate } from 'react-router-dom'
import { info, queries } from '../../../pages/People/info'

export const MobileLiveChoongJu = () => {
  /* const { data: main_live_choongju_data, error, isLoading } = useQuery('main_live_choongju_list', main_live_choongju_list); */

  const infoData= {}
  Object.keys(info[1]).map(key=> {
    if( key != 'images' ) infoData[key]= info[1][key];
  })
  const { name, id, url: urls }= infoData;
  const url= `${urls}/detail/`;

  const [MenBoxTwo, setMenBoxTwo] = useState(false);
  const { data: main_live_choongju_data, error, isLoading } = useQuery(queries?.[id]?.key, queries?.[id]?.query);
  // const { data: main_live_choongju_data, error, isLoading } = useQuery('live_recent', live_recent);
  // console.log(main_live_choongju_data)

  const navigate = useNavigate();
  const handleContentClick = (data) => (e)=> {
    console.log(data, infoData)
    navigate(`${url}${data.id}`, { state: { data: data, allData: main_live_choongju_data.articles, selectedCategory: name, infoData  } });
  };

  console.log(main_live_choongju_data)
  
  return (
    <>
      <Container>

        <MainText>충주에 살다</MainText>
        <SubTextSection>
          <SubText>충주 시민의 목소리를 통해 매력적인 충주를 느끼보세요.</SubText>
          <Link to={'/people/long-life'}>
            <MoreText>목록보기</MoreText>
          </Link>
        </SubTextSection>

        <Contents>

          <MenCardBox>
            {main_live_choongju_data && main_live_choongju_data?.articles?.slice(0, 4).map((data) => (
              <MobileMenCard data={data} handleContentClick={handleContentClick}/>
            ))}
          </MenCardBox>
        </Contents>

      </Container>

      <LiveLine/>
    </>
  )
}

const Container = styled.div`
  width: 93.48vw;
`

const MainText = styled.div`
  font-size: 5.71vw; /* 70px */
  font-family: 'SCDream7', serif;
  margin-bottom: 1.96vw; /* 24px */
`;

const SubTextSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubText = styled.div`
  font-size: 3.22vw; /* 39.5px */
  font-family: 'SCDream4', serif;
  margin-bottom: 1.55vw; /* 19px */
  letter-spacing: -0.1935vw; /* This was computed from your previous value */
`;

const MoreText = styled.text`
  color: #939598;
  font-size: 3.22vw; /* 39.5px */
  text-decoration: underline;
  font-family: 'SCDream4', serif;
  cursor: pointer;
`;

const Contents = styled.div`
  margin-top: 6.77vw; /* 83px */
  display: flex;
  align-items: center;

`

const PeopleIamge = styled.img`
  margin-right: 5.38vw; /* 66px */
  width: 20.80vw; /* 255px */
  height: 106.02vw; /* 1299px */
`

const MenCardBox = styled.div`

`

const LiveLine = styled.div`
  border: 0.163vw solid black; /* 2px */
  width: 93.48vw;
  margin-bottom: 9.71vw; /* 119px */
`