import styled from "styled-components";

import { BoardTopImg } from '../../_Include/Title';
import TopIcon1 from "../../../../media/images/Culture/our-city/top-icon-1.svg";
import TopIcon2 from "../../../../media/images/Life/top-icon-1.svg";
import TopIcon3 from "../../../../media/images/Culture/our-city/top-icon-3.svg";
import TopIcon4 from "../../../../media/images/Culture/our-city/top-icon-4.svg";
import {
  dream_likeme_list,
  dream_likeus_list,
  dream_likeyou_list,
  dream_localcreator_list
} from "../../../../api/DreamCity";


const TopIcon1Style= styled.img`
  position: absolute;
  bottom: -6%; right: 52%;
  width: 90%;
`
const TopIcon2Style= styled.img`
  position: absolute;
  bottom: -13%; right: 30%;
  width: 90%;
`
const TopIcon3Style= styled.img`
  position: absolute;
  bottom: -2%; right: 19%;
  width: 90%;
`

// const title= '문화꿀단지';
const eventFilter= ['전체', '진행중', '진행마감']
const searchFilter= [
  { key: '전체', value: 'all' }, 
  { key: '제목', value: 'subject' }, 
  { key: '내용', value: 'content' }, 
]
export const info= [
  {
    id: 'our-city-1',
    key: 'likeme',
    name: '우리가 선보이는 도시',
    // query: community_culture,
    title: '우리가 선보이는 도시',
    path: '충주(Culture)',
    subject: '모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!',
    url: '/culture/our-city-1',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<BoardTopImg icon={<TopIcon1Style src={TopIcon1} alt="" />}/>]
  },
  {
    id: 'our-city-2',
    key: 'likeyou',
    name: '우리가 함께하는 도시',
    // query: community_business,
    title: '우리가 함께하는 도시',
    path: '충주(Culture)',
    subject: '모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!',
    url: '/culture/our-city-2',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<BoardTopImg icon={<TopIcon2Style src={TopIcon2} alt="" />}/>]

  },
  {
    id: 'our-city-3',
    key: 'likeus',
    name: '우리가 생산하는 도시',
    // query: community_business,
    title: '우리가 생산하는 도시',
    path: '충주(Culture)',
    subject: '모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!',
    url: '/culture/our-city-3',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<BoardTopImg icon={<TopIcon3Style src={TopIcon3} alt="" />}/>]

  },
  {
    id: 'our-city-4',
    key: 'localcreator',
    name: '우리가 성장하는 도시',
    // query: community_business,
    title: '우리가 성장하는 도시',
    path: '충주(Culture)',
    subject: '모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!',
    url: '/culture/our-city-4',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<BoardTopImg icon={<TopIcon3Style src={TopIcon4} alt="" />}/>]

  },
];

export const queries= {
  ['our-city-1']: {
    key: 'dream_likeme_list',
    query: dream_likeme_list
  },
  ['our-city-2']: {
    key: 'dream_likeyou_list',
    query: dream_likeyou_list
  },
  ['our-city-3']: {
    key: 'dream_likeus_list',
    query: dream_likeus_list
  },
  ['our-city-4']: {
    key: 'dream_localcreator_list',
    query: dream_localcreator_list
  },
}