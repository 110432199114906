import {TitleSection} from '../_Include/Title';
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../style/comp/comm';
import {Link} from "react-router-dom";
import {TopSearch} from "../../blocks/TotalSearch/TopSearch";
import {useParams} from "react-router-dom";
// import { info, queries } from './info';
// console.log(info)



export default function TotalSearch ({ info }) {

  const params = useParams();
  const { id }= params;
  // const data= info.find(o=> o.id == params.id);
  // const data= info[id];
  // const { title, name, subject }= data;
 
  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10 zIdx2">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 통합검색`} 
              title={`통합검색`} 
              subtitle={''} 
            />
          </div>
        </div>
        <TopSearch info={info}/>
      </Component>
      
    </div>
  )

}