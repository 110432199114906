import React, {useEffect, useState} from "react";
import styled from "styled-components";
import WomenImage from '../../../media/images/TotalSearch/totalSearchWomen.webp'
// import PatternImage from '../../../media/images/TotalSearch/totalSearchPattern.webp'
import PatternImage from '../../../media/images/Comm/top-icon-4.svg'
import NoDataIcon from "../../../media/images/Comm/no-data.svg";

import {Component, BodyText3, BodyText1, TinyText}from '../../../style/comp/comm';
import {total_search_result, total_search_detail} from "../../../api/TotalSearch";
import {useQuery} from "react-query";
import qs from 'qs';
import {DetailCard} from "../_bbs/DetailCard";
import {useNavigate, useSearchParams} from "react-router-dom";

import {InputSection, AutoComplete} from './TotalSearchInput';
import { UltimatePagination } from '../../blocks/_bbs/Pagination'

const CategoryList= ({ index, id, name, selectedCategory, count, info, handleCategoryChange, line })=> {
  return (
    <div key={index} className="list-item p-y3 m-w50">
      <div 
        className="m-x5 cursor-point flx-0"
        onClick={(e) => handleCategoryChange(id)}
        style={{color: selectedCategory === id ? '#5b9dc9' : 'initial'}}
      >
        <span className="ft-b">{name}({count || 0})</span>
        { line && <div className="h-line m-hidden m-l10">|</div> }
      </div>
      
    </div>
  )
}


const perviewSize= 4;
const viewPerPage= 8;
export const TopSearch = ({ info }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const keywordParam = searchParams.get('keyword');
  const hashtagParam = searchParams.get('hashtag');
  const categoryParam = searchParams.get('category');
  const pageParam = searchParams.get('page');
  // console.log(keywordParam)
  const [keyword, setKeyword] = useState(keywordParam);
  const [hashtag, setHashtag] = useState(hashtagParam);
  const [submitKeyword, setSubmitKeyword]= useState()
  const { data, error, isLoading } = useQuery(['total_search_result', {
    search: submitKeyword ? qs.stringify({search_text: submitKeyword}) : null,
    hashtag: hashtagParam ? qs.stringify({search_hashtags: hashtagParam}) : null
  }], total_search_result);
  const [filterData, setFilterData]= useState();
  const [selectedCategory, setSelectedCategory] = useState(categoryParam);


  const [page, setPage]= useState(Number(pageParam));
  const { data: detailData, error: detailErr, isLoading: detailLoading } = useQuery(['total_search_detail', {
    search: submitKeyword ? qs.stringify({search_text: submitKeyword}) : null,
    hashtag: hashtagParam ? qs.stringify({search_hashtags: hashtagParam}) : null,
    boardId: qs.stringify({board_id: selectedCategory}),
    currentPage: qs.stringify({current_page: page}),
  }], total_search_detail, {
    // enabled: false
  });


  useEffect(()=> {
    const filters= {
      total_count: 0,
      search_boards_count: [],
      search_boards_result: [],
    }
    // console.log(info, data)
    if( data ) {
      const countFilter= data['search_boards_count'].filter(d => {
        const obj= info.find(o=> o.key == d.id)
        if( obj ) filters.total_count += d.board_count;
        return obj ? true : false;
      });
      filters.search_boards_count= countFilter;
      
      const resultFilter= data['search_boards_result'].filter(d => {
        const obj= info.find(o=> o.key == d.id)
        return obj ? true : false;
      });
      filters.search_boards_result= resultFilter;
      console.log(filters);
      setFilterData(filters)
    }
    setAutoCompleteOpen(false)
  }, [data]);

  useEffect(()=> {
    console.log(keywordParam, hashtagParam)
    setKeyword(keywordParam)
    setHashtag(hashtagParam)
    handleSubmit();
    if(!keywordParam && !hashtagParam) {
      setFilterData(null)
    }
  },[keywordParam, hashtagParam])

  /* useEffect(()=> {
    setKeyword(keywordParam)
    handleSubmit();
  }, [keywordParam]) */

  const handleSearch = () => {
    console.log(keyword)
    const len= keyword?.length || 0;
    if( len > 0 && len < 2 ) {
      alert('2글자 이상 입력해 주세요.')
      return;
    } 
    setSearchParams(qs.stringify({
      keyword
    }))
    // navigate(`?keyword=${keyword}`)
    // setSearchParams(`?keyword=${keyword}`)
    // handleSubmit()
  }
  const handleSubmit= ()=> {
    setSubmitKeyword(keywordParam);
    setSelectedCategory(categoryParam || 'all')
    setAutoCompleteOpen(false);
  }
  const [viewType, setViewType]= useState(perviewSize);
  const handleCategoryChange = (category) => {
    console.log(hashtagParam, hashtag)
    setSelectedCategory(category);
    setPage(1)

    /* console.log(
      qs.stringify({
        keyword, category, page: 1, hashtag
      })
    ) */
    setSearchParams(qs.stringify({
      keyword, category, page: 1, hashtag
    }))
  }

  const handlerInputChange= (e)=> {
    const { value }= e.target;
    // console.log(value.indexOf('#'), value)
    // value.indexOf('#') != -1 ? setKeyword(value) : setHashtag(value)
    setKeyword(value);
    setAutoKeyword(value)
  }

  const [autoKeyword, setAutoKeyword]= useState('')
  const [autoCompleteOpen, setAutoCompleteOpen]= useState(false)

  const handlePage= (page)=> {
    setPage(page);
    setSearchParams({
      keyword, category: selectedCategory, page
    })
  }
  console.log(!hashtag)
  return (
    <Component>
      <div className="w100 m-t20 m-b20">

        <div className="w100 mx700 mg-ct p-x10">

          <div className="inst-box zIdx3">
            <IconSectStyle_1 className="m-x10">
              <IconWrapStyle>
                <Icon1Style src={PatternImage} alt="" />
                <Icon2Style src={WomenImage} alt="" />
              </IconWrapStyle>
            </IconSectStyle_1>
            <div className="flx-cl">
              <div className="w100 inst-box">
                {
                  hashtag && 
                  <div className="">
                    <div className="min120 iblock-box bg-clr-bk flx-cc iflx p-x7 p-y4 bd-rd-20">
                      <span className="ft-wh m-r5">#{hashtagParam}</span>
                      <div onClick={e=>setHashtag('')} className="cursor-point flx-0">
                        <svg fill="none" width="18" height="18" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg" id="fi_4347434"><g fill="rgb(255,255,255)"><path d="m255.575 476.292c-28.978.054-57.68-5.62-84.458-16.694s-51.103-27.331-71.5785-47.836c-86.0513-86.051-86.0513-226.057 0-312.108 20.4445-20.5595 44.7645-36.8599 71.5515-47.9576 26.786-11.0978 55.508-16.7725 84.503-16.6956 58.95 0 114.37 22.9517 156.036 64.6532 41.684 41.684 64.653 97.103 64.653 156.054s-22.952 114.37-64.653 156.054c-20.479 20.505-44.808 36.762-71.588 47.836-26.781 11.074-55.486 16.747-84.466 16.694zm.018-405.9809c-24.357-.0691-48.485 4.6953-70.987 14.0174s-42.931 23.0165-60.103 40.2895c-35.0103 35.011-54.2898 81.567-54.2898 131.09s19.2795 96.062 54.2898 131.09c72.28 72.28 189.899 72.298 262.162 0 35.01-35.01 54.307-81.567 54.307-131.09s-19.28-96.062-54.307-131.09c-17.173-17.268-37.599-30.9588-60.097-40.2806-22.499-9.3218-46.622-14.0892-70.975-14.0263z"></path><path d="m180.677 348.25c-3.495.008-6.914-1.023-9.822-2.961-2.908-1.939-5.175-4.698-6.512-7.927-1.338-3.229-1.685-6.783-1-10.21.686-3.427 2.375-6.573 4.852-9.039l149.804-149.804c1.639-1.639 3.585-2.939 5.727-3.827 2.141-.887 4.437-1.343 6.755-1.343s4.614.456 6.755 1.343c2.142.888 4.088 2.188 5.727 3.827s2.94 3.585 3.827 5.727 1.344 4.437 1.344 6.755-.457 4.614-1.344 6.756c-.887 2.141-2.188 4.087-3.827 5.726l-149.804 149.805c-1.635 1.645-3.58 2.949-5.723 3.837-2.142.888-4.44 1.342-6.759 1.335z"></path><path d="m330.491 348.25c-2.319.003-4.615-.453-6.757-1.341-2.143-.887-4.088-2.19-5.725-3.831l-149.805-149.805c-1.639-1.639-2.939-3.585-3.826-5.726-.887-2.142-1.344-4.438-1.344-6.756s.457-4.613 1.344-6.755 2.187-4.088 3.826-5.727c1.64-1.639 3.586-2.939 5.727-3.827 2.142-.887 4.438-1.343 6.756-1.343s4.613.456 6.755 1.343c2.142.888 4.088 2.188 5.727 3.827l149.804 149.804c2.477 2.466 4.166 5.612 4.851 9.039.686 3.427.338 6.981-.999 10.21-1.338 3.229-3.604 5.988-6.512 7.927-2.909 1.938-6.327 2.969-9.822 2.961z"></path></g></svg>
                      </div>
                    </div>
                    
                  </div>
                }
                {
                  !hashtag && 
                  <InputSection
                    keyword={keyword} 
                    // hashtag={hashtag}
                    handlerInputChange={handlerInputChange} 
                    handleSearch={handleSearch}
                  />
                }
                
                {
                  keyword && 
                  <AutoComplete 
                    keyword={autoKeyword} open={autoCompleteOpen} setOpen={setAutoCompleteOpen}/>
                }
              </div>
            </div>
          </div>

          <CategoryStyle className="flx-w m-t10">
            <CategoryList 
              index={'all'}
              name={'전체'}
              id={'all'}
              selectedCategory={selectedCategory}
              count={filterData?.total_count || 0}
              info={info}
              handleCategoryChange={handleCategoryChange}
              line={true}
            />
            
            {info.map((val, index) => {
              const {name, key: id}= val;
              const countData= getCount(id, filterData, 'search_boards_count')
              const line= index < info.length-1;
              if( !countData?.board_count ) return <React.Fragment key={id}></React.Fragment>
              return (
                <CategoryList 
                  key={id}
                  index={index}
                  name={name}
                  id={id}
                  selectedCategory={selectedCategory}
                  count={countData?.board_count || 0}
                  info={info}
                  handleCategoryChange={handleCategoryChange}
                  line={line}
                />
            )})}
          </CategoryStyle>

        </div>
        
        

        <div className="inst-box m-t30 m-mg-t10 w100 mg-ct p-x10">

          <IconSectStyle className="m-x10">
            <IconWrapStyle>
              <Icon1Style src={PatternImage} alt="" />
              <Icon2Style src={WomenImage} alt="" />
            </IconWrapStyle>
          </IconSectStyle>

          <ResultStyle className="flx-col w100 mg-ct">
            {
              ( isLoading || detailLoading ) && 
              <div className="w100 bd-tp-bk m-t10">
                <div className="flx-cc w100 p-y40">
                  {/* <img src={NoDataIcon} alt="" className="icon-img-m" /> */}
                  <BodyText1>...Loading</BodyText1>
                </div>
                
              </div>
            }
            
            {
              (!isLoading && !detailLoading) && 
              <>
              {
              (!filterData || filterData?.search_boards_result?.length == 0) && 
              <div className="w100 bd-tp-bk m-t10">
                <div className="flx-cc w100 p-y40">
                  <img src={NoDataIcon} alt="" className="icon-img-m" />
                  <BodyText1>검색결과가 없습니다.</BodyText1>
                </div>
                
              </div>
              }
              
              {
                info.map((infoData) => {
                  const { name, key: id, url }= infoData;
                  const countData= getCount(id, filterData, 'search_boards_count')
                  const resultData= getCount(id, filterData, 'search_boards_result')
                  const param={};
                  Object.keys(infoData).map(key=> {
                    if( key != 'images' ) param[key]= infoData[key]
                  })

                  if( !countData?.board_count ) return <React.Fragment key={id}></React.Fragment>

                  if( selectedCategory != 'all' && selectedCategory != id ) return <React.Fragment key={id}></React.Fragment>

                  // console.log(perview, resultData, detailData)
                  const currentData= selectedCategory != 'all' ? detailData : resultData;
                  const viewResultData= selectedCategory != 'all' ? currentData?.[0]?.articles : currentData?.articles.slice(0, perviewSize);
                  console.log(currentData, viewResultData)
                  const totalPage= countData?.board_count ? Math.ceil(countData?.board_count/viewPerPage) : 0;
                  return (
                    <div key={id} className="m-y10 ">
                      <div className="flx-sbc bd-tp-bk bd-bt-bk p-y7">
                        <div className="flx-cl">
                          <BodyText3 className="ft-b">{name}</BodyText3>
                          <BodyText3 className="ft-b ft-c1">({countData?.board_count || 0})</BodyText3>
                        </div>

                        <div className="">
                          {
                            selectedCategory == 'all' && 
                            <p 
                              onClick={() => {
                                handleCategoryChange(id)
                                window.scrollTo(0, 0)
                              }}
                              className="cursor-point ft-c1-hover"
                            >
                              <TinyText className="txt-underline">더보기</TinyText>
                            </p>
                          }
                        </div>
                      </div>
                      
                      {
                        viewResultData && 
                        <div className="flx-col-cc m-t10">
                          <div className="grid-10 flx-w">
                            {
                              viewResultData.map( d=> {
                                const { id }= d;
                                return (
                                  <div key={id} className="w25-g10 m-w50-g10 mg-s10 m-y10 flx-0">
                                    <DetailCard 
                                      data={d} 
                                      url={`${url}/detail/`} 
                                      id={id} 
                                      infoData={param}
                                      type='total-search'
                                    />
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      }

                      {
                        selectedCategory != 'all' && 
                        <div className="p-y20">
                          <UltimatePagination
                            totalPages={totalPage}
                            boundaryPagesRange={1}
                            siblingPagesRange={2}
                            currentPage={page}
                            onChange={handlePage}
                          />
                        </div>
                      }
                      

                    </div>
                  )
                })
              }
              </>
            }
          </ResultStyle>
        </div>


      </div>
    </Component>
  )
}


function getCount (id, data, key) {
  const countData= data?.[key]?.find(v=> v.id == id);
  return countData;
}
const ResultStyle = styled.ul`
  min-height: 400px;
  @media (max-width: 800px) {
    /* margin-top: 80px; */
  }
`

const IconSectStyle = styled.div`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  max-width: 160px;
  transform: translateY(-100%);
  @media (max-width: 800px) {
    max-width: 80px;
    display: none;
  }
`
const IconSectStyle_1 = styled(IconSectStyle)`
  top: -10px;
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`
const IconWrapStyle = styled.div`
  position: relative;
  margin-bottom: -1em;
  overflow: hidden;
  padding-top: 140%;
`
const Icon1Style = styled.img`
  width: 100%;
  position: absolute;
  top: 25%; left: 0%;
`
const Icon2Style = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0px; left: 0px;
`

const CategoryStyle = styled.div`
  position: relative;
  /* background-color: #f5f5f1; */
  z-index: 2;
  :last-child.list-item .h-line {
    display: none;
  }
`


