import React from "react";
import styled from "styled-components";
import {ShareHeader} from "../../blocks/layouts/ShareHeader";
import {ShareFooter} from "../../blocks/layouts/ShareFooter";
import {ShareCategory} from "../../blocks/layouts/ShareCategory";
import {useRecoilValue} from "recoil";
import {MobileShowCategory, MobileShowSearchResult, ShowCategory, ShowSearchResult} from "../../../recoil/LayoutRecoil";
import {ShareSearch} from "../../blocks/layouts/ShareSearch";
import {useMediaQuery} from "react-responsive";
import {MobileShareHeader} from "../../mobile/blocks/layouts/MobileShareHeader";
import {MobileShareFooter} from "../../mobile/blocks/layouts/MobileShareFooter";
import {MobileShareCategory} from "../../mobile/blocks/layouts/MobileShareCategory";
import {MobileShareSearch} from "../../mobile/blocks/layouts/MobileShareSearch";
import {CultureMapContents} from "../../blocks/CultureMap/CultureMapContents";
import {MobileCultureMapContents} from "../../mobile/blocks/CultureMap/MobileCultureMapContents";



export default function CultureMap () {
  const ShowCategoryState = useRecoilValue(ShowCategory)
  const MobileShowCategoryState = useRecoilValue(MobileShowCategory)
  const ShowSearchResultState = useRecoilValue(ShowSearchResult)
  const MobileShowSearchResultState = useRecoilValue(MobileShowSearchResult)
  const isMobile = useMediaQuery({query: '(max-width: 1226px)'});

  return (
    <Container>

      {isMobile ? <MobileCultureMapContents/> : <CultureMapContents/>}


    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #f5f5f1;
`