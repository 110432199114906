import React, {useEffect, useState} from "react";
import styled from "styled-components";
import ResponsiveContainer from "../../../hooks/ResponsiveContainer";
import CardPicture from '../../../media/images/MainHome/CommunityBusiness/CardImage.webp'
import PatterImage from '../../../media/images/MainHome/CommunityBusiness/pattern3.webp'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation'; // Navigation CSS

// import required modules
import {Autoplay, Navigation} from 'swiper/modules';
import {CommunityData} from "../../../configs/MainHome/CommunityData";
import PrevImage from "../../../media/images/MainHome/TopSlide/prev.webp";
import NextImage from "../../../media/images/MainHome/TopSlide/next.webp";
import {useQuery} from "react-query";
// import {main_communities_list} from "../../../api/MainHome";
import {PUBLISH_URL} from "../../../api/global";
import { Link, useNavigate } from 'react-router-dom'
import { info, queries } from '../../pages/Life/community/info'


export const CommunityBiz = () => {
  // const { data: main_communities_list_data, error, isLoading } = useQuery('main_communities_list', main_communities_list);
  // const infos= info[0];
  // delete infos.images;
  const infoData= {}
  Object.keys(info[0]).map(key=> {
    if( key != 'images' ) infoData[key]= info[0][key];
  })
  // const infoData= infos;
  // console.log(infoData)
  const { name, id, url: urls }= infoData;
  const url= `${urls}/detail/`;
  


  const { data: main_communities_list_data, error, isLoading } = useQuery(queries?.[id]?.key, queries?.[id]?.query);
  const navigate = useNavigate();
  const handleContentClick = (data) => (e)=> {
    console.log(data, infoData)
    navigate(`${url}${data.id}`, { state: { data: data, allData: main_communities_list_data.articles, selectedCategory: name, infoData  } });
  };

  // console.log(main_communities_list_data)
  return (
    <ResponsiveContainer>
      <Component>
        <PatternImage src={PatterImage}/>

        {main_communities_list_data && main_communities_list_data.articles.length > 4 &&
          <NavigationBox>
            <PrevArrowButton className="prev" src={PrevImage}/>
            <NextArrowButton className="next" src={NextImage}/>
          </NavigationBox>
        }

        <MainText>커뮤니티 사업</MainText>
        <SubText>충주의 다양한 문화사업 현황을 확인해보세요.</SubText>
        <Link to={'/life/community/honey'}>
          <MoreText>목록보기</MoreText>
        </Link>

        {/* 카드 Carosual */}
        <Contents>
          <Swiper
            modules={[Autoplay, Navigation]}
            spaceBetween={24}
            slidesPerView={4}
            loop={true}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: false,
            // }}
            navigation={{
              nextEl: '.next',
              prevEl: '.prev',
            }}
          >
            {main_communities_list_data && main_communities_list_data.articles.map((item) => {
              return (
              <SwiperSlide 
                key={item.id} 
                onClick={handleContentClick(item)}
                style={{ cursor: 'pointer' }}
              >
                <ImageCard>
                  <MainImage src={PUBLISH_URL + item.thumbnail}/>

                  <CardTextSection>
                    <CardTitle>{item.subject}</CardTitle>
                    <CardLine/>
                    <CardHashTag>
                      {item.tags.map((tag) => 
                        <Link 
                          onClick={e=> e.stopPropagation()}
                          to={`/totalSearch?hashtag=${tag}`} className="m-x5 ft-c1-hover"
                        >{`#${tag}`}</Link>
                      )}
                    </CardHashTag>
                  </CardTextSection>
                </ImageCard>
              </SwiperSlide>
              )
            })}
          </Swiper>
        </Contents>

      </Component>

    </ResponsiveContainer>
  )
}

const Component = styled.div`
  width: 1434px;
  position: relative;
  margin-bottom: 96px;

  @media (max-width: 1920px) {
    width: 74.6875vw;
    margin-bottom: 5vw;
  }
`
const PatternImage = styled.img`
  position: absolute;
  width: 315px;
  height: 267px;
  right: -42px;
  top: 82px;

  @media (max-width: 1920px) {
    width: 16.4063vw;
    height: 13.9063vw;
    right: -2.1875vw;
    top: 4.2708vw;
  }
`

const MainText = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  margin-bottom: 19px;

  @media (max-width: 1920px) {
    font-size: 2.0833vw;
    margin-bottom: 0.9896vw;
  }
`

const SubText = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  margin-bottom: 13px;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
    margin-bottom: 0.6771vw;
  }
`

const MoreText = styled.text`
  color: #939598;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
  }
`

const Contents = styled.div`
  margin-top: 28px;

  @media (max-width: 1920px) {
    margin-top: 1.4583vw;
  }
`

const ImageCard = styled.div`
  width: 341px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1920px) {
    width: 17.7604vw;
  }
`

const MainImage = styled.img`
  width: 341px;
  height: 341px;

  @media (max-width: 1920px) {
    width: 17.7604vw;
    height: 17.7604vw;
  }
`

const CardTextSection = styled.div`
  margin-top: 26px;

  @media (max-width: 1920px) {
    margin-top: 1.3542vw;
  }
`

const CardTitle = styled.div`
  font-family: 'SCDream3', serif;
  font-size: 18px;
  width: 283px;
  margin-bottom: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    width: 14.7396vw;
    margin-bottom: 1.25vw;
  }
`

const CardLine = styled.div`
  border-bottom: 2px black solid;
  margin-bottom: 15px;
  width: 318px;

  @media (max-width: 1920px) {
    margin-bottom: 0.7813vw;
    width: 16.5625vw;
  }
`

const CardHashTag = styled.div`
  width: 286px;
  font-family: 'SCDream2', serif;
  font-size: 14.5px;

  @media (max-width: 1920px) {
    width: 14.8958vw;
    font-size: 0.7552vw;
  }
`

const NavigationBox = styled.div`
  position: absolute;
  right: 10px;
  top: 120px;

  @media (max-width: 1920px) {
    right: 0.5208vw;
    top: 6.25vw;
  }
`

const PrevArrowButton = styled.img`
  width: 18px;
  height: 21px;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 0.9375vw;
    height: 1.0938vw;
    margin-right: 1.0417vw;
  }
`

const NextArrowButton = styled.img`
  width: 18px;
  height: 21px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 0.9375vw;
    height: 1.0938vw;
  }
`