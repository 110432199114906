import {atom} from "recoil";

import { info as communityInfo } from "../components/pages/Life/community/info";
import { info as cultureInfo } from "../components/pages/Culture/our-city/info";
import { info as peopleInfo } from "../components/pages/People/info";
import { info as noticeInfo } from "../components/pages/Notice/info";

export const ShowCategory = atom({
  key: 'showCategory',
  default: false
})

export const ShowSearchResult = atom({
  key: 'showSearchResult',
  default: false
})

export const MobileShowCategory = atom({
  key: 'mobileShowCategory',
  default: false
})

export const MobileShowSearchResult = atom({
  key: 'mobileShowSearchResult',
  default: false
})

export const DreamCityDetailCategory = atom({
  key: 'dreamCityDetailCategory',
  default: '우리가 선보이는 도시'
})

export const TotalSearchResultAtom = atom({
  key: 'totalSearchResultAtom',
  default: ''
})




export const totalSearchInfoState = atom({
  key: 'totalSearchInfoState',
  default: [...communityInfo, ...peopleInfo, 
    ...cultureInfo, 
    // ...noticeInfo,
    
  ]
})
