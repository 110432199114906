import React from "react";
import styled from "styled-components";
import CategoryIcon from '../../../media/images/layouts/CategoryArrow.webp'
import {useSetRecoilState} from "recoil";
import {DreamCityDetailCategory, ShowCategory} from "../../../recoil/LayoutRecoil";
import {Link} from "react-router-dom";

export const ShareCategory = () => {
  const setShowCategory = useSetRecoilState(ShowCategory)
  const setDreamCityDetailCategory = useSetRecoilState(DreamCityDetailCategory)
  const userAgent = window.navigator.userAgent.toLowerCase();


  return (
    <Componenat onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(false)}
                Medge={userAgent.includes('windows')}>

      {/* 충주 Local */}
      <LocalSection>


        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/local/about">
            <MainText>충주에 대해서</MainText>
          </Link>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/local/intro">
            <MainText>지역소개</MainText>
          </Link>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/cultureMap">
            <MainText>문화지도</MainText>
          </Link>
        </TextSection>
      </LocalSection>


      {/* 살면 Life */}
      <LifeSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/life/choongjuro'}>
            <MainText>충주로</MainText>
          </Link>
        </TextSection>
        {/* <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <MainText>취업(귀농)</MainText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <MainText>취업(귀촌)</MainText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <MainText>재택근무</MainText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <MainText>로컬워크스테이</MainText>
        </TextSection> */}
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/life/community/honey'}>
            <MainText>커뮤니티 사업</MainText>
          </Link>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/life/community/honey'}>
            <SubText>- 문화꿀단지</SubText>
          </Link>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/life/community/out'}>
            <SubText>- 집밖문지방</SubText>
          </Link>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/life/events'}>
            <MainText>이벤트</MainText>
          </Link>
        </TextSection>
      </LifeSection>


      {/* 충주 Culture */}
      <CultureSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/culture/city'}>
            <MainText>문화도시 충주</MainText>
          </Link>
        </TextSection>
        {/* <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <MainText>함께하는 충주</MainText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <SubText>- 추진위</SubText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <SubText>- 문화다양성</SubText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <SubText>- 인문사회</SubText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <SubText>- 청년문화기획단</SubText>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/dreamCity">
            <MainText>우리가 꿈꾸는 도시</MainText>
          </Link>
        </TextSection> */}


        <TextSection onClick={() => {
          setDreamCityDetailCategory('우리가 선보이는 도시')
          setShowCategory(false)
        }}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/culture/our-city-1">
            <MainText>우리가 선보이는 도시</MainText>
          </Link>
        </TextSection>

        <TextSection onClick={() => {
          setDreamCityDetailCategory('우리가 함께하는 도시')
          setShowCategory(false)
        }}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/culture/our-city-2">
            <MainText>우리가 함께하는 도시</MainText>
          </Link>
        </TextSection>

        <TextSection onClick={() => {
          setDreamCityDetailCategory('우리가 생산하는 도시')
          setShowCategory(false)
        }}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/culture/our-city-3">
            <MainText>우리가 생산하는 도시</MainText>
          </Link>
        </TextSection>

        <TextSection onClick={() => {
          setDreamCityDetailCategory('우리가 성장하는 도시')
          setShowCategory(false)
        }}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/culture/our-city-4">
            <MainText>우리가 성장하는 도시</MainText>
          </Link>
        </TextSection>

        {/* <TextSection onClick={() => {
          setDreamCityDetailCategory('나답게')
          setShowCategory(false)
        }}>
          <TextIcon src={CategoryIcon}/>
          <Link to="/dreamCityDetail">
            <SubText>- 나답게</SubText>
          </Link>
        </TextSection>
        
        <TextSection onClick={() => {
          setDreamCityDetailCategory('너답게')
          setShowCategory(false)
        }
        }>
          <TextIcon src={CategoryIcon}/>
          <Link to="/dreamCityDetail">
            <SubText>- 너답게</SubText>
          </Link>
        </TextSection>
        <TextSection onClick={() => {
          setDreamCityDetailCategory('우리답게')
          setShowCategory(false)
        }
        }>
          <TextIcon src={CategoryIcon}/>
          <Link to="/dreamCityDetail">
            <SubText>- 우리답게</SubText>
          </Link>
        </TextSection>
        <TextSection onClick={() => {
          setDreamCityDetailCategory('충격의 로컬크리에이터')
          setShowCategory(false)
        }
        }>
          <TextIcon src={CategoryIcon}/>
          <Link to="/dreamCityDetail">
            <SubText>- 충격의 로컬크리에이터</SubText>
          </Link>
        </TextSection> */}
      </CultureSection>


      {/* 사람 People */}
      <PeopleSection>
        
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/people/long-life'}>
            <MainText>충주에 살아보면</MainText>
          </Link>
        </TextSection>

        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/people/long-life'}>
            <SubText>- 오래 살아보면</SubText>
          </Link>
        </TextSection>
        <TextSection onClick={() => setShowCategory(false)}>
          <TextIcon src={CategoryIcon}/>
          <Link to={'/people/recently-life'}>
            <SubText>- 최근 살아보면</SubText>
          </Link>
        </TextSection>
      </PeopleSection>

    </Componenat>
  )
}


const Componenat = styled.div`
  width: 1047px;
  height: 518px;
  background-color: white;
  z-index: 9999;
  position: absolute;
  top: 122px;
  overflow: hidden;
  display: flex;
  margin-left: 53px;

  @media (max-width: 1920px) {
    width: 54.5365vw;
    height: 26.9792vw;
    top: 6.3542vw;
    margin-left: 2.76vw;

  }
`

const LocalSection = styled.div`
  margin-top: 29px;
  margin-left: 30px;

  @media (max-width: 1920px) {
    margin-top: 1.5104vw;
    margin-left: 1.5625vw;
  }
`

const LifeSection = styled.div`
  margin-top: 29px;
  margin-left: 55px;

  @media (max-width: 1920px) {
    margin-top: 1.5104vw;
    margin-left: 2.86458vw;
  }
`

const CultureSection = styled.div`
  margin-top: 29px;
  margin-left: 227px;

  @media (max-width: 1920px) {
    margin-top: 1.5104vw;
    margin-left: 11.8229vw;
  }
`

const PeopleSection = styled.div`
  margin-top: 29px;
  margin-left: 27px;

  @media (max-width: 1920px) {
    margin-top: 1.5104vw;
    margin-left: 1.40625vw;
  }
`

const MainText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 19px;
  margin-left: 10px;
  transition: color 0.3s ease;


  @media (max-width: 1920px) {
    font-size: 0.9896vw;
    margin-left: 0.5208vw;
  }
`

const SubText = styled.div`
  font-size: 14.5px;
  font-family: 'SCDream3', serif;
  margin-left: 10px;

  @media (max-width: 1920px) {
    font-size: 0.7552vw;
    margin-left: 0.5208vw;
  }
`

const TextIcon = styled.img`
  width: 21px;
  height: 8px;
  margin-bottom: 7px;
  opacity: 0;
  transition: opacity 0.3s ease;

  @media (max-width: 1920px) {
    width: 1.0938vw;
    height: 0.4167vw;
    margin-bottom: 0.3646vw;
  }
`

const TextSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;

  @media (max-width: 1920px) {
    margin-bottom: 1.3021vw;
  }


  &:hover ${MainText} {
    color: #5b9dc9;
  }

  &:hover ${SubText} {
    color: #5b9dc9;
  }

  &:hover ${TextIcon} {
    opacity: 1;
  }
`