import React, { Fragment, useState, useRef, useMemo, useEffect } from 'react'
import { format, parse, startOfWeek, getDay, getMonth, now, toDate, parseISO } from "date-fns";
import PropTypes from 'prop-types';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, dateFnsLocalizer, DateLocalizer } from 'react-big-calendar'
import styled from "styled-components";
import NextArrow from '../../../../media/images/Comm/pageNextIcon.webp'
import PrevArrow from '../../../../media/images/Comm/pagePrevIcon.webp'

import {useQuery} from "react-query";
import { Link, useSearchParams } from 'react-router-dom';
import {events_list} from '../../../../api/Community'

import events from './events'
import {ko} from "date-fns/locale";
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../../style/comp/comm';
import EventList from './EventList';
// import Layout from 'react-tackle-box/Layout'

// require('globalize/lib/cultures/globalize.culture.en-GB')
// require('globalize/lib/cultures/globalize.culture.es')
// require('globalize/lib/cultures/globalize.culture.fr')
// require('globalize/lib/cultures/globalize.culture.ar-AE')
// require('globalize/lib/cultures/globalize.culture.ko')

// const cultures = ['en', 'en-GB', 'es', 'fr', 'ar-AE', 'ko']

console.log(format)
const lang = {
  en: null,
  'en-GB': null,
  ko: {
    week: '주',
    work_week: 'Semana de trabajo',
    day: '일',
    month: '월',
    previous: '이전달',
    next: '다음달',
    today: '오늘',
    agenda: '주제',

    showMore: (total) => `+${total} 더보기`,
  },
}

const locales = {
	"ko-KR": ko
};
const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales
});

export default function CalendarEvent ({ id, info, infoData, queries }) {
  const { key, url, images, filter_1, filter_2, name }= infoData;
  const query= queries?.[id]?.query;
  const [culture, setCulture] = useState('ko');
  const [searchParams, setSearchParams] = useSearchParams();
  const monthParam= searchParams.get('month');
  const categoryParam= searchParams.get('category');
  const params= {
    month: monthParam || format(new Date(), 'yyyy-MM-dd'),
    category: categoryParam || 'all',
  }
  const today= new Date(params.month)

  const { data, error, isLoading, refetch } = useQuery([key, 
    // searchParams
  ], 
  query,);

  

  const [eventData, setEventData]= useState([]);
  const [viewData, setViewData]= useState([]);
  /* const margeEvents= (data)=> {
    console.log(data)
    const marge= {}
    data.map(d=> {
      const { resource: { category }}= d;
      if( marge?.[category] ) marge[category] +=1;
      else marge[category]= 1;
    })
    console.log(marge)
  } */

  useMemo(()=> {
    console.log(data)
    const view= []
    const eventDatas= data?.articles?.map( d=> {
      const { id, subject: title, start_date, end_date, category }= d;
      if( (!categoryParam || categoryParam == 'all') || (categoryParam && categoryParam == category) ) view.push(
        {
          id,
          title: `[${category}] ${title}`,
          start: toDate(start_date),
          end: toDate(end_date),
          resource: d
        }
      )
      return {
        id,
        title: `[${category}] ${title}`,
        start: toDate(start_date),
        end: toDate(end_date),
        resource: d
      }
    })
    setEventData(eventDatas)
    setViewData(view)
    // margeEvents(eventDatas)
  }, [data, categoryParam])

  
  // const [rightToLeft, setRightToLeft] = useState(false)

  /* const cultureOnClick = useCallback(
    ({ target: { value } }) => {
      // really better to useReducer for simultaneously setting multiple state values
      setCulture(value)
      setRightToLeft(value === 'ar-AE')
    },
    [setCulture]
  ) */
  const { defaultDate, messages } = useMemo(
    () => {
      console.log(culture, lang[culture])
      return {
        defaultDate: today,
        messages: lang[culture],
      }
    },
    [culture]
  )
  const formats = useMemo(() => ({
    dateFormat: 'd',
    weekdayFormat: (date, culture, localizer) => {
      return format(date, 'E', {locale: ko})
    },
    monthHeaderFormat:  (date, culture, localizer) => {
      return `${format(date, 'yyyy년 M월', {locale: ko})}`
    }
  }), [])


  const components = useMemo(() => ({
    // eventWrapper: MyEvent,
    toolbar: MyToolbar,
    // eventContainerWrapper: MyMonthDateHeader,
    month: {
      header: MyMonthHeader,
      // dateHeader: MyMonthDateHeader,
      eventWrapper: MyEvent,
    }
  }), [])

  const [selected, setSelected]= useState(today)
  const handleSelect= (slotInfo)=> {
    console.log(slotInfo)
    setSelected(slotInfo.start)
    setSearchParams(searchParams=> ({
      ...params,
      month: format(slotInfo.start, 'yyyy-MM-dd')
    }))
    listEl.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  /* const selectedDate= useMemo(()=> {
    return selected;
  }, [selected]); */
  
  const handleNav= ( newDate )=> {
    const now= format(newDate, 'yyyy-MM-01');
    setSearchParams(searchParams=> ({
      ...params,
      month: now
    }))
  }


  const listEl= useRef();
  return (
    <Fragment>
      {images && images[0]}
      <div className="flx-0 m-flx-col">
        
        <CalendarStyle className='w75 m-w100'>
          <Calendar
            culture={culture}
            formats={formats}
            defaultDate={defaultDate}
            // events={events}
            events={viewData}
            localizer={localizer}
            messages={messages}
            // rtl={rightToLeft}
            // style={{ height: '100%' }}
            // onSelectEvent={e=> console.log(e)}
            components={components}
            // allDayMaxRows={5}
            // max={5}
            popup={true}
            selectable={true}
            onDrillDown={()=>true}
            onSelectSlot={handleSelect}
            // onSelecting={handleSelect}
            showAllEvents={true}
            getNow={()=>selected}
            // date={new Date(2023, 6, 3)}
            onNavigate={handleNav}
            longPressThreshold={10}
          />
        </CalendarStyle>
        <ListStyle ref={listEl} className="w25 flx-0 m-w100">
          {
            data &&
            <EventList 
              info={infoData} data={data} eventData={eventData} 
              currentDate={selected} bgcolor={bgcolor} setViewData={setViewData}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              params={params}
            />
          }
        </ListStyle>
      </div>
    </Fragment>
  )
}
// CulturesDemo.propTypes = {
//   localizer: PropTypes.instanceOf(DateLocalizer),
// }

const bgcolor= {
  '공연': '#f69679',
  '전시': '#8781bd',
  '체험': '#ed145b',
  '축제': '#3777bc',
}

const MyMonthHeader= (props)=> {
  // console.log(props)
  const { label }= props;
  return (
    <div className='bg-clr-bk p-y5 '>
      <TinyText className={`ft-rg`}
        style={{ color: `${label == '일' ? '#f69679' : '#fff'}`}}
      >{label}</TinyText>
    </div>
  )  
}

const MyToolbar= (props)=> {
  const {
    date,
    onNavigate
  } = props;

  const navigate = (action) => {
    onNavigate(action);
  };
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <ToolbarStyle className="flx-cl">
          {/* <button type="button" onClick={navigate.bind(null, 'TODAY')}>
            이번달
          </button> */}
          <button
            type="button"
            onClick={navigate.bind(null, 'PREV')}
            className='cursor-point'
          >
            <img src={PrevArrow} alt="" />
          </button>
          <span className="rbc-toolbar-label">{`${date.getFullYear()}년 ${date.getMonth() + 1}월`}</span>
          <button
            type="button"
            onClick={navigate.bind(null, 'NEXT')}
            className='cursor-point'
          >
            <img src={NextArrow} alt="" />
          </button>
        </ToolbarStyle>
      </span>
    </div>
  )
}
const MyEvent= (props)=> {
  const { event: { title, resource} }= props
  return (
    <EventStyle $category={resource?.category}>
      {title}
    </EventStyle>
  )
}



const height= 800;
const ListStyle= styled.div`
  height: ${height}px;
  @media (max-width: 800px) {
    height: auto;
  }
`
const ToolbarStyle= styled.div`
  position: relative;
  left: 135px;
  @media (max-width: 800px) {
    left: 0;
  }
`


const EventStyle= styled.div`
  /* font-size: .8em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 1px 3px;
  background-color: ${(prop)=> prop.$category ? bgcolor[prop.$category] : '#ddd'};
`

const CalendarStyle= styled.div`
  height: ${height}px;
  /* .rbc-date-cell { font-size: 1.4em; } */
  .rbc-header { padding: 0; }
  /* .rbc-row.rbc-month-header { font-size: 1.1em; } */
  .rbc-toolbar-label { font-size: 1.2em; font-weight: 600; }
  .rbc-row-segment { font-size: .7em; color: #fff; }
  /* .rbc-row { font-size: .7em; } */
  .rbc-toolbar button { border: none; }
  .rbc-month-row { flex-basis: 0px; cursor: pointer; }
  .rbc-day-bg.rbc-today { background-color: #93d2ff; z-index: 3; }
  /* .rbc-day-bg { cursor: pointer; } */
  /* .rbc-btn-group {
    position: absolute;
    top: 0%; left: 0;
  } */
`