import {API_KEY, API_URL} from "./global";
import { qryToString } from './_qryToString';

export const live_long = async(params) => {
  const qryStr= qryToString(params)
  const response = await fetch(API_URL + `api/boards/livelong${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('live_long Error')
  }

  return response.json();
}

export const live_recent = async(params) => {
  const qryStr= qryToString(params)
  const response = await fetch(API_URL + `api/boards/liverecent${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('live_recent Error')
  }

  return response.json();
}