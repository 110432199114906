import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import MapLine from "../../../media/images/CultureMap/cultureMapLine.webp";
import MapImage from "../../../media/images/CultureMap/cultureMapImage.webp";
import SearchIconImage from "../../../media/images/CultureMap/cultureMapSearchIcon.webp";
import DropIconImage from "../../../media/images/CultureMap/cultureMapSearchArrow.webp";
import FirstButtonImage from "../../../media/images/CultureMap/firstButton.webp";
import ResultSpaceImgae from "../../../media/images/CultureMap/spaceImage.webp";
import PagePrevIcon from "../../../media/images/CultureMap/pagePrevIcon.webp";
import PageNextIcon from "../../../media/images/CultureMap/pageNextIcon.webp";
import ResultDetailImage from "../../../media/images/CultureMap/detailSpaceImage.webp";
import CategoryIcon from "../../../media/images/CultureMap/cultureMapIcon.webp";
import RedMarker from "../../../media/images/CultureMap/mapRedMarker.png";
import {map_list} from "../../../api/Map";
import {useQuery} from "react-query";
import {PUBLISH_URL} from "../../../api/global";
import ReactPaginate from 'react-paginate';
import { UltimatePagination } from '../../blocks/_bbs/Pagination'


const itemsPerPage = 5; // 페이지 당 항목 수
export const MapItem = () => {
  const [showCategory, setShowCategory] = useState(false);
  const [categoryValue, setCategoryValue] = useState('전체');
  const [isFirstHidden, setIsFirstHidden] = useState(false);
  const [isSecondHidden, setIsSecondHidden] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const mapRef = useRef();
  const mapInstanceRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const { data:map_list_data, error, isLoading } = useQuery(['map_list', {
    searchValue, categoryValue,
    // currentPage
  }], map_list);
  // const totalPages = Math.ceil(map_list_data && map_list_data.total_count / itemsPerPage) || null // 전체 페이지 수
  const [markers, setMarkers] = useState([]);
  const scrollWrapperRef = useRef();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [totalPages, setTotalPages]= useState(0)
  console.log(map_list_data, totalPages, isLoading)

  useEffect(() => {
    // console.log(map_list_data)
    const initialCenter = map_list_data && map_list_data.articles && map_list_data.articles.length > 0
      ? new window.naver.maps.LatLng(map_list_data.articles[0].latitude, map_list_data.articles[0].longitude)
      : new window.naver.maps.LatLng(36.9940614, 127.8782901);

    const map = new window.naver.maps.Map(mapRef.current, {
      center: initialCenter,
      minZoom: 6,
      zoom: 14,
      zoomControl: true,
      zoomControlOptions: {
        position: window.naver.maps.Position.TOP_LEFT
      },
      scrollWheel: false,
      scaleControl : false,
      logoControl: false,
    });


    // map.setOptions({scaleControl : false, logoControl: false, minZoom: 13, maxZoom: 13})

    mapInstanceRef.current = map;
    let newMarkers = [];


    if (map_list_data && map_list_data.articles) {
      setTotalPages(Math.ceil(map_list_data && map_list_data.total_count / itemsPerPage) || null)
      map_list_data.articles.forEach((article) => {
        if (article.latitude && article.longitude) {
          const marker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(article.latitude, article.longitude),
            map: map,
            icon: {
              url: 'URL_TO_DEFAULT_ICON_IMAGE',
              size: new window.naver.maps.Size(22, 32), // Adjust the size to match the RedMarker size
              origin: new window.naver.maps.Point(0, 0), // You might adjust these values as well if needed
              anchor: new window.naver.maps.Point(11, 32) // Make sure anchor points are consistent
            }
          });

          // 마커 클릭 이벤트 추가
          window.naver.maps.Event.addListener(marker, 'click', () => {
            
            hadleMarkerClick(article, marker); // 해당 정보의 SecondSearchDepthSection 열기
          });

          newMarkers.push({ marker, article });
        }
      });

      setMarkers(newMarkers);
    }

    return () => {
      newMarkers.forEach(({ marker }) => marker.setMap(null));
    };
  }, [map_list_data]);

  useEffect(() => {
    console.log(isSecondHidden)
    if( isSecondHidden ){ 
      setSelectedMarker(null);
      if( map_list_data ) {
        console.log(map_list_data)
        const newCenter = new window.naver.maps.LatLng(map_list_data.articles[0].latitude, map_list_data.articles[0].longitude)
        mapInstanceRef.current.setCenter(newCenter);
      }
      
      return;
    }
    if(!isSecondHidden && mapInstanceRef.current) {
      return;
      // 현재 지도의 중심 위치 가져오기
      const currentCenter = mapInstanceRef.current.getCenter();
      const currentLat = currentCenter.lat();
      const currentLng = currentCenter.lng();

      // 지도의 뷰포트 너비를 기반으로 30%만큼 경도 조절
      const mapBounds = mapInstanceRef.current.getBounds();
      const westLng = mapBounds._min._lng; // 가장 왼쪽의 경도
      const eastLng = mapBounds._max._lng; // 가장 오른쪽의 경도

      const adjustLng = (eastLng - westLng) * -0.3;
      const newCenter = new window.naver.maps.LatLng(currentLat, currentLng - adjustLng);

      // 지도 중심을 새로운 위치로 이동
      mapInstanceRef.current.setCenter(newCenter);
    }
  }, [isSecondHidden]);


  const handleSelection = (value) => {
    setCategoryValue(value)
    setShowCategory(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleResultClick = (data) => {
    setSelectedItem(data);
    setIsFirstHidden(false); // FirstSearchDepthSection 열기
    setIsSecondHidden(false); // SecondSearchDepthSection 열기

    // Use the existing map instance to set the center
    if (mapInstanceRef.current) {
      const newPosition = new window.naver.maps.LatLng(data.latitude, data.longitude);
      mapInstanceRef.current.setCenter(newPosition);
    }

    // 마커 아이콘 업데이트
    markers.forEach(({ marker, article }) => {
      console.log(article, data)
      if (article.id === data.id) {
        // setSelectedMarker(marker)
        hadleMarkerClick(article, marker, true);
        marker.setIcon({
          url: RedMarker,
          size: new window.naver.maps.Size(32, 32), // 조절된 크기
          scaledSize: new window.naver.maps.Size(32, 32), // 또는 스케일링하고 싶은 크기로 조절
          origin: new window.naver.maps.Point(0, 0),
          anchor: new window.naver.maps.Point(16, 32)
        });
      } else {
        // setSelectedMarker(null)
        marker.setIcon({
          url: 'URL_TO_DEFAULT_ICON_IMAGE',
          size: new window.naver.maps.Size(22, 32),
          origin: new window.naver.maps.Point(0, 32),
          anchor: new window.naver.maps.Point(6, 32)
        });
      }
    });

    if(!isSecondHidden && mapInstanceRef.current) {
      // 현재 지도의 중심 위치 가져오기
      const currentCenter = mapInstanceRef.current.getCenter();
      const currentLat = currentCenter.lat();
      const currentLng = currentCenter.lng();

      // 지도의 뷰포트 너비를 기반으로 30%만큼 경도 조절
      const mapBounds = mapInstanceRef.current.getBounds();
      const westLng = mapBounds._min._lng; // 가장 왼쪽의 경도
      const eastLng = mapBounds._max._lng; // 가장 오른쪽의 경도

      const adjustLng = (eastLng - westLng) * -0.3;
      const newCenter = new window.naver.maps.LatLng(currentLat, currentLng - adjustLng);

      // 지도 중심을 새로운 위치로 이동
      mapInstanceRef.current.setCenter(newCenter);
    }

    // 스크롤 최상단 위치
    if (scrollWrapperRef.current) {
      scrollWrapperRef.current.scrollTop = 0;
    }

    setIsSecondHidden(false);
  };

  const hadleMarkerClick = (data, clickedMarker, contentClick) => {
    setSelectedItem(data);
    setIsFirstHidden(false); // FirstSearchDepthSection 열기
    setIsSecondHidden(false); // SecondSearchDepthSection 열기
    setSelectedMarker(clickedMarker);

    // return;

    if(mapInstanceRef.current) {
      const newPosition = new window.naver.maps.LatLng(data.latitude, data.longitude);
      mapInstanceRef.current.setCenter(newPosition);

      // 현재 지도의 중심 위치 가져오기
      const currentCenter = mapInstanceRef.current.getCenter();
      const currentLat = currentCenter.lat();
      const currentLng = currentCenter.lng();

      // 지도의 뷰포트 너비를 기반으로 30%만큼 경도 조절
      const mapBounds = mapInstanceRef.current.getBounds();
      const westLng = mapBounds._min._lng; // 가장 왼쪽의 경도
      const eastLng = mapBounds._max._lng; // 가장 오른쪽의 경도

      const viewWidth= !contentClick ? -0.3 : (isSecondHidden ? -0.15 : 0);
      const adjustLng = (eastLng - westLng) * viewWidth;
      const newCenter = new window.naver.maps.LatLng(currentLat, currentLng - adjustLng);

      // 지도 중심을 새로운 위치로 이동
      mapInstanceRef.current.setCenter(newCenter);
    }

    // 스크롤 최상단 위치
    if (scrollWrapperRef.current) {
      scrollWrapperRef.current.scrollTop = 0;
    }

  }

  // 마커 렌더링 부분 수정
  useEffect(() => {
    console.log(markers, selectedMarker)
    markers.forEach(({ marker, article }) => {
      if (marker === selectedMarker) {
        marker.setIcon({
          url: RedMarker,
          size: new window.naver.maps.Size(32, 32),
          scaledSize: new window.naver.maps.Size(32, 32),
          origin: new window.naver.maps.Point(0, 0),
          anchor: new window.naver.maps.Point(16, 32)
        });
      } else {
        marker.setIcon({
          url: 'URL_TO_DEFAULT_ICON_IMAGE',
          size: new window.naver.maps.Size(22, 32),
          origin: new window.naver.maps.Point(0, 32),
          anchor: new window.naver.maps.Point(6, 32)
        });
      }
    });
  }, [markers, selectedMarker]);

  const handleSearchIconClick = () => {
    console.log(searchTerm)
    if( searchTerm == '' ) {
      setSearchValue(searchTerm);
      return;
    }
    if (searchTerm.length < 2) {
      alert('2자 이상 입력해주세요');
    } else {
      setSearchValue(searchTerm)
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Component className="inst-box" >
      
      <TopContentsLine src={MapLine}/>

      <Contents>

        {/* 지도 */}
        <MapWrapper ref={mapRef} />

        {/* 검색 리스트 */}
        <FirstSearchDepthSection isFirstHidden={isFirstHidden} onClick={() => {
          if (showCategory) {
            setShowCategory(false)
          }
        }}>

          <FirstButton src={FirstButtonImage} onClick={() => {
            setIsFirstHidden(!isFirstHidden)
            setIsSecondHidden(true)
          }}/>

          <SearchInput placeholder={'검색어를 입력하세요.'} value={searchTerm} onChange={handleInputChange} onKeyDown={(e) => e.key === 'Enter' && handleSearchIconClick()}/>
          <SearchIcon src={SearchIconImage} onClick={handleSearchIconClick}/>

          {/* 카테고리 설정 버튼 */}
          <DropdownSection>
            <DropdownInput onClick={() => setShowCategory(!showCategory)}
                           readOnly value={categoryValue}/>
            <DropIcon src={DropIconImage}/>
            {showCategory && (
              <DropdownListSection>
                <DropdownListText onClick={() => handleSelection("전체")}>전체</DropdownListText>
                <DropdownListText onClick={() => handleSelection("제목")}>제목</DropdownListText>
                <DropdownListText onClick={() => handleSelection("내용")}>내용</DropdownListText>
              </DropdownListSection>
            )}
          </DropdownSection>
          
        

          {/* 리스트 결과 */}
          <FirstResultSection>
            {
              isLoading && <p className="w100 h200p p-10 flx-cc">...Loading</p>
            }
            {
              !isLoading && 
              <>
              {
                map_list_data && map_list_data.articles ? (
                  map_list_data.articles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((data) => {
                  // map_list_data.articles.map((data) => {
                    const { id, category, subject, address, phone, images }= data;
                    return (
                      <ResultList key={id} onClick={() => handleResultClick(data)}>
                        <ResultSpaceimg src={`${PUBLISH_URL}${images[0]}`}/>

                        <ResultTextSection>
                          <ResultCategory>{category}</ResultCategory>
                          <ResultName>{subject}</ResultName>
                          <ResultAddress>{address}</ResultAddress>
                          <ResultPhone>{phone}</ResultPhone>
                        </ResultTextSection>
                      </ResultList>
                    )
                    
                  })
                ) : ( <NoSearch>검색 결과가 없습니다.</NoSearch> )
              }
              </>
            }
            
            {
              totalPages && 
              <div className="p-y20">
                <UltimatePagination
                  totalPages={totalPages}
                  boundaryPagesRange={1}
                  siblingPagesRange={2}
                  currentPage={currentPage}
                  onChange={(page)=>setCurrentPage(page)}
                />
              </div>
            }
            {/* 페이지네이션 */}
            {/* { map_list_data && map_list_data.articles && (
              <PaginationSection>
                <NextPrevButton onClick={handlePrevPage} src={PagePrevIcon}/>
                {Array.from({length: totalPages}).map((_, idx) => {
                  // 현재 페이지 주변에만 버튼 표시
                  if (idx >= currentPage - 3 && idx <= currentPage + 3) {
                    return (
                      <PageButton key={idx} onClick={() => setCurrentPage(idx + 1)} isActive={currentPage === idx + 1}>
                        {idx + 1}
                      </PageButton>
                    );
                  }
                  return null;
                })}
                <NextPrevButton onClick={handleNextPage} src={PageNextIcon}/>
              </PaginationSection>
            )} */}
          </FirstResultSection>

          

        </FirstSearchDepthSection>

        {/* 리스트 디테일 드로우 페이지 */}
        <SecondSearchDepthSection isSecondHidden={isSecondHidden}>
          <ScrollWrapper ref={scrollWrapperRef}>

          <SecondButton src={FirstButtonImage} onClick={() => setIsSecondHidden(!isSecondHidden)}/>

          <SecondImage src={selectedItem && `${PUBLISH_URL}${selectedItem.images[0]}`}/>

          <SecondCateforySection>
            <SecondCateforyIcon src={CategoryIcon}/>
            <SecondCategory>{selectedItem && selectedItem.category}</SecondCategory>
          </SecondCateforySection>

          <SecondName>공간명 {selectedItem ? selectedItem.subject : ''}</SecondName>
          <SecondAddress>주소 {selectedItem ? selectedItem.address : ''}</SecondAddress>
          <SecondPhone>연락처 {selectedItem ? selectedItem.phone : ''}</SecondPhone>

          <SecondLine/>

          <SecondDetail dangerouslySetInnerHTML={{ __html: selectedItem && selectedItem.content.replace(/\/data\/images/g, `${PUBLISH_URL}/data/images`)}} />

          </ScrollWrapper>
        </SecondSearchDepthSection>

      </Contents>
      <BottomContentsLine src={MapLine}/>
    </Component>
  )
}


const Pagination= styled.div`
  ul { 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & li {
    cursor: pointer;
  }
  & li.selected {
    color: red;
    
  }
`

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Contents = styled.div`
  width: 1602px;
  height: 901px;
  background-color: white;
  position: relative;
  overflow: hidden;

  @media (max-width: 1920px) {
    width: 83.4375vw;
    height: 46.9271vw;
  }
`

const TopContentsLine = styled.img`
  width: 1601px;
  height: 6px;
  z-index: 3;
  position: relative;
  top: 1px;

  @media (max-width: 1920px) {
    width: 83.3854vw;    // 1601px
    height: 0.3125vw;    // 6px
    top: 0.0521vw;       // 1px
  }
`

const BottomContentsLine = styled.img`
  width: 1605px;
  height: 6px;
  margin-bottom: 84px;
  z-index: 1000;
  position: relative;

  @media (max-width: 1920px) {
    width: 83.59375vw;
    height: 0.3125vw;
    margin-bottom: 4.375vw;
  }
`

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const FirstSearchDepthSection = styled.div`
  width: 417px;
  height: 900px;
  background-color: white;
  position: absolute;
  top: 0;
  right: ${props => props.isFirstHidden ? '-417px' : '0'};
  transition: right 0.3s ease;
  z-index: 1;
  border: #d1d3d4 1px solid;
  /* overflow-x: hidden; */

  @media (max-width: 1920px) {
    width: 21.71875vw;
    /* height: 46.875vw; */
    right: ${props => props.isFirstHidden ? '-21.71875vw' : '0'};
    border: 0.0521vw solid #d1d3d4;
  }
`

const FirstButton = styled.img`
  width: 43px;
  height: 77px;
  position: absolute;
  left: -41px;
  top: -3px;
  cursor: pointer; // 클릭 가능한 효과 추가

  @media (max-width: 1920px) {
    width: 2.2396vw;
    height: 4.0104vw;
    left: -2.1354vw;
    top: -0.1563vw;
  }
`

const SearchInput = styled.input`
  width: 367px;
  height: 47px;
  background-color: #f1f2f2;
  border-radius: 23.33px;
  border: none;
  margin-top: 12px;
  margin-left: 26px;
  font-size: 18px;
  font-family: 'SCDream4', serif;
  padding-left: 21px;
  box-sizing: border-box;

  @media (max-width: 1920px) {
    width: 19.1146vw;
    height: 2.4479vw;
    border-radius: 1.2146vw;
    margin-top: 0.625vw;
    margin-left: 1.3542vw;
    font-size: 0.9375vw;
    padding-left: 1.0938vw;
  }
`

const SearchIcon = styled.img`
  position: absolute;
  width: 19px;
  height: 19px;
  top: 27px;
  right: 37px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 0.9896vw;
    height: 0.9896vw;
    top: 1.4063vw;
    right: 1.9271vw;
  }
`

const FirstResultSection = styled.div`
  width: 417px;
  height: 769px;
  overflow-y: scroll;
  border-top: 1px solid #d1d3d4;
  
  overflow-x: hidden;
  @media (max-width: 1920px) {
    width: 21.71875vw;
    height: 40.0521vw;
    border-top: 0.0521vw solid #d1d3d4;
  }
`

const DropdownSection = styled.div`
  position: relative;
  width: 55px;
  margin-left: 28px;
  margin-top: 36px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 1920px) {
    width: 2.8646vw;
    margin-left: 1.4583vw;
    margin-top: 1.875vw;
    margin-bottom: 0.7813vw;
  }
`

const DropdownInput = styled.input`
  border: none;
  font-size: 14px;
  font-family: 'SCDream5', serif;
  width: 35px;
  outline: none;

  @media (max-width: 800px) {
    font-size: 0.9375vw;
    width: 1.82vw;
  }
`

const DropdownListSection = styled.div`
  border: 1px solid #959595;
  position: absolute;
  width: 55px;
  top: 24px;
  background-color: white;

  @media (max-width: 1920px) {
    border: 0.0521vw solid #959595;
    /* width: 2.8646vw;
    top: 1.25vw; */
  }
`

const DropdownListText = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'SCDream5', serif;
  cursor: pointer;

  @media (max-width: 1920px) {
    /* margin-left: 0.5208vw;
    margin-top: 0.5208vw;
    margin-bottom: 0.5208vw;
    font-size: 0.9375vw; */
  }
`

const DropIcon = styled.img`
  //position: absolute;
  width: 14px;
  height: 12px;
  //top: 5px;
  //right: 0;

  @media (max-width: 1920px) {
    width: 0.7292vw;
    height: 0.625vw;
    //top: 0.36vw;
    //right: 0;
  }
`

const ResultList = styled.div`
  display: flex;
  width: 417px;
  height: 174px;
  border-bottom: 1px solid #d1d3d4;
  cursor: pointer;

  &:last-child {
    border-bottom: transparent;
  }

  @media (max-width: 1920px) {
    width: 21.71875vw;
    height: 9.0625vw;
    border-bottom: 0.0521vw solid #d1d3d4;
  }
`

const ResultSpaceimg = styled.img`
  object-fit: cover;
  width: 126px;
  height: 126px;
  margin-left: 34px;
  margin-top: 23px;

  @media (max-width: 1920px) {
    width: 6.5625vw;
    height: 6.5625vw;
    margin-left: 1.7708vw;
    margin-top: 1.1979vw;
  }
`

const ResultTextSection = styled.div`
  margin-left: 21px;
  margin-top: 23px;

  @media (max-width: 1920px) {
    margin-left: 1.0938vw;
    margin-top: 1.1979vw;
  }
`

const ResultCategory = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;
  color: #5b9dc9;
  margin-bottom: 42px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin-bottom: 2.1875vw;
  }
`

const ResultName = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;
  

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
  }
`

const ResultAddress = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 210px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    width: 10.771vw;
  }
`

const ResultPhone = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
  }
`

const PaginationSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 25px;
  overflow-x: auto; // 가로 스크롤 추가
  white-space: nowrap; // 가로로 나열되도록 설정
  overflow-x: hidden;

  @media (max-width: 1920px) {
    margin-top: 1.0417vw;
    margin-bottom: 1.3021vw;
  }
`;

const NextPrevButton = styled.img`
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
  width: 12px;
  height: 13px;

  @media (max-width: 1920px) {
    padding: 0.2604vw 0.5208vw;
    margin: 0 0.5208vw;
    width: 0.625vw;
    height: 0.6771vw;
  }
`;

const PageButton = styled.div`
  font-size: 18px;
  font-family: 'SCDream6', serif;
  margin: 0 15px;
  cursor: pointer;
  color: ${props => props.isActive ? '#5b9dc9' : 'inherit'};

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin: 0 0.7813vw;
  }
`;

const SecondSearchDepthSection = styled.div`
  width: 417px;
  height: 900px;
  background-color: white;
  position: absolute;
  top: 0;
  right: ${props => props.isSecondHidden ? '-417px' : '417px'};
  transition: right 0.3s ease;
  z-index: 0;
  border: #d1d3d4 1px solid;
  //overflow-y: scroll;
  //overflow-x: hidden;
  

  @media (max-width: 1920px) {
    width: 21.7188vw;
    /* height: 46.875vw; */
    right: ${props => props.isSecondHidden ? '-21.7188vw' : '21.7188vw'};
    border: #d1d3d4 0.0521vw solid;
  }
`

const ScrollWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 900px;
  
  @media (max-width: 1920px) {
    /* height: 46.875vw; */
  }
`

const SecondButton = styled.img`
  width: 43px;
  height: 77px;
  position: absolute;
  left: -41px;
  top: -3px;
  cursor: pointer; // 클릭 가능한 효과 추가
  

  @media (max-width: 1920px) {
    width: 2.2396vw;
    height: 4.0104vw;
    left: -2.1354vw;
    top: -0.1563vw;
  }
`

const SecondImage = styled.img`
  width: 368px;
  height: 238px;
  margin-top: 71px;
  margin-left: 29px;

  @media (max-width: 1920px) {
    width: 19.1667vw;
    height: 12.3958vw;
    margin-top: 3.6979vw;
    margin-left: 1.5104vw;
  }
`

const SecondCateforySection = styled.div`
  display: flex;
  margin-top: 31px;

  @media (max-width: 1920px) {
    margin-top: 1.6146vw;
  }
`

const SecondCateforyIcon = styled.img`
  width: 20px;
  height: 21px;
  margin-left: 31px;
  margin-right: 5px;

  @media (max-width: 1920px) {
    width: 1.0417vw;
    height: 1.0938vw;
    margin-left: 1.6146vw;
    margin-right: 0.2604vw;
  }
`

const SecondCategory = styled.div`
  font-size: 18px;
  color: #5b9dc9;
  font-family: 'SCDream4', serif;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
  }
`

const SecondName = styled.div`
  margin-top: 22px;
  font-size: 18px;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 31px;

  @media (max-width: 1920px) {
    margin-top: 1.1458vw;
    font-size: 0.9375vw;
    margin-left: 1.6146vw;
  }
`

const SecondAddress = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 31px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin-left: 1.6146vw;
  }
`

const SecondPhone = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 31px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin-left: 1.6146vw;
  }
`

const SecondLine = styled.div`
  border-bottom: 1px solid #d1d3d4;
  margin-top: 31px;
  margin-bottom: 37px;

  @media (max-width: 1920px) {
    border-bottom: 0.0521vw solid #d1d3d4;
    margin-top: 1.6146vw;
    margin-bottom: 1.9271vw;
  }
`

const SecondDetail = styled.div`
  white-space: pre-line;
  font-size: 16.5px;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 31px;
  line-height: 25.5px;

  @media (max-width: 1920px) {
    font-size: 0.8594vw;
    margin-left: 1.6146vw;
    line-height: 1.3281vw;
  }
`

const NoSearch = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-top: 24px;
  margin-left: 28px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin-top: 1.25vw;
    margin-left: 1.4583vw;
  }
`