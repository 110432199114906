import React, {useRef, useState} from "react";
import styled from "styled-components";
import PrevImage from '../../../../media/images/MainHome/TopSlide/prev.webp'
import NextImage from '../../../../media/images/MainHome/TopSlide/next.webp'
import PauseImage from '../../../../media/images/MainHome/TopSlide/pause.webp'
import PatternImage from '../../../../media/mobileImage/MainHome/TopSlide/mobileTopPattern.webp'
import Visual1 from "../../../../media/mobileImage/MainHome/TopSlide/mobileVisual-01.webp";
import Visual2 from "../../../../media/mobileImage/MainHome/TopSlide/mobileVisual-02.webp";
import Visual3 from "../../../../media/mobileImage/MainHome/TopSlide/mobileVisual-03.webp";

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

// import required modules
import {Autoplay, Scrollbar, Navigation} from 'swiper/modules';

import {API_KEY, API_URL, PUBLISH_URL} from "../../../../api/global";
import {Link, useParams} from "react-router-dom";

export const MobileTopSlide = ({ data }) => {
  const swiperRef = useRef(null);
  const [pauseState, setPauseState] = useState(true);


  const pauseAutoplay = () => {
    const swiper = swiperRef.current;
    swiper.swiper.autoplay.pause()
    setPauseState(false)
  };

  const resumeAutoplay = () => {
    const swiper = swiperRef.current;
    swiper.swiper.autoplay.resume()
    setPauseState(true)
  };

  return (
    <>
      <PatternSection>
        <PatternImg src={PatternImage}/>
      </PatternSection>

      <Container>
        <Swiper
          ref={swiperRef}
          modules={[Autoplay, Navigation, Scrollbar]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          scrollbar={{
            draggable: true,
            el: '.myScroll',
            enabled: true
          }}
          navigation={{
            nextEl: '.next',
            prevEl: '.prev',
          }}
          loop={true} // 무한 반복
          style={{width: "1226px"}} // 너비 설정
        >

          {
            data && data.map( d=> {
              
              return (
                <SwiperSlide>
                  <Link to={d.link} target={d.link_new_win ? '_blank' : '_self'}>
                    <MainImageSection>
                      <MainImage src={`${PUBLISH_URL}${d.image_mo}`}/>
                    </MainImageSection>
                  </Link>
                </SwiperSlide>
              )
            })
          }

          {/* <SwiperSlide>

            <MainImageSection>
              <MainImage src={Visual1}/>
              <ButtonSection/>
            </MainImageSection>

          </SwiperSlide>

          <SwiperSlide>

            <MainImageSection>
              <MainImage src={Visual2}/>
              <ButtonSection/>
            </MainImageSection>

          </SwiperSlide>

          <SwiperSlide>

            <MainImageSection>
              <MainImage src={Visual3}/>
              <ButtonSection/>
            </MainImageSection>

          </SwiperSlide> */}

        </Swiper>


        <ControllerBox>
          <ScrollBarBox>
            <ScrollBarLine className={'myScroll'}>
              <ScrollDrag className={'swiper-scrollbar-drag'}/>
            </ScrollBarLine>
          </ScrollBarBox>

          <NavigationBox>
            <PrevArrowButton className="prev" src={PrevImage}/>
            {pauseState ? <PauseButton onClick={pauseAutoplay} src={PauseImage}/>
              :
              <PlayButton src={NextImage} onClick={resumeAutoplay}/>
            }
            <NextArrowButton className="next" src={NextImage}/>
          </NavigationBox>
        </ControllerBox>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 11.18vw; /* 137px */
  z-index: 1;
`;

const PatternSection = styled.div`
  /* height: 3.26vw; */
  height: 0;
  overflow: hidden;
  position: relative;
  /* margin-top: 1.06vw;  */
  display: flex;
  justify-content: end;
  width: 100vw;
`;

const PatternImg = styled.img`
  width: 40.45vw; /* 496px */
  height: 19.25vw; /* 236px */
  margin-right: -2.45vw; /* -30px */
`;

const MainImageSection = styled.div`
  position: relative;
`;

const ButtonSection = styled.div`
  width: 37.2vw; /* 456px */
  height: 5.47vw; /* 67px */
  position: absolute;
  bottom: 11.19vw; /* 137px */
  left: 4.49vw; /* 55px */
  cursor: pointer;
  background-color: transparent;
  border-radius: 2.04vw; /* 25px */
`;

const MainImage = styled.img`
  width: 100vw;
`;

const ControllerBox = styled.div`
  display: flex;
  height: 3.26vw; /* 40px */
  flex-direction: column;
`;

const NavigationBox = styled.div`
  width: 93.48vw;
  display: flex;
  justify-content: end;
  margin-top: 2.37vw; /* 29px */
`;

const PrevArrowButton = styled.img`
  width: 2.77vw; /* 34px */
  height: 3.26vw; /* 40px */
  margin-right: 6.28vw; /* 77px */
  cursor: pointer;
`;

const NextArrowButton = styled.img`
  width: 2.85vw; /* 35px */
  height: 3.26vw; /* 40px */
  margin-left: 6.28vw; /* 77px */
  cursor: pointer;
`;

const PlayButton = styled.img`
  width: 2.85vw; /* 35px */
  height: 3.26vw; /* 40px */
  cursor: pointer;
`;

const PauseButton = styled.img`
  cursor: pointer;
  width: 2.61vw; /* 32px */
  height: 3.18vw; /* 39px */
`;

const ScrollBarBox = styled.div`
  width: 93.48vw;
  margin-top: 1.39vw; /* 17px */
  position: relative;
  height: 0.82vw; /* 10px */
`;

const ScrollBarLine = styled.div`
  border-top: 0.24vw solid #464646; /* 3px */
`;

const ScrollDrag = styled.div`
  background-color: black;
  width: 50%;
  border-radius: unset;
  height: 0.49vw; /* 6px */
  position: absolute;
  top: -0.08vw; /* -1px */
`;
