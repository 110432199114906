import React from "react";
import styled from "styled-components";
import DownloadImage from '../../../../media/images/Notice/noticeDownloadIcon.webp'
import PreviewImage from '../../../../media/images/Notice/noticePreviewIcon.webp'
import ViewListButtonImage from '../../../../media/images/NoticeDetail/noticeDetailButton.webp'
import PatternImage from '../../../../media/images/DreamCityCardDetail/dreamCityCardDetailPattern.webp'
import PagePrevIcon from "../../../../media/images/CultureMap/pagePrevIcon.webp";
import PageNextIcon from "../../../../media/images/CultureMap/pageNextIcon.webp";
import {Link} from "react-router-dom";
import {API_KEY, API_URL, PUBLISH_URL} from "../../../../api/global";
import {useQuery} from "react-query";

export const MobileDreamCityCardDetailContents = ({data, allData, selectedCategory}) => {
  const [currentData, setCurrentData] = React.useState(data);
  const [prevData, setPrevData] = React.useState(allData.find(d => Number(d.id) < Number(data.id)));
  const [nextData, setNextData] = React.useState(allData.find(d => Number(d.id) > Number(data.id)));

  /* const dream_card_detail = async() => {
    const response = await fetch(API_URL + `api/boards/${(selectedCategory === '나답게' && 'likeme') || (selectedCategory === '너답게' && 'likeyou') || (selectedCategory === '우리답게' && 'likeus') || (selectedCategory === '충격의 로컬크리에이터' && 'localcreator')}/${data.id}`, {
      headers: {
        'X-CCFCJ-API-KEY' : `${API_KEY}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials" : "true",
      }
    })

    if(!response.ok){
      throw new Error('main_communities_list Error')
    }

    return response.json();
  }

  const { data:dream_card_detail_data, error, isLoading } = useQuery('dream_card_detail', dream_card_detail); */
  const dream_card_detail_data= currentData;

  const handlePrevClick = () => {
    const currentIndex = allData.findIndex(d => d.id === currentData.id);
    if (currentIndex > 0) {
      setCurrentData(allData[currentIndex - 1]);
      setPrevData(currentIndex > 1 ? allData[currentIndex - 2] : null);
      setNextData(allData[currentIndex]);
    }
  };

  const handleNextClick = () => {
    const currentIndex = allData.findIndex(d => d.id === currentData.id);
    if (currentIndex < allData.length - 1) {
      setCurrentData(allData[currentIndex + 1]);
      setPrevData(allData[currentIndex]);
      setNextData(currentIndex < allData.length - 2 ? allData[currentIndex + 2] : null);
    }
  };




  return(
    <Component>

      <Title>{selectedCategory}</Title>
      {/* <CategoryText>{selectedCategory}</CategoryText> */}
      <SubTitle>모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!</SubTitle>

      <PatternImg src={PatternImage}/>

      <TitleSection>
        <TitleText>{dream_card_detail_data && dream_card_detail_data.subject}</TitleText>
      </TitleSection>

      <DescriptionText>{dream_card_detail_data && dream_card_detail_data.summary}</DescriptionText>

      <PeroidSection>
        {selectedCategory !== '자료실' && <PeriodText>기간 : {dream_card_detail_data && `${dream_card_detail_data.start_date.slice(0, 10)} ~ ${dream_card_detail_data.end_date.slice(0, 10)}`}</PeriodText>}
        <CreatedText>작성일 : {dream_card_detail_data && dream_card_detail_data.createdate.slice(0, 10)}</CreatedText>
      </PeroidSection>

      <MainText selectedCategory={selectedCategory}dangerouslySetInnerHTML={{ __html: dream_card_detail_data && dream_card_detail_data.content.replace(/\/data\/images/g, `${PUBLISH_URL}/data/images`)}} />

      {/*<MainImg/>*/}

      {/*<MainText>{currentData.maidText}</MainText>*/}

      <PreviewSection>
        <PreviewIconImg src={PreviewImage}/>
        <PreviewText>미리보기</PreviewText>
        {dream_card_detail_data && dream_card_detail_data.pdf_files.map((data) => (
          <DownloadContent target="_blank" rel="noopener noreferrer" href={data.url}>{data.name}</DownloadContent>
        ))}
      </PreviewSection>

      <DownloadSection>
        <DownloadImg src={DownloadImage}/>
        <DownloadText>첨부파일</DownloadText>
        {dream_card_detail_data && dream_card_detail_data.files.map((data) => (
          <DownloadContent href={`${PUBLISH_URL}${data.url}`}>{data.name}</DownloadContent>
        ))}
      </DownloadSection>

      <BottomSection>
        <PrevListSection prevData={prevData} onClick={handlePrevClick}>
          <PrevIconImg src={PagePrevIcon}/>
          <PrevText>이전 글</PrevText>
        </PrevListSection>

        <Link to={'/dreamCityDetail'}>
          <ViewListSection>
            <ViewListButton src={ViewListButtonImage}/>
            <ViewListText>목록 보기</ViewListText>
          </ViewListSection>
        </Link>

        <NextListSection nextData={nextData} onClick={handleNextClick}>
          <NextText>다음 글</NextText>
          <NextIconImg src={PageNextIcon}/>
        </NextListSection>
      </BottomSection>


    </Component>
  )
}



const Component = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

`

const Title = styled.div`
  font-size: 5.70692vw;
  font-family: 'SCDream7', serif;
  margin-bottom: 2.44543vw;
  margin-top: 7.50102vw;

`

const SubTitle = styled.div`
  font-size: 2.36707vw;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-bottom: 3.99vw;
  white-space: pre-line;
`

const CategoryText = styled.div`
  font-size: 4.97vw;
  font-family: 'SCDream7', serif;
  margin-bottom: 10.93377vw;
`


const PatternImg = styled.img`
  position: absolute;
  left: 4.89686vw;
  top: 30.74208vw;
  z-index: 0;
  width: 40.21vw;
  height: 9.21vw;

`

const TitleSection = styled.div`
  border-top: 0.48969vw solid black;
  border-bottom: 0.24484vw solid #464646;
  width: 91.82283vw;
  display: flex;
  justify-content: space-between;
  padding: 0 0.81627vw;
  align-items: center;
  z-index: 1;
  position: relative;

`

const TitleText = styled.div`
  font-size: 3.47407vw;
  font-family: 'SCDream6', serif;
  margin: 3.09839vw 0;

`

const CreatedText = styled.div`
  font-size: 2.32645vw;
  font-family: 'SCDream5', serif;


`

const DescriptionText = styled.div`
  font-size: 2.32645vw;
  font-family: 'SCDream5', serif;
  width: 91.82283vw;
  border-bottom: 0.24484vw solid #dddede;
  display: flex;
  align-items: center;
  padding: 2.93871vw 0.81627vw;
  color: #333333;

`

const PeroidSection = styled.div`
  width: 91.82283vw;
  border-bottom: 0.24484vw solid #464646;
  display: flex;
  align-items: center;
  padding: 2.93871vw 0.81627vw;
  justify-content: space-between;
  margin-bottom: 7.09709vw;
`

const PeriodText = styled.div`
  font-size: 2.32645vw;
  font-family: 'SCDream5', serif;
  color: #333333;


`

const MainText = styled.div`
  font-size: 2.32645vw;
  font-family: 'SCDream4', serif;
  width: 91.82283vw;
  padding: 0 0.81627vw;
  margin-top: ${({selectedCategory}) => selectedCategory === '자료실' ? '3.34152vw' : ''};


`

const MainImg = styled.div`
  width: 91.82283vw;
  height: 54.47435vw;
  background-color: #959595;
  margin-top: 2.04063vw;
  margin-bottom: 1.95937vw;


`

const PreviewSection = styled.div`
  display: flex;
  border-top: 0.24484vw solid #464646;
  border-bottom: 0.24484vw solid #dddede;
  padding: 1.95937vw 0.81627vw;
  width: 91.82283vw;
  align-items: center;
  margin-top: 14.75542vw;


`

const PreviewIconImg = styled.img`
  width: 4.81387vw;
  height: 5.54803vw;


`

const PreviewText = styled.div`
  font-size: 3.47407vw;
  font-family: 'SCDream5', serif;
  margin-left: 4.89686vw;
  color: #231f20;

`

const DownloadSection = styled.div`
  display: flex;
  border-bottom: 0.24484vw solid black;
  padding: 3.26225vw 1.22413vw;
  width: 91.82283vw;
  align-items: center;


`

const DownloadImg = styled.img`
  width: 4.32465vw;
  height: 4.32465vw;

`

const DownloadText = styled.div`
  font-size: 3.47407vw;
  font-family: 'SCDream5', serif;
  margin-left: 5.13679vw;
  color: #231f20;

`

const DownloadContent = styled.a`
  font-size: 2.47407vw;
  font-family: 'SCDream5', serif;
  margin-left: 3.32465vw;
  color: #58595b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 25vw;
`

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92.95114vw;
  margin-top: 12.88813vw;
  margin-bottom: 7.09709vw;


`

const PrevListSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.prevData ? 1 : 0};
  pointer-events: ${props => props.prevData ? 'auto' : 'none'};

`

const PrevIconImg = styled.img`
  width: 2.20278vw;
  height: 2.52868vw;
  margin-right: 2.36707vw;
  margin-bottom: 0.40813vw;


`

const PrevText = styled.div`
  font-size: 3.47407vw;
  font-family: 'SCDream5', serif;


`

const ViewListSection = styled.div`
  position: relative;
  cursor: pointer;
`

const ViewListButton = styled.img`
  width: 47.05529vw;
  height: 7.34156vw;


`

const ViewListText = styled.div`
  font-size: 3.38359vw;
  font-family: 'SCDream5', serif;
  position: absolute;
  top: 2vw;
  left: 16.24393vw;


`

const NextListSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.nextData ? 1 : 0};
  pointer-events: ${props => props.nextData ? 'auto' : 'none'};
`

const NextText = styled.div`
  font-size: 3.47407vw;
  font-family: 'SCDream5', serif;


`

const NextIconImg = styled.img`
  width: 2.20278vw;
  height: 2.52868vw;
  margin-left: 2.12299vw;
  margin-bottom: 0.40813vw;


`