import styled from "styled-components";
import PagePrevIcon from "../../../media/images/CultureMap/pagePrevIcon.webp";
import PageNextIcon from "../../../media/images/CultureMap/pageNextIcon.webp";
const ReactUltimatePagination = require('react-ultimate-pagination');

const PageStyle= styled.span`
  cursor: pointer;
  width: 16px;
  text-align: center;
  color: ${({ $isActive })=> $isActive ? `#5b9dc9` : `#333`};
  font-size: 13px;
`

const PageNumStyle= styled(PageStyle)`
  margin-left: 4px;
  margin-right: 4px;
  color: ${({ $isActive })=> $isActive ? `#5b9dc9` : `#333`};
`


function Page(props) {
  return (
    <PageNumStyle
      style={props.isActive ? {fontWeight: 'bold'} : null}
      onClick={props.onClick}
      disabled={props.disabled}
      $isActive={props.isActive}
    >{props.value}</PageNumStyle>
  );
}


function Ellipsis(props) {
  return <PageStyle onClick={props.onClick} disabled={props.disabled}>...</PageStyle>
}

function FirstPageLink(props) {
  return <PreNextPageLinkStyle onClick={props.onClick} disabled={props.disabled}>&lt;&lt;</PreNextPageLinkStyle>
}

const PreNextPageLinkStyle= styled(PageNumStyle)`
  & .arrow {
    width: 10px;
  }
`
function PreviousPageLink(props) {
  return <PreNextPageLinkStyle onClick={props.onClick} disabled={props.disabled}>
    <img src={PagePrevIcon} alt="" className="arrow" />
  </PreNextPageLinkStyle>
}

function NextPageLink(props) {
  return <PreNextPageLinkStyle onClick={props.onClick} disabled={props.disabled}>
    <img src={PageNextIcon} alt="" className="arrow" />
  </PreNextPageLinkStyle>
}

function LastPageLink(props) {
  return <PreNextPageLinkStyle onClick={props.onClick} disabled={props.disabled}>&gt;&gt;</PreNextPageLinkStyle>
}

function Wrapper(props) {
  return <div className="pagination flx-cc">
    <div className="flx-0">{props.children}</div>
  </div>
}

var itemTypeToComponent = {
  'PAGE': Page,
  'ELLIPSIS': Ellipsis,
  'FIRST_PAGE_LINK': ()=><></>,
  'PREVIOUS_PAGE_LINK': PreviousPageLink,
  'NEXT_PAGE_LINK': NextPageLink,
  'LAST_PAGE_LINK': ()=><></>
};

export const UltimatePagination = ReactUltimatePagination.createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper
});