import {API_KEY, API_URL} from "./global";

export const map_list = async(params) => {
  const {searchValue: searchTerm, categoryValue, currentPage}= params.queryKey[1];
  const strCurrentPage= currentPage ? `&current_page=${currentPage}` : '';
  const encodedSearchTerm = `&search_value=${encodeURIComponent(searchTerm)}`;
  const encodedCategoryValue = `&search_column=${(categoryValue === '전체' && 'all') || (categoryValue === '제목' && 'subject') || (categoryValue === '내용' && 'content')}`;

  console.log(`api/maps?center_latitude=36.9923165&center_longitude=127.8347377&zoom=13${strCurrentPage}${searchTerm && encodedSearchTerm}${searchTerm && encodedCategoryValue}`)
  const response = await fetch(API_URL + `api/maps?center_latitude=36.9923165&center_longitude=127.8347377&zoom=13${strCurrentPage}${searchTerm && encodedSearchTerm}${searchTerm && encodedCategoryValue}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('map_list Error')
  }

  return response.json();
}
