import React from "react";
import styled from "styled-components";
import {DreamCityFirstCard} from "../../atoms/DreamCity/DreamCityFirstCard";
import {DreamCitySecondCard} from "../../atoms/DreamCity/DreamCitySecondCard";
import {DreamCityThirdCard} from "../../atoms/DreamCity/DreamCityThirdCard";

export const DreamCityContents = () => {

  return (
    <Component>
      <PathSection>홈 &gt; 충주(Culture) &gt; 우리가 꿈꾸는 도시</PathSection>

      <Title>우리가 꿈꾸는 도시</Title>
      <SubTitle>모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!</SubTitle>

      {/* 카드 컨텐츠 */}
      <CardContents>
        <DreamCityFirstCard/>

        <DreamCitySecondCard/>

        <DreamCityThirdCard/>
      </CardContents>


    </Component>
  )
}

const Component = styled.div`
  width: 1434px;

  @media (max-width: 1920px) {
    width: 74.6875vw;
  }
`

const PathSection = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-top: 33px;
  margin-bottom: 42px;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    margin-top: 1.72vw;
    margin-bottom: 2.19vw;
  }
`

const Title = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  text-align: center;
  margin-bottom: 21px;

  @media (max-width: 1920px) {
    font-size: 2.08vw;
    margin-bottom: 1.09vw;
  }
`

const SubTitle = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-bottom: 121px;

  @media (max-width: 1920px) {
    font-size: 1.15vw;
    margin-bottom: 6.3021vw;
  }
`

const CardContents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
