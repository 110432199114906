import React from "react";
import styled from "styled-components";
import DownloadImage from '../../../media/images/Notice/noticeDownloadIcon.webp'
import PreviewImage from '../../../media/images/Notice/noticePreviewIcon.webp'
import ViewListButtonImage from '../../../media/images/NoticeDetail/noticeDetailButton.webp'
import PatternImage from '../../../media/images/DreamCityCardDetail/dreamCityCardDetailPattern.webp'
import PagePrevIcon from "../../../media/images/CultureMap/pagePrevIcon.webp";
import PageNextIcon from "../../../media/images/CultureMap/pageNextIcon.webp";
import {Link} from "react-router-dom";
import {API_KEY, API_URL, PUBLISH_URL} from "../../../api/global";
import {useQuery} from "react-query";
import {TitleSection as TitleSections} from '../../pages/_Include/Title';

export const DreamCityCardDetailContents = ({data, allData, selectedCategory}) => {
  const [currentData, setCurrentData] = React.useState(data);
  const [prevData, setPrevData] = React.useState(allData.find(d => Number(d.id) < Number(data.id)));
  const [nextData, setNextData] = React.useState(allData.find(d => Number(d.id) > Number(data.id)));

  const dream_card_detail = async() => {
    const response = await fetch(API_URL + `api/boards/${(selectedCategory === '나답게' && 'likeme') || (selectedCategory === '우리가 함께하는 도시' && 'likeyou') || (selectedCategory === '우리답게' && 'likeus') || (selectedCategory === '충격의 로컬크리에이터' && 'localcreator')}/${data.id}`, {
      headers: {
        'X-CCFCJ-API-KEY' : `${API_KEY}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials" : "true",
      }
    })

    if(!response.ok){
      throw new Error('main_communities_list Error')
    }

    return response.json();
  }
  // const dream_card_detail_data= currentData;
  const { data:dream_card_detail_data, error, isLoading } = useQuery('dream_card_detail', dream_card_detail);

  console.log(dream_card_detail_data)
  const handlePrevClick = () => {
    const currentIndex = allData.findIndex(d => d.id === currentData.id);
    if (currentIndex > 0) {
      setCurrentData(allData[currentIndex - 1]);
      setPrevData(currentIndex > 1 ? allData[currentIndex - 2] : null);
      setNextData(allData[currentIndex]);
    }
  };

  const handleNextClick = () => {
    const currentIndex = allData.findIndex(d => d.id === currentData.id);
    if (currentIndex < allData.length - 1) {
      setCurrentData(allData[currentIndex + 1]);
      setPrevData(allData[currentIndex]);
      setNextData(currentIndex < allData.length - 2 ? allData[currentIndex + 2] : null);
    }
  };




  return(
    <Component>
      
      <PathSection>홈 &gt; 충주(Culture) &gt; {selectedCategory}</PathSection>

      <Title>{selectedCategory}</Title>
      <SubTitle>모든 사람들이 참여하고 즐기는 충주의 로컬 문화와 활동을 확인해보세요!</SubTitle>

      <PatternImg src={PatternImage} />

      <TitleSection>
        <TitleText>{dream_card_detail_data && dream_card_detail_data.subject}</TitleText>
        <CreatedText>{dream_card_detail_data && dream_card_detail_data.createdate.slice(0, 10)}</CreatedText>
      </TitleSection>

      <DescriptionText>{dream_card_detail_data && dream_card_detail_data.summary}</DescriptionText>

      {selectedCategory !== '자료실' && <PeriodText>기간 : {dream_card_detail_data && `${dream_card_detail_data.start_date.slice(0, 10)} ~ ${dream_card_detail_data.end_date.slice(0, 10)}`}</PeriodText>}

      <MainText selectedCategory={selectedCategory} dangerouslySetInnerHTML={{ __html: dream_card_detail_data && dream_card_detail_data.content.replace(/\/data\/images/g, `${PUBLISH_URL}/data/images`)}} />

      {/*<MainImg />*/}

      {/*<MainText>{currentData.maidText}</MainText>*/}

      <PreviewSection>
        <PreviewIconImg src={PreviewImage}/>
        <PrevviewText>미리보기</PrevviewText>
        {dream_card_detail_data && dream_card_detail_data.pdf_files.map((data) => (
          <DownloadContent target="_blank" rel="noopener noreferrer" href={data.url}>{data.name}</DownloadContent>
        ))}
      </PreviewSection>

      <DownloadSection>
        <DownloadImg src={DownloadImage}/>
        <DownloadText>첨부파일</DownloadText>
        {dream_card_detail_data && dream_card_detail_data.files.map((data) => (
          <DownloadContent href={`${PUBLISH_URL}${data.url}`}>{data.name}</DownloadContent>
        ))}
      </DownloadSection>

      <BottomSection>
          <PrevListSection prevData={prevData} onClick={handlePrevClick}>
            <PrevIconImg src={PagePrevIcon}/>
            <PrevText>이전 글</PrevText>
          </PrevListSection>

        <Link to={'/dreamCityDetail'}>
          <ViewListSection>
            <ViewListButton src={ViewListButtonImage}/>
            <ViewListText>목록 보기</ViewListText>
          </ViewListSection>
        </Link>

          <NextListSection nextData={nextData} onClick={handleNextClick}>
            <NextText>다음 글</NextText>
            <NextIconImg src={PageNextIcon}/>
          </NextListSection>
      </BottomSection>


    </Component>
  )
}

const Component = styled.div`
  width: 1436px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
  @media (max-width: 1920px) {
    width: 74.79vw;
  }
`

const PathSection = styled.div`
  width: 100%;
  margin-top: 33px;
  margin-bottom: 42px;
  font-size: 18px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    margin-top: 1.72vw;
    margin-bottom: 2.19vw;
    font-size: 0.94vw;
  }
`

const Title = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  margin-bottom: 21px;

  @media (max-width: 1920px) {
    font-size: 2.08vw;
    font-family: 'SCDream7', serif;
    margin-bottom: 1.09vw;
  }
`

const SubTitle = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-bottom: 121px;

  @media (max-width: 1920px) {
    font-size: 1.15vw;
    margin-bottom: 6.3vw;
  }
`

const PatternImg = styled.img`
  position: absolute;
  left: 10px;
  top: 222px;
  z-index: 0;
  width: 378px;
  height: 87px;

  @media (max-width: 1920px) {
    left: 0.52vw;
    top: 12.66vw;
    z-index: 0;
    width: 19.69vw;
    height: 4.53vw;
  }
`

const TitleSection = styled.div`
  border-top: 4px solid black;
  border-bottom: 3px solid black;
  width: 1434px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  height: 59px;
  align-items: center;
  z-index: 1;
  position: relative;

  @media (max-width: 1920px) {
    border-top: 0.21vw solid black;
    border-bottom: 0.16vw solid black;
    width: 74.76vw;
    display: flex;
    justify-content: space-between;
    padding: 0 0.26vw;
    height: 3.07vw;
  }
`

const TitleText = styled.div`
  font-size: 22px;
  font-family: 'SCDream6', serif;

  @media (max-width: 1920px) {
    font-size: 1.15vw;
  }
`

const CreatedText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
  }
`

const DescriptionText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  width: 1434px;
  height: 55px;
  border-bottom: 3px solid #959595;
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: #333333;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream5', serif;
    width: 74.76vw;
    height: 2.86vw;
    border-bottom: 0.16vw solid #959595;
    display: flex;
    align-items: center;
    padding: 0 0.26vw;
  }
`

const PeriodText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  width: 1434px;
  height: 56px;
  border-bottom: 2px solid black;
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: #333333;
  margin-bottom: 41px;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream5', serif;
    width: 74.76vw;
    height: 2.92vw;
    border-bottom: 0.1vw solid black;
    display: flex;
    align-items: center;
    padding: 0 0.26vw;
    color: #333333;
    margin-bottom: 2.14vw;
  }
`

const MainText = styled.div`
  font-size: 18px;
  font-family: 'SCDream4', serif;
  width: 1436px;
  padding: 0 5px;
  margin-top: ${({selectedCategory}) => selectedCategory === '자료실' ? '41px' : ''};

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream4', serif;
    width: 74.79vw;
    padding: 0 0.26vw;
    margin-top: ${({selectedCategory}) => selectedCategory === '자료실' ? '2.14vw' : ''};
  }
`

const MainImg = styled.div`
  width: 1434px;
  height: 668px;
  background-color: #959595;
  margin-top: 25px;
  margin-bottom: 24px;

  @media (max-width: 1920px) {
    width: 74.76vw;
    height: 34.79vw;
    background-color: #959595;
    margin-top: 1.3vw;
    margin-bottom: 1.25vw;
  }
`

const Youtube = styled.div`
  width: 1434px;
  height: 668px;
  margin-top: 23px;

  @media (max-width: 1920px) {
    width: 74.76vw;
    height: 34.79vw;
    margin-top: 1.2vw;
  }
`

const PreviewSection = styled.div`
  display: flex;
  border-top: 3px solid black;
  border-bottom: 3px solid #959595;
  height: 55px;
  width: 1436px;
  padding: 0 5px;
  align-items: center;
  margin-top: 82px;

  @media (max-width: 1920px) {
    border-top: 0.16vw solid black;
    border-bottom: 0.16vw solid #959595;
    height: 2.86vw;
    width: 74.79vw;
    padding: 0 0.26vw;
    align-items: center;
    margin-top: 4.27vw;
  }
`

const PreviewIconImg = styled.img`
  width: 30px;
  height: 35px;

  @media (max-width: 1920px) {
    width: 1.56vw;
    height: 1.82vw;
  }
`

const PrevviewText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-left: 31px;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream5', serif;
    margin-left: 1.62vw;
  }
`

const DownloadSection = styled.div`
  display: flex;
  border-bottom: 3px solid black;
  height: 55px;
  width: 1436px;
  padding: 0 8px;
  align-items: center;

  @media (max-width: 1920px) {
    border-bottom: 0.16vw solid black;
    height: 2.86vw;
    width: 74.79vw;
    padding: 0 0.42vw;
  }
`

const DownloadImg = styled.img`
  width: 27px;
  height: 27px;

  @media (max-width: 1920px) {
    width: 1.41vw;
    height: 1.41vw;
  }
`

const DownloadText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-left: 32px;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
    font-family: 'SCDream5', serif;
    margin-left: 1.67vw;
  }
`

const DownloadContent = styled.a`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-left: 53px;
  color: #58595b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;

  @media (max-width: 1920px) {
    width: 20vw;
    font-size: 0.94vw;
    font-family: 'SCDream5', serif;
    margin-left: 2.76vw;
  }
`

const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1436px;
  margin-top: 80px;
  margin-bottom: 84px;

  @media (max-width: 1920px) {
    width: 74.79vw;
    margin-top: 4.17vw;
    margin-bottom: 4.38vw;
  }
`

const PrevListSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.prevData ? 1 : 0};
  pointer-events: ${props => props.prevData ? 'auto' : 'none'};
  
`

const PrevIconImg = styled.img`
  width: 14px;
  height: 16px;
  margin-right: 25px;
  margin-bottom: 3px;

  @media (max-width: 1920px) {
    width: 0.73vw;
    height: 0.83vw;
    margin-right: 1.3vw;
    margin-bottom: 0.16vw;
  }
`

const PrevText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
  }
`

const ViewListSection = styled.div`
  position: relative;
  cursor: pointer;
`


const ViewListButton = styled.img`
  width: 295px;
  height: 46px;

  @media (max-width: 1920px) {
    width: 15.36vw;
    height: 2.4vw;
  }
`

const ViewListText = styled.div`
  font-size: 21.5px;
  font-family: 'SCDream5', serif;
  position: absolute;
  top: 13px;
  left: 105px;

  @media (max-width: 1920px) {
    font-size: 1.12vw;
    font-family: 'SCDream5', serif;
    position: absolute;
    top: 0.68vw;
    left: 5.47vw;
  }
`

const NextListSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.nextData ? 1 : 0};
  pointer-events: ${props => props.nextData ? 'auto' : 'none'};
`

const NextText = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    font-size: 0.94vw;
  }
`

const NextIconImg = styled.img`
  width: 14px;
  height: 16px;
  margin-left: 25px;
  margin-bottom: 3px;

  @media (max-width: 1920px) {
    width: 0.73vw;
    height: 0.83vw;
    margin-left: 1.3vw;
    margin-bottom: 0.16vw;
  }
`