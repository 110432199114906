export const qryToString= (params)=> {
  console.log(params)
  if( !params.queryKey?.[1] ) return '';
  const { currentPage, search, process, id }= params.queryKey[1];
  const idParam= id || '';
  const currentPageParam= currentPage || '';
  const searchParam= search || '';
  const processParam= process || '';
  const str= `${idParam}?${currentPageParam}&${searchParam}&${processParam}`
  return str;
}