import {Routes, Route, useLocation, useNavigate } from "react-router-dom";
import React, {useEffect} from "react";

// Pages
import MainHome from "./pages/MainHome";
import TotalSearch from "./pages/TotalSearch";
import ConsultationRequest from "./pages/ConsultationRequest";
import {DreamCity} from "./pages/DreamCity/DreamCity";
import {About, Intro} from "./pages/Local";
import Region from "./pages/Local/intro/region";
import City from "./pages/Culture/city/City";
import Choongjuro from "./pages/Life/choongjuro/Choongjuro";
import Community from "./pages/Life/community";
import Event from "./pages/Life/event";
import People from "./pages/People";
import CultureOurCity from "./pages/Culture/our-city";
import CultureOurCityDetail from "./pages/Culture/our-city/Detail";
import LifeCommunityDetail from "./pages/Life/community/Detail";
import PeopleDetail from "./pages/People/Detail";


// import CommunityDetail from "./blocks/Community/Detail";
import BBSDetail from "./blocks/_bbs/Detail";


import CultureMap from "./pages/CultureMap";
// import {DreamCityDetail} from "./pages/DreamCityDetail/DreamCityDetail";
import Notice from "./pages/Notice";
// import NoticeDetail from "./pages/NoticeDetail";
// import DreamCityCardDetail from "./pages/DreamCityCardDetail";
import Include from "./pages/_Include";
import Wrapper from "./pages/_Include/Wrapper";
import Boards from "./pages/_Boards";
// import Detail from "./pages/_Boards/Detail";
import { info as communityInfo, queries as communityQueries } from "./pages/Life/community/info";
import { info as cultureInfo, queries as cultureQueries } from "./pages/Culture/our-city/info";
import { info as peopleInfo, queries as peopleQueries } from "./pages/People/info";
import { info as noticeInfo, queries as noticeQueries } from "./pages/Notice/info";
import PrivatePolicy from "./pages/Policy/Private";
import CopyrightPolicy from "./pages/Policy/Copyright";
import PolicyBottom from "./pages/Policy/Bottom";


import {useRecoilState} from "recoil";
import {totalSearchInfoState} from "../recoil/LayoutRecoil";
import useRouteInfo from './Route';

// import BBS from "./blocks/_bbs";


/* export const totalSearchInfoState = atom({
  key: 'totalSearchInfoState',
  default: [...communityInfo, ...peopleInfo, 
    ...cultureInfo
  ]
}) */


const titles = {
  '/': '홈',
  '/about': 'About',
}

function App() {
  const routeInfo = useRouteInfo();
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  
  useEffect(() => {
    
    window.scrollTo(0, 0);
    const info= routeInfo.find(o=> o.path == location.pathname );
    // console.log(navigate, location,info)
    document.title = info ? (info?.title ? `환승이주 : ${info?.title}` : '환승이주') : '환승이주'
    // document.title = titles[location.pathname] ?? 'Hello World'
  }, [pathname]);



  return (
    <>
      <Routes>
        <Route exact={true} path={''} element={<Include pathname={pathname}/>}>
          {
            routeInfo.map(route=> {
              const {path, element, title, policy}= route;
              // const Component= element;
              const routeInfo= { path, title, policy }
              return (
                <Route key={path} exact={true} path={path} element={
                  // <Component.type {...Component.props} routeInfo={routeInfo} />
                  <Wrapper component={element} routeInfo={routeInfo}/>
                }/>
              )
            })
          }
        </Route>
      </Routes>
    </>
  );
}

export default App;
