import React, {useEffect} from "react";
import styled from "styled-components";
import {ShareHeader} from "../../blocks/layouts/ShareHeader";
import {ShareFooter} from "../../blocks/layouts/ShareFooter";
import {ShareCategory} from "../../blocks/layouts/ShareCategory";
import {useRecoilValue} from "recoil";
import {MobileShowCategory, MobileShowSearchResult, ShowCategory, ShowSearchResult} from "../../../recoil/LayoutRecoil";
import {ShareSearch} from "../../blocks/layouts/ShareSearch";
import {useMediaQuery} from "react-responsive";
import {MobileShareHeader} from "../../mobile/blocks/layouts/MobileShareHeader";
import {MobileShareFooter} from "../../mobile/blocks/layouts/MobileShareFooter";
import {MobileShareCategory} from "../../mobile/blocks/layouts/MobileShareCategory";
import {MobileShareSearch} from "../../mobile/blocks/layouts/MobileShareSearch";
import { useLocation } from 'react-router-dom';
import {DreamCityCardDetailContents} from "../../blocks/DreamCityCardDetail/DreamCityCardDetailContents";
import {
  MobileDreamCityCardDetailContents
} from "../../mobile/blocks/DreamCityCardDetail/MobileDreamCityCardDetailContents";

import {DetailPage} from "./DetailPage";
import {useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";

import qs from "qs";

export default function Detail ({ info, queries }) {

  const location = useLocation();
  const params = useParams();
  const { id: key, key: id }= params;
  let [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');
  const infoData= info.find(o=> o.id == key);
  // const { title, path, subject, key: qryKey }= infoData;

  const query= queries?.[key]?.query;
  const reqParam= {
    id: `/${id}`,
    // ...location?.state?.reqParam
  }
  console.log(id, key, queries, query)
  const { data, error, isLoading, refetch } = useQuery([key, 
    reqParam
  ], 
  query,);
  console.log(data)
  // const selectedCategory = location?.state?.selectedCategory;


  return (
    <>
    {
      data &&
      <DetailPage data={data} selectedCategory={infoData.name} infoData={infoData} type={type} />
    }
    
    {/* <Container>

      {isMobile ? <MobileShareHeader/> : <ShareHeader/>}

      <HeaderLine/>

      {ShowCategoryState ? <ShareCategory/> : <></>}

      {ShowSearchResultState ? <ShareSearch/> : <></>}

      {MobileShowSearchResultState ? <MobileShareSearch/> : <></>}

      {MobileShowCategoryState ? <MobileShareCategory/> : <></>}

      {isMobile ? <MobileDreamCityCardDetailContents data={data} allData={allData} selectedCategory={selectedCategory}/> : <DetailPage data={data} allData={allData} selectedCategory={selectedCategory} infoData={infoData}/>}

      {isMobile ? <MobileShareFooter/> : <ShareFooter/>}

    </Container> */}

    </>
    
  )
}