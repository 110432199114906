import styled from "styled-components";
import {Title, SubTitle} from '../../../style/comp/comm';
import TopBgIcon from "../../../media/images/Comm/top-icon-2.svg";


export function TitleSection ({ path, title, subtitle }) {
  return (
    <>
      <PathSection>{path}</PathSection>

      <Title>{title}</Title>
      <SubTitle className="p-x10 txt-keep">{subtitle}</SubTitle>
      
    </>
  )
}



const PathSection = styled.div`
  width: 100%;
  margin-top: 33px;
  margin-bottom: 42px;
  font-size: 14px;
  font-family: 'SCDream5', serif;

  @media (max-width: 800px) {
    font-size: 1em;
  }
`





const ImgSize=styled.div`
  width: 100%; max-width: 150px;
  @media (max-width: 800px) {
    max-width: 80px;
  }
`
const TopImgStyle= styled.div`
  position: relative;
  margin-top: -80px;
  /* top: 0; right: 0; */
  @media (max-width: 800px) {
    margin-top: -40px;
  }
`
const TopBgStyle= styled.img`
  width: 100%;
  position: relative;
  bottom: -40px; right: 0;
`

export const BoardTopImg= ({icon})=> {
  return (
    <TopImgStyle className="inst-box w100 flx-cr oflow-h">
      <ImgSize className="w100 inst-box">
        <TopBgStyle src={TopBgIcon} alt="" />
        {icon}
      </ImgSize>
    </TopImgStyle>
  )
}