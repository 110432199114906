import {useState} from 'react';
import {BodyText1, BodyText2, TinyText1, Divider, Divider1_1,}from '../../../../style/comp/comm';
import styled from "styled-components";
import {Title} from '../../../../style/comp/comm';
import Img1 from "../../../../media/images/Life/img-1.png";
import Img2 from "../../../../media/images/Life/img-2.png";
import Img3 from "../../../../media/images/Life/img-3.png";
import Img4 from "../../../../media/images/Life/img-4.png";
import Arrow2 from "../../../../media/images/Comm/arrow-2.svg";

import { Link } from 'react-router-dom';

const info= [
  {
    id: '1',
    title: '01. 귀농/귀촌 고민하기',
    subject: (
      <>
      <p className="txt-keep">
        <BodyText1>귀농/귀촌을 고민하고 있는 단계에서 충주의 전문가와의 상담을 통해 고민을 해결 할 수 있습니다. <br></br> <br></br>

        이주하고 싶은 지역의 이야기를 들어보고 싶은 분은 아래의 ‘상담신청’ 페이지를 통해 상담 진행 부탁 드립니다.
        </BodyText1>
      </p>
      
      </>
    ),
    image: Img1,
    link: {
      txt: '상담신청 바로가기',
      url: '/consultaionRequest'
    }
  },
  {
    id: '2',
    title: '02. 귀농/귀촌 정보 수집하기',
    subject: (
      <>
      <p className="txt-keep">
        <BodyText1>이벤트 페이지를 통해 선배 귀농/귀촌인들이 어떻게 이주했는지,<br></br>
        이주하면서 변화된 삶의 모습과 생각보다 힘들었던 점 등 <br></br>
        귀농/귀촌 선배들의 이야기를 찾아보세요.
        </BodyText1>
      </p>
      </>
    ),
    image: Img2,
    link: {
      txt: '이벤트 바로가기',
      url: '/life/events'
    }
  },
  {
    id: '3',
    title: '03. 지역 방문 및 영농체험하기',
    subject: (
      <>
      <p className="txt-keep">
        <BodyText1>
        귀농/귀촌하고 싶은 지역은 어디신가요?<br></br>
        충주의 어떤 것들을 알고 싶으신가요?<br></br><br></br>

        충주 전문가와의 상담을 통해 <br></br>
        이러한 정보를 얻으실 수 있습니다.
        </BodyText1>
      </p>
      </>
    ),
    image: Img3,
    link: {
      txt: '상담신청 바로가기',
      url: '/consultaionRequest'
    }
  },
  {
    id: '4',
    title: '04. 정착지 물색하기',
    subject: (
      <>
      <p className="txt-keep">
        <BodyText1>
        취업박람회, 귀농인 정착교육, 충주에서 살아보기 지원 등 <br></br>
        충주로의 환승이주에는 여러 방법이 있습니다. <br></br><br></br>

        충주시에서 지원해주는 여러가지 지원 정책들을 살펴보고 
        정착지를 물색해 보세요. 

        </BodyText1>
      </p>
      </>
    ),
    image: Img4,
    link: {
      txt: '충주시청 귀농/귀촌 지원 홈페이지 바로가기',
      url: 'https://www.chungju.go.kr/www/contents.do?key=3590',
      target: '_blank'
    }
  },
];

export default function CityBottom () {
  const [drop, setDrop]= useState('');
  const handlerDrop= (area)=> (e)=> {
    if( drop ==  area ) {
      setDrop('');
      return;
    }
    setDrop(area);
  }
  return (
    <div className='m-t60'>


      <div className="m-t60 p-x20 m-pd-s10">
        {/* <Title>함께하는 사람들 </Title>
        <p className="txt-center m-t5">
          <BodyText2 className='m-t10'>다양한 사람들이 함께 문화도시 충주를 이야기하고 만듭니다. </BodyText2>
        </p> */}


        {
          info.map(val=> {
            const { id, title, subject, image, link }= val;
            const { txt, url, target }= link;
            // const value= drop == id;
            
            return (
              <div key={id} className="m-t40">
                <p className="">
                  <BodyText2 className='ft-b '>{title}</BodyText2>
                </p>
                <div key={id} className="flx-0 m-flx-col m-t15">
                  <div className="w50 m-w100 inst-box bd-rd-30 oflow-h">
                    <BorderGradient className='bg-cover-area'></BorderGradient>
                    <img src={image} alt="" className="block-box w100" />
                  </div>
                  <BtnLinkWrap className="w50 m-w100 p-l20 m-pd-s0 p-y15 flx-col-sb p-r60" >
                    
                    <div className="lh-15">{subject}</div>

                    <div className="m-w100 inst-box bd-rd-30 oflow-h m-t10">
                      <BorderGradient1 className='bg-cover-area'></BorderGradient1>
                      <Link to={url} target={target} className='inst-box'>
                        <div className="p-y7 p-x5 txt-center flx-sbc p-x20">
                          <BodyText1 className='ft-b flx-itm-r '>{txt}</BodyText1>
                          <ArrowImg2 src={Arrow2} alt="" className='m-l10 m-t1' />
                        </div>
                      </Link>
                      
                    </div>
                    
                  </BtnLinkWrap>
                </div>
              </div>
            )
          })
        }
        



      </div>
    </div>
  )
}

const BtnLinkWrap= styled.div`
  align-items: flex-start;
  @media (max-width: 800px) {
    align-items: stretch;
  }
`
const ArrowImg2= styled.img`
  width: 16px;
  @media (max-width: 800px) {
    width: 12px;
  }
`

const BorderGradient= styled.div`
  border-radius: 30px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(90deg,#ddd,#ddd) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /*4*/
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
`

const BorderGradient1= styled.div`
  border-radius: 30px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(90deg,#e9e223,#92caf0) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /*4*/
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
`
/* const BorderGradient1= styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  margin: 10px;
  border: 3px solid transparent;
  border-radius: 10%;
  background-image: linear-gradient(#fff, #fff), 
  linear-gradient(to right, red 0%,  orange 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
` */


