import React from "react";
import styled from "styled-components";
import FourthCardImage from "../../../../media/images/DreamCity/fourthCardImage.webp";
import GradientTextImage from "../../../../media/images/DreamCity/thirdCardText.webp";
import ThirdImage from "../../../../media/images/DreamCity/thirdCardImage.webp";
import QuoteIconImage from "../../../../media/images/DreamCity/quoteIcon.webp";
import HillImage from "../../../../media/mobileImage/DreamCity/mobileDreamCitiHillImage.webp";
import {useSetRecoilState} from "recoil";
import {DreamCityDetailCategory} from "../../../../recoil/LayoutRecoil";
import {Link} from "react-router-dom";

export const MobileDreamCityThirdCard = () => {
  const setDreamCityDetailCategory = useSetRecoilState(DreamCityDetailCategory)

  return (
    <Components>

      <ThirdCardContents>
        <QuoteIcon src={QuoteIconImage}/>

        <TextSection>
          <TopSection>
            <Title>우리답게</Title>
            <SubTitle>{`누구나 즐기는 로컬 문화를 만들고자
문화실험과 시범사업을 진행하고
횡단적 협업과 실험을 시도합니다.`}</SubTitle>
            <GradientText src={GradientTextImage}/>
          </TopSection>

          <TextLine/>

          <BottomSection>
            <BizText>{`<주요사업>
[문화실험 및 시범사업] 충주형 문화리빙랩1 "라운드테이블와유키키"
[문화실험 및 시범사업] 충주형 문화리빙랩2 "문화실험해유키키"
[문화실험 및 시범사업] 주민주도 참여예산제 "다 해봐 ~ 충주C"
[횡단적 협업과 실험] 유휴공간 문화실험 "별별 유원지"
[횡단적 협업과 실험] 타 부처 사업간 횡단적 협업 "온천살롱"`}</BizText>
            <Link to="/dreamCityDetail" style={{textDecoration: 'none'}}
                  onClick={() => {
                    setDreamCityDetailCategory('우리답게')
                  }}>
              <DetailText>자세히 보기</DetailText>
            </Link>
          </BottomSection>
        </TextSection>

        <ThirdImg src={ThirdImage}/>
      </ThirdCardContents>

      <FourthCardSection>
        <FourthCardImg src={FourthCardImage}/>
        <Link to="/dreamCityDetail" style={{textDecoration: 'none'}}
              onClick={() => {
                setDreamCityDetailCategory('충격의 로컬크리에이터')
              }}>
          <FourthCardDetail/>
        </Link>
      </FourthCardSection>

      <HillImg src={HillImage}/>

    </Components>
  )
}

const Components = styled.div`
  width: 100vw;
  position: relative;
  margin-top: 6.93vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4.07vw;
`

const ThirdCardContents = styled.div`
  width: 92vw;
  height: 128.62vw;
  background-color: white;
  border-radius: 2.72vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

`

const FourthCardSection = styled.div`
  position: relative;
  margin-top: 6.68vw;
  display: flex;
  width: 100vw;
  justify-content: center;
`

const FourthCardImg = styled.img`
  width: 83.53vw;
  height: 124.14vw;
  z-index: 1;
`

const FourthCardDetail = styled.div`
  position: absolute;
  width: 10.6vw;
  height: 2.44vw;
  background-color: transparent;
  bottom: 48.52vw;
  left: 21.61vw;
  cursor: pointer;
  z-index: 100;
`

const TextSection = styled.div`
  margin-left: 4.72vw;
`

const QuoteIcon = styled.img`
  width: 8.07vw;
  height: 6.36vw;
  position: absolute;
  top: -4.89vw;
  left: 8.24vw;
  z-index: 2;

`

const TopSection = styled.div`
  margin-top: 5.78vw;
`

const BottomSection = styled.div`
`

const Title = styled.div`
  font-size: 4.89vw;
  font-family: 'SCDream8', serif;
  margin-bottom: 3.26vw;
`

const SubTitle = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 2.44vw;
  white-space: pre-line;
  margin-bottom: 3.59vw;
`

const GradientText = styled.img`
  width: 26.92vw;
  height: 2.53vw;
`

const TextLine = styled.div`
  border-bottom: 2px solid black;
  width: 72.91vw;
  margin-top: 3.83vw;
  margin-bottom: 2.94vw;
`

const BizText = styled.div`
  font-family: 'SCDream4', serif;
  font-size: 1.96vw;
  color: #808080;
  white-space: pre-line;
  line-height: 3.43vw;
`

const DetailText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 2.45vw;
  margin-top: 2.77vw;
  text-decoration: underline;
  display: flex;
  align-items: end;
  cursor: pointer;
`

const ThirdImg = styled.img`
  width: 92vw;
  height: 61.16vw;
`

const HillImg = styled.img`
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100vw;
  height: 14.22vw;
`

