// import {useRef} from 'react';
import {useParams} from "react-router-dom";
import {info} from '../info';
import styled from "styled-components";
import {TitleSection} from '../../../_Include/Title';
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../../../style/comp/comm';
import ArrowLeft from "../../../../../media/images/Comm/arrow-left.svg";
import TopIcon1 from "../../../../../media/images/Comm/top-icon-1.svg";
import Icon1 from "../../../../../media/images/Local/local-icon-1.svg";
import Icon2 from "../../../../../media/images/Local/local-icon-2.svg";
import Bg1 from "../../../../../media/images/Local/bg-1.png";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination, EffectFade, Controller, Autoplay, A11y  } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// SwiperCore.use([Navigation, Pagination, EffectFade, Controller, Autoplay]);

export default function Region () {
  const params = useParams();
  const data= info.find(o=> o.id == params.id);
  console.log(params, info, data)
  if( !data ) return <></>

  const { id, title, subject, images, localIntro, localData }= data;
  const { peopleNum, houseNum, area, welfareCenter, elementarySchool, middleSchool, highSchool, hospital, facility, traffic, bottoomImg, bottoomImgSize}= localData;
  // const nextEl= useRef(null);
  // const prevEl= useRef(null);
  return (
    <div key={id} className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 충주(Local) > 지역소개 > ${title}`} 
              title={title} 
              subtitle={subject} 
            />
            
            <div className="inst-box">

              <NavWrap>
                <div className="custom-prev-btn">
                  <ArrowImg src={ArrowLeft} dir={'right'}/>
                </div>
                <div className="custom-next-btn">
                  <ArrowImg src={ArrowLeft} />
                </div>
              </NavWrap>

              <Swiper
                modules={[Navigation, Pagination, EffectFade, Controller, Autoplay, A11y]}
                loop
                autoplay={{
                  delay: 3000
                }}
                // navigation
                navigation= {{
                  nextEl: '.custom-next-btn',
                  prevEl: '.custom-prev-btn',
                }}
                /* navigation= {{
                  nextEl: nextEl.current,
                  prevEl: prevEl.current,
                }} */
                // onInit={swiper=> setFirstSwiper(true)}
                onSwiper={(swiper) => {
                  // Delay execution for the refs to be defined
                  // setTimeout(() => {
                    // Override prevEl & nextEl now that refs are defined
                    // swiper.params.navigation.prevEl = prevEl.current
                    // swiper.params.navigation.nextEl = nextEl.current

                    // Re-init navigation
                    // swiper.navigation.destroy()
                    // swiper.navigation.init()
                    // swiper.navigation.update()
                  // })
                  // setFirstSwiper(true)
                }}
                // freeMode
                spaceBetween={5}
                slidesPerView={1}
                breakpoints={{
                  960: {
                    slidesPerView: 1,
                    spaceBetween: 5
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 5
                  },
                  
                }}
                className="bd-rd-30 m-bd-rd-10 m-t40 m-mg-t30"
              >

                {
                  images && images.map(img=> {
                    return (
                      <SwiperSlide key={img}>
                        <div className="flx-cc img40 m-img70" >
                          <SlideImg src={img} alt="" />
                        </div>
                      </SwiperSlide>
                    )
                  })
                }
                
                
              </Swiper>


            </div>

            <div className="m-t80 m-mg-t40 inst-box ">
              <SectTitleBox style={{ color: '#5b9dc9'}}>{title}</SectTitleBox>

              <div className="inst-box ">
                <TopIcon src={TopIcon1} />
                <Divider className="inst-box m-t10"/>

                <div className="inst-box bg-clr-wh">

                  {
                    localIntro && 
                    <>
                    <div className="p-20 m-pd-15">
                      <p className="inst-box p-l10">
                        <DotTitle1 className="ft-eb">지역소개</DotTitle1>
                      </p>

                      <p className="lh-18 ft-md p-l10 p-r40 m-pd-s10 m-t5 txt-keep">
                        <BodyText1>{localIntro}</BodyText1>
                      </p>
                    </div>
                    <Divider2/>
                    </>
                  }
                  

                  <div className="p-20 m-pd-15">
                    <p className="inst-box p-l10">
                      <DotTitle1 className="ft-eb">지역 데이터</DotTitle1>
                    </p>

                    <div className="txt-keep m-t15">


                      <div className="bd-tp-bk2 flx-0">
                        <div className="w25 bg-clr-gr ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>구분</BodyText1>
                        </div>
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>계</BodyText1>
                        </div>
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>남</BodyText1>
                        </div>
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk">
                          <BodyText1>여</BodyText1>
                        </div>
                      </div>

                      <div className="flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>인구수</BodyText1>
                        </div>
                        <div className="w25 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>{peopleNum.total.toLocaleString()}</BodyText1>
                        </div>
                        <div className="w25 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>{peopleNum.man.toLocaleString()}</BodyText1>
                        </div>
                        <div className="w25 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk">
                          <BodyText1>{peopleNum.women.toLocaleString()}</BodyText1>
                        </div>
                      </div>


                    </div>


                    <div className="m-t5 txt-keep">

                      <div className="bd-tp-bk flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>세대수</BodyText1>
                        </div>
                        <div className="w75 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk p-x20 m-pd-s10">
                          <BodyText1>{houseNum.toLocaleString()}</BodyText1>
                        </div>
                      </div>

                      <div className="flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>면적(km2) </BodyText1>
                        </div>
                        <div className="w75 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk p-x20 m-pd-s10">
                          <BodyText1>{area.toLocaleString()}</BodyText1>
                        </div>
                      </div>

                      <div className="flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>행복복지센터 </BodyText1>
                        </div>
                        <div className="w75 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk p-x20 m-pd-s10">
                          <BodyText1>{welfareCenter}</BodyText1>
                        </div>
                      </div>

                      <div className="flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>초등학교(개소) </BodyText1>
                        </div>
                        <div className="w75 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk p-x20 m-pd-s10">
                          <BodyText1>{elementarySchool}</BodyText1>
                        </div>
                      </div>

                      <div className="flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>중학교(개소) </BodyText1>
                        </div>
                        <div className="w75 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk p-x20 m-pd-s10">
                          <BodyText1>{middleSchool}</BodyText1>
                        </div>
                      </div>

                      <div className="flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>고등학교(개소) </BodyText1>
                        </div>
                        <div className="w75 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk p-x20 m-pd-s10">
                          <BodyText1>{highSchool}</BodyText1>
                        </div>
                      </div>

                      <div className="flx-0">
                        <div className="w25 bg-clr-0 ft-md p-y5 txt-center bd-bt-bk bd-rt-bk">
                          <BodyText1>병원(개소) </BodyText1>
                        </div>
                        <div className="w75 bg-clr-wh ft-md p-y5 txt-center bd-bt-bk p-x20 m-pd-s10">
                          <BodyText1>{hospital}</BodyText1>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div className="p-20 m-pd-15">
                    <p className="inst-box p-l10 m-t10">
                      <DotTitle1 className="ft-eb">주요 시설</DotTitle1>
                    </p>

                    <ul className="m-t10 m-mg-t10">
                      {
                        facility && facility.map( (f, i)=> {
                          return (
                            <li key={i} className="inst-box p-l10 m-l10 ft-md p-y5 m-pd-h2">
                              <DotBodyText1>{f}</DotBodyText1>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>


                  <div className="p-20 m-pd-15">
                    <p className="inst-box p-l10 m-t10">
                      <DotTitle1 className="ft-eb">주요 교통</DotTitle1>
                    </p>
                    <div className="w100 mx500 m-t15 bd-bt-bk">
                      <div className="bd-tp-bk flx-0 m-flx-col">
                        <div className="w30 p-y7 flx-0c m-flx-cl m-w100">
                          <Icon1Img src={Icon1} alt="" className="m-x10" />
                          <BodyText1 className="ft-md m-x10">열차</BodyText1>
                        </div>
                        <div className="w70 m-w100 p-y7">
                          {traffic && traffic?.train.map((t,i)=> {
                            return (
                              <BodyText1 key={i} className="ft-md">{t}</BodyText1>
                            )
                          })}
                        </div>
                      </div>

                      <div className="bd-tp-bk flx-0 m-flx-col">
                        <div className="w30 p-y7 flx-0c m-flx-cl m-w100">
                          <Icon1Img src={Icon2} alt="" className="m-x10" />
                          <BodyText1 className="ft-md m-x10">자동차</BodyText1>
                        </div>
                        <div className="w70 m-w100 p-y7">
                          {traffic && traffic?.car.map((t,i)=> {
                            return (
                              // <BodyText1 key={i} className="ft-md">{t}</BodyText1>
                              <p key={i} className="inst-box p-l5 p-y2 m-pd-h2">
                                <DotBodyText1  className="ft-rg">{t}</DotBodyText1>
                              </p>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <BttomImg src={bottoomImg} $bottoomImgSize={bottoomImgSize} alt="" />
                </div>
              </div>
              
            </div>



            
          </div>
        </div>
      </Component>
      <BgImg1 className="p-y80 inst-box" />
    </div>
  )
}

const BttomImg= styled.img`
  position: absolute;
  right: 50px; bottom: 40px;
  height: ${props=> {
    let h= '200px'; 
    if( props.$bottoomImgSize ) {
      const {height}= props.$bottoomImgSize;
      h= height;
    }
    return h;
  }};
  width: auto;
  @media (max-width: 800px) {
    display: none;
  }
`

const BgImg1= styled.div`
  margin-top: -10em;
  position: relative;
  width: 100%;
  /* height: 100%; */
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(50% + 360px) 0;
`

const Icon1Img= styled.img`
  height: 18px;
  
`

const TopIcon= styled.img`
  position: absolute;
  right: 0; top: 0px;
  width: 350px;
  transform: translateY(-50%);
  @media (max-width: 800px) {
    width: 180px;
  }
`

const SlideImg= styled.img`
  position: absolute; 
  top: 0; left: 0;
  width: 100%; height: 100%;
  object-fit: cover;
`

const NavWrap = styled.div`
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0; top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 800px) {
    display: none;
  }
`
const ArrowImg= styled.img`
  /* width: calc(50% + 36px); */
  width: 32px;
  transform: ${(props) => props.dir =='right' || `rotate(180deg)`};
  @media (max-width: 800px) {
    width: 22px;
  }
`