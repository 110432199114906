import React from "react";
import styled from "styled-components";
import MenPicture from '../../../../media/images/MainHome/LiveChoongJu/menPicture.webp'
import {PUBLISH_URL} from "../../../../api/global";

export const MobileMenCard = ({data, handleContentClick}) => {

  return (
    <Container
      onClick={handleContentClick(data)}
    >

      <MenImage src={data.thumbnail === "" ? MenPicture : PUBLISH_URL + data.thumbnail}/>

      <TextBox>
        <MenTitle>{data.subject}</MenTitle>
        <CardLine/>
        <HashTagText>
          {data.tags.map((data) => (
            data + ' '
          ))}
        </HashTagText>
      </TextBox>

    </Container>
  )
}

const Container = styled.div`
  width: 93.2vw;
  height: 26.45vw;
  border-radius: 3.83vw;
  margin-bottom: 4.57vw;
  display: flex;
  background-color: white;
  cursor: pointer;
`;

const MenImage = styled.img`
  width: 22.69vw;
  height: 26.45vw;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.12vw;
`;

const MenTitle = styled.div`
  width: 60.7vw;
  font-family: 'SCDream5', serif;
  font-size: 3.14vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const CardLine = styled.div`
  border-bottom: solid 0.163vw black;
  width: 63.3vw;
  margin-top: 1.88vw;
  margin-bottom: 2.12vw;
`;

const HashTagText = styled.text`
  width: 62.9vw;
  font-size: 2.61vw;
  font-family: 'SCDream2', serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;
