import React, {useEffect} from "react";
import styled from "styled-components";
import ResponsiveContainer from "../../../hooks/ResponsiveContainer";
import {noticeData} from "../../../configs/MainHome/NoticeData";
import NoticeImage from '../../../media/images/MainHome/Notice/notice.webp'
import NoticeArrow from '../../../media/images/MainHome/Notice/noticeArrow.webp'
import {useQuery} from "react-query";
import {main_notice_list} from "../../../api/MainHome";
import {Link, useNavigate} from "react-router-dom";

export const Notice = () => {
  const { data:main_notice_list_data, error, isLoading } = useQuery('main_notice_list', main_notice_list);
  const navigate = useNavigate();

  const handleContentClick = (data) => {
    navigate(`/noticeDetail/${data.id}`, { state: { data: data, allData: main_notice_list_data.articles, selectedCategory: '공지사항' } });
  };

  console.log(main_notice_list_data)


  return (
    <ResponsiveContainer>

      <Container>
        <MainText>공지사항</MainText>
        <SubText>문화도시 충주의 새로운 소식을 지금 바로 알아보세요.</SubText>
        <Link to="/notice/notice">
          <MoreText>더 알아보기</MoreText>
        </Link>
      </Container>

      {/* 공지사항 데이터 리스트 */}
      <NoticeContainer>
        {main_notice_list_data && main_notice_list_data.articles
          .filter(article => article.event_text === "접수중")
          .sort((a, b) => new Date(b.createdate) - new Date(a.createdate))
          .slice(0, 4)
          .map((data) => (
          <>
            <NoticeLineBox onClick={() => handleContentClick(data)}>
              <NoticeHead>
                <NoticeCategory src={NoticeImage}/>
                <NoticeState>{data.event_text}</NoticeState>
                <NoticeTitle>{data.subject}</NoticeTitle>
              </NoticeHead>

              <NoticeTail>
                <NoticeDate>{data.createdate.slice(0, 10)}</NoticeDate>
                <NoticeArrowImage src={NoticeArrow}/>
              </NoticeTail>
            </NoticeLineBox>

            <RegularLine/>
          </>
        ))}
      </NoticeContainer>

    </ResponsiveContainer>
  )
}

const Container = styled.div`
  width: 1432px;

  @media (max-width: 1920px) {
    width: 74.5833vw;

  }
`

const MainText = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  margin-bottom: 19px;

  @media (max-width: 1920px) {
    font-size: 2.0833vw;
    margin-bottom: 0.9896vw;
  }
`

const SubText = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  margin-bottom: 13px;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
    margin-bottom: 0.6771vw;
  }
`

const MoreText = styled.text`
  color: #939598;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
  }
`

const NoticeContainer = styled.div`
  border-bottom: 4px solid black;
  border-top: 4px solid black;;
  width: 1432px;
  margin-top: 39px;
  margin-bottom: 114px;
  background-color: white;

  @media (max-width: 1920px) {
    border-bottom: 0.2083vw solid black;
    border-top: 0.2083vw solid black;
    width: 74.5833vw;
    margin-top: 2.0313vw;
    margin-bottom: 5.9375vw;
  }
`

const RegularLine = styled.div`
  border: 2px solid black;
  width: 100%;

  &:last-child {
    border: 0;
  }

  @media (max-width: 1920px) {
    border: 0.1042vw solid black;
  }
`

const NoticeLineBox = styled.div`
  display: flex;
  padding: 30px 0;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 1920px) {
    padding: 1.5625vw 0;
  }
`

const NoticeHead = styled.div`
  display: flex;
  align-items: center;
`

const NoticeTail = styled.div`
  display: flex;
  align-items: center;
`

const NoticeCategory = styled.img`
  margin-right: 49px;
  margin-left: 15px;
  width: 73px;
  height: 34px;

  @media (max-width: 1920px) {
    margin-right: 2.5521vw;
    margin-left: 0.7813vw;
    width: 3.8021vw;
    height: 1.7708vw;
  }
`

const NoticeState = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-right: 57px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin-right: 2.9688vw;
  }
`

const NoticeTitle = styled.div`
  font-size: 22px;
  font-family: 'SCDream5', serif;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
  }
`

const NoticeDate = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
  }
`

const NoticeArrowImage = styled.img`
  padding-bottom: 7px;
  margin-left: 45px;
  margin-right: 29px;
  width: 21px;

  @media (max-width: 1920px) {
    padding-bottom: 0.3646vw;
    margin-left: 2.3438vw;
    margin-right: 1.5104vw;
    width: 1.0938vw;
  }
`