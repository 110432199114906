import {Routes, Route, useLocation} from "react-router-dom";
import React, {useEffect} from "react";

// Pages
import MainHome from "./pages/MainHome";
import TotalSearch from "./pages/TotalSearch";
import ConsultationRequest from "./pages/ConsultationRequest";
import {DreamCity} from "./pages/DreamCity/DreamCity";
import {About, Intro} from "./pages/Local";
import Region from "./pages/Local/intro/region";
import City from "./pages/Culture/city/City";
import Choongjuro from "./pages/Life/choongjuro/Choongjuro";
import Community from "./pages/Life/community";
import Event from "./pages/Life/event";
import People from "./pages/People";
import CultureOurCity from "./pages/Culture/our-city";
import CultureOurCityDetail from "./pages/Culture/our-city/Detail";
import LifeCommunityDetail from "./pages/Life/community/Detail";
import PeopleDetail from "./pages/People/Detail";


// import CommunityDetail from "./blocks/Community/Detail";
import BBSDetail from "./blocks/_bbs/Detail";


import CultureMap from "./pages/CultureMap";
// import {DreamCityDetail} from "./pages/DreamCityDetail/DreamCityDetail";
import Notice from "./pages/Notice";
// import NoticeDetail from "./pages/NoticeDetail";
// import DreamCityCardDetail from "./pages/DreamCityCardDetail";
import Include from "./pages/_Include";
import Boards from "./pages/_Boards";
// import Detail from "./pages/_Boards/Detail";
import { info as communityInfo, queries as communityQueries } from "./pages/Life/community/info";
import { info as cultureInfo, queries as cultureQueries } from "./pages/Culture/our-city/info";
import { info as peopleInfo, queries as peopleQueries } from "./pages/People/info";
import { info as noticeInfo, queries as noticeQueries } from "./pages/Notice/info";
import { info as eventInfo, queries as eventQueries } from "./pages/Life/event/info";
import PrivatePolicy from "./pages/Policy/Private";
import CopyrightPolicy from "./pages/Policy/Copyright";
import PolicyBottom from "./pages/Policy/Bottom";


import {useRecoilState} from "recoil";
import {totalSearchInfoState} from "../recoil/LayoutRecoil";


export default function useRouteInfo () {
  const [totalSearchInfo, setTotalSearchInfo] = useRecoilState(totalSearchInfoState);
  return [
    { path: '/', element: <MainHome/>, title: '충주로' },
    { path: '/local/about', element: <About/>, title: '충주에 대해서', policy: <PolicyBottom type={'type2'} /> },
    { path: '/local/intro', element: <Intro/>, title: '지역소개', policy: <PolicyBottom type={'type2'} /> },
    { path: '/local/intro/:id', element: <Region/>, title: '지역소개', policy: <PolicyBottom type={'type2'} /> },
    { path: '/culture/city', element: <City />, title: '문화도시 충주', policy: <PolicyBottom type={'type2'} /> },
    { path: '/life/choongjuro', element: <Choongjuro />, title: '충주로', policy: <PolicyBottom type={'type1'} /> },
    { path: '/life/community/:id', element: <Community info={communityInfo} queries={communityQueries}/>, title: '커뮤니티 사업', policy: <PolicyBottom type={'type1'} /> },
    { path: '/life/events', element: <Event info={eventInfo} queries={eventQueries}/>, title: '이벤트', policy: <PolicyBottom type={'type1'} /> },
    { path: '/life/:id/detail/:key/', element: <Boards info={eventInfo} queries={eventQueries} detail={true}/>, title: '', policy: <PolicyBottom type={'type2'} /> },
    { path: '/people/:id', element: <People info={peopleInfo} queries={peopleQueries}/>, title: '충주에 살아보면', policy: <PolicyBottom type={'type1'} /> },
    { path: '/culture/:id', element: <Boards info={cultureInfo} queries={cultureQueries}/>, title: '문화도시 충주', policy: <PolicyBottom type={'type2'} /> },
    { path: '/consultaionRequest', element: <ConsultationRequest/>, title: '상담신청' },
    { path: '/culture/:id/detail/:key/', element: <Boards info={cultureInfo} queries={cultureQueries} detail={true}/>, title: '', policy: <PolicyBottom type={'type2'} /> },
    { path: '/life/community/:id/detail/:key/', element: <Boards info={communityInfo} queries={communityQueries} detail={true}/>, title: '커뮤니티 사업', policy: <PolicyBottom type={'type1'} /> },
    { path: '/people/:id/detail/:key/', element: <Boards info={peopleInfo} queries={peopleQueries} detail={true}/>, title: '충주에 살아보면', policy: <PolicyBottom type={'type1'} /> },
    { path: '/totalSearch', element: <TotalSearch info={totalSearchInfo} />, title: '통합검색' },
    { path: '/notice/:id', element: <Notice info={noticeInfo} queries={noticeQueries}/>, title: '공지사항' },
    { path: '/notice/:id/detail/:key/', element: <Boards info={noticeInfo} queries={noticeQueries} detail={true}/>, title: '공지사항' },
    { path: '/cultureMap', element: <CultureMap/>, title: '문화지도', policy: <PolicyBottom type={'type2'} /> },
    { path: '/private-policy', element: <PrivatePolicy/>, title: '충주에 대해서' },
    { path: '/copyright-policy', element: <CopyrightPolicy/>, title: '충주에 대해서' },
  ]
}
