import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {PUBLISH_URL} from "../../../api/global";
import {Component, Container, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, BodyText3, TinyText, TinyText2, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../style/comp/comm';
import NoDataIcon from "../../../media/images/Comm/no-data.svg";
import qs from 'qs';
import DownloadImage from '../../../media/images/Notice/noticeDownloadIcon.webp'
import PreviewImage from '../../../media/images/Notice/noticePreviewIcon.webp'

const ValueText= ({value})=><span className="ft45 m-ft4 ft-md"> {value} </span> 
const ValueDateText= ({value})=><BodyText1 className="ft-md"> {value} </BodyText1> 
const ValueStatus= ({value})=>{
  return <TinyText className="ft-md">
    {
      value && 
      <div className="w80p m-w60p txt-center iblock-box ft-wh p-x5 p-y3 bd-rd-20"
        style={{
          // backgroundColor: '#000',
          backgroundImage: value == '접수중' ? `linear-gradient(to left, #cddd88, #5082a4)` : `linear-gradient(to left, #000, #000)`
        }}
      >
        {value}
      </div>
    }
    
  </TinyText> 
}
const ValuePriod= ({value, data})=>{
  console.log(data)
  return (
    data?.start_date && data?.end_date &&
    <TinyText2 className="ft-md">
      {data?.start_date.slice(0,10)} - {data?.end_date.slice(0,10)}
    </TinyText2> 
  )
}
const ValuePdf= ({value, data})=>{
  console.log(value, value[0]?.url)
  return (
    value && value?.length > 0 &&
    <TinyText2 className="ft-md">
      <a onClick={e=> e.stopPropagation()} href={`${value[0].url}`} target="_blank">
        <img src={PreviewImage} alt="" className="icon-img-s" style={{objectFit:'contain'}} />
      </a>
    </TinyText2> 
  )
}
const ValueFile= ({value, data})=>{
  console.log(value, value[0]?.url)
  return (
    value && value?.length > 0 &&
    <TinyText2 className="ft-md">
      <a onClick={e=> e.stopPropagation()} href={`${value[0].url}`} download={true}>
        <img src={DownloadImage} alt="" className="icon-img-s" style={{objectFit:'contain'}} />
      </a>
    </TinyText2> 
  )
}
export const DetailList = ({data, selectedCategory, url, infoData, type}) => {
  const columns= [
    { id: 'id', name: 'No', width: '5%', mobile: true, value: ({value})=> <ValueText value={value} />  },
    { id: 'event_text', name: '상태', width: '15%', mobile: true, value: ({value})=> <ValueStatus value={value} /> },
    { id: 'subject', name: '제목', width: '70%', mobile: true, value: ({value})=> <ValueText value={value} /> },
    { id: 'period', name: '기간', width: '30%', mobile: false, value: ({value, data})=> <ValuePriod value={value} data={data} /> },
    { id: 'createdate', name: '작성일', width: '20%', mobile: true, value: ({value})=> <ValueText value={value.slice(0,10)} /> },
    { id: 'pdf_files', name: '', width: '5%', mobile: false, value: ({value, data})=> <ValuePdf value={value} data={data}/> },
    { id: 'files', name: '', width: '5%', mobile: false, value: ({value, data})=> <ValueFile value={value} data={data}/> },
  ]
  
  if( infoData.id == 'informations' ) columns[1]= { id: 'event_text', name: '상태', width: '15%', value: ({value})=> <ValueStatus value={value} /> }
  if( infoData.id == 'informations' ) columns.splice(3,1)
  console.log(columns)

  return (
    <div className="bd-bt-bk2">
      <ul className="flx-0 bd-bt-bk2 p-y10">
        {
          columns.map( col=> {
            const { id, name, width, mobile }= col;
            return (
              <li key={id} className={`${!mobile ? 'm-hidden' : ''} ft-md txt-center`} style={{ width }}>
                {name}
              </li>
            )
          })
        }
        
      </ul>
      
      <div className="">
      {
        data?.articles.length == 0 && 
        <div className="w100">
          <div className="flx-cc w100 p-y40">
            <img src={NoDataIcon} alt="" className="icon-img-m" />
            <BodyText1>검색결과가 없습니다.</BodyText1>
          </div>
          
        </div>
      }

      <ListStyle className="">
        {
          data?.articles.map((item, key) => {
            const param={};
            Object.keys(infoData).map(key=> {
              if( key != 'images' ) param[key]= infoData[key]
            })

            return <ListItem 
              key={item.id}
              columns={columns}
              data={item} allData={data} 
              selectedCategory={selectedCategory} 
              url={`${url}/detail/`} 
              // id={id} info={info} 
              infoData={param}
              // reqParam={reqParam}
              // query={query}
            />
            
          })
        }
      </ListStyle>
      </div>
    </div>
  )
}



const ListItem = ({columns, data, allData, selectedCategory, url, infoData, type})=> {
  const navigate = useNavigate();
  const handleContentClick = (data) => {
    // console.log(data, infoData)
    navigate(`${url}${data.id}${qs.stringify({type})}`, { state: { data: data, allData: allData, selectedCategory: selectedCategory, infoData } });
  };
  console.log(data)

  return (
    <ListItemStyle 
      onClick={() => handleContentClick(data)}
      className="w100 flx-col cursor-point"
    >
      {/* 우리가 꿈꾸는 도시 카드 */}

      {/* <CardSection>
        <div className="img100 inst-box">
          <CardImg src={`${PUBLISH_URL}${data.thumbnail}`} lazy={'true'} />
          { !infoData?.disableBadge && <CardProcessIcon src={data.event_text === '진행중' ? ProcessingIcon : EndProcessIcon}/> }
        </div>
      </CardSection> */}

      
      <div className="txt-keep flx-itm-r flx-col">
        <div className="flx-0 p-y10 txt-center">
          {
            columns.map(col=> {
              console.log(col)
              const { id, width, value: ValueFormat, mobile }= col;
              
              return (
                <div key={id} className={`${!mobile ? 'm-hidden' : ''}`} style={{ width }}>
                  {
                    ValueFormat && <ValueFormat value={data?.[id]} data={data} />
                  }
                </div>
              )
            })
          }
          
          
          {/* <p className="" style={{ width: columns[2].width }}>
            <BodyText1 className="ft-rg">{data.subject}</BodyText1>
          </p>
          {
            columns?.[3] && 
            <p className="" style={{ width: columns[3].width }}>
              <BodyText1 className="ft-rg">{data.subject}</BodyText1>
            </p>
          }
          <p className="" style={{ width: columns[4].width }}>
            <BodyText1 className="ft-rg">{data.subject}</BodyText1>
          </p>
          <p className="" style={{ width: columns[5].width }}>
            <BodyText1 className="ft-rg">{data.subject}</BodyText1>
          </p> */}
        </div>

        {/* <div className="bd-tp-dg2 m-t5">
          <p className="m-t5">
            <BodyText1 className="ft-rg">{data.target}</BodyText1>
          </p>
          {
            data?.tags?.length > 0 &&
            <div className="">
              {data?.tags?.map(tag=> <span key={tag} className="ft45 ft-c1">{`#${tag} `}</span>)}
            </div>
          }
          { !infoData?.disableBadge && 
            <p className="">
              <TinyText2 className="ft-c1 ft-md">{`${data.start_date.slice(0, 10)} ~ ${data.end_date.slice(0, 10)}`}</TinyText2>
            </p>
          }
        </div> */}
          
      </div>
    </ListItemStyle>
  )
}

const ListStyle= styled.ul`
  :last-child {
    border: none;
  }
`
const ListItemStyle= styled.li`
  border-bottom: solid 1px #b0b0b0;
`