import React from "react";
import styled from "styled-components";
import AboutChoongJuImage from '../../../../media/mobileImage/MainHome/CultureChoongJu/mobileAboutChoongJu.webp'
import IntroLocalImage from '../../../../media/mobileImage/MainHome/CultureChoongJu/mobileIntroLocal.webp'
import CutureMapImage from '../../../../media/mobileImage/MainHome/CultureChoongJu/mobileCultureMap.webp'
import { Link } from 'react-router-dom';

export const MobileCultureChoongJu = () => {
  return (
    <>
      <Container>

        <MainText>문화도시 충주</MainText>
        <SubTextSection>
          <SubText>충주의 모든 것(지역/문화공간)을 알려드립니다.</SubText>
          <Link to={'/local/about'}>
            <MoreText>목록보기</MoreText>
          </Link>
        </SubTextSection>

      </Container>

      <Contents>
        <Link to={'/local/about'}>
          <ContentImg src={AboutChoongJuImage}/>
        </Link>
        <Link to={'/local/intro'}>
          <ContentImg src={IntroLocalImage}/>
        </Link>
        <Link to={'/cultureMap'}>
          <ContentImg src={CutureMapImage}/>
        </Link>
      </Contents>

      <BottomLine/>

    </>
  )
}

const Container = styled.div`
  width: 93.48vw;
`;

const MainText = styled.div`
  font-size: 5.71vw; /* 70px */
  font-family: 'SCDream7', serif;
  margin-bottom: 1.96vw; /* 24px */
`;

const SubTextSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubText = styled.div`
  font-size: 3.22vw; /* 39.5px */
  font-family: 'SCDream4', serif;
  margin-bottom: 1.55vw; /* 19px */
  letter-spacing: -0.016vw; /* This was computed from your previous value */
`;

const MoreText = styled.text`
  color: #939598;
  font-size: 3.22vw; /* 39.5px */
  text-decoration: underline;
  font-family: 'SCDream4', serif;
  cursor: pointer;
`;

const Contents = styled.div`
  margin-top: 4.98vw; /* 61px */
`;

const ContentImg = styled.img`
  width: 100vw;
  margin-bottom: 1.06vw; /* 13px */
  cursor: pointer;
`;

const BottomLine = styled.div`
  border: 0.163vw solid black; /* 2px */
  width: 93.48vw;
  margin-top: 1.79vw; /* 22px */
  margin-bottom: 9.71vw; /* 119px */
`;
