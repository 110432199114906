import {API_KEY, API_URL} from "./global";
import { qryToString } from './_qryToString';

export const dream_likeme_list = async(params) => {
  const qryStr= qryToString(params)
  const response = await fetch(API_URL + `api/boards/likeme${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('dream_likeme_list Error')
  }

  return response.json();
}

export const dream_likeyou_list = async(params) => {
  const qryStr= qryToString(params)
  const response = await fetch(API_URL + `api/boards/likeyou${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('dream_likeyou_list Error')
  }

  return response.json();
}

export const dream_likeus_list = async(params) => {
  const qryStr= qryToString(params)
  console.log(qryStr)
  const response = await fetch(API_URL + `api/boards/likeus${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('dream_likeus_list Error')
  }

  return response.json();
}

export const dream_localcreator_list = async(params) => {
  const qryStr= qryToString(params)
  const response = await fetch(API_URL + `api/boards/localcreator${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('dream_localcreator_list Error')
  }
  // console.log(response?.json())

  return response.json();
}