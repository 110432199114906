import React from 'react';
import { useMediaQuery } from 'react-responsive';

const ResponsiveContainer = ({ children }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1920 });

  const containerStyle = {
    width: isDesktopOrLaptop ? '1920px' : '100%',
    margin: isDesktopOrLaptop ? '0 auto' : '0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  };

  return (
    <div style={containerStyle}>
      {children}
    </div>
  );
};

export default ResponsiveContainer;