import {API_KEY, API_URL} from "./global";
import { qryToString } from './_qryToString';

export const total_search_keyword = async(params) => {
  // const qryStr= qryToString(params)
  if( !params.queryKey?.[1] ) return '';
  const { currentPage, search }= params.queryKey[1];
  console.log(search)
  if( !search ) return;

  const searchParam= search ? `${search}` : '';
  const qryStr= `?${searchParam}`
  console.log(`api/totalsearch/keyword${qryStr}`)
  const response = await fetch(API_URL + `api/totalsearch/keyword${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('live_long Error')
  }

  return response.json();
}


export const total_search_result = async(params) => {
  // const qryStr= qryToString(params)
  if( !params.queryKey?.[1] ) return '';
  const { currentPage, search, hashtag }= params.queryKey[1];
  console.log(params.queryKey[1])
  // if( !search ) return;
  
  const searchParam= search ? `${search}` : '';
  if( !hashtag && !search ) return;
  const hashtagParam= hashtag ? `${hashtag}` : '';

  const qryStr= `?${searchParam}${hashtagParam}`
  console.log(`api/totalsearch/result${qryStr}`)
  const response = await fetch(API_URL + `api/totalsearch/result${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('live_long Error')
  }

  return response.json();
}



export const total_search_detail = async(params) => {
  // const qryStr= qryToString(params)
  if( !params.queryKey?.[1] ) return '';
  const { currentPage, search, boardId, hashtag }= params.queryKey[1];
  console.log(search)
  if( boardId == 'all' ) return;
  // if( !search ) return;
  
  const searchParam= search ? `&${search}` : '';
  if( !hashtag && !search ) return;
  const hashtagParam= hashtag ? `${hashtag}` : '';

  const currentPageParam= currentPage ? `&${currentPage}` : '';
  const boardIdParam= boardId ? `&${boardId}` : '';
  const qryStr= `?${searchParam}${hashtagParam}${boardIdParam}${currentPageParam}`
  console.log(`api/totalsearch/detail${qryStr}`)
  const response = await fetch(API_URL + `api/totalsearch/detail${qryStr}`, {
    headers: {
      'X-CCFCJ-API-KEY' : `${API_KEY}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials" : "true",
    }
  })

  if(!response.ok){
    throw new Error('totalsearch_detail Error')
  }

  return response.json();
}