import React, {useState} from "react";
import styled from "styled-components";
import CardPicture from '../../../../media/images/MainHome/CommunityBusiness/CardImage.webp'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';

// import required modules
import {CommunityData} from "../../../../configs/MainHome/CommunityData";
import {useQuery} from "react-query";
import {main_communities_list} from "../../../../api/MainHome";
import {PUBLISH_URL} from "../../../../api/global";
import { Link, useNavigate } from 'react-router-dom'
import { info, queries } from '../../../pages/Life/community/info'


export const MobileCommunityBiz = () => {
  // const { data: main_communities_list_data, error, isLoading } = useQuery('main_communities_list', main_communities_list);

  const infoData= {}
  Object.keys(info[1]).map(key=> {
    if( key != 'images' ) infoData[key]= info[0][key];
  })
  const { name, id, url: urls }= infoData;
  const url= `${urls}/detail/`;

  const [MenBoxTwo, setMenBoxTwo] = useState(false);
  const { data: main_communities_list_data, error, isLoading } = useQuery(queries?.[id]?.key, queries?.[id]?.query);
  // const { data: main_live_choongju_data, error, isLoading } = useQuery('live_recent', live_recent);
  // console.log(main_live_choongju_data)

  const navigate = useNavigate();
  const handleContentClick = (data) => (e)=> {
    console.log(data, infoData)
    navigate(`${url}${data.id}`, { state: { data: data, allData: main_communities_list_data.articles, selectedCategory: name, infoData  } });
  };

  return (
    <>
      <Component>

        <MainText>커뮤니티 사업</MainText>
        <SubTextSection>
          <SubText>충주의 모든 것(지역/문화공간)을 알려드립니다.</SubText>
          <Link to={'/life/community/honey'}>
            <MoreText>목록보기</MoreText>
          </Link>
        </SubTextSection>


        {/* 카드 아이템 4종 */}
        <Contents>
          {main_communities_list_data && main_communities_list_data.articles.slice(0, 4).map((data) => (
            <ImageCard onClick={handleContentClick(data)}>
              <MainImage src={PUBLISH_URL + data.thumbnail}/>
              <CardTitle>{data.subject}</CardTitle>
              {/*<CardLine />*/}
              <CardHashTag>
                {data.tags.map((tag) => 
                  <Link 
                    onClick={e=> e.stopPropagation()}
                    to={`/totalSearch?hashtag=${tag}`} className="m-x3 ft-c1"
                  >{`#${tag}`}</Link>
                )}
                {/* {data.tags.slice(0, 3).map((data) => (
                  data + ' '
                ))} */}
              </CardHashTag>
            </ImageCard>
          ))}
        </Contents>

        <BizLine/>

      </Component>

    </>
  )
}

const Component = styled.div`
  width: 93.48vw; /* Already in vw */
  position: relative;
  margin-bottom: 9.31vw; /* 114px */
`

const MainText = styled.div`
  font-size: 5.71vw; /* 70px */
  font-family: 'SCDream7', serif;
  margin-bottom: 1.96vw; /* 24px */
`;

const SubTextSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubText = styled.div`
  font-size: 3.22vw; /* 39.5px */
  font-family: 'SCDream4', serif;
  margin-bottom: 1.55vw; /* 19px */
  letter-spacing: -0.016vw; /* Already computed from previous value */
`;

const MoreText = styled.text`
  color: #939598;
  font-size: 3.22vw; /* 39.5px */
  text-decoration: underline;
  font-family: 'SCDream4', serif;
  cursor: pointer;
`;

const Contents = styled.div`
  margin-top: 2.69vw; /* 33px */
  margin-bottom: 0.41vw; /* 5px */
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(2, 1fr); 
  gap: 2.61vw; /* 32px */
`

const ImageCard = styled.div`
  width: 45.46vw; /* 557px */
  margin-bottom: 5.79vw; /* 71px */
`

const MainImage = styled.img`
  width: 100%;
  height: 45.28vw; /* 555px */
  border-radius: 25px;
`

const CardTitle = styled.div`
  margin-top: 2.85vw; /* 35px */
  font-family: 'SCDream5', serif;
  font-size: 3.39vw; /* 41.5px */
  width: 44.32vw; /* 543px */
  margin-bottom: 2.61vw; /* 32px */
`

const CardHashTag = styled.div`
  width: 34.67vw; /* 425px */
  font-family: 'SCDream2', serif;
  font-size: 2.41vw; /* 29.5px */
`

const BizLine = styled.div`
  border-bottom: 0.16vw solid black; /* 2px */
`


// const CardLine = styled.div`
//   border-bottom: 3px black solid;
//   margin-bottom: 19px;
//   width: 382px;
//
// `

// const NavigationBox = styled.div`
//   position: absolute;
//   right: 0;
//   top: 140px;
//
// `
//
// const PrevArrowButton = styled.img`
//   width: 22px;
//   height: 25px;
//   margin-right: 24px;
//   cursor: pointer;
//
// `
//
// const NextArrowButton = styled.img`
//   width: 22px;
//   height: 25px;
//   cursor: pointer;
//
// `