import React from "react";
import styled from "styled-components";
import QuoteIconImage from '../../../../media/images/DreamCity/quoteIcon.webp'
import PatternImage from '../../../../media/images/DreamCity/dreamCityPattern.webp'
import FirstImage from '../../../../media/mobileImage/DreamCity/mobileDreamCityFirstImage.webp'
import GradientTextImage from '../../../../media/images/DreamCity/firstCardText.webp'
import {useSetRecoilState} from "recoil";
import {DreamCityDetailCategory} from "../../../../recoil/LayoutRecoil";
import {Link} from "react-router-dom";

export const MobileDreamCityFirstCard = () => {
  const setDreamCityDetailCategory = useSetRecoilState(DreamCityDetailCategory)
  const userAgent = window.navigator.userAgent.toLowerCase();

  return (
    <Components>

      <QuoteIcon src={QuoteIconImage}/>
      <PatternImg src={PatternImage}/>

      <Contents>
        <FirstImg src={FirstImage}/>

        <TextSection>
          <TopSection Medge={userAgent.includes('windows')}>
            <Title>나답게</Title>
            <SubTitle>{`누구나 참여하는 문화도시를 만들고자 
문화인력을 양성하고
안정적인 지원시스템을 구축합니다.`}
            </SubTitle>
            <GradientText src={GradientTextImage}/>
          </TopSection>

          <TextLine/>

          <BottomSection>
            <BizText>{`<주요사업>
[문화인력 육성] 문화기획자 양성과정 "문채부_문화를 채우는 부족"
[문화인력 육성]충주 예술인 & 단체 지원사업 "믿어줄게 밀어줄게"
[안정적 지원시스템 구축]문화도시센터 운영
[안정적 지원시스템 구축]문화도시 거버넌스
[안정적 지원시스템 구축]시외권 생활문화거점 플랫폼 "문화 꿀단지“`}</BizText>
            <Link to="/dreamCityDetail" style={{textDecoration: 'none'}}
                  onClick={() => {
                    setDreamCityDetailCategory('나답게')
                  }}>
              <DetailText Medge={userAgent.includes('windows')}>자세히 보기</DetailText>
            </Link>
          </BottomSection>
        </TextSection>
      </Contents>

    </Components>
  )
}

const Components = styled.div`
  width: 100vw;
  height: 58.35vw;
  position: relative;
`

const Contents = styled.div`
  display: flex;
  background-color: white;
  z-index: 1;
  position: relative;
`

const FirstImg = styled.img`
  width: 32.87vw;
  height: 58.35vw;
`

const QuoteIcon = styled.img`
  width: 8.07vw;
  height: 6.36vw;
  position: absolute;
  top: -4.89vw;
  left: 33.53vw;
  z-index: 2;
`

const PatternImg = styled.img`
  width: 39.89vw;
  height: 19vw;
  position: absolute;
  top: -9.54vw;
  left: -7.43vw;
  z-index: 0;
`

const TextSection = styled.div`
  margin-left: 3.67vw;
`

const TopSection = styled.div`
  margin-top: 4.48vw;
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 4.85vw;
  font-family: 'SCDream8', serif;
  margin-bottom: 2.36vw;
`

const SubTitle = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 2.36vw;
  white-space: pre-line;
  margin-bottom: 3.42vw;
`

const GradientText = styled.img`
  width: 27.33vw;
  height: 2.36vw;
`

const TextLine = styled.div`
  border-bottom: 2px solid black;
  width: 50.41vw;
  margin-top: 2.53vw;
  margin-bottom: 2.77vw;
`

const BizText = styled.div`
  font-family: 'SCDream4', serif;
  font-size: 2.08vw;
  color: #808080;
  white-space: pre-line;
  line-height: 3.27vw;
`

const DetailText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 2.36vw;
  text-decoration: underline;
  cursor: pointer;
  text-align: start;
  margin-top: 1.14vw;
`


