import React from "react";
import styled from "styled-components";
import QuoteIconImage from "../../../media/images/DreamCity/quoteIcon.webp";
import SecondImage from "../../../media/images/DreamCity/secondCardImage.webp";
import GradientTextImage from "../../../media/images/DreamCity/secondCardText.webp";
import {useSetRecoilState} from "recoil";
import {DreamCityDetailCategory} from "../../../recoil/LayoutRecoil";
import {Link} from "react-router-dom";

export const DreamCitySecondCard = () => {
  const setDreamCityDetailCategory = useSetRecoilState(DreamCityDetailCategory)
  const userAgent = window.navigator.userAgent.toLowerCase();


  return (
    <Components>
      {/* 너답게 카드 */}

      <QuoteIcon src={QuoteIconImage}/>

      <Contents>
        <TextSection>
          <TopSection>
            <Title>너답게</Title>
            <SubTitle>{`개인의 라이프스타일과 취향을 인정하고
누구나 공감하는 문화활동의 기반을 만들고자
문화도시를 브랜딩하고 로컬문화산업을 창조합니다.`}</SubTitle>
            <GradientText src={GradientTextImage}/>
          </TopSection>

          <TextLine/>

          <BottomSection>
            <BizText>{`<주요사업>
[문화도시 브랜딩] 충주로컬자원아카이브 "WE LOVE 충주시티"
[문화도시 브랜딩]취향공유 커뮤니티 지원 "집 밖 문지방"
[로컬문화산업 창조]문화정기구독플랫폼"이 달의 충주문화"
[로컬문화산업 창조]충주문화자산 활성화"충주주민(ZOOM-IN)센터“`}</BizText>
            <Link to="/dreamCityDetail" style={{textDecoration: 'none'}}
                  onClick={() => {
                    setDreamCityDetailCategory('너답게')
                  }}>
              <DetailText Medge={userAgent.includes('windows')}>자세히 보기</DetailText>
            </Link>
          </BottomSection>
        </TextSection>

        <SecondImg src={SecondImage}/>
      </Contents>

    </Components>
  )
}

const Components = styled.div`
  width: 1434px;
  height: 501px;
  position: relative;
  margin-top: 83px;

  @media (max-width: 1920px) {
    width: 74.6875vw;
    height: 26.09375vw;
    margin-top: 4.3229vw;
  }
`

const Contents = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  border-radius: 33.33px;
  z-index: 1;
  position: relative;

  @media (max-width: 1920px) {
    border-radius: 1.7365vw;
  }
`

const SecondImg = styled.img`
  width: 684px;
  height: 501px;

  @media (max-width: 1920px) {
    width: 35.625vw;
    height: 26.09375vw;
  }
`

const QuoteIcon = styled.img`
  width: 67px;
  height: 53px;
  position: absolute;
  top: -40px;
  left: 30px;
  z-index: 2;

  @media (max-width: 1920px) {
    width: 3.4896vw;
    height: 2.7604vw;
    top: -2.0833vw;
    left: 1.5625vw;
  }
`

const TextSection = styled.div`
  margin-left: 68px;

  @media (max-width: 1920px) {
    margin-left: 3.5417vw;
  }
`

const TopSection = styled.div`
  margin-top: 51px;

  @media (max-width: 1920px) {
    margin-top: 2.65625vw;
  }
`

const BottomSection = styled.div`

`

const Title = styled.div`
  font-size: 33px;
  font-family: 'SCDream8', serif;
  margin-bottom: 23px;

  @media (max-width: 1920px) {
    font-size: 1.71875vw;
    margin-bottom: 1.1979vw;
  }
`

const SubTitle = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 22px;
  white-space: pre-line;
  margin-bottom: 24px;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
    margin-bottom: 1.25vw;
  }
`

const GradientText = styled.img`
  width: 219px;
  height: 23px;

  @media (max-width: 1920px) {
    width: 11.40625vw;
    height: 1.1979vw;
  }
`

const TextLine = styled.div`
  border-bottom: 2px solid black;
  width: 618px;
  margin-top: 33px;
  margin-bottom: 32px;

  @media (max-width: 1920px) {
    border-bottom: 0.1042vw solid black;
    width: 32.1875vw;
    margin-top: 1.7188vw;
    margin-bottom: 1.6667vw;
  }
`

const BizText = styled.div`
  font-family: 'SCDream4', serif;
  font-size: 16px;
  color: #808080;
  white-space: pre-line;
  line-height: 1.6;
  width: 487px;

  @media (max-width: 1920px) {
    font-size: 0.8333vw;
    width: 25.3646vw;
  }
`

const DetailText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 16px;
  width: 100px;
  text-decoration: underline;
  display: flex;
  align-items: end;
  cursor: pointer;
  margin-top: 17px;

  @media (max-width: 1920px) {
    font-size: 0.8333vw;
    width: 5.2083vw;
    margin-top: 0.8854vw;
  }
`