import styled from "styled-components";
import {MapItem} from "../../atoms/CultureMap/MapItem";
import MenIconImage from '../../../media/images/CultureMap/cultureMapMenIcon.webp'
import PatterunImage from '../../../media/images/CultureMap/culureMapPattern.webp'
import {TitleSection} from '../../pages/_Include/Title';

export const CultureMapContents = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return (
    <Component className="p-x10"> 
      <TitleSection 
        path={`홈 > 충주(Local) > 문화지도`} 
        title={'문화지도'} 
        subtitle={'충주의 다양한 로컬 크리에이터들의 공간을 확인해보세요!'} 
      />
      {/* <PathSection>홈 &gt; 충주(Local) &gt; 문화지도</PathSection>

      <Title>문화지도</Title>
      <SubTitle>충주의 다양한 로컬 크리에이터들의 공간을 확인해보세요!</SubTitle> */}

      <div className="inst-box m-t80">
      <CultureMapMenIcon src={MenIconImage} Medge={userAgent.includes('windows')}/>
      <CultureMapPattern src={PatterunImage}/>
      </div>

      {/* 맵 관련 컨텐츠 */}
      <MapItem/>

    </Component>
  )
}

const Component = styled.div`
  /* max-width: 1120px; */
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1920px) {
    width: 100%;
  }
`

const PathSection = styled.div`
  font-size: 18px;
  font-family: 'SCDream5', serif;
  margin-top: 33px;
  margin-bottom: 42px;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
    margin-top: 1.7188vw;
    margin-bottom: 2.1875vw;
  }
`

const Title = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  text-align: center;
  margin-bottom: 21px;

  @media (max-width: 1920px) {
    font-size: 2.0833vw;
    margin-bottom: 1.0938vw;
  }
`

const SubTitle = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-bottom: 142px;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
    margin-bottom: 7.3958vw;
  }
`

const CultureMapMenIcon = styled.img`
  position: absolute;
  /* width: 152px;
  height: 186px;
  top: 148px;
  right: 27px;
  z-index: 1; */
  width: 122px;
  height: auto;
  bottom: -2px;
  right: 20px;
  z-index: 2;
  @media (max-width: 1920px) {
    /* width: 7.9167vw;
    height: 9.6875vw;
    top: 7.7083vw;
    right: 1.4063vw; */
    width: 122px;
    height: auto;
    bottom: -2px;
  }
`

const CultureMapPattern = styled.img`
  position: absolute;
  width: 286px;
  /* height: 267px; */
  bottom: -119px;
  right: -0px;

  @media (max-width: 1920px) {
    width: 286px;
    /* height: 267px; */
    bottom: -119px;
    /* width: 16.4583vw;
    height: 13.9063vw;
    top: 9.8958vw;
    right: -4.1667vw; */
  }
`