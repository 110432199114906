import React, {useState} from "react";
import styled from "styled-components";
import LogoImage from "../../../../media/mobileImage/layouts/mobileLogo.webp";
import CancleImage from "../../../../media/mobileImage/layouts/mobileCancleIcon.webp";
import TextIconImage from "../../../../media/mobileImage/layouts/mobileTextIcon.webp";
import SouthArrowImage from "../../../../media/mobileImage/layouts/mobileSouthArrow.webp";
import RightArrowImage from "../../../../media/mobileImage/layouts/mobileRightArrow.webp";
import {useSetRecoilState} from "recoil";
import {DreamCityDetailCategory, MobileShowCategory} from "../../../../recoil/LayoutRecoil";
import {Link} from "react-router-dom";

export const MobileShareCategory = () => {
  const SetMobileShowCategory = useSetRecoilState(MobileShowCategory)
  const setDreamCityDetailCategory = useSetRecoilState(DreamCityDetailCategory)
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);
  const [openFour, setOpenFour] = useState(false);


  return (
    <Component>
      <Contents>
        <HeaderSection>
          <LogoImg src={LogoImage}/>
          <CancleImg src={CancleImage} onClick={() => SetMobileShowCategory(false)}/>
        </HeaderSection>

        {/* 충주 Local */}
        <MainCategorySection onClick={() => setOpenOne(!openOne)}>
          <MainTextIconImg src={TextIconImage}/>
          <TextSection>
            <MainKoText>충주</MainKoText>
            <MainEnText>Local</MainEnText>
          </TextSection>
          <SouthArrowImg src={SouthArrowImage}/>
        </MainCategorySection>

        {/* 충주 Local 하위 카테고리 */}
        {openOne && <SubCategorySection>
          <Link to="/local/about">
            <SubMainText onClick={() => SetMobileShowCategory(false)}>충주에 대해서</SubMainText>
          </Link>
          <Link to="/local/intro">
            <SubMainText onClick={() => SetMobileShowCategory(false)}>지역소개</SubMainText>
          </Link>
          <Link to="/cultureMap">
            <SubMainText onClick={() => SetMobileShowCategory(false)}>문화지도</SubMainText>
          </Link>
        </SubCategorySection>}


        {/* 살면 Life */}
        <MainCategorySection onClick={() => setOpenTwo(!openTwo)}>
          <MainTextIconImg src={TextIconImage}/>
          <TextSection>
            <MainKoText>살면</MainKoText>
            <MainEnText>Life</MainEnText>
          </TextSection>
          <SouthArrowImg src={SouthArrowImage}/>
        </MainCategorySection>

        {/* 살면 Life 하위 카테고리 */}
        {openTwo && <SubCategorySection>
          <Link to={'/life/choongjuro'}>
            <SubMainText onClick={() => SetMobileShowCategory(false)}>충주로</SubMainText>
          </Link>
          {/* <SubMainText onClick={() => SetMobileShowCategory(false)}>취업(귀농)</SubMainText>
          <SubMainText onClick={() => SetMobileShowCategory(false)}>취업(귀촌)</SubMainText>
          <SubMainText onClick={() => SetMobileShowCategory(false)}>재택근무</SubMainText>
          <SubMainText onClick={() => SetMobileShowCategory(false)}>로컬워크스테이</SubMainText> */}
          <Link to={'/life/community/honey'}>
            <SubMainText>커뮤니티 사업</SubMainText>
          </Link>
          <Link to={'/life/community/honey'}>
            <SubSubText onClick={() => SetMobileShowCategory(false)}>- 문화꿀단지</SubSubText>
          </Link>
          <Link to={'/life/community/out'}>
            <SubSubText onClick={() => SetMobileShowCategory(false)}>- 집밖문지방</SubSubText>
          </Link>
          <Link to={'/life/events'}>
            <SubMainText onClick={() => SetMobileShowCategory(false)}>이벤트</SubMainText>
          </Link>
        </SubCategorySection>}


        {/* 충주 Culture */}
        <MainCategorySection onClick={() => setOpenThree(!openThree)}>
          <MainTextIconImg src={TextIconImage}/>
          <TextSection>
            <MainKoText>충주</MainKoText>
            <MainEnText>Culture</MainEnText>
          </TextSection>
          <SouthArrowImg src={SouthArrowImage}/>
        </MainCategorySection>

        {/* 충주 Culture 하위 카테고리 */}
        {openThree && <SubCategorySection>
          <Link to={'/culture/city'}>
            <SubMainText onClick={() => SetMobileShowCategory(false)}>문화도시 충주</SubMainText>
          </Link>
          {/* <SubMainText>함께하는 충주</SubMainText>
          <SubSubText onClick={() => SetMobileShowCategory(false)}>- 추진위</SubSubText>
          <SubSubText onClick={() => SetMobileShowCategory(false)}>- 문화다양성</SubSubText>
          <SubSubText onClick={() => SetMobileShowCategory(false)}>- 인문사회</SubSubText>
          <SubSubText onClick={() => SetMobileShowCategory(false)}>- 청년문화기획단</SubSubText>
          <Link to="/dreamCity" onClick={() => SetMobileShowCategory(false)}>
            <SubMainText style={{paddingBottom: '0px'}}>우리가 꿈꾸는 도시</SubMainText>
          </Link> */}
          <Link to="/culture/our-city-1" onClick={() => SetMobileShowCategory(false)}>
            <SubMainText style={{paddingBottom: '0px'}}>우리가 선보이는 도시</SubMainText>
          </Link>
          <Link to="/culture/our-city-2" onClick={() => SetMobileShowCategory(false)}>
            <SubMainText style={{paddingBottom: '0px'}}>우리가 함께하는 도시</SubMainText>
          </Link>
          <Link to="/culture/our-city-3" onClick={() => SetMobileShowCategory(false)}>
            <SubMainText style={{paddingBottom: '0px'}}>우리가 생산하는 도시</SubMainText>
          </Link>
          <Link to="/culture/our-city-4" onClick={() => SetMobileShowCategory(false)}>
            <SubMainText style={{paddingBottom: '0px'}}>우리가 성장하는 도시</SubMainText>
          </Link>
          {/* <Link to="/dreamCityDetail" onClick={() => {
            setDreamCityDetailCategory('나답게')
          }}>
            <SubSubText onClick={() => SetMobileShowCategory(false)} style={{paddingBottom: '0px'}}>- 나답게</SubSubText>
          </Link>
          <Link to="/dreamCityDetail" onClick={() => {
            setDreamCityDetailCategory('너답게')
          }}>
            <SubSubText onClick={() => SetMobileShowCategory(false)} style={{paddingBottom: '0px'}}>- 너답게</SubSubText>
          </Link>
          <Link to="/dreamCityDetail" onClick={() => {
            setDreamCityDetailCategory('우리답게')
          }}>
            <SubSubText onClick={() => SetMobileShowCategory(false)} style={{paddingBottom: '0px'}}>- 우리답게</SubSubText>
          </Link>
          <Link to="/dreamCityDetail" onClick={() => {
            setDreamCityDetailCategory('충격의 로컬크리에이터')
          }}>
            <SubSubText onClick={() => SetMobileShowCategory(false)}>- 충격의 로컬크리에이터</SubSubText>
          </Link> */}
        </SubCategorySection>}


        {/* 사람 People */}
        <MainCategorySection onClick={() => setOpenFour(!openFour)}>
          <MainTextIconImg src={TextIconImage}/>
          <TextSection>
            <MainKoText>사람</MainKoText>
            <MainEnText>People</MainEnText>
          </TextSection>
          <SouthArrowImg src={SouthArrowImage}/>
        </MainCategorySection>

        {/* 사람 People 하위 카테고리 */}
        {openFour && <SubCategorySection>
          <Link to={'/life/community/honey'}>
            <SubMainText>커뮤니티 사업</SubMainText>
          </Link>
          <Link to={'/people/long-life'}>
            <SubSubText onClick={() => SetMobileShowCategory(false)}>- 오래 살아보면</SubSubText>
          </Link>
          <Link to={'/people/recently-life'}>
            <SubSubText onClick={() => SetMobileShowCategory(false)}>- 최근 살아보면</SubSubText>
          </Link>
        </SubCategorySection>}


        <MainCategorySection onClick={() => SetMobileShowCategory(false)}>
          <Link to="/consultaionRequest">
            <EctText>상담신청</EctText>
          </Link>
          <RightArrowImg src={RightArrowImage}/>
        </MainCategorySection>


        <MainCategorySection onClick={() => SetMobileShowCategory(false)}>
          <Link to="/notice/notice">
            <EctText>공지사항</EctText>
          </Link>
          <RightArrowImg src={RightArrowImage}/>
        </MainCategorySection>

      </Contents>

    </Component>
  )
}

const Component = styled.div`
  width: 100vw;
  height: 660.03vw;
  background-color: rgba(1, 1, 1, 0.7);
  z-index: 1000;
  position: absolute;
`

const Contents = styled.div`
  height: 304.26vw; /* 3731px */
  width: 100vw;
  background-color: white;
`

const HeaderSection = styled.div`
  width: 100vw;
  height: 12.98vw;
  display: flex;
  align-items: center;
  border-bottom: 0.24vw solid black; /* 3px */
`

const LogoImg = styled.img`
  width: 20.56vw;
  height: 8.41vw;
  margin-right: 28.53vw; /* 350px */
  margin-left: 40.37vw;
`

const CancleImg = styled.img`
  width: 7.18vw; /* 88px */
  height: 7.18vw; /* 88px */
  cursor: pointer;
`

const MainCategorySection = styled.div`
  height: 11.42vw; /* 140px */
  display: flex;
  align-items: center;
`

const TextSection = styled.div`
  display: flex;
  align-items: center;
  width: 22.85vw; /* 279px */
  margin-right: 61.12vw; /* 749px */
`

const MainTextIconImg = styled.img`
  width: 1.63vw; /* 20px */
  height: 0.65vw; /* 8px */
  margin-left: 4.08vw; /* 50px */
  margin-right: 3.02vw; /* 37px */
`

const MainKoText = styled.div`
  margin-right: 1.63vw; /* 20px */
  font-size: 3.92vw; /* 48px */
  font-family: 'SCDream5', serif;
`

const MainEnText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 3.92vw; /* 48px */
  color: #959595;
`

const SouthArrowImg = styled.img`
  width: 4.32vw; /* 53px */
  height: 2.37vw; /* 29px */
`

const EctText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 3.92vw; /* 48px */
  color: #959595;
  margin-left: 8.65vw; /* 106px */
  margin-right: 69.00vw; /* 846px */
  cursor: pointer;
`

const RightArrowImg = styled.img`
  width: 4.00vw; /* 49px */
  height: 1.63vw; /* 20px */
`

const SubCategorySection = styled.div`
  background-color: #e6e7e8;
  padding-bottom : 3.67vw;
`

const SubMainText = styled.div`
  margin-left: 8.89vw; /* 109px */
  font-size: 3.92vw; /* 48px */
  font-family: 'SCDream5', serif;
  padding-top: 3.67vw; /* 45px */
  cursor: pointer;


  
`

const SubSubText = styled.div`
  margin-left: 8.89vw; /* 109px */
  font-size: 3vw; /* 48px */
  font-family: 'SCDream3', serif;
  padding-top: 3.67vw; /* 45px */
  cursor: pointer;
`
