import React from "react";
import styled from "styled-components";
import QuoteIconImage from '../../../media/images/DreamCity/quoteIcon.webp'
import PatternImage from '../../../media/images/DreamCity/dreamCityPattern.webp'
import FirstImage from '../../../media/images/DreamCity/firstCardImage.webp'
import GradientTextImage from '../../../media/images/DreamCity/firstCardText.webp'
import {useSetRecoilState} from "recoil";
import {DreamCityDetailCategory} from "../../../recoil/LayoutRecoil";
import {Link} from "react-router-dom";

export const DreamCityFirstCard = () => {
  const setDreamCityDetailCategory = useSetRecoilState(DreamCityDetailCategory)

  return (
    <Components>
      {/* 나답게 카드 */}

      <QuoteIcon src={QuoteIconImage}/>
      <PatternImg src={PatternImage}/>

      <Contents>
        <FirstImg src={FirstImage}/>

        <TextSection>
          <TopSection>
            <Title>나답게</Title>
            <SubTitle>{`누구나 참여하는 문화도시를 만들고자 
문화인력을 양성하고
안정적인 지원시스템을 구축합니다.`}</SubTitle>
            <GradientText src={GradientTextImage}/>
          </TopSection>

          <TextLine/>

          <BottomSection>
            <BizText>{`<주요사업>
[문화인력 육성] 문화기획자 양성과정 "문채부_문화를 채우는 부족"
[문화인력 육성]충주 예술인 & 단체 지원사업 "믿어줄게 밀어줄게"
[안정적 지원시스템 구축]문화도시센터 운영
[안정적 지원시스템 구축]문화도시 거버넌스
[안정적 지원시스템 구축]시외권 생활문화거점 플랫폼 "문화 꿀단지“`}</BizText>
            <Link to="/dreamCityDetail" style={{textDecoration: 'none'}}
                  onClick={() => {
                    setDreamCityDetailCategory('나답게')
                  }}>
              <DetailText>자세히 보기</DetailText>
            </Link>
          </BottomSection>
        </TextSection>
      </Contents>

    </Components>
  )
}


const Components = styled.div`
  width: 1434px;
  height: 501px;
  position: relative;

  @media (max-width: 1920px) {
    width: 74.6875vw;
    height: 26.09375vw;
  }
`

const Contents = styled.div`
  display: flex;
  background-color: white;
  border-radius: 33.33px;
  z-index: 1;
  position: relative;

  @media (max-width: 1920px) {
    border-radius: 1.7365vw;
  }
`

const FirstImg = styled.img`
  width: 684px;
  height: 501px;

  @media (max-width: 1920px) {
    width: 35.625vw;
    height: 26.09375vw;
  }
`

const QuoteIcon = styled.img`
  width: 67px;
  height: 53px;
  position: absolute;
  top: -40px;
  left: 711px;
  z-index: 2;

  @media (max-width: 1920px) {
    width: 3.4896vw;
    height: 2.7604vw;
    top: -2.0833vw;
    left: 37.0052vw;
  }
`

const PatternImg = styled.img`
  width: 413px;
  height: 197px;
  position: absolute;
  top: -97px;
  left: 141px;
  z-index: 0;

  @media (max-width: 1920px) {
    width: 21.5104vw;
    height: 10.2604vw;
    top: -5.0521vw;
    left: 7.3385vw;
  }
`

const TextSection = styled.div`
  margin-left: 66px;

  @media (max-width: 1920px) {
    margin-left: 3.4375vw;
  }
`

const TopSection = styled.div`
  margin-top: 51px;

  @media (max-width: 1920px) {
    margin-top: 2.65625vw;
  }
`

const BottomSection = styled.div`
  display: flex;
`

const Title = styled.div`
  font-size: 33px;
  font-family: 'SCDream8', serif;
  margin-bottom: 23px;

  @media (max-width: 1920px) {
    font-size: 1.71875vw;
    margin-bottom: 1.1979vw;
  }
`

const SubTitle = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 22px;
  white-space: pre-line;
  margin-bottom: 24px;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
    margin-bottom: 1.25vw;
  }
`

const GradientText = styled.img`
  width: 239px;
  height: 21px;

  @media (max-width: 1920px) {
    width: 12.4479vw;
    height: 1.09375vw;
  }
`

const TextLine = styled.div`
  border-bottom: 2px solid black;
  width: 618px;
  margin-top: 35px;
  margin-bottom: 31px;

  @media (max-width: 1920px) {
    width: 32.1875vw;
    margin-top: 1.8229vw;
    margin-bottom: 1.6146vw;
  }
`

const BizText = styled.div`
  font-family: 'SCDream4', serif;
  font-size: 16px;
  color: #808080;
  white-space: pre-line;
  line-height: 1.6;
  height: 151px;
  width: 487px;

  @media (max-width: 1920px) {
    font-size: 0.8333vw;
    line-height: 1.6;
    height: 7.8646vw;
    width: 25.3646vw;
  }
`

const DetailText = styled.div`
  font-family: 'SCDream5', serif;
  font-size: 16px;
  width: 100px;
  text-decoration: underline;
  margin-left: 46px;
  position: absolute;
  bottom: 40px;
  right: 45px;
  cursor: pointer;
  height: 3px;

  @media (max-width: 1920px) {
    font-size: 0.8333vw;
    width: 5.2083vw;
    margin-left: 2.3958vw;
    bottom: 1.9vw;
    right: 2.3438vw;
  }
`

