import About1 from "../../../../media/images/About/about-1.jpg";
import About2 from "../../../../media/images/About/about-2.png";
import Slide_1_1 from "../../../../media/images/Local/slide-1-1.jpg";
import Img1 from "../../../../media/images/Local/img-1.svg";
import Slide_2_1 from "../../../../media/images/Local/slide-2-1.jpg";
import Img2 from "../../../../media/images/Local/img-2.svg";
import Slide_3_1 from "../../../../media/images/Local/slide-3-1.jpg";
import Img3 from "../../../../media/images/Local/img-3.svg";
import Slide_4_1 from "../../../../media/images/Local/slide-4-1.jpg";
import Slide_4_2 from "../../../../media/images/Local/slide-4-2.jpg";
import Img4 from "../../../../media/images/Local/img-4.svg";
import Slide_5_1 from "../../../../media/images/Local/slide-5-1.jpg";
import Img5 from "../../../../media/images/Local/img-5.svg";
import Slide_6_1 from "../../../../media/images/Local/slide-6-1.jpg";
import Slide_6_2 from "../../../../media/images/Local/slide-6-2.jpg";
import Img6 from "../../../../media/images/Local/img-6.svg";
import Slide_7_1 from "../../../../media/images/Local/slide-7-1.jpg";
import Img7 from "../../../../media/images/Local/img-7.svg";
import Slide_8_1 from "../../../../media/images/Local/slide-8-1.jpg";
import Slide_8_2 from "../../../../media/images/Local/slide-8-2.jpg";
import Slide_8_3 from "../../../../media/images/Local/slide-8-3.jpg";
import Img8 from "../../../../media/images/Local/img-8.svg";
import Slide_9_1 from "../../../../media/images/Local/slide-9-1.jpg";
import Slide_9_2 from "../../../../media/images/Local/slide-9-2.jpg";
import Slide_9_3 from "../../../../media/images/Local/slide-9-3.jpg";
import Img9 from "../../../../media/images/Local/img-9.svg";
import Slide_10_1 from "../../../../media/images/Local/slide-10-1.jpg";
import Slide_10_2 from "../../../../media/images/Local/slide-10-2.jpg";
import Slide_10_3 from "../../../../media/images/Local/slide-10-3.jpg";
import Slide_10_4 from "../../../../media/images/Local/slide-10-4.jpg";
import Img10 from "../../../../media/images/Local/img-10.svg";
import Slide_11_1 from "../../../../media/images/Local/slide-11-1.jpg";
import Slide_11_2 from "../../../../media/images/Local/slide-11-2.jpg";
import Slide_11_3 from "../../../../media/images/Local/slide-11-3.jpg";
import Slide_11_4 from "../../../../media/images/Local/slide-11-4.jpg";
import Slide_11_5 from "../../../../media/images/Local/slide-11-5.jpg";
import Img11 from "../../../../media/images/Local/img-11.svg";
import Slide_12_1 from "../../../../media/images/Local/slide-12-1.jpg";
import Slide_12_2 from "../../../../media/images/Local/slide-12-2.jpg";
import Slide_12_3 from "../../../../media/images/Local/slide-12-3.jpg";
import Img12 from "../../../../media/images/Local/img-12.svg";
import Slide_13_1 from "../../../../media/images/Local/slide-13-1.jpg";
import Slide_13_2 from "../../../../media/images/Local/slide-13-2.jpg";
import Slide_13_3 from "../../../../media/images/Local/slide-13-3.jpg";
import Img13 from "../../../../media/images/Local/img-13.svg";
import Slide_14_1 from "../../../../media/images/Local/slide-14-1.jpg";
import Slide_14_2 from "../../../../media/images/Local/slide-14-2.jpg";
import Slide_14_3 from "../../../../media/images/Local/slide-14-3.jpg";
import Img14 from "../../../../media/images/Local/img-14.svg";


export const info= [
  {
    id: 'geumgamyeon',
    title: '금가면',
    subject: <>충주의 중앙부에 위치한 면으로 충주시내에서는 북쪽에 위치해 있습니다. <br></br>
    남한강의 지류인 대전천(大田川)이 중앙부를 가로질러 서쪽으로 흐르고 있습니다. <br></br>
    남쪽으로 목행·용탄동, 동쪽으로 동량면, 서쪽으로 중앙탑면, 북쪽으로는 엄정면에 접합니다</>,
    images: [Slide_1_1],
    localIntro: <>높은 산이 없는 평탄한 지형으로서 수많은 연꽃떨기의 형상을 하고 있으며, 동남서 삼면을 남한강이 굽이쳐 흐르고 있습니다.<br></br>
    공군비행장이 전체 면적의 1/3을 차지하고 있으며, 뛰어난 남한강변의 풍광과 자전거길이 있습니다.
    </>,
    localData: {
      peopleNum: {
        total: 3877,
        man: 2090,
        women: 1787
      },
      houseNum: 1854,
      area: 34.67,
      welfareCenter: <>충북 충주시 금가면 강수로 331 금가면사무소 </>,
      elementarySchool: <>2 (오석초등학교, 금가초등학교)</>,
      middleSchool: <>-</>,
      highSchool: <>-</>,
      hospital: <>2(금가보건지소, 오석보건진료소) </>,
      facility: [
        '하강서원',
        '모현정',
        '김생사지(비)',
        '이수일 장군 묘소',
        '이수일 장군 신도비',
        '임페리얼 레이크 컨트리클럽',
      ],
      traffic: {
        train: ['-'],
        car: [
          '중앙탑면 행정복지센터 - 충주 IC (15분, 17km)',
          '중앙탑면 행정복지센터 - 북충주 IC(14분, 17km)',
          '중앙탑면 행정복지센터 - 충주시청(10분, 6.9km)',
        ]
      },
      bottoomImg: Img1
    }
  },


  {
    id: 'noeunmyeon',
    title: '노은면',
    subject: <>충주에서 서쪽으로  약25km  지점에  있으며, 동쪽으로는  중앙탑면, 서쪽으로는 음성군 감곡면,<br></br>
    남쪽으로는 신니면과 주덕읍, 북쪽으로는 앙성면이 연접해 있으며, 한강지류의 기원이되는 한포천이 중심으로 흐르고 있습니다.<br></br>
    노은면은 한포천을 중심으로 마을이 형성된 중산간지의 전형적인 농촌지역이며 <br></br>
    명산인 국망산과 보련산이 있습니다.
    </>,
    images: [Slide_2_1],
    localIntro: <>
    <p className="">· 1956년 7월 시 승격: 중원군 노은면으로 개칭</p>  
    <p className="">· 1995년 1월 시·군 통합: 충주시 노은면으로 개칭 </p>
    <p className="">· 중부내륙고속도로 북충주 I·C 가 위치한 교통요충지로 「개발 잠재여건」이 매우 풍부한 지역입니다.</p>
    </>,
    localData: {
      peopleNum: {
        total: 2232,
        man: 1140,
        women: 1092
      },
      houseNum: 1275,
      area: 61.81,
      welfareCenter: <>충북 충주시 노은면 연하중앙길 15 노은면사무소 </>,
      elementarySchool: <>1(노은초등학교) </>,
      middleSchool: <>1(노은중학교) </>,
      highSchool: <>-</>,
      hospital: <>3(노은보건지소, 안락보건진료소, 수룡보건진료소) </>,
      facility: [
        '충경공 유백증 영정',
        '홍차기 효자비각',
        '명성황후 유허비',
        '노은 3세 효자각',
        '수룡폭포',
        '보련산',
        '국망산',
        '원통산',
      ],
      traffic: {
        train: ['-'],
        car: [
          '노은면 행정복지센터 - 충주 IC (4분, 3km)',
          '노은면 행정복지센터 - 북충주 IC(14분, 14km)',
          '노은면 행정복지센터 - 충주시청(16분, 12km)',
        ]
      },
      bottoomImg: Img2
    }
  },


  {
    id: 'daesowonmyeon',
    title: '대소원면',
    subject: <>동쪽은 달천동과 살미면, 괴산군 장연면, 남쪽은 괴산군 감물면과 불정면, 서쪽은 주덕읍,<br></br>
    북쪽은 중앙탑면과 경계를 이루고 있으며, 충주와 청주, 서울을 통하는 국도 3호선 주변에 <br></br>
    면소재지인 대소리가 위치하고 있습니다.
    
    </>,
    images: [Slide_3_1],
    localIntro: <>
    <p className="">· 국도 및 충북선 철도가 중심부 관통</p>  
    <p className="">· 중부내륙 고속도로 관통으로 충주IC 이용 활발</p>
    <p className="">· 충주산업도시 및 기업도시 조성에 따라 서충주 개발의 중심지로 급부상 할 수 있는</p>
    <p className="">· 무한한 발전가능성 보유</p>
    </>,
    localData: {
      peopleNum: {
        total: 9239,
        man: 5235,
        women: 4004
      },
      houseNum: 5577,
      area: 62.17,
      welfareCenter: <>충북 충주시 대소원면 대소원1길 29 </>,
      elementarySchool: <>1(대소원초등학교) </>,
      middleSchool: <>-</>,
      highSchool: <>-</>,
      hospital: <>4</>,
      facility: [
        '마산봉수',
        '대소원 학생 만세운동비',
        '다인철소지',
        '팔봉서원',
        '문주리석불좌상',
        '문주리소재의쌍효각',
        '문주리산성',
        '문화유씨열녀문',
        '충주 문주리 요지',
        '이승소 사당',
        '팔봉강변유원지',
      ],
      traffic: {
        train: ['달천역'],
        car: [
          '대소원면 행정복지센터 - 충주 IC (4분, 3km)',
          '대소원면 행정복지센터 - 북충주 IC(14분, 14km)',
          '대소원면 행정복지센터 - 충주시청(16분, 12km)',
        ]
      },
      bottoomImg: Img3
    }
  },

  
  {
    id: 'donglyangmyeon',
    title: '동량면',
    subject: <>충주시내로부터 북쪽으로 12km 지점에 위치 하고 동쪽으로는 제천시 한수면, 서쪽으로는 금가면, <br></br>
    남쪽으로는 목행동, 북쪽으로는 산척면과 경계를 이루고 있습니다.
    
    </>,
    images: [Slide_4_1, Slide_4_2],
    localIntro: <>
    <p className="">· 충주호와 선사유적, 한국코타 등 천혜의 관광자원을 보유한 발전 잠재력이 풍부한 지역</p>  
    <p className="">· 충주사과의 주산지로 명품사과 생산과 사과주 생산 등 Well-Being 농업의 선도적 역활 </p>
    <p className="">· 천혜의 관광자원과 개발 잠재력을 보유</p>
    <p className="">· 풍부한 수자원(충주댐 85년 10월 준공, 전력 40만km/h) </p>
    <p className="">· 도시근교농업의 최적지</p>
    </>,
    localData: {
      peopleNum: {
        total: 3524,
        man: 1790,
        women: 1734
      },
      houseNum: 1877,
      area: 109.34,
      welfareCenter: <>충북 충주시 동량면 조돈안길 1 동량면사무소 </>,
      elementarySchool: <>2(대미초등학교, 동량초등학교) </>,
      middleSchool: <>1(중원중학교) </>,
      highSchool: <>-</>,
      hospital: <>2(금가보건지소, 오석보건진료소) </>,
      facility: [
        '정토사 법경대사 자등탑비',
        '조동리 지석묘',
        '영모사',
        '조동리 선사유적 박물관',
        '충주자연생태체험관',
      ],
      traffic: {
        train: ['-'],
        car: [
          '동량면 행정복지센터 - 충주 IC (20분, 21km)',
          '동량면 행정복지센터 - 북충주 IC(19분, 21km)',
          '동량면 행정복지센터 - 충주시청(10분, 7.4km)',
        ]
      },
      bottoomImg: Img4
    }
  },

  
  {
    id: 'sancheogmyeon',
    title: '산척면',
    subject: <>충주시가지에서 동북쪽 16Km지점에서 면 소재지가 위치하고 있으며, 동쪽으로 제천시 백운면과 청풍면,남쪽은 동량면,<br></br>
    서쪽은 금가면, 북쪽은 엄정면과 인접하고 있습니다. 서쪽으로는 평탄한 중산가 지의 지형적인 특성으로 농작물의 수확기 기온 격차가 15℃이상이 되어<br></br>
    높은 당도와 착색이 타지역보다  월등히  좋아 사과, 고추, 고구마, 산채 등의  농작물이  최상의  상품으로 생산되고 있어 주민 소득 기여하고 있습니다.
    </>,
    images: [Slide_5_1],
    localIntro: <>
    자연발생 유원지로 깨끗한 물과 기암괴석이 장관을 이루고 있는 삼탄유원지에는 여름철 수많은 행락객이 찾는<br></br>
    피서지로 각광받고 있으며 2000년 6월 18일 완공한 체육공원 및 편의시설로 축구, 배구,족구, 농구와 청소년수련시설을 갖추고 있습니다.<br></br>
    또한 天地人의 삼태극을 이루어 천하명산이라 일컬어지는 삼등산 중 천등산에는 최근 각계각층의 높은 관심으로 지역내 <br></br>
    크고 작은 행사의 성화 채화지로 알려지고 있으며, 등산로가 개발되어 매년 수많은 등산객의 발길이 끊이지 않고 있습니다.
    </>,
    localData: {
      peopleNum: {
        total: 2205,
        man: 1126,
        women: 1079
      },
      houseNum: 1299,
      area: 78.35,
      welfareCenter: <>충북 충주시 동산로 736 산척면사무소 </>,
      elementarySchool: <>-</>,
      middleSchool: <>-</>,
      highSchool: <>-</>,
      hospital: <>4(산척성모의원, 다을한의원, 산척보건지소, 명서보건진료소, 산척간이보건진료소)
      </>,
      facility: [
        '천등산 박달재 ',
        '삼등산',
        '천등산',
        '지등산',
        '삼탄 자연발생 유원지',
      ],
      traffic: {
        train: ['삼탄역 '],
        car: [
          '산척면 행정복지센터 - 충주 IC (23분, 23km)',
          '산척면 행정복지센터 - 북충주 IC(15분, 17km)',
          '산척면 행정복지센터 - 충주시청(18분, 13km)',
        ]
      },
      bottoomImg: Img5
    }
  },

  
  {
    id: 'salmimyeon',
    title: '살미면',
    subject: <>충주시의 동남쪽에  위치하여 북서쪽에 대림산, 쪽으로 충주의 남산, 남쪽으로  대미산이 있어 <br></br>
    경계의 지표가 되며,동쪽으로는 제천시 한수면, 서쪽으로는 대소원면, 남쪽으로는 수안보면과 <br></br>
    괴산군 장연면, 북쪽으로는 호암동과 동량면을 접하고 있습니다.
    </>,
    images: [Slide_6_1, Slide_6_2],
    localIntro: <>
    충주댐 건설에 의해 농경지 및 인구가 1/2이상 감소되고, 상수원 보호구역으로 지정 등으로 <br></br>
    지역발전에 어려움이 있었으나, 중부내륙고속도로 개통과 더불어 3번, 36번 국도가 연결된 교 통요지이며, <br></br>
    면 관내에는 충주 낚시터 와 문강유황 온천이 있어 관광객들의 발길이 이어지고 있다. <br></br>
    또한 인근에 월악산국립공원, 수안보온천등이 있어 주변 관광지와 연계한 개발가능 잠재력이 높은 지역입니다
    </>,
    localData: {
      peopleNum: {
        total: 2014,
        man: 1021,
        women: 993
      },
      houseNum: 1117,
      area: 91.55,
      welfareCenter: <>충북 충주시 살미면 세성양지말길 41  </>,
      elementarySchool: <>1(세성초등학교) </>,
      middleSchool: <>-</>,
      highSchool: <>-</>,
      hospital: <>3(살미보건지소, 공이보건진료소, 문산보건진료소) </>,
      facility: [
        '충민공 임경업영정 ',
        '최함월 고가',
        '대림산 봉수 ',
      ],
      traffic: {
        train: ['- '],
        car: [
          '살미면 행정복지센터 - 충주 IC (16분, 18km)',
          '살미면 행정복지센터 - 북충주 IC(25분, 25km)',
          '살미면 행정복지센터 - 충주시청(19분, 14km)',
        ]
      },
      bottoomImg: Img6
    }
  },

  
  {
    id: 'sotaemyeon',
    title: '소태면',
    subject: <>충주시의 서북쪽에 위치하고 있으며, 동쪽은 엄정면, 남쪽은 중앙탑면, 서쪽은 앙성면, 북쪽은 강원도 원주시 귀래면과 부론면에 접하고 있고<br></br>
    남한강이 면의 동남서에서 서쪽으로 흐르고 있으며, 황산천을 경계로 강원도와 접경을 이루고 있으며<br></br>
    동북부에 백운산맥이 있어 산악지대가 많으며 평야가 적습니다.
    
    </>,
    images: [Slide_7_1],
    localIntro: <>
    <p className="">· 강원도 원주시와 경계지역으로 친환경농업지역(Green Village)</p>  
    <p className="">· 청룡사보각국사정혜원융탑(국보197호) 등 문화유적지역(Culture Village) </p>
    <p className="">· 남한강 중심으로 한 청정수변구역(Clean Water Village)</p>
    </>,
    localData: {
      peopleNum: {
        total: 1893,
        man: 978,
        women: 915
      },
      houseNum: 1036,
      area: 63.02,
      welfareCenter: <>충북 충주시 소태면 쟁골1길 1 소태면사무소  </>,
      elementarySchool: <>1(소태초등학교)  </>,
      middleSchool: <>-</>,
      highSchool: <>-</>,
      hospital: <>2(소태보건지소, 복탄보건진료소) </>,
      facility: [
        '청룡사지',
        '충주 청룡사지 보각국사탑',
        '태조의 스승이었던 보각국사의 묘탑 ',
        '충주 청룡사지 보각국사탑 앞 사자 석등',
        '충주 청룡사지 보각국사탑비',
        '향오공 허한부자영정',
      ],
      traffic: {
        train: ['- '],
        car: [
          '소태면 행정복지센터 - 충주 IC (30분, 23km)',
          '소태면 행정복지센터 - 북충주 IC(20분, 14km)',
          '소태면 행정복지센터 - 충주시청(29분, 21km)',
        ]
      },
      bottoomImg: Img7
    }
  },

  
  {
    id: 'suanbomyeon',
    title: '수안보면',
    subject: <>충청북도 동북부이고, 충주 동남쪽으로부터 21km 지점에 위치하고 <br></br>
    동쪽으로는제천시 한수면, 남쪽으로는 괴산군 연풍면, 서쪽으로는괴산군 장연면,<br></br>
    북쪽으로는 살미면에 접하고 있습니다.    
    
    </>,
    images: [Slide_8_1, Slide_8_2, Slide_8_3],
    localIntro: <>
    <p className="">· 천혜의 온천자원과 유적, 유물이 많은 고장 - 리듐단순유황온천, 미륵리사지, 월악산국립공원 </p>  
    <p className="">· 관광상업농의 개발이 기대되는 지역 - 야생조류, 산채류, 토종닭, 토종벌꿀</p>
    <p className="">· 산과 계곡이 깊어 산지자원화 가능지역 - 버섯, 머루, 다래등 자연식품의 소득화 </p>
    <p className="">· 전골미각의 개발 - 꿩요리, 토종닭, 산채정식 등</p>
    </>,
    localData: {
      peopleNum: {
        total: 2842,
        man: 1426,
        women: 1416
      },
      houseNum: 1639,
      area: 73.04,
      welfareCenter: <>충북 충주시 수안보면 관동길 9-9 수안보보건지소   </>,
      elementarySchool: <>2(수안보초등학교, 수회초등학교) </>,
      middleSchool: <>1(수안보중학교)</>,
      highSchool: <>-</>,
      hospital: <>5</>,
      facility: [
        '미륵리 5층 석탑 ',
        '미륵리 3층석탑 ',
        '미륵리 석불 입상 ',
        '미륵리 석등',
        '주정산봉수',
        '미륵리도요지',
        '하늘재',
        '유원지',
        '석문계곡',
        '마요폭포(뫼악폭포) ',
        '만수계곡',
        '조산공원',
      ],
      traffic: {
        train: ['-'],
        car: [
          '살미면 행정복지센터 - 충주 IC (25분, 28km)',
          '살미면 행정복지센터 - 북충주 IC(33분, 37km)',
          '살미면 행정복지센터 - 충주시청(28분, 24km)',
        ]
      },
      bottoomImg: Img8
    }
  },

  
  {
    id: 'sinaedongjigu',
    title: '시내동지구',
    subject: <>12개의 동으로 이루어져 있으며 전통적인 원핵 도시의 특징을 가지고 있습니다. 계명산과 남산을 등지고 남한강과 달천을 두르고 있는 분지 지형이며 <br></br>
    충주공용버스터미널과 충주역이 있어 타지역간 교통의 중심 역할을 수행합니다. <br></br>
    또한 시청과 법원 등 주요 행정시설들과 주요 상권들이 위치해 있어 행정 중심 역할을 맡고 있습니다. 
    
    </>,
    images: [Slide_9_1, Slide_9_2, Slide_9_3],
    /* localIntro: <>
    <p className="">· 천혜의 온천자원과 유적, 유물이 많은 고장 - 리듐단순유황온천, 미륵리사지, 월악산국립공원 </p>  
    <p className="">· 관광상업농의 개발이 기대되는 지역 - 야생조류, 산채류, 토종닭, 토종벌꿀</p>
    <p className="">· 산과 계곡이 깊어 산지자원화 가능지역 - 버섯, 머루, 다래등 자연식품의 소득화 </p>
    <p className="">· 전골미각의 개발 - 꿩요리, 토종닭, 산채정식 등</p>
    </>, */
    localData: {
      peopleNum: {
        total: 151803,
        man: 76034,
        women: 75769
      },
      houseNum: 71644,
      area: 97.73,
      welfareCenter: <>성내충인동, 교현안림동, 교현2동, 용산동, 지현동, 문화동, 호암직동, 달천동,
      봉방동, 칠금금릉동, 연수동, 목행용탄동
      </>,
      elementarySchool: <>17(목행초등학교, 금릉초등학교, 연수초등학교, 충주중앙초등학교, 칠금초등학교, 탄금초등학교,
        국원초등학교,예성초등학교, 삼원초등학교, 교현초등학교, 대림초등학교, 성남초등학교,
        용산초등학교, 남산초등학교, 충주남한강초등학교, 단월 초등학교, 충주교현초등학교)
        </>,
      middleSchool: <>9(탄금중학교, 칠금중학교, 충주중앙중학교, 충주북여자중학교, 충주여자중학교, 충일중학교,
        충주중학교, 충주예성여자중학교, 미덕중학교)
        </>,
      highSchool: <>9(국원고등학교, 한림디자인고등학교, 충주공업고등학교, 충주여자고등학교,
        대원고등학교, 충주고등학교, 예정여자고등학교, 충주상업고등학교, 중산고등학교)
        </>,
      hospital: <>-</>,
      facility: [
        '충주시청',
        '충주시립도서관',
        '충주문화회관',
        '충주호암예술관',
        '충주체육관',
        '충주종합운동장',
        '건국대학교 GLOCAL캠퍼스',
        '탄금대',
        '관아공원',
        '호암지',
        '무학시장',
        '자유시장',
        '중앙어울림시장',
      ],
      traffic: {
        train: ['충주역'],
        car: [
          '충주시청 - 충주 IC (16분, 11km)',
          '충주시청 - 북충주 IC(18분, 16km) ',
        ]
      },
      bottoomImg: Img9,
      bottoomImgSize: { height: '340px' }
    }
  },


  {
    id: 'sinnimyeon',
    title: '신니면',
    subject: <>충주시의 서북쪽 관문에 위치하여 음성군과 경계. 서울 등 대도시와의 접근성이 양호.<br></br>
    중부·중부내륙고속도로 인접(충주·북충주·음성·일죽IC) <br></br>
    평택~삼척간 동서고속도록 통과 (서충주IC 설치)
    </>,
    images: [Slide_10_1, Slide_10_2, Slide_10_3, Slide_10_4],
    localIntro: <>
    <p className="">· 대표적인 농업: 쌀(충주미소진쌀), 방울토마토, 채소, 수박, 축산업 (한우, 낙농, 양돈) </p>  
    <p className="">· 충주지역 3.1운동의 시발지며, 한국전쟁 최초의 전승지</p>
    <p className="">· 동락전승비, 김재옥 여교사 기념관 유치</p>
    </>,
    localData: {
      peopleNum: {
        total: 2781,
        man: 1430,
        women: 1351
      },
      houseNum: 1588,
      area: 59.81,
      welfareCenter: <>충북 충주시 신니면 신덕로 780 신니면사무소 
      </>,
      elementarySchool: <>2(동락초등학교, 용원초등학교) </>,
      middleSchool: <>1(신니면 중학교) </>,
      highSchool: <>-</>,
      hospital: <>2(신니보건지소, 동락보건진료소) </>,
      facility: [
        '충주 숭선사지',
        '충주 원평리석조여래입상',
        '충주 원평리 삼층석탑',
        '충주 마수리농요',
        '충주 박팽년 사당',
        '충주 신청리 고인돌',
        '충주 견학리 토성',
        '충주 숭선사지 당간지주',
        '충주 고불선원 소조여래좌상',
        '김재옥 여교사 현충탑',
        '동락전승비',
        '신니면민 만세운동 유적비',
      ],
      traffic: {
        train: ['충주역'],
        car: [
          '신니면 행정복지센터 - 충주 IC (10분, 11km)',
          '신니면 행정복지센터 - 북충주 IC(11분, 11km)',
          '신니면 행정복지센터 - 충주시청(20분, 21km)',
        ]
      },
      bottoomImg: Img10,
    }
  },


  {
    id: 'angseongmyeon',
    title: '앙성면',
    subject: <>충주시 북서쪽 32km 지점, 남한강 상류에 위치하여 서울로 가는 길목으로, 경기도 여주군, 강원도 원주시와 접한 삼도 접경지역입니다.<br></br>
    국내 유일의 대단위 탄산온천타운(능암온천지구, 중원온천지구)으로, 매년 수많은 관광객이 찾아오고 있습니다.<br></br>
    갈대와 억새, 버드나무 군락지로 장관을 이루는 생태의 보고 비내섬이 충북 최초 국가 습지보호지역으로 지정되어<br></br>
    체계적 관리와 보전을 통해 중부권 생태관광의 중심지가 될 것으로 예상됩니다.
    
    </>,
    images: [Slide_11_1, Slide_11_2, Slide_11_3, Slide_11_4, Slide_11_5],
    localIntro: <>
    비내길 마라톤대회, 소리의 섬, 비내섬 축제 등 전국적으로 유명한 지역 축제·행사를 개최하고 있습니다.<br></br>
    중부내륙고속도로와 38번 국도가 교차하며, 중부내륙선철도 앙성온천역 개통 및 충북선 고속화에 따른 편리한 교통편이 갖추어져 있습니다.<br></br>
    수도권과의 접근성 향상으로 온천관광, 비내섬 습지탐방 및 다수의 골프장 등 자연과 휴양이 바탕이 되는 북부권 관광도시로의 도약이
    기대됩니다.

    </>,
    localData: {
      peopleNum: {
        total: 3928,
        man: 2014,
        women: 1914
      },
      houseNum: 2326,
      area: 100.27,
      welfareCenter: <>충북 충주시 앙성면 가곡로 1005 
      </>,
      elementarySchool: <>1(앙성초등학교) </>,
      middleSchool: <>1(앙성중학교)  </>,
      highSchool: <>-</>,
      hospital: <>7(요양병원 2, 의원 2, 보건지소1, 보건진료소1, 병원 1) </>,
      facility: [
        '오갑사지 석불좌상',
        '지당리 석불입상',
        '강천리 석불입상',
      ],
      traffic: {
        train: ['앙성온천역'],
        car: [
          '앙성면 행정복지센터 - 충주 IC (23분, 23km)',
          '앙성면 행정복지센터 - 북충주 IC(16분, 12km)',
          '앙성면 행정복지센터 - 충주시청(24분, 28km)',
        ]
      },
      bottoomImg: Img11,
    }
  },

  {
    id: 'eomjeongmyeon',
    title: '엄정면',
    subject: <>충주시 북부에 위치하고 있으며, 동쪽은 산척면, 서쪽은 소태면 ,남쪽은 금가면, 북쪽은 제천시 백운면과 접경을 이루고,<br></br> 
    제천방면에서 서울로 통하는 교통의 중심지입니다. 충주시 북단에 위치한 중산간지로 쌀, 고추, 담배, 콩의 주산지로서 <br></br>
    벼와 콩은 종자단지의 육성단지로 지정된 지역입니다.    
    
    </>,
    images: [Slide_12_1, Slide_12_2, Slide_12_3],
    localIntro: <>
    추평저수지, 원곡소류지 축조로 전 면적의 몽리구역 및 경지정리화 목계나루터·내창장터 등 중원 물류의 옛 중심지  
    </>,
    localData: {
      peopleNum: {
        total: 3046,
        man: 1544,
        women: 1502
      },
      houseNum: 1657,
      area: 56.46,
      welfareCenter: <>충북 충주시 엄정면 내창로 185 엄정면사무소 </>,
      elementarySchool: <>1(엄정초등학교)  </>,
      middleSchool: <>1(신명중학교)</>,
      highSchool: <>1(충원고등학교)</>,
      hospital: <>4(엄정보건지소, 연세의원, 이화의원, 주치한의원) </>,
      facility: [
        '엄정 목계나루 유체꽃밭 ',
        '엄정 얼음썰매장 ',
        '목계 별신제',
        '목계 줄다리기 ',
        '억정사대지국사비 ',
        '경종대왕태실',
        '백운암철불여래좌상',
        '윤민걸고가',
        '추평리 3층석탑',
        '충주도자기마을',
      ],
      traffic: {
        train: ['-'],
        car: [
          '엄정면 행정복지센터 - 충주 IC (22분, 23km)',
          '엄정면 행정복지센터 - 북충주 IC(15분, 18km)',
          '엄정면 행정복지센터 - 충주시청(18분, 13km)',
        ]
      },
      bottoomImg: Img12,
    }
  },


  {
    id: 'judeogeub',
    title: '주덕읍',
    subject: <>충주시 서쪽에 위치하고 동쪽으로는 대소원면, 서쪽으로는 신니면과 음성군 소이면, <br></br>
    남쪽으로는 괴산군 불정면에 접하고, 북쪽으로는 노은면과 가금면을 접하고 있으며,<br></br>
    충주시의 관문으로 서울·청주를 연결하는 교통의 요충지입니다.
    </>,
    images: [Slide_13_1, Slide_13_2, Slide_13_3],
    localIntro: <>
    <p>· 서울·청주의 분기점으로 교통의 요충지</p>  
    <p>· 충주시의 관문이며 농공단지 조성으로 농외소득증대</p>  
    <p>· 읍승격 및 첨단산업단지, 기업도시 유치로 지역개발 활성화전망 </p>  
    <p>· 평야 지대로 수도, 과수집단재배, 축산업이 발달</p>  
    </>,
    localData: {
      peopleNum: {
        total: 5448,
        man: 2999,
        women: 2449
      },
      houseNum: 2678,
      area: 48.57,
      welfareCenter: <>충북 충주시 주덕읍 신덕로 1359 주덕보건지소 </>,
      elementarySchool: <>2(덕신초등학교, 주덕초등학교/주덕초등학교병설유치원)  </>,
      middleSchool: <>1(주덕중학교) </>,
      highSchool: <>1(주덕고등학교) </>,
      hospital: <>10</>,
      facility: [
        '충강공 이상급 신도비',
        '충주 용화사 석조여래입상(忠州龍華寺石造如來立像)',
      ],
      traffic: {
        train: ['주덕역'],
        car: [
          '주덕읍사무소 - 충주 IC (5분, 4.9km)',
          '주덕읍사무소 - 북충주 IC(15분, 14km)',
          '주덕덕읍사무소 - 충주시청(18분, 14km) ',
        ]
      },
      bottoomImg: Img13,
    }
  },


  {
    id: 'jungangtabmyeon',
    title: '중앙탑면',
    subject: <>'중앙탑면'은 충주시의 북서부에 위치하며 남한강과 산악으로 둘러쌓인 준 평야지대로 <br></br>
    동쪽은 금가면, 서쪽은 노은면, 남쪽은 대소원면과 주덕읍, 서북쪽은 앙성면과 소태면에 인접하며<br></br>
    충주시내와도 인접해 접근성이 좋은 편입니다.
    
    </>,
    images: [Slide_14_1, Slide_14_2, Slide_14_3],
    localIntro: <>
    국보 제6호 충주 탑평리 칠층석탑(일명 중앙탑), 국보 제205호 충주 고구려비 등 8개 지정문화재(국가 5, 도 4)와 함께 탄금호, 국제조정경기장, 충주박물관, 세계술박물관, 고구려천문과학관, 중앙탑공원, 중원체육공원, 남한강자전거길 등 폭넓은 문화관광 인프라가 구축돼 많은 관광객들이찾고 있습니다.<br></br><br></br>

    사과와 복숭아 재배를 비롯해 갈마마을의 시설채소 재배, 장천리 지역의 단무지용 무 재배 등 지역특성과 토질을 이용한 최적의 농작물 재배로 많은 농가가 높은 소득을 올리고 있습니다.

    </>,
    localData: {
      peopleNum: {
        total: 13746,
        man: 7100,
        women: 6646
      },
      houseNum: 6121,
      area: 47.75,
      welfareCenter: <>충북 충주시 중앙탑면 중앙탑길 247  </>,
      elementarySchool: <>2(중앙탑토중학교, 가흥초등학교)   </>,
      middleSchool: <>1(중앙탑중학교) </>,
      highSchool: <>1(중앙탑고등학교)  </>,
      hospital: <>7(한의원 1, 의원 2, 치과의원 2, 보건지소 1, 보건진료소 1)</>,
      facility: [
        '충주고구려비',
        '충주 탑평리 칠층석탑',
        '충주 창동 마애불',
        '장미산성',
        '충주 루암리 고분군',
        '충주 봉황리 마애불상군',
        '충주 창동 오층석탑',
        '이수일진무공신녹권 및 영정',
        '중원체육공',
        '탄금호 벚꽃길',
        '충주고구려천문과학관',
      ],
      traffic: {
        train: ['충주역에서 411번, 404번, 413번, 245번'],
        car: [
          '‘충주’ 방면으로 1.1km ',
        ]
      },
      bottoomImg: Img14,
      bottoomImgSize: { height: '300px' }
    }
  },
]

 



