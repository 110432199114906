import React, {useState, useRef} from 'react';
import styled from "styled-components";
import {TitleSection} from '../../_Include/Title';
import {Component, SectTitleBox, BodyText1, BodyText2, Divider}from '../../../../style/comp/comm';
import {Link} from "react-router-dom";

import Bg1 from "../../../../media/images/Local/bg-1.png";
import IntroMapImg from "../../../../media/images/Local/intro-map.png";
import TopIcon from "../../../../media/images/Comm/top-icon.svg";
import Arrow1 from "../../../../media/images/Comm/arrow-1.png";
import Arrow2 from "../../../../media/images/Comm/arrow-2.svg";
import BgIcon1 from "../../../../media/images/Local/local-intro-bg-icon.svg";

import IntroRegionImg1 from "../../../../media/images/Local/intro-region-1.png";
import IntroRegionImg2 from "../../../../media/images/Local/intro-region-2.png";
import IntroRegionImg3 from "../../../../media/images/Local/intro-region-3.png";
import IntroRegionImg4 from "../../../../media/images/Local/intro-region-4.png";
import IntroRegionImg5 from "../../../../media/images/Local/intro-region-5.png";
import IntroRegionImg6 from "../../../../media/images/Local/intro-region-6.png";
import IntroRegionImg7 from "../../../../media/images/Local/intro-region-7.png";
import IntroRegionImg8 from "../../../../media/images/Local/intro-region-8.png";
import IntroRegionImg9 from "../../../../media/images/Local/intro-region-9.png";
import IntroRegionImg10 from "../../../../media/images/Local/intro-region-10.png";
import IntroRegionImg11 from "../../../../media/images/Local/intro-region-11.png";
import IntroRegionImg12 from "../../../../media/images/Local/intro-region-12.png";
import IntroRegionImg13 from "../../../../media/images/Local/intro-region-13.png";

import MapImg from "../../../../media/images/Local/map/local-map.svg";
import MapChrtImg from "../../../../media/images/Local/map/local-map-chrt.svg";
import MapTtlImg from "../../../../media/images/Local/map/local-map-title.png";


const MapText= styled.span`
  &:hover {
    color: #5b9cc9;
  }
  display: inline-block;
  position: absolute;
  font-size: .9em;
  font-weight: 800;
  cursor: pointer;
  
  @media (max-width: 800px) {
    font-size: .7em;
  }
`
const MapTextEtc= styled.span`
  display: inline-block;
  position: absolute;
  font-size: .9em;
  font-weight: 800;
  color: #939393;
  @media (max-width: 800px) {
    font-size: .7em;
  }
`

const Text1= styled(MapText)` // 양성면
  top: 21%; left: 20%;
`
const Text2= styled(MapText)` // 소태면
  top: 21%; left: 39%;
`
const Text3= styled(MapText)` // 엄정면
  top: 18%; left: 53%;
`
const Text4= styled(MapText)` // 산척면
  top: 30%; left: 68%;
`
const Text5= styled(MapText)` // 노은면
  top: 36%; left: 18%;
`
const Text6= styled(MapText)` // 중앙탑면
  top: 36%; left: 37%;
`
const Text7= styled(MapText)` // 금가면
  top: 41%; left: 45%;
`
const Text8= styled(MapText)` // 동량면
  top: 43%; left: 72%;
`
const Text9= styled(MapText)` // 신니
  top: 48%; left: 12%;
`
const Text10= styled(MapText)` // 주덕읍
  top: 48%; left: 26%;
`
const Text11= styled(MapText)` // 대소원면
  top: 59%; left: 36%;
`
const Text12= styled(MapText)` // 시내동지구
  top: 54%; left: 55%;
`
const Text13= styled(MapText)` // 살미면
  top: 70%; left: 67%;
`
const Text14= styled(MapText)` // 수안보면
  top: 86%; left: 67%;
`

const Text15= styled(MapTextEtc)` // 여주시
  top: 21%; left: 3%;
`
const Text16= styled(MapTextEtc)` // 제천시
  top: 24%; left: 89%;
`
const Text17= styled(MapTextEtc)` // 음성군
  top: 66%; left: 7%;
`
const Text18= styled(MapTextEtc)` // 괴산군
  top: 77%; left: 33%;
`

const title= '지역소개';
const info= [
  {
    id: '북부권',
    title: '북부권',
    region: [
      {
        id: 'jungangtabmyeon',
        name: '중앙탑면',
        image: IntroRegionImg1,
        el: <Text6>중앙탑면</Text6>
      },
      {
        id: 'sotaemyeon',
        name: '소태면',
        image: IntroRegionImg2,
        el: <Text2>소태면</Text2>

      },
      {
        id: 'eomjeongmyeon',
        name: '엄정면',
        image: IntroRegionImg3,
        el: <Text3>엄정면</Text3>
      },
    ]
  },
  {
    id: '서부권',
    title: '서부권',
    region: [
      {
        id: 'judeogeub',
        name: '주덕읍',
        image: IntroRegionImg4,
        el: <Text10>주덕읍</Text10>
      },
      {
        id: 'angseongmyeon',
        name: '앙성면',
        image: IntroRegionImg5,
        el: <Text1>앙성면</Text1>
      },
      {
        id: 'noeunmyeon',
        name: '노은면',
        image: IntroRegionImg6,
        el: <Text5>노은면</Text5>
      },
      {
        id: 'sinnimyeon',
        name: '신니면',
        image: IntroRegionImg7,
        el: <Text9>신니면</Text9>
      },
    ]
  },
  {
    id: '동부권',
    title: '동부권',
    region: [
      {
        id: 'geumgamyeon',
        name: '금가면',
        image: IntroRegionImg8,
        el: <Text7>금가면</Text7>
      },
      {
        id: 'donglyangmyeon',
        name: '동량면',
        image: IntroRegionImg9,
        el: <Text8>동량면</Text8>
      },
      {
        id: 'sancheogmyeon',
        name: '산척면',
        image: IntroRegionImg10,
        el: <Text4>산척면</Text4>
      },
    ]
  },
  {
    id: '남부권',
    title: '남부권',
    region: [
      {
        id: 'suanbomyeon',
        name: '수안보면',
        image: IntroRegionImg11,
        el: <Text14>수안보면</Text14>
      },
      {
        id: 'daesowonmyeon',
        name: '대소원면',
        image: IntroRegionImg12,
        el: <Text11>대소원면</Text11>
      },
      {
        id: 'salmimyeon',
        name: '살미면',
        image: IntroRegionImg13,
        el: <Text13>살미면</Text13>
      },
    ]
  },
  {
    id: '시내권',
    title: '시내권',
    region: [
      {
        id: 'sinaedongjigu',
        name: '시내동지구',
        image: IntroRegionImg11,
        el: <Text12>시내동지구</Text12>
      },
    ]
  },
]

export default function Region () {

  const [drop, setDrop]= useState('');
  
  const handlerDrop= (area)=> (e)=> {
    if( drop ==  area ) {
      setDrop('');
      return;
    }
    setDrop(area);
  }

  const [selected, setSelected]= useState(null);
  const elements= useRef({});

  const handleSelect= (data)=> (e)=> {
    setTimeout(e=>elements.current[data.id].scrollIntoView(),100)
    
    setSelected(data)
    
  }
  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 충주(Local) > ${title}`} 
              title={title} 
              subtitle={'충주시 내 각각의 개성과 매력이 묻어나는 읍·면을 소개합니다!'} 
            />
            
            <div className="inst-box m-t10">
              <IconImg src={TopIcon} alt="" className="" />

              <div className="inst-box">
                <div className="bg-cover-area flx-col-cc">
                  <MapLeftBg />
                </div>
                <div className="flx-0 m-flx-col inst-box">

                  <div className="w70 m-w100 inst-box">
                    <div className="w100 mg-ct inst-box" style={{ maxWidth: 560}}>
                      <div className="bg-cover-area">
                        {
                          info.map(val=> {
                            const { id, title, region }= val;
                            return region.map( rg=> {
                              const current= selected?.region?.id == rg.id;
                              return (
                                <div key={rg.id} onClick={handleSelect({ id, title, region: rg })}
                                  style={{ 
                                    color: current ? '#3aa1ea' : 'inherit',
                                    // fontSize: current ? '16px' : 'inherit',
                                  }}
                                >
                                  {rg.el}
                                </div>
                              )
                            })
                            
                          })
                        }
                        <Text15>여주시</Text15>
                        <Text16>제천시</Text16>
                        <Text17>음성군</Text17>
                        <Text18>괴산군</Text18>
                      </div>
                      <img src={MapImg} alt="" className='w100' />
                    </div>
                  </div>

                  <MapRightArea>
                    <MapRightWrap >
                      <div className="flx-col-cr">
                        <img src={MapTtlImg} alt="" className='w100 m-hidden' />
                        <div className="w100 " style={{ maxWidth: 130}}>
                          <img src={MapChrtImg} alt="" className='w100' />
                        </div>
                      </div>
                    </MapRightWrap>
                  </MapRightArea>

                </div>
              </div>


              {/* <img src={IntroMapImg} alt="" className="w100 inst-box" /> */}

              <div className="m-t80 m-mg-t40 inst-box ">
                <div className="flx-el">
                  <SectTitleBox >지역</SectTitleBox>
                  <p className="ft-b m-l20">
                    <BodyText1>문화도시 충주, 지역별 정보를 확인해 보세요! </BodyText1>
                  </p>
                </div>


                <Divider className="inst-box m-t10"/>

                <div className="inst-box bg-clr-wh">

                  {
                    info.map(v=> {
                      const { id, title, region }= v;
                      const value= drop == id || selected?.id == id;
                      
                      return (
                        <div key={id} ref={el=> elements.current[id] = el } className="bd-bt-gr">
                          <div className="p-y10 p-x20 m-pd-15 flx-sbc cursor-point"
                            onClick={handlerDrop(id)}
                          >
                            <BodyText2 className="ft-eb">{title}</BodyText2>
                            <ArrowImg src={Arrow1} alt="" drop={value.toString()} />
                          </div>
                          {
                            value && 
                            <div className="w100 flx-0r p-x10 p-y20 m-pd-h10">
                              <div className="w90 m-w100 flx-w">
                                {
                                  region.map(rg=> {
                                    const { id, name, image }= rg;
                                    return (
                                      <div key={id} className="w50 m-w100 p-5">
                                        <Link to={`/local/intro/${id}`}>
                                        <div className="p-10 bd-gr bd-rd-20 flx-cl">
                                          <div className="w50 bd-rd-10 oflow-h">
                                            <div className="inst-box img40">
                                              <BoxImg src={image} alt="" style={{ width: '100%', objectFit:'cover'}} />
                                            </div>
                                          </div>

                                          <div className="w50 flx-0">
                                            <div className="flx-cc flx-itm-r">
                                              <BodyText2 className='ft-md'>{name}</BodyText2>
                                            </div>
                                            <ArrowImg2 src={Arrow2} alt="" className='m-r10' />
                                          </div>
                                          
                                        </div>
                                        </Link>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          }
                          
                        </div>
                      )
                    })
                  }
                  {/* <div className="bd-bt-gr">

                    <Link to={'/local/intro/sinaedongjigu'}>
                      <div className="p-10 m-pd-15 flx-sbc cursor-point"
                        // onClick={handlerDrop(id)}
                      >
                        <BodyText2 className="ft-eb">{'시내권'}</BodyText2>
                        <ArrowImg src={Arrow1} alt="" />
                      </div>
                    </Link>
                  </div> */}

                </div>
              </div>
                      
            </div>
            

            



            
          </div>
        </div>
      </Component>

      <div className="inst-box">
        <BgImg1 className="p-y80 m-pd-h40 inst-box" />
        <BttomImg src={BgIcon1} alt="" />
      </div>
    </div>
  )
}




const MapLeftBg= styled.div`
  width: 100%;
  height: 70%;
  background-image: linear-gradient(-124deg, #eae9c5, #b6c6d0);
  border-radius: 25px;
`

const MapRightArea= styled.div`
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: 800px) {
    display: none;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
`
const MapRightWrap= styled.div`
  width: 100%;
  max-width: 230px;
  margin-right: 20%;
  @media (max-width: 800px) {
    max-width: 50px;
    margin-top: -120px;
    margin-left: 10px;
  }
`

const BoxImg= styled.img`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const IconImg = styled.img`
  position: absolute;
  top: -20px; left: 0;
  width: 212px;
  @media (max-width: 800px) {
    width: 112px;
    top: 1.6%;
  }
`
const BttomImg= styled.img`
  position: absolute;
  right: calc(50% - 500px); 
  bottom: 40px;
  z-index: 2;
  width: 140px;
  @media (max-width: 800px) {
    width: 80px;
    right: calc(50% - 200px);
    display: none;
  }
`

const BgImg1= styled.div`
  /* margin-top: -10em; */
  position: relative;
  width: 100%;
  /* height: 100%; */
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(50% + 360px) -15%;
  @media (max-width: 800px) {

    background-position: calc(50% + 280px) 3%;
  }
`




const ArrowImg= styled.img`
  cursor: pointer;
  width: 16px;
  
  transform: ${(props) => props.drop =='true' || `rotate(180deg)`};
  @media (max-width: 800px) {
    width: 12px;
  }
`
const ArrowImg2= styled.img`
  width: 16px;
  @media (max-width: 800px) {
    width: 12px;
  }
`