import React from "react";
import styled from "styled-components";
import { Outlet } from 'react-router-dom';
import {ShareHeader} from "../../blocks/layouts/ShareHeader";
import {ShareFooter} from "../../blocks/layouts/ShareFooter";
import {ShareCategory} from "../../blocks/layouts/ShareCategory";
import {useRecoilValue} from "recoil";
import {MobileShowCategory, MobileShowSearchResult, ShowCategory, ShowSearchResult} from "../../../recoil/LayoutRecoil";
import {ShareSearch} from "../../blocks/layouts/ShareSearch";
import {useMediaQuery} from "react-responsive";
import {MobileShareHeader} from "../../mobile/blocks/layouts/MobileShareHeader";
import {MobileShareFooter} from "../../mobile/blocks/layouts/MobileShareFooter";
import {MobileShareCategory} from "../../mobile/blocks/layouts/MobileShareCategory";
import {MobileShareSearch} from "../../mobile/blocks/layouts/MobileShareSearch";
// import {CultureMapContents} from "../../blocks/CultureMap/CultureMapContents";
// import {MobileCultureMapContents} from "../../mobile/blocks/CultureMap/MobileCultureMapContents";
// import {TitleSection} from './Title';
import { Link } from 'react-router-dom';

import Call from "../../../media/images/Comm/call.svg"

const snsLinks= {
  youtube: 'https://www.youtube.com/@chungju_cultural_foundation',
  facebook: 'https://www.facebook.com/profile.php?id=100078656146262',
  instagram: 'https://www.instagram.com/cccj2022/',
}
export default function Include ({pathname}) {
  
  console.log(pathname)
  const ShowCategoryState = useRecoilValue(ShowCategory)
  const MobileShowCategoryState = useRecoilValue(MobileShowCategory)
  const ShowSearchResultState = useRecoilValue(ShowSearchResult)
  const MobileShowSearchResultState = useRecoilValue(MobileShowSearchResult)
  const isMobile = useMediaQuery({query: '(max-width: 800px)'});

  return (
    <Container>

      {isMobile ? <MobileShareHeader/> : <ShareHeader/>}

      <HeaderLine/>

      {ShowCategoryState ? <ShareCategory/> : <></>}

      {ShowSearchResultState ? <ShareSearch/> : <></>}

      {/* {MobileShowSearchResultState ? <MobileShareSearch/> : <></>} */}

      {MobileShowCategoryState ? <MobileShareCategory/> : <></>}


      <Outlet/>

      {/* {isMobile ? <MobileCultureMapContents/> : <CultureMapContents/>} */}

      {isMobile ? <MobileShareFooter snsLinks={snsLinks}/> : <ShareFooter snsLinks={snsLinks}/>}
      <FixedCallWrapStyle $isMobile={isMobile} $pathname={pathname}>
        <FixedCallStyle className=""  >
          <Link to={'/consultaionRequest'} className="">
            <img src={Call} alt="" className="w80 m-w70 mg-ct block-box"/>
            <p className="ft-wh ft5 txt-center m-t2">상담신청</p>
          </Link>
        </FixedCallStyle>
      </FixedCallWrapStyle>
    </Container>
  )
}

const FixedCallWrapStyle= styled.div`
  z-index: 998;
  position: fixed;
  width: 100%;
  max-width: 1120px;
  right: 50%;
  transform: translateX(50%);
  bottom: ${props=> props.$pathname == '/cultureMap' && props.$isMobile ? '17%' : '40px'};

`

const FixedCallStyle= styled.div`
  position: absolute;
  bottom: 0; right: 20px;
  /* right: 0; */
  /* margin-right: -32px; */
  width: 64px;
  height: 64px;
  border-radius: 10px;
  padding: 6px;
  background-image: linear-gradient(-124deg, #e9e223, #92caf0);
  @media (max-width: 800px) {
    width: 52px;
    height: 52px;
    right: 15px;
    margin-right: 0;
    padding: 6px;
  }
`


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: #f5f5f1;
`

const HeaderLine = styled.div`
  border-bottom: 1px solid #000000;
  width: 100%;
`

