import React from "react";
import styled from "styled-components";
import ResponsiveContainer from "../../../hooks/ResponsiveContainer";
import AboutChoongJu from '../../../media/images/MainHome/CultureChoongJu/aboutChoongJu.webp'
import IntroLocal from '../../../media/images/MainHome/CultureChoongJu/introLocal.webp'
import CultureMap from '../../../media/images/MainHome/CultureChoongJu/cultureMap.webp'
import SmallWhiteArrow from '../../../media/images/MainHome/CultureChoongJu/smallWhiteArrow.webp'
import PatternImg from '../../../media/images/MainHome/CultureChoongJu/pattern2.webp'
import { Link } from 'react-router-dom';

export const CultureChoongJu = () => {

  return (
    <ResponsiveContainer>
      <Component>

        <PatternImage src={PatternImg}/>

        <MainText>About 충주</MainText>
        <SubText>충주의 모든 것(지역/문화공간)을 알려드립니다.</SubText>
        <Link to={'/local/about'}>
        <MoreText>목록보기</MoreText>
        </Link>
        <Contents>
          {/* 충주에 대해서 카드*/}
          
          
            <ImageSection>
              <Link to={'/local/about'}>
                <ImageWrap>
                  <ImageItem bgImage={AboutChoongJu}/>
                  <TextSection>
                    <ImageText>충주에 대해서</ImageText>
                    <ImageArrow src={SmallWhiteArrow}/>
                  </TextSection>
                </ImageWrap>

                <ImageSubText>매력적인 도시 충주를 소개합니다.</ImageSubText>
              </Link>
            </ImageSection>

          {/* 지역소개 카드*/}
          <ImageSection>
            <Link to={'/local/intro'}>
              <ImageWrap>
                <ImageItem bgImage={IntroLocal}/>
                <TextSection>
                  <ImageText>지역소개</ImageText>
                  <ImageArrow src={SmallWhiteArrow}/>
                </TextSection>
              </ImageWrap>

              <ImageSubText>충주의 다양한 지역(읍/면)을 만나보세요.</ImageSubText>
            </Link>
          </ImageSection>

          {/* 문화지도 카드 */}
          <ImageSection>
            <Link to={'/cultureMap'}>
              <ImageWrap>
                <ImageItem bgImage={CultureMap}/>
                <TextSection>
                  <ImageText>문화 지도</ImageText>
                  <ImageArrow src={SmallWhiteArrow}/>
                </TextSection>
              </ImageWrap>

              <ImageSubText>충주 로컬 크리에이터 공간을 한 눈에 확인해보세요.</ImageSubText>
            </Link>
          </ImageSection>
        </Contents>

        <CultureLine/>
      </Component>
    </ResponsiveContainer>
  )
}

const Component = styled.div`
  width: 1434px;
  position: relative;

  @media (max-width: 1920px) {
    width: 74.6875vw;
  }
`
const PatternImage = styled.img`
  position: absolute;
  left: 485px;
  top: -30px;
  width: 315px;
  height: 267px;

  @media (max-width: 1920px) {
    left: 25.2604vw;
    top: -1.5625vw;
    width: 16.4063vw;
    height: 13.9063vw;
  }
`

const MainText = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  margin-bottom: 19px;

  @media (max-width: 1920px) {
    font-size: 2.0833vw;
    margin-bottom: 0.9896vw;
  }
`

const SubText = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  margin-bottom: 13px;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
    margin-bottom: 0.6771vw;
  }
`

const MoreText = styled.text`
  color: #939598;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
  }
`

const Contents = styled.div`
  display: flex;
  margin-top: 27px;
  margin-bottom: 44px;

  @media (max-width: 1920px) {
    margin-top: 1.4063vw;
    margin-bottom: 2.2917vw;
  }
`

const ImageSection = styled.div`
  margin-right: 26px;

  @media (max-width: 1920px) {
    margin-right: 1.3542vw;
  }

  &:last-child {
    margin-right: 0
  }
`

const ImageItem = styled.div`
  width: 459px;
  height: 316px;
  background-image: url(${props => props.bgImage});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: background-size 1.5s ease; // 부드러운 효과
  border: none;

  @media (max-width: 1920px) {
    width: 23.9063vw;
    height: 16.4583vw;
  }
`


const ImageWrap = styled.div`
  position: relative;
  cursor: pointer;

  &:hover ${ImageItem} {
    background-size: 110% 110%; // 확대 효과
  }
`

const TextSection = styled.div`
  position: absolute;
  bottom: 12px;
  left: 13px;
  display: flex;
  align-items: center;

  @media (max-width: 1920px) {
    bottom: 0.625vw;
    left: 0.6771vw;
  }
`

const ImageText = styled.div`
  color: #fffefe;
  font-family: 'SCDream6', serif;
  font-size: 25.5px;

  @media (max-width: 1920px) {
    font-size: 1.3281vw;
  }
`

const ImageArrow = styled.img`
  width: 21px;
  height: 8px;
  margin-left: 7px;
  margin-bottom: 8px;

  @media (max-width: 1920px) {
    width: 1.0938vw;
    height: 0.4167vw;
    margin-left: 0.3646vw;
    margin-bottom: 0.4167vw;
  }
`

const ImageSubText = styled.div`
  margin-top: 11px;
  font-size: 18px;
  font-family: 'SCDream2', serif;
  margin-left: 12px;

  @media (max-width: 1920px) {
    margin-top: 0.5729vw;
    font-size: 0.9375vw;
    margin-left: 0.625vw;
  }
`

const CultureLine = styled.div`
  border-bottom: 2px solid black;
  margin-bottom: 105px;

  @media (max-width: 1920px) {
    border-bottom: 0.1042vw solid black;
    margin-bottom: 5.4688vw;
  }
`