
import styled from "styled-components";
import { BoardTopImg } from '../_Include/Title';

import TopIcon2 from "../../../media/images/Comm/top-icon-2.svg";
import TopIcon3 from "../../../media/images/People/top-icon-1.svg";
import TopIcon4 from "../../../media/images/People/top-icon-2.svg";
import {
  live_long, live_recent,
} from "../../../api/People";

const TopIcon2Style= styled.img`
  position: absolute;
  bottom: -20px; right: 10%;
  width: 80%;
`
const TopIcon3Style= styled.img`
  position: absolute;
  bottom: -20px; right: 30px;
  width: 90%;
`
const eventFilter= ['전체', '진행중', '진행마감']
const searchFilter= [
  { key: '전체', value: 'all' }, 
  { key: '제목', value: 'subject' }, 
  { key: '내용', value: 'content' }, 
]
export const info= [
  {
    id: 'long-life',
    key: 'livelong',
    name: '오래 살아보면',
    // query: community_culture,
    title: '오래 살아보면',
    path: '사람(People)',
    subject: '충주 시민의 목소리를 통해 매력적인 충주를 느껴보세요! ',
    url: '/people/long-life',
    filter_1: eventFilter,
    filter_2: searchFilter,
    disableBadge: true,
    images: [<BoardTopImg icon={<TopIcon2Style src={TopIcon3} alt="" />}/>]
  },
  {
    id: 'recently-life',
    key: 'liverecent',
    name: '최근 살아보면',
    // query: community_business,
    title: '최근 살아보면',
    path: '사람(People)',
    subject: '충주 시민의 목소리를 통해 매력적인 충주를 느껴보세요! ',
    url: '/people/recently-life',
    filter_1: eventFilter,
    filter_2: searchFilter,
    disableBadge: true,
    images: [<BoardTopImg icon={<TopIcon3Style src={TopIcon4} alt="" />}/>]
  },
];

export const queries= {
  ['long-life']: {
    key: 'live_long',
    query: live_long
  },
  ['recently-life']: {
    key: 'live_recent',
    query: live_recent
  },
}