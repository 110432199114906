import React from "react";
import styled from "styled-components";
import NoticeImage from '../../../../media/images/MainHome/Notice/notice.webp'
import NoticeArrow from '../../../../media/images/MainHome/Notice/noticeArrow.webp'
import {useQuery} from "react-query";
import {main_notice_list} from "../../../../api/MainHome";
import {Link, useNavigate} from "react-router-dom";

export const MobileNotice = () => {
  const { data:main_notice_list_data, error, isLoading } = useQuery('main_notice_list', main_notice_list);
  const navigate = useNavigate();

  const handleContentClick = (data) => {
    navigate(`/noticeDetail/${data.id}`, { state: { data: data, allData: main_notice_list_data.articles, selectedCategory: '공지사항' } });
  };
  console.log(main_notice_list_data)

  return (
    <>

      <Container>
        <MainText>공지사항</MainText>
        <SubTextSection>
          <SubText>문화도시 충주의 새로운 소식을 지금 바로 알아보세요.</SubText>
          <Link to="/notice/notice">
            <MoreText>더 알아보기</MoreText>
          </Link>
        </SubTextSection>
      </Container>

      <NoticeContainer>
        {main_notice_list_data && main_notice_list_data.articles
          .filter(article => article.event_text === "접수중")
          .sort((a, b) => new Date(b.createdate) - new Date(a.createdate))
          .slice(0, 4)
          .map((data) => (
          <>
            <NoticeLineBox onClick={() => handleContentClick(data)}>
              <NoticeHead>
                {/*{data.category === '공지' ? <NoticeCategory src={NoticeImage}/> : <></>}*/}
                <NoticeCategory src={NoticeImage}/>
                <NoticeState>{data.event_text}</NoticeState>
                <NoticeTitle>{data.subject}</NoticeTitle>
              </NoticeHead>

              <NoticeTail>
                <NoticeDate>{data.createdate.slice(0, 10)}</NoticeDate>
                <NoticeArrowImage src={NoticeArrow}/>
              </NoticeTail>
            </NoticeLineBox>

            <RegularLine/>
          </>
        ))}
      </NoticeContainer>

    </>
  )
}

const Container = styled.div`
  width: 93.48vw;
`;

const MainText = styled.div`
  font-size: 5.71vw; /* 70px */
  font-family: 'SCDream7', serif;
  margin-bottom: 1.96vw; /* 24px */
`;

const SubTextSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubText = styled.div`
  font-size: 3.22vw; /* 39.5px */
  font-family: 'SCDream4', serif;
  margin-bottom: 1.55vw; /* 19px */
  letter-spacing: -0.193vw;
`;

const MoreText = styled.text`
  color: #939598;
  font-size: 3.22vw; /* 39.5px */
  text-decoration: underline;
  font-family: 'SCDream4', serif;
  cursor: pointer;
`;

const NoticeContainer = styled.div`
  border-bottom: 0.245vw solid black; /* 3px */
  border-top: 0.245vw solid black; /* 3px */
  width: 93.48vw;
  margin-top: 5.06vw; /* 62px */
  margin-bottom: 11.18vw; /* 137px */
  background-color: white;
`;

const RegularLine = styled.div`
  border: 0.082vw solid black; /* 1px */
  width: 100%;

  &:last-child {
    border: 0;
  }
`;

const NoticeLineBox = styled.div`
  display: flex;
  padding: 2.69vw 0; /* 33px */
  justify-content: space-between;
  cursor: pointer;
`;

const NoticeHead = styled.div`
  display: flex;
  align-items: center;
`;

const NoticeTail = styled.div`
  display: flex;
  align-items: center;
`;

const NoticeCategory = styled.img`
  margin-right: 4.41vw; /* 54px */
  margin-left: 2.20vw; /* 27px */
  width: 8.08vw; /* 99px */
  height: 3.83vw; /* 47px */
`;

const NoticeState = styled.div`
  font-size: 2.49vw; /* 30.5px */
  font-family: 'SCDream5', serif;
  margin-right: 4.73vw; /* 58px */
`;

const NoticeTitle = styled.div`
  font-size: 2.49vw; /* 30.5px */
  font-family: 'SCDream5', serif;
  width: 39.32vw; /* 482px */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NoticeDate = styled.div`
  font-size: 2.12vw; /* 26px */
  font-family: 'SCDream4', serif;
`;

const NoticeArrowImage = styled.img`
  padding-bottom: 0.57vw; /* 7px */
  margin-left: 4.16vw; /* 51px */
  margin-right: 2.94vw; /* 36px */
  width: 4vw; /* 49px */
  height: 1.63vw; /* 20px */
`;
