import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import SearchIconImage from "../../../../media/mobileImage/TotalSearch/mobileTotlaSearchIcon.webp";
import DropIconImage from "../../../../media/images/CultureMap/cultureMapSearchArrow.webp";
import SecondButtonImage from "../../../../media/mobileImage/CultureMap/mobileCulutreMapSecondArrow.webp";
import PagePrevIcon from "../../../../media/images/CultureMap/pagePrevIcon.webp";
import PageNextIcon from "../../../../media/images/CultureMap/pageNextIcon.webp";
import CategoryIcon from "../../../../media/images/CultureMap/cultureMapIcon.webp";
import ListIcon from '../../../../media/mobileImage/CultureMap/mobileCultureMapListIcon.webp'
import MapIcon from '../../../../media/mobileImage/CultureMap/mobileCultureMapIcon.webp'
import {useQuery} from "react-query";
import {map_list} from "../../../../api/Map";
import RedMarker from "../../../../media/images/CultureMap/mapRedMarker.png";
import {PUBLISH_URL} from "../../../../api/global";
import { UltimatePagination } from '../../../blocks/_bbs/Pagination'

export const MobileMapItem = () => {
  const [showCategory, setShowCategory] = useState(false);
  const [categoryValue, setCategoryValue] = useState('전체');
  const [isFirstHidden, setIsFirstHidden] = useState(false);
  const [isSecondHidden, setIsSecondHidden] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [contentsState, setContentsState] = useState('map')
  const userAgent = window.navigator.userAgent.toLowerCase();
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const itemsPerPage = 4; // 페이지 당 항목 수
  const mapRef = useRef();
  const mapInstanceRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const { data:map_list_data, error, isLoading } = useQuery(['map_list', {
    searchValue, categoryValue,
    // currentPage
  }], map_list);
  const [markers, setMarkers] = useState([]);
  // const totalPages = Math.ceil(map_list_data && map_list_data.articles.length / itemsPerPage); // 전체 페이지 수
  const [totalPages, setTotalPages]= useState(0)
  const scrollWrapperRef = useRef();
  const [selectedMarker, setSelectedMarker] = useState(null);


  useEffect(() => {
    const initialCenter = map_list_data && map_list_data.articles && map_list_data.articles.length > 0
      ? new window.naver.maps.LatLng(map_list_data.articles[0].latitude, map_list_data.articles[0].longitude)
      : new window.naver.maps.LatLng(36.9940614, 127.8782901);

    const map = new window.naver.maps.Map(mapRef.current, {
      center: initialCenter,
      zoom: 13,
    });

    map.setOptions({
      scaleControl : true, 
      logoControl: false, 
      // minZoom: 14, maxZoom: 14,
      
      /* draggable: true,
      pinchZoom: true,
      scrollWheel: true,
      keyboardShortcuts: true,
      disableDoubleTapZoom: false,
      disableDoubleClickZoom: false,
      disableTwoFingerTapZoom: false */
    })

    mapInstanceRef.current = map;

    let newMarkers = [];

    if (map_list_data && map_list_data.articles) {
      setTotalPages(Math.ceil(map_list_data && map_list_data.total_count / itemsPerPage) || null)
      map_list_data.articles.forEach((article) => {
        if (article.latitude && article.longitude) {
          const marker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(article.latitude, article.longitude),
            map: map,
            icon: {
              url: 'URL_TO_DEFAULT_ICON_IMAGE',
              size: new window.naver.maps.Size(22, 32), // Adjust the size to match the RedMarker size
              origin: new window.naver.maps.Point(0, 0), // You might adjust these values as well if needed
              anchor: new window.naver.maps.Point(11, 32) // Make sure anchor points are consistent
            }
          });

          // 마커 클릭 이벤트 추가
          window.naver.maps.Event.addListener(marker, 'click', () => {
            hadleMarkerClick(article, marker); // 해당 정보의 SecondSearchDepthSection 열기
          });

          newMarkers.push({ marker, article });
        }
      });

      setMarkers(newMarkers);
    }

    return () => {
      newMarkers.forEach(({ marker }) => marker.setMap(null));
    };
  }, [map_list_data]);

  const handleSelection = (value) => {
    setCategoryValue(value)
    setShowCategory(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleResultClick = (data) => {
    setSelectedItem(data);

    // Use the existing map instance to set the center
    if (mapInstanceRef.current) {
      mapInstanceRef.current.setCenter(new window.naver.maps.LatLng(data.latitude, data.longitude));
    }

    markers.forEach(({ marker, article }) => {
      if (article === data) {
        marker.setIcon({
          url: RedMarker,
          size: new window.naver.maps.Size(32, 32), // 조절된 크기
          scaledSize: new window.naver.maps.Size(32, 32), // 또는 스케일링하고 싶은 크기로 조절
          origin: new window.naver.maps.Point(0, 0),
          anchor: new window.naver.maps.Point(16, 32)
        });
      } else {
        marker.setIcon({
          url: 'URL_TO_DEFAULT_ICON_IMAGE',
          size: new window.naver.maps.Size(22, 32),
          origin: new window.naver.maps.Point(0, 32),
          anchor: new window.naver.maps.Point(6, 32)
        });
      }
    });

    setIsSecondHidden(false);
  }

  const handleContentsState = (state) => {
    setIsSecondHidden(true);

    if (state === 'list') {
      setContentsState('map')
    } else if (state === 'map') {
      setContentsState('list')
    }

  }

  const hadleMarkerClick = (data, clickedMarker) => {
    setSelectedItem(data);
    setContentsState('list')

    setIsFirstHidden(false); // FirstSearchDepthSection 열기
    setIsSecondHidden(false); // SecondSearchDepthSection 열기
    setSelectedMarker(clickedMarker);


    // 스크롤 최상단 위치
    if (scrollWrapperRef.current) {
      scrollWrapperRef.current.scrollTop = 0;
    }

  }

  // 마커 렌더링 부분 수정
  useEffect(() => {
    markers.forEach(({ marker, article }) => {
      if (marker === selectedMarker) {
        marker.setIcon({
          url: RedMarker,
          size: new window.naver.maps.Size(32, 32),
          scaledSize: new window.naver.maps.Size(32, 32),
          origin: new window.naver.maps.Point(0, 0),
          anchor: new window.naver.maps.Point(16, 32)
        });
      } else {
        marker.setIcon({
          url: 'URL_TO_DEFAULT_ICON_IMAGE',
          size: new window.naver.maps.Size(22, 32),
          origin: new window.naver.maps.Point(0, 32),
          anchor: new window.naver.maps.Point(6, 32)
        });
      }
    });
  }, [markers, selectedMarker]);


  const handleSearchIconClick = () => {
    console.log(searchTerm)
    if( searchTerm == '' ) {
      setSearchValue(searchTerm);
      return;
    }
    if (searchTerm.length < 2) {
      alert('2자 이상 입력해주세요');
    } else {
      setSearchValue(searchTerm)
      if (contentsState === 'map') {
        setContentsState('list')
      }
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };


  return (
    <Component>
      <Contents>


        {/* 리스트 및 맵 변환 버튼 */}
        <ListOrMapIcon 
          src={contentsState === 'list' ? MapIcon : ListIcon}
          onClick={() => {
            handleContentsState(contentsState)
          }}
        />


        {/* 검색창 */}
        <SearchSection>
          <SearchInput placeholder={'검색어를 입력하세요.'} value={searchTerm} onChange={handleInputChange} onKeyDown={(e) => e.key === 'Enter' && handleSearchIconClick()}/>
          <SearchIcon src={SearchIconImage} onClick={handleSearchIconClick}/>
        </SearchSection>


        {/* 지도 맵 사진 */}
        <MapWrapper ref={mapRef} />


        {/* 데이터 리스트 패이지 */}
        {contentsState === 'list' &&
          <>
            <FirstSearchDepthSection
              isFirstHidden={isFirstHidden}
              onClick={() => {
                if (showCategory) {
                  setShowCategory(false)
                }
              }}>

              <DropdownSection>
                <DropdownInput onClick={() => setShowCategory(!showCategory)}
                               readOnly value={categoryValue}/>
                <DropIcon src={DropIconImage} Medge={userAgent.includes('windows')}/>
                {showCategory && (
                  <DropdownListSection>
                    <DropdownListText onClick={() => handleSelection("전체")}>전체</DropdownListText>
                    <DropdownListText onClick={() => handleSelection("제목")}>제목</DropdownListText>
                    <DropdownListText onClick={() => handleSelection("내용")}>내용</DropdownListText>
                  </DropdownListSection>
                )}
              </DropdownSection>

              <FirstResultSection>
                {map_list_data && map_list_data.articles ? (
                  map_list_data.articles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((data) => (
                    <ResultList onClick={() => handleResultClick(data)}>
                      <ResultSpaceimg src={`${PUBLISH_URL}${data.images[0]}`}/>

                      <ResultTextSection Medge={userAgent.includes('windows')}>
                        <ResultCategory>{data.category}</ResultCategory>
                        <ResultName>{data.subject}</ResultName>
                        <ResultAddress>{data.address}</ResultAddress>
                        <ResultPhone>{data.phone}</ResultPhone>
                      </ResultTextSection>
                    </ResultList>
                  ))
                ) : (
                  <NoSearch>검색 결과가 없습니다.</NoSearch>
                )}


                {/* 페이지네이션 */}
                {
                  totalPages && 
                  <div className="p-y20">
                    <UltimatePagination
                      totalPages={totalPages}
                      boundaryPagesRange={1}
                      siblingPagesRange={2}
                      currentPage={currentPage}
                      onChange={(page)=>setCurrentPage(page)}
                    />
                  </div>
                }
                {/* {map_list_data && map_list_data.articles && (
                  <PaginationSection>
                    <NextPrevButton onClick={handlePrevPage} src={PagePrevIcon}/>
                    {Array.from({length: totalPages}).map((_, idx) => {
                      // 현재 페이지 주변에만 버튼 표시
                      if (idx >= currentPage - 3 && idx <= currentPage + 3) {
                        return (
                          <PageButton key={idx} onClick={() => setCurrentPage(idx + 1)} isActive={currentPage === idx + 1}>
                            {idx + 1}
                          </PageButton>
                        );
                      }
                      return null;
                    })}
                    <NextPrevButton onClick={handleNextPage} src={PageNextIcon}/>
                  </PaginationSection>
                )} */}
              </FirstResultSection>

            </FirstSearchDepthSection>


            {/* 리스트 디테일 페이지 */}
            <SecondSearchDepthSection isSecondHidden={isSecondHidden}>
              <SecondHeaderSection>
                <SecondButton src={SecondButtonImage} onClick={() => setIsSecondHidden(!isSecondHidden)}/>
              </SecondHeaderSection>

              <SecondImage src={selectedItem && `${PUBLISH_URL}${selectedItem.images[0]}`}/>
              <SecondCateforySection>
                <SecondCateforyIcon src={CategoryIcon}/>
                <SecondCategory>{selectedItem && selectedItem.category}</SecondCategory>
              </SecondCateforySection>

              <SecondName>공간명 {selectedItem ? selectedItem.subject : ''}</SecondName>
              <SecondAddress>주소 {selectedItem ? selectedItem.address : ''}</SecondAddress>
              <SecondPhone>연락처 {selectedItem ? selectedItem.phone : ''}</SecondPhone>

              <SecondLine/>

              <SecondDetail dangerouslySetInnerHTML={{ __html: selectedItem && selectedItem.content.replace(/\/data\/images/g, `${PUBLISH_URL}/data/images`)}} />
            </SecondSearchDepthSection>
          </>
        }


      </Contents>
    </Component>
  )
}

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  position: relative;
`

const Contents = styled.div`
  width: 100vw;
  background-color: white;
  position: relative;
`

const MapWrapper = styled.div`
  width: 100%;
  height: 163.94vw;
`

const ListOrMapIcon = styled.img`
  z-index: 9999999;
  width: 16.07vw;
  height: 16.07vw;
  position: fixed;
  bottom: 7.63vw;
  right: 4.08vw;
  cursor: pointer;
`

const SearchSection = styled.div`
  width: 100vw;
  height: 14.43vw;
  align-items: center;
  display: flex;
  position: relative;
`

const FirstSearchDepthSection = styled.div`
  width: 100vw;
  height: 163.94vw;
  background-color: white;
  position: absolute;
  top: 14.41vw;
  right: ${props => props.isFirstHidden ? '-34.00vw' : '0'};
  transition: right 0.3s ease;
  z-index: 9999;
`

const SearchInput = styled.input`
  width: 91.82vw;
  height: 7.67vw;
  background-color: #f1f2f2;
  border-radius: 3.38vw;
  border: none;
  font-size: 3.26vw;
  font-family: 'SCDream4', serif;
  padding-left: 3.83vw;
  box-sizing: border-box;
  margin-left: 4.08vw;
`

const SearchIcon = styled.img`
  position: absolute;
  width: 4.40vw;
  height: 4.65vw;
  top: 4.90vw;
  right: 6.53vw;
`

const FirstResultSection = styled.div`
  width: 100vw;
  border-top: 0.24vw solid #a7a9ac;
`

const DropdownSection = styled.div`
  position: relative;
  /* width: 9.79vw; */
  margin-left: 5.63vw;
  margin-bottom: 1.30vw;
  display: flex;
  align-items: center;
`

const DropdownInput = styled.input`
  border: none;
  /* font-size: 3.02vw; */
  /* font-family: 'SCDream5', serif; */
  width: 36px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
`

const DropdownListSection = styled.div`
  border: 0.08vw solid #959595;
  position: absolute;
  width: 9.55vw;
  top: 4.00vw;
  background-color: white;
`

const DropdownListText = styled.div`
  font-weight: 500;
  padding: 2px 4px;
  /* margin-left: 0.16vw;
  margin-top: 0.82vw;
  margin-bottom: 0.82vw; */
  /* font-size: 3.02vw; */
  /* font-family: 'SCDream5', serif; */
  cursor: pointer;
`

const DropIcon = styled.img`
  width: 10px;
  /* height: 1.96vw; */
`

const ResultList = styled.div`
  display: flex;
  width: 100vw;
  height: 36.06vw;
  border-bottom: 0.24vw solid #c5c5c5;
  cursor: pointer;

  &:last-child {
    border-bottom: transparent;
  }
`

const ResultSpaceimg = styled.img`
  width: 26.76vw;
  height: 26.76vw;
  margin-left: 4.08vw;
  margin-top: 3.92vw;
`

const ResultTextSection = styled.div`
  margin-left: 4.65vw;
  margin-top: 5.71vw;
`

const ResultCategory = styled.div`
  font-size: 3.88vw;
  font-family: 'SCDream4', serif;
  color: #5b9dc9;
  margin-bottom: 9.39vw;
`

const ResultName = styled.div`
  font-size: 3.10vw;
  font-family: 'SCDream4', serif;
`

const ResultAddress = styled.div`
  font-size: 3.10vw;
  font-family: 'SCDream4', serif;
`

const ResultPhone = styled.div`
  font-size: 3.10vw;
  font-family: 'SCDream4', serif;
`

const PaginationSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.08vw;
`

const NextPrevButton = styled.img`
  margin: 0 7.92vw;
  cursor: pointer;
  width: 2.04vw;
  height: 2.45vw;
`

const PageButton = styled.div`
  font-size: 3.38vw;
  font-family: 'SCDream6', serif;
  margin: 0 3.26vw;
  cursor: pointer;
  color: ${props => props.isActive ? '#5b9dc9' : 'inherit'};
`

const SecondSearchDepthSection = styled.div`
  width: 100vw;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: ${props => props.isSecondHidden ? '-100vw' : '0'};
  transition: left 0.3s ease;
  z-index: 99999;
  border: #d1d3d4 0.08vw solid;
  padding-bottom: 3vw;

  overflow-y: scroll;
  overflow-x: hidden;
`

const SecondButton = styled.img`
  width: 3.92vw;
  height: 6.12vw;
  position: absolute;
  left: 4.73vw;
  top: 3.59vw;
  cursor: pointer;
`

const SecondImage = styled.img`
  width: 91.82vw;
  height: 59.38vw;
  margin-top: 0.90vw;
  margin-left: 4.08vw;
`

const SecondCateforySection = styled.div`
  display: flex;
  margin-top: 3.67vw;
  align-items: center;
`

const SecondCateforyIcon = styled.img`
  width: 3.50vw;
  height: 3.67vw;
  margin-left: 4.48vw;
  margin-right: 1.06vw;
`

const SecondCategory = styled.div`
  font-size: 3.83vw;
  color: #5b9dc9;
  font-family: 'SCDream4', serif;
`

const SecondName = styled.div`
  margin-top: 5.14vw;
  font-size: 3.22vw;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 4.48vw;
  margin-bottom: 1.22vw;
`

const SecondAddress = styled.div`
  font-size: 3.22vw;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 4.48vw;
  margin-bottom: 1.22vw;
`

const SecondPhone = styled.div`
  font-size: 3.22vw;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 4.48vw;
`

const SecondLine = styled.div`
  border-bottom: 0.16vw solid #d1d3d4;
  width: 91.67vw;
  margin-top: 5.38vw;
  margin-bottom: 5.22vw;
  margin-left: 4.24vw;
`

const SecondDetail = styled.div`
  white-space: pre-line;
  font-size: 3.22vw;
  font-family: 'SCDream4', serif;
  color: #464646;
  margin-left: 4.65vw;
  line-height: 5.14vw;
  
`

const NoSearch = styled.div`
  font-size: 4.77vw;
  font-family: 'SCDream5', serif;
  margin-top: 18.67vw;
  margin-left: 29.52vw;
`

const SecondHeaderSection = styled.div`
  width: 100%;
  height: 13.62vw;
  border-bottom: 0.24vw solid #a7a9ac;
`

