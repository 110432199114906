import styled from "styled-components";
import { BoardTopImg } from '../../_Include/Title';

// import TopIcon2 from "../../../../media/images/Comm/top-icon-2.svg";
import TopIcon3 from "../../../../media/images/Life/top-icon-1.svg";
import TopIcon4 from "../../../../media/images/Life/top-icon-2.svg";
import {
  community_culture, community_business,
} from "../../../../api/Community";

const TopIcon2Style= styled.img`
  position: absolute;
  bottom: -20px; right: 0;
  /* width: 80%; */
`
const TopIcon3Style= styled.img`
  position: absolute;
  bottom: -20px; right: 20px;
  width: 100%;
`
const eventFilter= ['전체', '진행중', '진행마감']
const searchFilter= [
  { key: '전체', value: 'all' }, 
  { key: '제목', value: 'subject' }, 
  { key: '내용', value: 'content' }, 
]
export const info= [
  {
    id: 'honey',
    key: 'communityculture',
    name: '문화꿀단지',
    title: '문화꿀단지',
    path: '살면(Life)',
    subject: '충주의 다양한 문화사업 현황을 확인해보세요!',
    url: '/life/community/honey',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<BoardTopImg icon={<TopIcon2Style src={TopIcon3} alt="" />}/>]
  },
  {
    id: 'out',
    key: 'communitybusiness',
    name: '집밖문지방',
    title: '집밖문지방',
    path: '살면(Life)',
    subject: '충주의 다양한 문화사업 현황을 확인해보세요!',
    url: '/life/community/out',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<BoardTopImg icon={<TopIcon3Style src={TopIcon4} alt="" />}/>]

  },
];

export const queries= {
  honey: {
    key: 'community_culture',
    query: community_culture
  },
  out: {
    key: 'community_business',
    query: community_business
  },
}