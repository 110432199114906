import React from "react";
import styled from "styled-components";
import {TitleSection} from '../_Include/Title';
import {Component, SectTitle, SectTitleBox, SubTitle0, SubTitle1, BodyText1, BodyText2, TinyText1, Divider, SubTitle3, Divider1} from '../../../style/comp/comm';

import About5 from "../../../media/images/About/about-5.png";
import BgIcon1 from "../../../media/images/Policy/icon-img-4.svg";
import Bg1 from "../../../media/images/Comm/bg-2.png";


import ManImage from '../../../media/images/Policy/icon-img-3.svg'
// import PatternImage from '../../../media/images/TotalSearch/totalSearchPattern.webp'
import PatternImage from '../../../media/images/Comm/top-icon-4.svg'

import OpneTypeImg1 from '../../../media/images/Policy/img_opentype02.png'
import OpneTypeImg2 from '../../../media/images/Policy/img_opentype04.png'


const title= '저작권정책';

const IconSectStyle = styled.div`
  position: absolute;
  top: 0; right: 40px;
  width: 100%;
  max-width: 150px;
  transform: translateY(-100%);
  @media (max-width: 800px) {
    max-width: 80px;
    right: 4px;
    /* display: none; */
  }
`

const IconWrapStyle = styled.div`
  position: relative;
  margin-bottom: -1em;
  overflow: hidden;
  padding-top: 140%;
`
const Icon1Style = styled.img`
  width: 100%;
  position: absolute;
  top: 40%; left: 0%;
`
const Icon2Style = styled.img`
  width: 60%;
  position: absolute;
  bottom: 0px; left: 20%;
`
export default function Copyright () {

  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10">
          <div className="inst-box">
            <TitleSection 
              path={`홈 >  ${title}`} 
              title={title} 
              subtitle={''} 
            />

            <div className="w100 inst-box m-t60">
              {/* <IconImg src={TopIcon} alt="" className="" /> */}
              <IconSectStyle className="m-x10">
                <IconWrapStyle>
                  <Icon1Style src={PatternImage} alt="" />
                  <Icon2Style src={ManImage} alt="" />
                </IconWrapStyle>
              </IconSectStyle>

              <div className="inst-box bg-clr-wh w100 mx1000 mg-ct">
                
                <div className="p-30">
                  <SubTitle1 className="ft-hv">공공저작물 자유이용허락표시기준</SubTitle1>
                  <p className="lh-18 txt-keep m-t20">
                    <BodyText1 className="">
                      저작권법 제24조의2(공공저작물의 자유이용)에 따라 충주이주플랫폼에서<br></br> 
                      저작권재산의 전부를 보유하고 있거나 자유이용허락표시에 대한 권리자의 동의를 받은 경우는 <br></br> 
                      <span className="ft-b">“공공저작물 자유이용허락표시기준(공공누리, KOGL)”</span>을 부착하여 별도의 이용허락 없이 자유이용이 가능합니다.
                    </BodyText1>
                  </p>

                  <p className="lh-18 txt-keep m-t20">
                    <BodyText1 className="">
                    충주이주플랫폼의 각 게시물에 부착된 공공누리표시를 확인 한 이후 자유롭게 이용하시기 바랍니다.<br></br> 
                    더불어, 저작자가 별도로 표시된 일러스트 및 사진은 제외되므로 이 점 유념 바랍니다.
                    </BodyText1>
                  </p>

                </div>


                <div className="flx-0 m-flx-col">

                  <div className="w50 m-w100">
                    
                    <div className="bd-tp-bk2 bd-bt-bk2 txt-center">
                      <div className="bd-rt-gr2 m-bd-0 p-7">
                        <BodyText1 className="ft-hv">2유형</BodyText1>
                      </div>
                    </div>

                    <div className="bd-bt-gr2">
                      <div className="bd-rt-gr2 m-bd-0 p-y30 p-x5 ">
                        <OpenImgStyle src={OpneTypeImg1} alt="" className="w100 mg-ct block-box"/>
                      </div>
                    </div>

                    <div className="bd-bt-gr2">
                      <div className="flx-cc bd-rt-gr2 m-bd-0 p-y20 p-x10">
                        <ul className="w100 mx300 list-style--disc lh-16 ">
                          <li className="">출처 표시</li>
                          <li className="">변형 가능</li>
                          <li className="">상업적 이용 불가능</li>
                        </ul>
                      </div>
                    </div>

                    <div className="bd-bt-bk2">
                      <Box1 className="flx-cc bd-rt-gr2 m-bd-0 p-y20 p-x10">
                        <ul className="w100 mx300 list-style--disc lh-16 ">
                          <li className="">메인 &gt; 사람(People) &gt; 오래 살아보면</li>
                          <li className="">메인 &gt; 사람(People) &gt; 최근 살아보면</li>
                          <li className="">메인 &gt; 살면(Life) &gt; 충주로</li>
                          <li className="">메인 &gt; 살면(Life) &gt; 커뮤니티 사업 &gt; 문화꿀단지</li>
                          <li className="">메인 &gt; 살면(Life) &gt; 커뮤니티 사업 &gt; 집밖문지방</li>
                          <li className="">메인 &gt; 살면(Life) &gt; 이벤트</li>
                        </ul>
                      </Box1>
                    </div>
                    
                  </div>









                  <div className="w50 m-w100 m-mg-t40">
                    
                    <div className="bd-tp-bk2 bd-bt-bk2 txt-center">
                      <div className="bd-rt-gr2 m-bd-0 p-7">
                        <BodyText1 className="ft-hv">4유형</BodyText1>
                      </div>
                    </div>

                    <div className="bd-bt-gr2">
                      <div className="bd-rt-gr2 m-bd-0 p-y30 p-x5 ">
                        <OpenImgStyle src={OpneTypeImg2} alt="" className="w100 mg-ct block-box"/>
                      </div>
                    </div>

                    <div className="bd-bt-gr2">
                      <div className="flx-cc bd-rt-gr2 m-bd-0 p-y20 p-x10">
                        <ul className="w100 mx300 list-style--disc lh-16 ">
                          <li className="">출처 표시</li>
                          <li className="">변형 불가능</li>
                          <li className="">상업적 이용 불가능</li>
                        </ul>
                      </div>
                    </div>

                    <div className="bd-bt-bk2 ">
                      <Box1 className="flx-cc bd-rt-gr2 m-bd-0 p-y20 p-x10">
                        <ul className="w100 mx300 list-style--disc lh-16 ">
                          <li className="">메인 &gt; 충주(Local) &gt; 충주에 대해서</li>
                          <li className="">메인 &gt; 충주(Local) &gt; 지역소개</li>
                          <li className="">메인 &gt; 충주(Local) &gt; 문화지도</li>
                          <li className="">메인 &gt; 충주(Culture) &gt; 문화도시 충주</li>
                          <li className="">메인 &gt; 충주(Culture) </li>
                          <li className="">메인 &gt; 충주(Culture) &gt; 우리가 선보이는/함께하는/성장하는/생산하는 도시</li>
                        </ul>
                      </Box1>
                    </div>
                    
                  </div>

                </div>


                <div className="p-y20 p-x10">
                  <p className="txt-center lh-18">
                    <BodyText1 className="">
                      문화체육관광부 고시 제2015-43 제5장 제19조 ‘공공누리 이용약관의 준수’에 따라 이용조건 등에 따른 의무를 준수하여야 합니다. <br></br>
                      공공누리가 부착되지 않은 자료들을 사용하고자 할 경우에는 담당자와 사전에 협의가 필요한 점 유의 부탁 드립니다.
                    </BodyText1>
                  </p>
                </div>


                <div className="m-t10 p-x5">
                  <div className="w100 mx250 m-mxw-0 mg-ct inst-box bd-rd-30 oflow-h">
                    <BorderGradient1 className='bg-cover-area'></BorderGradient1>
                    <a href="https://www.kogl.or.kr/index.do" target="_blank" className='inst-box'>
                      <div className="p-y7 p-x5 txt-center p-x20">
                        <BodyText1 className='ft-b flx-itm-r '>공공누리바로가기</BodyText1>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="p-y20 p-x10 m-t10">
                  <p className="txt-center lh-18 flx-col">
                    <BodyText1 className="txt-keep m-flx-col-cc">
                      <span className="ft-hv">업무상 저작물관리 책임자</span> 
                      <span className="m-hidden"> │ </span> 
                      <span>문화도시기획팀 신재민 팀장 (<a href="tel:043-846-7980" className="txt-underline ft-c1-hover">043-846-7980</a>)</span>
                    </BodyText1>

                    <BodyText1 className="txt-keep m-flx-col-cc m-mg-t20">
                      <span className="ft-hv">업무상 저작물관리 담당자</span> 
                      <span className="m-hidden"> │ </span> 
                      <span>문화도시기획팀 우종욱 주임 (<a href="tel:043-846-7983" className="txt-underline ft-c1-hover">043-846-7983</a>)</span>
                    </BodyText1>

                  </p>
                



                </div>


              </div>
              
            </div>

          </div>
        </div> 
      </Component>

      <div className="inst-box h200p p-t80 m-t40">
        <BgImg1 className=" inst-box">
          <BttomImg src={BgIcon1} alt="" />
        </BgImg1>
        
      </div>

    </div>
  )
}

const Box1= styled.div`
  height: 220px;
`

const BorderGradient1= styled.div`
  border-radius: 30px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(90deg,#e9e223,#92caf0) border-box; /*3*/
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); /*4*/
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
`

const OpenImgStyle= styled.img`
  width: auto;
  height: 54px;
  /* object-fit: contain; */
`


const BttomImg= styled.img`
  position: absolute;
  right: calc(50% - 470px); 
  bottom: 90px;
  z-index: 3;
  width: 115px;
  @media (max-width: 800px) {
    width: 80px;
    right: calc(50% - 140px);
  }
`

const BgImg1= styled.div`
  /* padding-top: 36%; */
  /* margin-top: -10em; */
  position: absolute;
  bottom : 0;
  width: 100%;
  height: 270px;
  background-image: url('${Bg1}');
  background-repeat: no-repeat;
  background-position: calc(50%) -6%;
  /* background-size: 230%; */
  @media (max-width: 800px) {
    height: 180px;

    /* background-position: calc(50% + 280px) 3%; */
  }
`

