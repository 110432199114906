import React from "react";
import styled from "styled-components";
import facebook from "../../../media/images/layouts/fb.webp"
import youtube from "../../../media/images/layouts/youtube.webp"
import instagram from "../../../media/images/layouts/insgta.webp"
import Logo from "../../../media/images/layouts/logo.webp"
import CI from "../../../media/images/layouts/ci.webp"
import CI2 from "../../../media/images/layouts/logo2.svg"
import {Link} from "react-router-dom";

export const ShareFooter = ({ snsLinks }) => {

  return (
    <Component>

      <Contents>

        <FirstLine>
          <FirstLeftText>(재)충주문화관광재단</FirstLeftText>

          <div className="flx-cl">
            <FirstLeftText className="m-r10">
              <Link to={"/private-policy"}>개인정보보호정책</Link>
              <span className="m-x5">|</span>
              <Link to={"/copyright-policy"}>저작권정책</Link>
            </FirstLeftText>

            <SNSbox>
              <Link to={snsLinks['youtube']} target="_blank">
                <SNSImg src={youtube}/>
              </Link>
              <Link to={snsLinks['facebook']} target="_blank">
                <SNSImg src={facebook}/>
              </Link>
              <Link to={snsLinks['instagram']} target="_blank">
                <SNSImg src={instagram}/>
              </Link>
            </SNSbox>
          </div>
          
        </FirstLine>

        <SecondLine>
          <SecondAddress>{`(27388) 충북 충주시 중앙로 128 2층
            사업자등록번호 303-82-07489   Fax 043-851-7983   대표전화 043-723-1356 / 043-723-1346`}
          </SecondAddress>
        </SecondLine>

        <FooterLine/>

        <ThirdLine>
          <ThirdLeftText>Copyright © 재단법인 충주문화관광재단. All Rights Reserved.</ThirdLeftText>
          <ThirdImageBox>
            <ThirdLogoImg src={Logo}/>
            <ThirdCultureImg src={CI2}/>
          </ThirdImageBox>
        </ThirdLine>

      </Contents>

    </Component>
  )
}

const Component = styled.footer`
  background-color: #333132;
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1920px) {
    height: 13.0208vw;
  }

`

const Contents = styled.div`
  width: 1432px;

  @media (max-width: 1920px) {
    width: 74.5833vw;
  }
`

const FirstLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const FirstLeftText = styled.p`
  color: #bcbec0;
  font-family: 'SCDream6', serif;
  font-size: 20px;

  @media (max-width: 1920px) {
    font-size: 1.0417vw;
  }
`

const SNSbox = styled.div`
  width: 155px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1920px) {
    width: 8.0729vw;
  }
`

const SNSImg = styled.img`
  display: block;
  width: 32px;
  height: 32px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 1.6667vw;
    height: 1.6667vw;
  }
`

const SecondLine = styled.div`
  margin-top: 21px;
  margin-bottom: 32px;

  @media (max-width: 1920px) {
    margin-top: 1.0938vw;
    margin-bottom: 1.6667vw;
  }
`

const SecondAddress = styled.p`
  color: #bcbec0;
  white-space: pre-line;
  font-family: 'SCDream2', serif;
  font-size: 16.5px;

  @media (max-width: 1920px) {
    font-size: 0.8594vw;
  }
`

const FooterLine = styled.div`
  border-bottom: 1px solid #959595;
  margin-bottom: 26px;

  @media (max-width: 1920px) {
    margin-top: 1.0938vw;
    margin-bottom: 1.6667vw;
  }
`

const ThirdLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const ThirdLeftText = styled.p`
  color: #bcbec0;
  font-size: 11.5px;
  font-family: 'SCDream2', serif;

  @media (max-width: 1920px) {
    font-size: 0.5989vw;
  }
`

const ThirdImageBox = styled.div`

`

const ThirdLogoImg = styled.img`
  width: 32px;
  height: 36px;

  @media (max-width: 1920px) {
    width: 1.6667vw;
    height: 1.875vw;
  }
`

const ThirdCultureImg = styled.img`
  width: 157px;
  height: 36px;
  margin-left: 19px;

  @media (max-width: 1920px) {
    width: 8.1771vw;
    height: 1.875vw;
    margin-left: 0.9896vw;
  }
`