import {TitleSection} from '../_Include/Title';
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../style/comp/comm';
import {Link} from "react-router-dom";
import BBS from "../../blocks/_bbs";
import {useParams} from "react-router-dom";


// import {info, queries} from "./info";


export default function Notice ({ info, queries }) {
  const params = useParams();
  const { id }= params;
  const data= info.find(o=> o.id == params.id);
  // const data= info[id];
  console.log(info, params)

  console.log(data)
  const { title, path, subject }= data;
 
  return (
    <div className="w100">
      <Component className="zIdx2">    
        <div className="w100 p-x10 zIdx2">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > ${path} > ${title}`} 
              title={`${title}`} 
              subtitle={subject} 
            />
          </div>
        </div>
        <BBS id={id} info={info} infoData={data} queries={queries} porcessType={'tab-side'} viewType={'list'} />
      </Component>
      
    </div>
  )

}