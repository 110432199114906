import React, {useState} from "react";
import styled from "styled-components";
import ResponsiveContainer from "../../../hooks/ResponsiveContainer";
import PeopleImage from "../../../media/images/MainHome/LiveChoongJu/people.webp"
import SouthArrowImage from "../../../media/images/MainHome/LiveChoongJu/SouthArrow.webp"
import {MenData} from "../../../configs/MainHome/MenData";
import {MenCard} from "../../atoms/MainMenu/LiveChoongJu/MenCard";
import {useQuery} from "react-query";
// import {main_live_choongju_list} from "../../../api/MainHome";
import {live_recent} from "../../../api/People";

import { Link, useNavigate } from 'react-router-dom'
import { info, queries } from '../../pages/People/info'

export const LiveChoongJu = () => {

  // const infos= info[1];
  // delete infos.images;
  // const infoData= infos;
  const infoData= {}
  Object.keys(info[1]).map(key=> {
    if( key != 'images' ) infoData[key]= info[1][key];
  })
  const { name, id, url: urls }= infoData;
  const url= `${urls}/detail/`;

  const [MenBoxTwo, setMenBoxTwo] = useState(false);
  const { data: main_live_choongju_data, error, isLoading } = useQuery(queries?.[id]?.key, queries?.[id]?.query);
  // const { data: main_live_choongju_data, error, isLoading } = useQuery('live_recent', live_recent);
  // console.log(main_live_choongju_data)

  const navigate = useNavigate();
  const handleContentClick = (data) => (e)=> {
    console.log(data, infoData)
    navigate(`${url}${data.id}`, { state: { data: data, allData: main_live_choongju_data.articles, selectedCategory: name, infoData  } });
  };

  return (
    <ResponsiveContainer>
      <Container>

        <MainText>충주에 살다</MainText>
        <SubText>충주 시민의 목소리를 통해 매력적인 충주를 느끼보세요.</SubText>
        <Link to={'/people/long-life'}>
          <MoreText>목록보기</MoreText>
        </Link>

        <Contents>
          {/* 캐릭터 인물들 사진 */}
          <PeopleIamge src={PeopleImage}/>

          {/* 인물 카드 데이터 리스트 */}
          <MenCardBox>
            {main_live_choongju_data && main_live_choongju_data.articles.slice(0, 4).map((data) => (
              <MenCard data={data} handleContentClick={handleContentClick}/>
            ))}
          </MenCardBox>
        </Contents>

        {/* 화살표 버튼 클릭 시, 등장하는 인물 카드 6종 */}
        {/*{MenBoxTwo &&*/}
        {/*  <MenCardBoxTwo>*/}
        {/*    {main_live_choongju_data && main_live_choongju_data.slice(4, 10).map((data) => (*/}
        {/*      <MenCard data={data}/>*/}
        {/*    ))}*/}
        {/*  </MenCardBoxTwo>*/}
        {/*}*/}

        {/* 화살표 버튼 */}
        {/*{main_live_choongju_data && main_live_choongju_data.length > 4 &&*/}
        {/*  <SouthImageSection>*/}
        {/*    <SouthArrowImg src={SouthArrowImage} onClick={() => setMenBoxTwo(!MenBoxTwo)} rotate={MenBoxTwo}/>*/}
        {/*  </SouthImageSection>*/}
        {/*}*/}
        <LiveLine/>

      </Container>
    </ResponsiveContainer>
  )
}

const Container = styled.div`
  width: 1434px;

  @media (max-width: 1920px) {
    width: 74.6875vw;
  }
`

const MainText = styled.div`
  font-size: 40px;
  font-family: 'SCDream7', serif;
  margin-bottom: 19px;

  @media (max-width: 1920px) {
    font-size: 2.0833vw;
    margin-bottom: 0.9896vw;
  }
`

const SubText = styled.div`
  font-size: 22px;
  font-family: 'SCDream4', serif;
  margin-bottom: 13px;

  @media (max-width: 1920px) {
    font-size: 1.1458vw;
    margin-bottom: 0.6771vw;
  }
`

const MoreText = styled.text`
  color: #939598;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 1920px) {
    font-size: 0.9375vw;
  }
`

const Contents = styled.div`
  margin-top: 27px;
  display: flex;
  align-items: center;

  @media (max-width: 1920px) {
    margin-top: 1.4063vw;
  }
`

const PeopleIamge = styled.img`
  margin-left: 85px;
  margin-right: 74px;
  width: 313px;
  height: 329px;

  @media (max-width: 1920px) {
    margin-left: 4.4271vw;
    margin-right: 3.8542vw;
    width: 16.3021vw;
    height: 17.1354vw;
  }
`

const MenCardBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 열 */
  grid-template-rows: repeat(2, 1fr); /* 2 행 */
`

const SouthImageSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 5px;

  @media (max-width: 1920px) {
    margin-bottom: 1.0417vw;
    margin-top: 0.2604vw;
  }
`

const SouthArrowImg = styled.img`
  cursor: pointer;
  transform: rotate(${props => (props.rotate ? "180deg" : "0deg")}); // rotate 속성 값에 따라 회전
  transition: transform 0.3s ease-in-out; // 애니메이션 효과 설정
  width: 20px;
  height: 17px;

  @media (max-width: 1920px) {
    width: 1.0417vw;
    height: 0.8854vw;
  }
`;


const LiveLine = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 86px;

  @media (max-width: 1920px) {
    border-bottom: 0.0521vw solid black;
    margin-bottom: 4.4792vw;
  }
`

const MenCardBoxTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 열 */
  grid-template-rows: repeat(2, 1fr); /* 2 행 */
  position: relative;
  left: -13.5px;

  @media (max-width: 1920px) {
    left: -0.7031vw;
  }
`