import React from "react";
import styled from "styled-components";
import whanseung from "../../../media/images/layouts/whanseung.webp"
import ResponsiveContainer from "../../../hooks/ResponsiveContainer";
import SearchIcon from '../../../media/images/layouts/search.webp'
import {useSetRecoilState} from "recoil";
import {ShowCategory, ShowSearchResult} from "../../../recoil/LayoutRecoil";
import {Link} from 'react-router-dom';

export const ShareHeader = () => {
  const setShowCategory = useSetRecoilState(ShowCategory)
  const setShowSearchResult = useSetRecoilState(ShowSearchResult)

  return (
    <Container>

      <LeftContainer>
        <LeftTextContainer onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(false)}>
          <TextKo>충주</TextKo>
          <TextEn>Local</TextEn>
          <MouseHoverLift onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(true)}/>
        </LeftTextContainer>

        <LeftTextContainer onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(false)}>
          <TextKo>살면</TextKo>
          <TextEn>Life</TextEn>
          <MouseHoverLift onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(true)}/>
        </LeftTextContainer>
      </LeftContainer>


      {/* 헤더 메인 로고 이미지 */}
      <Link to="/" >
        <ImageContainer>
          <CenterImage src={whanseung} alt={"whanseung-Image"}/>
        </ImageContainer>
      </Link>


      <RightContainer>
        <RightTextContainer onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(false)}>
          <TextKo>충주</TextKo>
          <TextEn>Culture</TextEn>
          <MouseHoverLift onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(true)}/>
        </RightTextContainer>

        <RightTextContainer onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(false)}>
          <TextKo>사람</TextKo>
          <TextEn>People</TextEn>
          <MouseHoverLift onMouseEnter={() => setShowCategory(true)} onMouseLeave={() => setShowCategory(true)}/>
        </RightTextContainer>
      </RightContainer>


      <SubContainer>
        <SearchImg src={SearchIcon} onClick={() => setShowSearchResult(true)}/>
        <Link to="/consultaionRequest">
          <SubText>
            상담신청
          </SubText>
        </Link>

        <Link to="/notice/notice">
          <SubText>
            공지사항
          </SubText>
        </Link>
      </SubContainer>


    </Container>
  )
}

const Container = styled.header`
  height: 121px;
  width: 1920px;
  display: flex;

  @media (max-width: 1920px) {
    height: 6.3vw;
    width: 100vw;
  }
`

const MouseHoverLift = styled.div`
  height: 10px;
  background-color: transparent;
  z-index: 2;
  width: 100px;
  position: absolute;
  bottom: -6px;

  @media (max-width: 1920px) {
    height: 0.52vw;
    width: 5.21vw;
    bottom: -0.31vw;
  }
`

const LeftContainer = styled.div`
  display: flex;
  margin-left: 524px;

  @media (max-width: 1920px) {
    margin-left: 27.29vw;
  }
`

const RightContainer = styled.div`
  display: flex;
  margin-right: 66px;

  @media (max-width: 1920px) {
    margin-right: 3.44vw;
  }
`


const LeftTextContainer = styled.div`
  display: flex;
  align-items: end;
  padding-bottom: 21px;
  margin-right: 104px;

  cursor: pointer;
  position: relative;

  @media (max-width: 1920px) {
    padding-bottom: 1.09vw;
    margin-right: 5.42vw;
  }
`

const RightTextContainer = styled.div`
  display: flex;
  align-items: end;
  padding-bottom: 21px;
  margin-left: 101px;
  cursor: pointer;
  position: relative;


  @media (max-width: 1920px) {
    padding-bottom: 1.09vw;
    margin-left: 5.26vw;
  }
`

const ImageContainer = styled.div`
  margin-left: 17px;
  margin-right: 13px;

  @media (max-width: 1920px) {
    margin-left: 0.89vw;
    margin-right: 0.68vw;
  }
`

const TextKo = styled.span`
  font-size: 23px;
  font-family: 'SCDream5', serif;
  margin-right: 5px;

  @media (max-width: 1920px) {
    font-size: 1.2vw;
    margin-right: 0.26vw;
  }
`

const TextEn = styled.div`
  font-size: 19px;
  font-family: 'SCDream5', serif;
  color: #959595;

  @media (max-width: 1920px) {
    font-size: 0.99vw;
  }
`

const CenterImage = styled.img`
  width: 67px;
  height: 74px;
  margin-top: 29px;

  @media (max-width: 1920px) {
    width: 3.49vw;
    height: 3.85vw;
    margin-top: 1.51vw;
  }
`

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 72px;

  @media (max-width: 1920px) {
    padding-bottom: 1.04vw;
    margin-top: 3.75vw;
  }
`

const SubText = styled.div`
  margin-right: 15px;
  color: #959595;
  font-family: 'SCDream5', serif;
  cursor: pointer;
  font-size: 19px;

  @media (max-width: 1920px) {
    margin-right: 0.78vw;
    font-size: 0.99vw;
  }
`

const SearchImg = styled.img`
  width: 17px;
  height: 18px;
  margin-right: 12px;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: 0.89vw;
    height: 0.94vw;
    margin-right: 0.63vw;
  }
`