import styled from "styled-components";


const mobile= 800;
export const Component = styled.div`
  width: 100%;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  position: relative;

  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`
export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  
`






export const SectTitle = styled.span`
  font-family: 'SCDream6', serif;
  font-size: 2em;
  @media (max-width: ${mobile}px) {
    font-size: 1.4em;
  }
`
export const SectTitleBox = styled(SectTitle)`
  padding-left: 1em;
  @media (max-width: ${mobile}px) {
    padding-left: 0em;

  }

`
export const TopTitle = styled.div`
  font-family: 'SCDream7', serif;
  font-size: 2.2em;
  @media (max-width: ${mobile}px) {
    font-size: 1.5em;
  }
`

export const Title = styled(TopTitle)`
  /* font-size: 32px;
  font-family: 'SCDream7', serif; */
  text-align: center;
`

export const SubTitle = styled.div`
  font-size: 14px;
  font-family: 'SCDream4', serif;
  text-align: center;
  margin-top: 12px;

  @media (max-width: 800px) {
    font-size: 1em;
  }
`
export const SubTitle0 = styled.span`
  font-family: 'S-CoreDream';
  /* font-weight: 400; */
  font-size: 1.3em;
`

export const SubTitle1 = styled.span`
  font-family: 'S-CoreDream';
  /* font-weight: 400; */
  font-size: 1.4em;
`
export const SubTitle2 = styled.span`
  font-family: 'S-CoreDream';
  /* font-weight: 400; */
  font-size: 1.6em;
  @media (max-width: ${mobile}px) { font-size: 1.3em; }
`

export const SubTitle3 = styled.span`
  font-family: 'S-CoreDream';
  /* font-weight: 400; */
  font-size: 1.8em;
  @media (max-width: ${mobile}px) { font-size: 1.4em; }
`
export const DotTitle1 = styled(SubTitle2)`
  &::before {
    content: '';
    position: absolute;
    top: 50%; left: 0;
    margin-top: -2px;
    width: 4px; height: 4px;
    background-color: #000;
  }
`

export const BodyText1 = styled.span`
  font-family: 'S-CoreDream';
  font-size: 1em;
  @media (max-width: ${mobile}px) {
    font-size: .9em;
  }
`
export const DotBodyText1 = styled(BodyText1)`
  &::before {
    content: '';
    position: absolute;
    top: 50%; left: 0;
    margin-top: -1px;
    width: 2px; height: 2px;
    border-radius: 2px;
    background-color: #000;
  }
`



export const BodyText2 = styled.span`
  font-family: 'S-CoreDream';
  font-size: 1.35em;
  @media (max-width: ${mobile}px) { font-size: 1.1em; }
`

export const BodyText3 = styled.span`
  font-family: 'S-CoreDream';
  font-size: 1.2em;
  @media (max-width: ${mobile}px) { font-size: 1em; }
`

export const TinyText = styled.span`
  font-family: 'S-CoreDream';
  font-size: .9em;
  @media (max-width: ${mobile}px) {
    font-size: .8em;
  }
`
export const TinyText1 = styled.span`
  font-family: 'S-CoreDream';
  font-size: .9em;
  line-height: 1.1;
  @media (max-width: ${mobile}px) {
    font-size: .8em;
  }
`
export const TinyText2 = styled.span`
  font-family: 'S-CoreDream';
  font-size: .8em;
  line-height: 1.1;
  @media (max-width: ${mobile}px) {
    font-size: .7em;
  }
`

export const Divider = styled.div`
  /* margin-bottom: 0; */
  border-width: 0px 0px 3px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 1);
`

export const Divider2 = styled.div`
  border-width: 0px 0px 2px;
  border-style: solid;
  border-color: #d1ceca;
`

export const Divider1_1 = styled.div`
  width: 32px;
  border-width: 0px 0px 4px;
  border-style: solid;
  border-color: #d1d3d4;
`

export const Divider1 = styled(Divider1_1)`
  margin-left: auto;
  margin-right: auto;
`