
import styled from "styled-components";
// import { BoardTopImg } from '../_Include/Title';
import {
  notice_notice_list, 
  notice_recruit_list,
  notice_service_list,
  notice_informations_list
} from "../../../api/Notice";

import TopIcon1 from "../../../media/images/Notice/icon-img-1.svg";
import TopIcon2 from "../../../media/images/Comm/top-icon-4.svg";


const eventFilter= ['전체', '접수중', '접수마감']
const searchFilter= [
  { key: '전체', value: 'all' }, 
  { key: '제목', value: 'subject' }, 
  { key: '내용', value: 'content' }, 
]

const TopImgSectStyle= styled.div`
  width: 100%;
  max-width: 140px;
  position: relative;
  bottom: -80px;
  @media (max-width: 800px) {
    max-width: 80px;
    bottom: -20px;
  }
`

const TopImgWrapStyle= styled.div`
  width: 100%;
  position: relative;
  padding-top: 130%;
  @media (max-width: 800px) {
    /* padding-top: 100%; */
  }
`

const TopIcon1Style= styled.img`
  position: absolute;
  max-width: 130px;
  bottom: 0px; left: 40px;
  width: 100%;
`
const TopIcon2Style= styled.img`
  width: 100%;
  /* max-width: 150px; */
  position: absolute;
  bottom: 20px;
`

const TopImg= ()=> <TopImgSectStyle className="">
  <TopImgWrapStyle className="">
    <TopIcon2Style src={TopIcon2} alt="" />
    <TopIcon1Style src={TopIcon1} alt="" />
  </TopImgWrapStyle>
</TopImgSectStyle>
export const info = [
  {
    id: 'notice',
    key: 'notice',
    name: '공지사항',
    // query: community_business,
    title: '공지사항',
    path: '공지사항',
    subject: '문화도시 충주의 새로운 소식과 정보를 확인해 보세요!',
    url: '/notice/notice',
    filter_1: eventFilter,
    filter_2: searchFilter,
    disableBadge: true,
    images: [<TopImg />]
  },
  {
    id: 'recruit',
    key: 'recruit',
    name: '채용',
    title: '채용',
    path: '공지사항',
    subject: '문화도시 충주의 새로운 소식과 정보를 확인해 보세요!',
    url: '/notice/recruit',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<TopImg />]
  },
  {
    id: 'service',
    key: 'service',
    name: '용역',
    title: '용역',
    path: '공지사항',
    subject: '문화도시 충주의 새로운 소식과 정보를 확인해 보세요!',
    url: '/notice/service',
    filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<TopImg />]
  },
  {
    id: 'informations',
    key: 'informations',
    name: '자료실',
    title: '자료실',
    path: '공지사항',
    subject: '문화도시 충주의 새로운 소식과 정보를 확인해 보세요!',
    url: '/notice/informations',
    // filter_1: eventFilter,
    filter_2: searchFilter,
    images: [<TopImg />]
  },
]

export const queries= {
  ['notice']: {
    key: 'notice_notice_list',
    query: notice_notice_list
  },
  ['recruit']: {
    key: 'notice_recruit_list',
    query: notice_recruit_list
  },
  ['service']: {
    key: 'notice_service_list',
    query: notice_service_list
  },
  ['informations']: {
    key: 'notice_informations_list',
    query: notice_informations_list
  },
  
}