import React, {useEffect, useState, useMemo, useCallback} from "react";
import styled from "styled-components";
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText, TinyText1, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../../style/comp/comm';
import { format, parse, startOfWeek, getDay, toDate, parseISO, set, isWithinInterval } from "date-fns";
import {ko} from "date-fns/locale";
import NoDataIcon from "../../../../media/images/Comm/no-data.svg";
import { Link, useSearchParams } from 'react-router-dom';

export default function EventList ({ info, data, eventData, currentDate, bgcolor, setViewData, searchParams, setSearchParams, params }) {
  const categoryParam= searchParams.get('category')
  
  const { key, url, images, filter_1, filter_2, name }= info;
  const [showCategory, setShowCategory] = useState(false);
  const [categoryValue, setCategoryValue] = useState(categoryParam ? filter_2.find(o=> o.value == categoryParam) : filter_2[0]);

  const handleSelection = (value) => {
    setCategoryValue(value)
    setShowCategory(false);
    setSearchParams(searchParams=> ({
      ...params,
      category: value.value,
    }))
    setViewData(list)
  };

  const list= useMemo(()=> {
    // console.log(currentDate, eventData)
    /* const today = new Date();
    const start = set(today, {
      hours: 9,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    });
    const end = set(today, {
      hours: 21,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    }); */

    /* const inRange = isWithinInterval(today, {
      start,
      end
    }); */

    
    const list= eventData?.filter(d=> {
      const {resource: {
        start_date, end_date, category
      }}= d;
      const start= toDate(start_date)
      const end= toDate(end_date)
      const inRange = isWithinInterval(currentDate, {
        start,
        end
      });
      // console.log(start,end,inRange)
      const categoryMatch= categoryValue.value == 'all' || categoryValue.value == category;
      return inRange && categoryMatch;
    })
    // setViewData(list)
    return list;
  }, [currentDate, categoryValue])

  useEffect(()=> {
    console.log(categoryValue, list)
    // setViewData(list)
  }, [categoryValue])

  return (
    <WrapperStyle className="flx-col w100">
      <div className="flx-cl txt-nowrap zIdx3">
        <div className="flx-0 inst-box w100">
          <DropdownInput 
            className=""
            onClick={() => setShowCategory(!showCategory)}
            readOnly value={categoryValue.key} 
            // Medge={userAgent.includes('windows')}
          />
          {showCategory && (
            <>
            {
              filter_2 && 
              <DropdownListSection className="p-y2">
                {
                  filter_2.map(f=> {
                    return (
                      <DropdownListText key={f.value} onClick={() => handleSelection(f)}>
                        {f.key}
                      </DropdownListText>
                    )
                  })
                }
              </DropdownListSection>
            }
            </>
            
          )}
          <div className="bd-bk2 w100 flx-cl bg-clr-wh p-x5">
            <TinyText className="ft-md">
              {currentDate && format(currentDate,'yyyy.MM.dd (EEEE)',{locale: ko})}의 일정
            </TinyText>
          </div>
        </div>
      </div>

      <div className="bg-clr-wh inst-box flx-itm-r oflow-y-auto">
        {
          (list && list.length > 0) && 
          <ul className="">
            {
              list?.map(val=> {
                const { resource: { id, category, subject, start_date, end_date, time_text } }= val;
                const start= format(start_date, 'yyyy-MM-dd')
                const end= format(end_date, 'yyyy-MM-dd')
                return (
                  <ListItemStyle key={id} className="p-y10 p-x10">
                    <Link to={`/life/events/detail/${id}`}>
                      <div className="">
                        <p className="">
                          <BodyText1 className="ft-b" style={{ color: bgcolor[category]}}>
                            [{category}]
                          </BodyText1>
                        </p>
                        <p className="txt-nowrap txt-ellip m-t3" title={subject} >
                          <BodyText1 className="ft-b" >
                            {subject}
                          </BodyText1>
                        </p>
                      </div>

                      <div className="bd-tp-gr m-t7 p-t7">
                        <div className="">
                          <TinyText className="">기간 : </TinyText>
                          <TinyText className="">{start}~{end}</TinyText>
                        </div>
                        <div className="">
                          <TinyText className="">시간 : </TinyText>
                          <TinyText className="">{time_text}</TinyText>
                        </div>
                      </div>
                    </Link>
                  </ListItemStyle>
                )
              })
            }
          </ul>
        }
        
        { 
          !list || list.length == 0 && 
          <div className="w100">
            <div className="flx-cc w100 p-y40">
              <img src={NoDataIcon} alt="" className="icon-img-m" />
              <BodyText1>일정이 없습니다.</BodyText1>
            </div>
            
          </div>
        }
      </div>
    </WrapperStyle>
  )
}
const ListItemStyle = styled.li`
  border-bottom: 2px solid #f5f5f1;
`

const WrapperStyle = styled.div`
  /* padding-top: 3.2em; */
  padding-top: 44.8px;
  margin-left: 3px;
`
const DropdownInput = styled.input`
  border: none;
  width: 85px;
  background-color: black;
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: .9em;
  padding: .63em 5px;
  @media (max-width: 800px) {
    font-size: .8em;
  }
`

const DropdownListSection = styled.div`
  position: absolute;
  bottom: -1px;
  background-color: black;
  width: 86px;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  transform: translateY(100%);
`

const DropdownListText = styled.div`
  cursor: pointer;
  margin: 4px 0;
  color: white;
  font-size: .9em;
  @media (max-width: 800px) {
    font-size: .8em;
  }
`