import useDebounce from "../../../support/useDebounce";
import {total_search_keyword} from "../../../api/TotalSearch";
import {Component, Container, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, BodyText3, TinyText, TinyText2, Divider, SubTitle3, DotTitle1,Divider2, DotBodyText1}from '../../../style/comp/comm';
import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";
import qs from 'qs';
import {useQuery} from "react-query";
import { useEffect, useState } from "react";
import InputIconImage from '../../../media/images/TotalSearch/totalSearchIcon.webp'
import {useRecoilValue} from "recoil";
import {totalSearchInfoState} from '../../../recoil/LayoutRecoil'

export function InputSection ({ keyword, hashtag, handlerInputChange, handleSearch, placeholder, inpuRef, ...props }) {
  console.log(hashtag)
  return (
    <div className="flx-cl">
      <InputSectionStyle
        ref={inpuRef}
        value={hashtag ? `${hashtag}` : keyword}
        // readOnly={hashtag ? true : false}
        onChange={handlerInputChange}
        onKeyUp={(e) => e.key === 'Enter' && handleSearch()}
        placeholder={ placeholder || ''}
        $hashtag={hashtag}
        {...props}
      ></InputSectionStyle>
      <img onClick={e=> handleSearch()} src={InputIconImage} alt="" className="icon-img-s m-l5 cursor-point m-icon-img-s" />
    </div>
  )
}
const InputSectionStyle = styled.input`
  z-index: 22;
  font-size: 14px;
  width: 100%;
  border: 1px solid #000;
  padding: 7px 10px;
  color: ${(props)=> props.$hashtag ? `#ddd` : `#000`};
  @media (max-width: 800px) {
    font-size: 12px;
    &::placeholder {
      font-size: 9.4px;
      line-height: .8;
      position: relative;
      /* top: -2px; */
    }
  }
`


export function AutoComplete ({ keyword, open, setOpen, callback }) {
  const totalSearchInfo= useRecoilValue(totalSearchInfoState);
  const navigate = useNavigate();

  const debouncedValue= useDebounce(keyword, 600);
  const { data, error, isLoading: searchKeywordLoading } = useQuery(['total_search_keyword', {
    search: debouncedValue ? qs.stringify({search_text: debouncedValue}) : null
  }], total_search_keyword);

  const [viewData, setViewData]= useState('');
  const [viewTag, setViewTag]= useState('');
  useEffect(()=> {
    if( data && data?.['search_text']?.length > 0 ) {
      console.log(data, totalSearchInfo);

      const viewDatas= data['search_text'].filter(d=> {
        const { id }= d;
        const filter= totalSearchInfo.find(info=> info.key == id);
        console.log(filter, id)
        return filter ? true : false;
      })    

      setViewData(viewDatas)
      setOpen(true)
    }

    if( data && data?.['search_hashtag']?.length > 0 ) {
      /* const viewTags= data['search_hashtag'].filter(d=> {
        const { id }= d;
        const filter= totalSearchInfo.find(info=> info.key == id);
        console.log(filter, id)
        return filter ? true : false;
      }) */

      setViewTag(data['search_hashtag'])
      setOpen(true)
    }
  }, [data]);

  
  
  const handleLink= ({keyword, hashtag})=> (e)=> {
    const params={
      keyword, hashtag
    }
    console.log(qs.stringify(params));
    navigate(`/totalSearch?${qs.stringify(params)}`);
    callback && callback(keyword, hashtag)
  }
  return (
    open && 
    <ResultSection
      // Medge={userAgent.includes('windows')}
    >
      <ResultContents>
        <div className="flx-cr">
          <TinyText 
            onClick={e=> setOpen(false)}
            className="cursor-point txt-underline ft-c1-hover">닫기</TinyText>
        </div>
        {
          viewData && viewData?.length > 0 && viewData.slice(0, 10).map((data, i) => {
            const { id, value }= data;
            return (
              <div key={`${id}_${i}`} onClick={handleLink({keyword: value})} className="cursor-point">
                <TinyText className="ft-gr1 ft-c1-hover">{value}</TinyText>
              </div>
            )
          })
        }
        {
          viewTag && viewTag?.length > 0 && 
          <ul className="m-t10 flx-w">
          {
            viewTag.slice(0, 10).map((data, i) => {
              // const { id, value }= data;
              return (
                <li key={`${data}_${i}`} onClick={handleLink({hashtag: data})} className="cursor-point m-r4">
                  <TinyText className="ft-c1 ft-c1-hover">#{data}</TinyText>
                </li>
              )
            })
          }
          </ul>
        }
      </ResultContents>
    </ResultSection>
  )
}

const ResultSection = styled.div`
  width: calc(100% - 25px);
  position: absolute;
  top: 37px; left: 0;
  z-index: 100;
  background-color: white;
  @media (max-width: 800px) {
  }
`

const ResultContents = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  @media (max-width: 800px) {

  }
`