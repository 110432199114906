import React from "react";
import styled from "styled-components";
import {TitleSection} from '../../_Include/Title';
import TopIcon from "../../../../media/images/Comm/top-icon.svg";
import About1 from "../../../../media/images/About/about-1.jpg";
import {Component, SectTitle, SectTitleBox, SubTitle1, BodyText1, BodyText2, TinyText1, Divider, SubTitle3, Divider1} from '../../../../style/comp/comm';
import Icon1 from "../../../../media/images/About/about-icon-1.svg";
import Icon2 from "../../../../media/images/About/about-icon-2.svg";
import Icon3 from "../../../../media/images/About/about-icon-3.svg";
import Icon4 from "../../../../media/images/About/about-icon-4.svg";
import Icon5 from "../../../../media/images/About/about-icon-5.svg";
import About2 from "../../../../media/images/About/about-2.png";
import About3 from "../../../../media/images/About/about-3.png";
import About4 from "../../../../media/images/About/about-4.png";
import About5 from "../../../../media/images/About/about-5-2.png";
import IconCheck from "../../../../media/images/Comm/icon-check.png";


const title= '충주에 대해서';

const diagram= [
  { 
    id: 1, 
    icon: Icon1, 
    color: 'gr',
    text: '인구', 
    subText: <><BodyText2 className="ft-hv">212천명</BodyText2></> 
  },
  { 
    id: 2, 
    icon: Icon2, 
    text: '면적', 
    color: 'dg',
    subText: <>
      <BodyText2 className="ft-hv">984km²</BodyText2>
      <TinyText1>(서울의 1.6배)</TinyText1>
    </> 
  },
  { 
    id: 3, 
    icon: Icon3, 
    color: 'gr',
    text: '행정구역', 
    subText: <><BodyText2 className="ft-hv">1읍12면12동 </BodyText2></> 
  },
  { 
    id: 4, 
    icon: Icon4, 
    color: 'dg',
    text: '공무원',
    subText: <><BodyText2 className="ft-hv">1,490명</BodyText2></> 
    },
  { 
    id: 5, 
    icon: Icon5, 
    color: 'gr',
    text: '예산규모', 
    subText: <>
      <BodyText2 className="ft-hv">1조2437억원</BodyText2>
      <TinyText1>*재정자립도 17.44%</TinyText1>
    </> 
  },
]
export default function About () {
  // const { policy }= routeInfo;
  // console.log(policy)
  return (
    <div className="w100">
      <Component>    
        <div className="w100 p-x10">
          <div className="inst-box">
            <TitleSection 
              path={`홈 > 충주(Local) > ${title}`} 
              title={title} 
              subtitle={'충주 귀농/귀촌에 대해 무엇이든 물어보세요!'} 
            />

            <div className="w100 inst-box m-t60">
              <IconImg src={TopIcon} alt="" className="" />

              <div className="img35 image-r bd-rd-20" style={{backgroundImage:`url(${About1})`}} >
                {/* <img src={About1} alt="" className="w100"/> */}
              </div>

              <div className="m-t30">
                <SectTitleBox>기본현황</SectTitleBox>
                <div className="p-x40">
                  <ul className="flx-wc grid-20">
                    {
                      diagram.map( (d)=> {
                        const { id, icon, text, subText, color }= d;
                        return (
                          <li key={id} className="w20-g20 m-w33-g10 m48-w50-g5 mg-20 m-mg-10 m48-mg-5 m-mx150">
                            <CircleList className={`${color}`}>
                              <div className="circle-in">
                                <CircleIcon src={icon} alt="" />
                                <p className="ft3 m-ft2 ft-b m-t15 m-mg-t10" style={{marginBottom: '-15px'}}>{text}</p>
                              </div>
                            </CircleList>
                            <div className="flx-col-cc m-t10">{subText}</div>
                            
                          </li>
                        )
                      })
                    }
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="m-t30">
            <Divider/>
            </div>
            <div className="m-t30">
              <SectTitleBox>지역특성과 발전잠재력</SectTitleBox>
              <div className="flx-0 m-flx-col oflow-h m-t20">
                <Box1 className="w50 flx-0" >
                  <img src={About2} alt="" className="w100" />
                </Box1>
                <Box2 className="w50 m-w100 bg-clr-wh p-x20 p-y20 flx-cc">
                  <div className="">
                    <div className="flx-col">
                      <SubTitle1 className="ft-md">4통 8달 고속교통망 개통과 함께 </SubTitle1>
                      <SubTitle1 className="ft-hv">한반도 중심도시를 향한 도약발전 </SubTitle1>
                    </div>
                    <div className="flx-col m-t10">
                      <BodyText1 className="p-y1">❶ 국토 중심부, 전국 어디서나 접근 용이 </BodyText1>
                      <BodyText1 className="p-y1">❷ 열십자형 고속교통망 구축, 철도교통의 중심지</BodyText1>
                      <BodyText1 className="p-y1">❸ 접근성·저렴한지가·인적자원등 최적의 산업입지</BodyText1>
                      <BodyText1 className="p-y1">❹ 산악·온천·호반 등 천혜의 관광자원 보유</BodyText1>
                      <BodyText1 className="p-y1">❺ 삼국문화 융합으로 태동된 "중원문화"의 중심</BodyText1>
                    </div>
                  </div>
                </Box2>
              </div>

              <div className="bg-clr-wh p-30 m-pd-20 m-t20">
                
                <div className="m-t40 m-mg-t20">
                  <p className="txt-center">
                    <SubTitle3 className="ft-eb">19세기 이전(국토중심 기능)</SubTitle3>
                  </p>
                  <div className="w100 mx600 mg-ct m-mg-t20">
                    <img src={About3} alt="" className="w100" />
                  </div>

                  <div className="flx-col-cc">
                    <div className="txt-center">
                      <CheckBox1 src={IconCheck} alt="" />
                      <BodyText2 className="ft-md txt-center">삼국이 각축을 벌이던 전략요충지</BodyText2>
                    </div>
                    <div className="txt-center">
                      <CheckBox1 src={IconCheck} alt="" />
                      <BodyText2 className="ft-md txt-center">한강 뱃길과 육로교통의 길목으로 충청의 행정·문화·경제 중심 </BodyText2>
                    </div>
                  </div>
                </div>
                
                <div className="m-t40 m-mg-t20">
                  <Divider1/>
                </div>

                <div className="m-t40 m-mg-t20">
                  <p className="txt-center">
                    <SubTitle3 className="ft-eb">20세기 동안(침체와 낙후) </SubTitle3>
                  </p>

                  <div className="flx-col-cc m-t15">
                    <div className="txt-center">
                      <CheckBox1 src={IconCheck} alt="" />
                      <BodyText2 className="ft-md txt-center">경부선 철도 등 국가개발축이 비켜감으로써 교통의 사각지대 전략</BodyText2>
                    </div>
                    <div className="txt-center">
                      <CheckBox1 src={IconCheck} alt="" />
                      <BodyText2 className="ft-md txt-center">100여년 전 충북도청의 청주 이전(1908년) </BodyText2>
                    </div>
                  </div>
                </div>


                <div className="m-t40 m-mg-t20">
                  <Divider1/>
                </div>

                <div className="m-t40 m-mg-t20">
                  <p className="txt-center">
                    <SubTitle3 className="ft-eb">21세기 현재(충주번영을 향한 전환기) </SubTitle3>
                  </p>

                  <div className="flx-col-cc m-t15">
                    <div className="txt-center">
                      <CheckBox1 src={IconCheck} alt="" />
                      <BodyText2 className="ft-md txt-center">고속교통망, 산업단지 등 지역발전의 기틀이 완비</BodyText2>
                    </div>
                    <div className="txt-center">
                      <CheckBox1 src={IconCheck} alt="" />
                      <BodyText2 className="ft-md txt-center">서충주 신도시 조성 등 시운용성의 호기 </BodyText2>
                    </div>
                  </div>
                </div>
                

                <div className="p-b40 m-pd-b20 inst-box">
                  <BgImg1 src={About4} />
                </div>

                
              </div>

              <div className="p-30 m-pd-20 m-t20">
                <div className="m-t40 m-mg-t20">
                  <p className="txt-center">
                    <SectTitle>신 국가발전축 내륙첨단산업벨트의 중심 </SectTitle>
                  </p>
                  
                  <div className="m-t20">
                    <p className="w100 mx600 mg-ct txt-center">
                      <BodyText2 className="ft-md ">
                        신 국가발전축 내륙첨단산업벨트의 중심 : 강원-충청-호남을 연결하는<br></br> 내륙첨단산업벨트가 국가 5대 초광역 발전축으로 선정
                      </BodyText2>
                    </p>
                    <p className="w100 mx800 mg-ct txt-center">
                    <BodyText2 className="ft-md ">
                        충청고속화도로, 충주 기업도시 등 발전축 핵심산업 선정, 내륙산업벨트의 거점으로 발도움 전망
                      </BodyText2>
                    </p>

                  </div>
                  

                  
                </div>
              </div>
              
            </div>

          </div>
        </div> 
      </Component>

      <div className="w100 mg-ct" style={{maxWidth: '3000px'}}>
        <BgImg2 alt="" className="w100 mg-ct" />
      </div>
      
      {/* { policy && policy } */}
    </div>
  )
}

const BgImg2= styled.div`
  margin-top: -7%;
  background-image: url('${About5}');
  background-size: 140% auto;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 68%;
  @media (max-width: 800px) {
    padding-top: 98%;
    background-size: 220% auto;
    background-position: 53% center;
  }
`

const BgImg1= styled.img`
  width: 150px;
  position: absolute;
  bottom: -90px; right: 40px;
  @media (max-width: 800px) {
    display: none;
  }
`


const CheckBox1= styled.img`
  width: 30px;
  @media (max-width: 800px) {
  width: 20px;

  }
`
const Box1= styled.div`
  /* width: calc(50% + 36px); */
  z-index: 1;
  width: calc(53.5%);
  @media (max-width: 800px) {
    width: calc(107%);
  }
`
const Box2= styled.div`
  /* margin-left: -36px; */
  margin-left: -3.3%;
  @media (max-width: 800px) {
    margin-left: 0;
  }

`

const IconImg = styled.img`
  position: absolute;
  top: -120px; right: 0;
  width: 212px;
  @media (max-width: 800px) {
    width: 102px; top: -8%;
  }
`
const CircleList = styled.div`
  
  padding-top: 100%;
  border-radius: 1000px;
  position: relative;
  overflow: hidden;
  &.gr { background-image: linear-gradient(to right, #eae9c5, #b6c6d0)}
  &.dg { background-image: linear-gradient(to right, #6d6e71, #6d6e71)}
  & .circle-in {
    display: flex;
    flex-direction: column;
    align-items: center; justify-content: center;
    position: absolute;
    top: 2px; left: 2px; bottom: 2px; right: 2px;
    background-color: #fff;
    border-radius: 1000px;
  }
  @media (max-width: 800px) {
    /* max-width: 150px; */
  }
`
const CircleIcon = styled.img`
  width: 100%;
  max-width: 48px;
  margin: 0 auto;
`