import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import SearchIcon from '../../../media/images/layouts/searchIcon.webp'
import CloseIcon from '../../../media/images/layouts/closeIcon.webp'
import {useSetRecoilState} from "recoil";
import {ShowSearchResult, TotalSearchResultAtom} from "../../../recoil/LayoutRecoil";
import {Link, useNavigate} from "react-router-dom";
import {API_KEY, API_URL} from "../../../api/global";
import {useQuery} from "react-query";
import {total_search_keyword} from "../../../api/TotalSearch";
import useDebounce from "../../../support/useDebounce";
import qs from 'qs';

import {InputSection, AutoComplete} from '../TotalSearch/TotalSearchInput';


export function ShareSearch () {
  const [inputText, setInputText] = React.useState('');
  const debouncedValue= useDebounce(inputText, 400);

  const { data, error, isLoading } = useQuery(['total_search_keyword', {
    search: debouncedValue ? qs.stringify({search_text: debouncedValue}) : null
  }], total_search_keyword);
  // console.log(data)


  const setShowSearchResult = useSetRecoilState(ShowSearchResult)
  // const userAgent = window.navigator.userAgent.toLowerCase();
  const navigate = useNavigate();
  

  const handleInputChange =  (event) => {
    setInputText(event.target.value);
  }

  const handleSearch = () => {
    navigate(`/totalSearch?keyword=${inputText}`);
  }

  const handleSubmit= ()=> {
    setShowSearchResult(false)
    setAutoCompleteOpen(false)
    handleSearch();
  }

  const [autoCompleteOpen, setAutoCompleteOpen]= useState(false)
  const input = useRef();

  useEffect(()=> {
    input.current.focus()
  }, [])
  return (
    <Componenat>
      <div className="w100 mx600 mg-ct inst-box p-r40 m-pd-s0 m-flx-col m-pd-h40">
        <CloseImg src={CloseIcon} onClick={() => setShowSearchResult(false)}/>
        <div className="w100 mx600 mg-ct inst-box ">
        <InputSection
          inpuRef={input}
          test={'test'}
          keyword={inputText} 
          handlerInputChange={handleInputChange} 
          handleSearch={handleSubmit}
          placeholder={'검색어를 자세히 입력할 수록 정확한 정보를 찾으실 수 있습니다.'} 
        />
        {
          inputText && 
          <AutoComplete 
            keyword={inputText} open={autoCompleteOpen} setOpen={setAutoCompleteOpen}
            callback={(keyword, hastag)=> {
              setShowSearchResult(false)
              setAutoCompleteOpen(false)
            }}
          />
        }
        </div>
      </div>
    </Componenat>
  )
}

const Componenat = styled.div`
  width: 100%;
  height: 189px;
  background-color: white;
  z-index: 50;
  position: absolute;
  top: 121px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  @media (max-width: 1920px) {
    top: 6.3vw;
  }
  @media (max-width: 800px) {
    top: 13.3vw;
    height: auto;
  }

`

const CloseImg = styled.img`
  position: absolute;
  top: 50%; right: 0;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
  @media (max-width: 800px) {
    top: 10px;
    transform: translateY(0%);
  }
`

