import React, {useEffect, useState, useCallback} from "react";


export default function useSearch ({ query, callback }) {

  const [searchValue, setSearchValue]= useState('');
  
  const handlerSearchEnter= (e)=> {
    if (e.keyCode === 13) {
      handlerSearchSubmit()
    }
  }
  const handlerSearchSubmit= (e)=> {
    console.log(query)
    callback(searchValue)
  }
  const handlerSearchValue= (e)=> {
    const { value }= e.target;
    setSearchValue(value)
  }
  return { searchValue, setSearchValue, handlerSearchEnter, handlerSearchSubmit, handlerSearchValue }
}