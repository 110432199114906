import {API_KEY, API_URL} from "./global";

export const consult_send_form = async(data) => {
  const response = await fetch(API_URL + 'api/consultant/register', {
    method: 'POST',
    headers: {
      'X-CCFCJ-API-KEY': `${API_KEY}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Credentials": "true",
    },
    body: data
  })

  if (!response.ok) {
    throw new Error('consult_send_form Error')
  }

  return response.json();
}

